import { PureComponent } from 'react';
import styled from 'styled-components';

const LogoPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PreviewImage = styled.img<{ maxWidth: number; maxHeight: number }>`
  display: flex;
  max-width: ${p => p.maxWidth}px;
  max-height: ${p => p.maxHeight}px;
  margin-left: 4px;
`;

interface LogoPreviewProps {
  url: string;
  maxWidth: number;
  maxHeight: number;
}

class LogoPreview extends PureComponent<LogoPreviewProps> {
  render() {
    const { url, ...restProps } = this.props;
    return (
      <LogoPreviewWrapper>
        {url && <PreviewImage src={url} {...restProps} />}
      </LogoPreviewWrapper>
    );
  }
}

export default LogoPreview;
