import { connect } from 'react-redux';
import { RootState } from 'shared/state';
import { selectSite, selectPages } from 'shared/state/ducks/sites';
import SiteRoot from './SiteRoot';

interface SiteRootContainerProps {
  siteId: number;
  basename: string;
}

const mapStateToProps = (
  state: RootState,
  ownProps: SiteRootContainerProps
) => ({
  site: selectSite(ownProps.siteId)(state),
  pages: selectPages(ownProps.siteId)(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SiteRoot);
