import axios from 'shared/util/axios';

const getTenancies = () => {
  return axios
    .get<Array<Tenancies>>('site-builder/tenancies')
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const getUserTenancy = () => {
  return axios
    .get<string>('site-builder/userTenancyByUuid')
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

export { getTenancies, getUserTenancy };
