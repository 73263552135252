import { connect } from 'react-redux';
import { RootState, Dispatch } from 'shared/state';
import { selectUser } from 'shared/state/misc/oidc';
import AuthenticatedRoute from './AuthenticatedRoute';
import { initiateLogin } from 'shared/state/ducks/session';
import { selectCurrentPage } from 'shared/state/ducks/sites';

interface Props {
  siteId: number;
  pageId: number;
}

const mapStateToProps = (state: RootState, ownProps: Props) => ({
  user: selectUser()(state),
  logoutPending: state.session && state.session.logoutPending,
  loginPending: state.session && state.session.loginPending,
  impersonationRequested: state.session && state.session.impersonationRequested,
  endImpersonationRequested: state.session && state.session.endImpersonation,
  currentPage: selectCurrentPage(ownProps.siteId, ownProps.pageId)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initiateLogin: () => {
    dispatch(initiateLogin());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute);
