import axios from 'shared/util/axios';

export interface IDP {
  alias: string;
  displayName: string;
}

const getIdps = () => {
  return axios
    .get<Array<IDP>>('site-builder/v2/idx/idps')
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

export { getIdps };
