import { connect } from 'react-redux';
import { RootState, Dispatch } from 'shared/state';
import {
  initiateSilentSignin,
  silentSigninFulfilled,
} from 'shared/state/ducks/session';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import { selectUser } from 'shared/state/misc/oidc';

const mapStateToProps = (state: RootState) => ({
  user: selectUser()(state),
  impersonationRequested: state.session && state.session.impersonationRequested,
  endImpersonationRequested: state.session && state.session.endImpersonation,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initiate: () => {
    dispatch(initiateSilentSignin());
  },
  fulfill: () => {
    dispatch(silentSigninFulfilled());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnauthenticatedRoute);
