import { PureComponent } from 'react';

import './Feedback-Styles.css';
import Trigger from './Trigger';
import { User } from 'shared/state/misc/oidc';

export interface FeedBackProps {
  value: FeedbackFin;
  siteId: number;
  siteName: string;
  user?: User;
}
class Feedback extends PureComponent<FeedBackProps> {
  render() {
    const { value, siteId, siteName, user } = this.props;

    const feedBackTypesList = ['Issue', 'Idea', 'Other'];

    const feedbackContent = (
      <>
        {value?.feedbackEnable && (
          <div className="frf-feedback-container" style={{ zIndex: 100000 }}>
            <Trigger
              feedbackTypes={feedBackTypesList}
              buttonColor={
                value.feedbackButtonColor
                  ? value.feedbackButtonColor
                  : '#6a35ff'
              }
              textColor={value.fontColor ? value.fontColor : '#000000'}
              hoverBorderColor={
                value.feedbackButtonColor
                  ? value.feedbackButtonColor
                  : '#6a35ff'
              }
              fontFamily={value.fontFamily}
              issueFeedBackTypeEmailList={value.issueFeedBackTypeEmailList}
              ideaFeedbackTypeEmailList={value.ideaFeedbackTypeEmailList}
              bugFeedbackTypeEmailList={value.bugFeedbackTypeEmailList}
              feedbackStyle={value.feedbackStyle}
              formfields={value.formFields}
              postSubmitButtonMsg={'Thanks for feedback'}
              submitButtonMsg={'Send Feedback'}
              zIndex={100000}
              siteId={siteId}
              siteName={siteName}
            />
          </div>
        )}
      </>
    );
    if (value?.feedbackAuthentication) {
      return user && feedbackContent;
    } else {
      return feedbackContent;
    }
  }
}

export default Feedback;
