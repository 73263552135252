import axios from 'shared/util/axios';
import { removeExistingData } from 'shared/util/analyticsPersistence';
const exportEvent = async (event: any) => {
  try {
    const response = await axios.post(
      `/analytics-rest-client/api/v1/messages`,
      { messages: [event] },
      { headers: { 'Content-Type': 'application/json' } }
    );

    if (response.status === 200) {
      console.log('Event sent successfully');
      removeExistingData();
    }
  } catch (error) {
    console.error('Error sending event:', error);
  }
};

export { exportEvent };
