import { Fragment, PureComponent } from 'react';
import { createBrowserHistory, History } from 'history';
import { Router as BrowserRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import SiteRoutes from './SiteRoutes';
import OrgObserver from 'client/components/OrgObserver';
import SessionObserver from 'client/components/SessionObserver';
import { track } from 'shared/util/analytics/track';
import { detect } from 'detect-browser';
import ThemeContext from 'shared/util/ThemeContext';
import SendEvents from 'shared/util/analytics/SendEvents';

export interface SiteRootProps {
  siteId: number;
  basename: string;
  site: Site;
  pages: Array<Page>;
}

@track<SiteRootProps>(props => ({
  timestamp: new Date(),
  domain: props.site.domain,
  siteId: props.siteId,
  browser: detect(),
}))
class SiteRoot extends PureComponent<SiteRootProps> {
  private history: History;

  constructor(props: SiteRootProps) {
    super(props);
    const { basename } = props;
    this.history = createBrowserHistory({
      basename,
      forceRefresh: false,
    });
  }

  render() {
    const { site } = this.props;

    return (
      <BrowserRouter history={this.history}>
        <Fragment>
          <ThemeContext.Provider
            value={{
              theme: site.theme,
            }}
          >
            <Helmet>
              <title>{site.name}</title>
              {site.theme.favicon && (
                <link rel="shortcut icon" href={site.theme.favicon.url} />
              )}
            </Helmet>
            <OrgObserver />
            <SessionObserver />
            <SendEvents site={site} />
            <SiteRoutes siteId={site.id} />
          </ThemeContext.Provider>
        </Fragment>
      </BrowserRouter>
    );
  }
}

export default SiteRoot;
