const ScreenSizeBreakpoints = {
  tiny: 320,
  extraSmall: 480,
  small: 640,
  medium: 1024,
  large: 1200,
  extraLarge: 1440,
};

export default ScreenSizeBreakpoints;
