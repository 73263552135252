import { PureComponent } from 'react';
import { Route, RouteProps } from 'react-router';
import { getUserManager } from 'client/util/userManager';
import { User } from 'shared/state/misc/oidc';
import Loading from 'shared/components/Loading';
import { track } from 'shared/util/analytics/track';
import LoadingWrapper from 'client/components/LoadingWrapper';

export interface UnauthenticatedRouteProps extends RouteProps {
  user?: User;
  initiate?: () => void;
  fulfill?: () => void;
  impersonationRequested: boolean;
  endImpersonationRequested: boolean;
}
@track<UnauthenticatedRouteProps>(
  props => {
    return {
      page: props.path,
      authenticated: !!props.user,
      user: props.user && props.user.profile.sub,
      uuid: props.user && props.user.profile.sub,
      data: { optumId: props.user && props.user.profile.preferred_username },
      action: 'render',
      idpType:
        props.user && (props.user.profile.identity_provider || 'link-id'),
      superuser:
        props.user &&
        props.user.profile.superuser &&
        props.user.profile.superuser.username,
      impersonation_active: props.user && !!props.user.profile.superuser,
      impersonated_user:
        props.user &&
        props.user.profile.superuser &&
        props.user.profile.preferred_username,
    };
  },
  { dispatchOnMount: true }
)
class UnauthenticatedRoute extends PureComponent<UnauthenticatedRouteProps> {
  componentDidMount() {
    const { initiate, fulfill } = this.props;
    const userManager = getUserManager();
    userManager.getUser().then(user => {
      if (!user) {
        initiate();
        userManager
          .signinSilent()
          .then(() => {
            fulfill();
          })
          .catch(error => {
            fulfill();

            console.debug(error);
          });
      }
    });
  }

  render() {
    const { impersonationRequested, endImpersonationRequested } = this.props;
    if (impersonationRequested || endImpersonationRequested) {
      return (
        <LoadingWrapper>
          <Loading message="Impersonation Request Pending" />
        </LoadingWrapper>
      );
    }
    return <Route {...this.props} />;
  }
}

export default UnauthenticatedRoute;
