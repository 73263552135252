import { connect } from 'react-redux';
import { RootState, Dispatch } from 'shared/state';
import {
  selectSite,
  selectPages,
  updateSite,
  saveSite,
  selectCurrentPage,
  addChild,
  updateWidgetConfig,
  selectWidget,
  setActiveWidgetId,
  updateCurrentPage,
  savePage,
  selectPage,
  updateWidgetPosition,
  createPage,
  removePage,
  selectDefaultPage,
  updatePageDefaultWidget,
} from 'shared/state/ducks/sites';
import { SiteLandingPage } from './SiteLandingPage';
import { fetchIdpsIfNeeded, selectIdps } from 'shared/state/ducks/idps';
import { fetchProvisioningStores } from 'shared/state/ducks/provisioningStores';
import { getShowGridToggleValue, toggleShowGrid } from 'shared/state/ducks/ui';
import { setResetToFalse } from 'shared/state/ducks/ui';
import {
  swapResetData,
  resetChange,
  saveSitePages,
} from 'shared/state/ducks/site';
import { initiateLogout } from 'shared/state/ducks/session';

interface SitelandingPageContainerProps {
  siteId: number;
  pageId: number;
  site?: Site;
  page?: Page;
  widgetId?: string;
  parentWidgetId: string;
}

const mapStateToProps = (
  state: RootState,
  ownProps: SitelandingPageContainerProps
) => ({
  site: selectSite(ownProps.siteId)(state),
  pages: selectPages(ownProps.siteId)(state),
  page: selectPage(ownProps.siteId, ownProps.pageId)(state),
  defaultPage: selectDefaultPage(ownProps.siteId)(state),
  idps: selectIdps()(state),
  provisioningStores: state.provisioningStores.data.provisioningStores,
  currentPage: selectCurrentPage(ownProps.siteId, ownProps.pageId)(state),
  widgetState: selectWidget(ownProps.siteId, ownProps.pageId, 'root')(state),
  activeWidgetId: state.sites.activeWidgetId,
  shouldDisplayGridLines: getShowGridToggleValue()(state),
  hasChanged: state.site.hasChanged,
  userModifiedDataState: state.site.newData,
  isAccordionPanelOpen: state.ui.panelWidget.open,
  accordionHeightShift: state.ui.panelWidget.height,
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: SitelandingPageContainerProps
) => ({
  updateSite: (site: Site) => {
    dispatch(updateSite(ownProps.siteId, site));
  },
  saveSite: () => {
    return dispatch(saveSite(ownProps.siteId));
  },
  fetchIdps: () => {
    dispatch(fetchIdpsIfNeeded());
  },
  fetchProvisioningStores: () => {
    dispatch(fetchProvisioningStores());
  },
  addChild: (
    type: string,
    initialConfig: any,
    location: number,
    widgetId: string,
    newParentWidgetId: string,
    tabIndex: number
  ) => {
    dispatch(
      addChild(
        ownProps.siteId,
        ownProps.pageId,
        newParentWidgetId || ownProps.parentWidgetId,
        type,
        initialConfig,
        location,
        widgetId,
        tabIndex
      )
    );
  },
  updateWidgetPosition: (bannerWidgetId, height, oldHeight) => {
    dispatch(
      updateWidgetPosition(
        ownProps.siteId,
        ownProps.pageId,
        bannerWidgetId,
        height,
        oldHeight
      )
    );
  },
  onUpdateConfig: (config: any) => {
    dispatch(
      updateWidgetConfig(ownProps.siteId, ownProps.pageId, 'root', config)
    );
  },
  setActiveWidgetId: (widgetId: string) => {
    dispatch(setActiveWidgetId(widgetId));
  },
  updateCurrentPage: (pageVersion: PageVersion) => {
    dispatch(updateCurrentPage(ownProps.siteId, pageVersion));
  },
  updatePageDefaultWidget: (
    widgetId: string,
    config: any,
    xDifference: number,
    yDifference: number
  ) => {
    dispatch(
      updatePageDefaultWidget(
        ownProps.siteId,
        ownProps.pageId,
        widgetId,
        config,
        xDifference,
        yDifference
      )
    );
  },
  setResetToFalse: () => dispatch(setResetToFalse()),
  toggleShowGrid: () => {
    dispatch(toggleShowGrid());
  },
  saveSitePages: (site: Site, pages: Array<Page>) => {
    dispatch(saveSitePages(site, pages));
  },
  swapResetData: () => {
    dispatch(swapResetData());
  },
  resetUserSyncChange: () => {
    dispatch(resetChange());
  },
  createPage: (page: Partial<PageVersion>) => {
    dispatch(createPage(ownProps.siteId, page));
  },
  removePage: (pageId: number) => {
    dispatch(removePage(ownProps.siteId, pageId));
  },
  savePage: (pageId: number, page: PageVersion) => {
    dispatch(savePage(ownProps.siteId, pageId, page));
  },
  initiateLogout: () => {
    dispatch(initiateLogout());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteLandingPage);
