import { Panel } from '@uitk/react';
import { widgetTypes } from 'builder/util/constants';
import styled from 'styled-components';
import React, { ReactElement } from 'react';
import { Config } from './types';

const StyledSectionWidget = styled.div``;
const StyledPanel = styled(Panel)<{
  hoverFocusColor: string;
  textColor: string;
  fontFamily: string;
  fontSize: number;
}>`
  & button {
    border-left: 4px solid ${p => p.textColor};
  }
  & button[aria-expanded='true'] {
    border-left: 4px solid ${p => p.textColor} !important;
  }
  & button span {
    color: ${p => p.textColor} !important;
    font-family: ${p => p.fontFamily} !important;
    font-size: ${p => p.fontSize}pt !important;
  }
  & button > span > span > svg {
    fill: ${p => p.textColor} !important;
  }
  & button:after {
    box-shadow: 0 4px 0 0 ${p => p.hoverFocusColor} !important;
  }
  & button:focus,
  & button:hover {
    cursor: default;
    box-shadow: 0 0 0 3px ${p => p.hoverFocusColor} !important;
  }
  .cGdfIs {
    padding: 0rem;
  }
`;

const AccordionWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const { currentPage, value, onChange } = props;
  const activeWidgetId = props.widgetState.id;
  const {
    accordionPanels,
    currentTabIndex,
    borderData,
    backgroundData,
    backgroundImage,
    lineColor,
    accordionConnected,
    hoverFocusColor,
    textColor,
    font,
    fontSize,
  } = props.widgetState.config;
  React.useEffect(() => {
    if (activeWidgetId !== 'root') {
      const tabWidgetChildren = currentPage.content[activeWidgetId].children;
      tabWidgetChildren.forEach(child => {
        if (currentPage.content[child]?.tabIndex !== currentTabIndex) {
          document.getElementById(child).style.visibility = 'hidden';
        } else {
          document.getElementById(child).style.visibility = 'visible';
          if (currentPage.content[child].type !== widgetTypes.Image) {
            if (currentPage.content[child].type === widgetTypes.Card) {
              document.getElementById(child).style.zIndex = '1';
            } else {
              document.getElementById(child).style.zIndex = '2';
            }
          }
        }
      });
    }
  }, [currentTabIndex]);

  const handlePanelClick = (panelId: number) => {
    if (panelId === null) {
      props.closeAccordionPanel(activeWidgetId, 0);
    } else {
      props.openAccordionPanel(activeWidgetId, getMaxYLocation(panelId));
    }
    onChange(
      {
        ...value,
        currentTabIndex: panelId,
      },
      activeWidgetId,
      props.widgetState.defaultWidget
    );
  };

  // Increment 50 per each accordion panel to the total accordionPanels length
  const getIncrementalPanelHeight = (panelLength: number): number => {
    let height = 50;
    for (let i = 0; i < panelLength; i++) {
      height += 50;
    }
    return height;
  };

  const getMaxYLocation = (panelId?: number): number => {
    const { currentPage } = props;
    const panelIndex = panelId !== undefined ? panelId : currentTabIndex;
    let maxY = 120,
      yLocation;
    const parentYLocation =
      currentPage.content[activeWidgetId].config.yLocation;
    Object.values(currentPage.content[activeWidgetId].children).map(
      childWidgetId => {
        if (currentPage.content[childWidgetId]?.tabIndex === panelIndex) {
          yLocation =
            currentPage.content[childWidgetId].config.yLocation -
            parentYLocation +
            currentPage.content[childWidgetId].config.height -
            getIncrementalPanelHeight(panelIndex);
        }
        if (yLocation > maxY) maxY = yLocation;
      }
    );
    return maxY;
  };

  let PanelGroupComponent;
  if (accordionConnected) {
    PanelGroupComponent = Panel.AccordionGroup;
  } else {
    PanelGroupComponent = Panel.Group;
  }

  return (
    <PanelGroupComponent
      className="PanelGroup"
      data-test-id="grouped-panel"
      onHeaderPress={handlePanelClick}
      open={currentTabIndex}
    >
      {accordionPanels.map((panel, index) => (
        <StyledPanel
          key={index}
          title={panel.name}
          className={`panel-${index}`}
          hoverFocusColor={hoverFocusColor}
          textColor={textColor}
          fontFamily={font}
          fontSize={fontSize}
        >
          <StyledSectionWidget
            id={'sectionWidget'}
            className={'sectionWidget'}
            style={{
              backgroundColor: backgroundData?.color,
              backgroundImage:
                backgroundData?.style === 'Image'
                  ? `url('${backgroundImage?.url}')`
                  : '',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              borderWidth: `${borderData?.thickness}px`,
              borderStyle: 'solid',
              borderColor: `${lineColor}`,
              borderRadius: `${borderData?.radius}px`,
              backgroundClip: 'content-box',
              width: '100%',
              height: getMaxYLocation(),
            }}
          />
        </StyledPanel>
      ))}
    </PanelGroupComponent>
  );
};
export default AccordionWidget;
