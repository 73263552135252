import { PureComponent, FormEvent, ChangeEvent } from 'react';
import styled from 'styled-components';
import axios from 'shared/util/axios';
import { Button, Input, Link } from 'link-ui-react';
import Typography from 'shared/components/Typography';
import { User } from 'shared/state/misc/oidc';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from 'client/config';
import { getUserManager } from 'client/util/userManager';
import { ScreenSizeBreakpoints as screenBreakpoints } from 'shared/constants';
import { Features } from 'shared/state/ducks/features';

const IDP_OPTUM_ID = 'optum-id';
const OPTUM_ID_VERSIONS = config.links.optumid;
const RELYING_APP_ID = config.links.relyingAppId;
const mobileBreakpoint = screenBreakpoints.small;
const largeBreakpoint = screenBreakpoints.large;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  @media (max-width: ${largeBreakpoint}px) {
    padding: 16px;
  }
`;

const KeycloakWrapper = styled.form`
  padding: 8px 0;
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  & > *:not(:first-child) {
    margin-right: 16px;
    margin-bottom: 10px;
    flex-basis: 300px;
  }
  & > *:first-child {
    flex: 0 1 100%;
    margin-bottom: 20px;
  }
  @media (max-width: ${mobileBreakpoint}px) {
    display: flex;
    justify-content: center;
    & > *:first-child {
      text-align: center;
    }
    & > *:not(:first-child) {
      margin-right: 0px;
      flex: 0 1 85%;
    }
  }
`;

const IDPWrapper = styled.div`
  padding: 8px 0;
  display: flex;
  align-items: left;
  flex-direction: column;
  @media (max-width: ${mobileBreakpoint}px) {
    position: relative;
    align-items: center;
  }
  margin-top: 20px;
`;

const ManageWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
`;

const LinkWrapper = styled.div`
  padding: 5px 5px 5px 0px;
  width: max-content;
  margin: 0px 0px 10px 0px;
  & > a {
    text-decoration: none;
  }
  @media (max-width: ${mobileBreakpoint}px) {
    width: 75%;
  }
`;

const ButtonSubmit = styled(Button)`
  align-self: flex-end;
  flex: 0 1 10% !important;
  @media (max-width: ${mobileBreakpoint}px) {
    align-self: center;
    flex: 0 1 35% !important;
  }
`;
export interface ProfileSettingsProps {
  user: User;
  domain: string;
  features: Features;
  fetchFeatures: () => void;
}

interface ProfileSettingsState {
  firstName: string;
  lastName: string;
  errorFirst: boolean; //if you needed to indicate an input error use these
  errorLast: boolean;
}

class ProfileSettings extends PureComponent<
  ProfileSettingsProps,
  ProfileSettingsState
> {
  constructor(props: ProfileSettingsProps) {
    super(props);
    this.state = {
      firstName: this.props.user.profile.given_name,
      lastName: this.props.user.profile.family_name,
      errorFirst: false,
      errorLast: false,
    };
  }

  componentDidMount() {
    this.props.fetchFeatures();
  }

  handleFirstNameChange = ({
    target: { value: firstName },
  }: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      firstName,
    });
  };

  handleLastNameChange = ({
    target: { value: lastName },
  }: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      lastName,
    });
  };

  buttonDisabled = () => {
    const { user } = this.props;
    if (
      this.state.firstName === '' ||
      this.state.lastName === '' ||
      (this.state.firstName === user.profile.given_name &&
        this.state.lastName === user.profile.family_name)
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (this.buttonDisabled()) {
      toast('Cannot submit with blank first or last name', {
        type: 'error',
        theme: 'colored',
      });
    } else {
      this.updateDisplayname(this.state);
    }
  };

  /**
   * Will update users display name in idx
   */
  updateDisplayname = (state: ProfileSettingsState) => {
    const displayName = {
      firstName: state.firstName,
      lastName: state.lastName,
    };
    axios
      .put(`portal-user/update/displayname`, displayName)
      .then(response => {
        console.debug(response);
        getUserManager().signinRedirect();
        return response.data;
      })
      .catch(() => {
        {
          toast('Error upating display name', {
            type: 'error',
            theme: 'colored',
          });
        }
      })
      .catch(() => {
        console.debug('Failed to update name');
      });
  };

  /**
   * Builds custom link based on client config
   */

  buildLink = (setting: string, url: string, index: number) => {
    const { domain } = this.props;
    const profileUrl = `${url}${domain}${
      this.props.features.idps.healthcareIdEnabled ? '' : RELYING_APP_ID
    }`;
    switch (setting) {
      case 'profile':
        return (
          <LinkWrapper>
            <Link href={profileUrl} key={index}>
              Update Profile
            </Link>
          </LinkWrapper>
        );
      case 'passReset':
        return (
          <LinkWrapper>
            <Link href={profileUrl} key={index}>
              Change Password
            </Link>
          </LinkWrapper>
        );
      case 'security':
        return (
          <LinkWrapper>
            <Link href={profileUrl} key={index}>
              Update Security Questions
            </Link>
          </LinkWrapper>
        );
      default:
        return (
          <LinkWrapper>
            <Link href={profileUrl} key={index}>
              ERROR
            </Link>
          </LinkWrapper>
        );
    }
  };

  render() {
    const { user, features } = this.props;
    const { firstName, lastName, errorFirst, errorLast } = this.state;
    const optumIdLinks = features.idps.healthcareIdEnabled
      ? OPTUM_ID_VERSIONS.healthcareId.links
      : OPTUM_ID_VERSIONS.onprem.links;
    const optumIdpName = features.idps.healthcareIdEnabled
      ? OPTUM_ID_VERSIONS.healthcareId.displayName
      : OPTUM_ID_VERSIONS.onprem.displayName;
    return (
      <Wrapper>
        <KeycloakWrapper onSubmit={this.handleSubmit}>
          <Typography variant="title">Update your Site display name</Typography>
          <Input
            label="First Name"
            value={firstName}
            onChange={this.handleFirstNameChange}
            error={errorFirst}
          />
          <Input
            label="Last Name"
            value={lastName}
            onChange={this.handleLastNameChange}
            error={errorLast}
          />
          <ButtonSubmit
            type="submit"
            disabled={this.buttonDisabled()}
            data-test-id="submit-button"
          >
            Update
          </ButtonSubmit>
        </KeycloakWrapper>
        <IDPWrapper>
          {user.profile.identity_provider === IDP_OPTUM_ID && (
            <>
              <Typography variant="title" gutterBottom>
                Manage your {optumIdpName}
              </Typography>
              <ManageWrapper>
                {Object.keys(optumIdLinks).map((key: string, index) => {
                  return this.buildLink(key, optumIdLinks[key], index);
                })}
              </ManageWrapper>
            </>
          )}
        </IDPWrapper>
      </Wrapper>
    );
  }
}

export default ProfileSettings;
