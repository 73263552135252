import { ChangeEvent, useState } from 'react';
import { Config } from './types';
import { Label, TextArea, FormControl } from '@uitk/react';
import styled from 'styled-components';
import { validationRegex } from 'builder/util/constants';

export const StyledFormControl = styled(FormControl)`
  padding: 0.25rem;
  font-family: 'OptumSans';
  align-content: center;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  #required-text-input-err {
    display: inline-flex;
  }
  width: 100%;
  flex-direction: row;
  -webkit-flex-direction: row;
`;

const StyledTextArea = styled(TextArea)`
  font-family: OptumSans;
`;

export const TextInputWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
) => {
  const {
    required,
    label,
    lineColor,
    borderData,
    backgroundColor,
    backgroundData,
    backgroundImage,
    height,
    validation,
    placeholder,
    value,
  } = props.widgetState.config;
  const [inputValue, setValue] = useState(value);
  // const [blurred, setBlurred] = useState(false);
  const [error, setError] = useState('');

  const onInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const handleChange = () => {
    const { onChange } = props;
    const { config } = props.widgetState;

    if (required) {
      if (inputValue == '') {
        setError('This field is required');
      } else {
        const result = validationRegex[validation](inputValue);
        setError(result);
      }
    } else if (
      // fields with this type of validation were showing an error when the field was empty, even if not required
      (validation === 'date' && inputValue === '') ||
      (validation === 'email' && inputValue === '') ||
      (validation === 'optumemail' && inputValue === '') ||
      (validation === 'number' && inputValue === '')
    ) {
      setError(''); // don't show error if the field is empty and not required
    } else {
      if (validation !== 'none') {
        const result = validationRegex[validation](inputValue);
        setError(result);
      }
    }
    onChange(
      {
        ...config,
        value: inputValue,
      },
      props.widgetState.id,
      props.widgetState.defaultWidget
    );
  };

  return (
    <>
      <StyledFormControl
        id={'required-text-input'}
        error={error}
        required={required}
      >
        <Label>{label || label === '' ? label : 'Label'}</Label>
        <StyledTextArea
          id={props.widgetState.id}
          data-test-id="text-input-widget-text-area"
          onChange={onInputChange}
          onBlur={handleChange}
          readOnly={props.editing}
          value={value}
          placeholder={placeholder}
          style={{
            overflow: 'hidden',
            borderWidth: `${borderData?.thickness}px`,
            borderStyle: 'solid',
            borderColor: `${lineColor ? lineColor : '#ff0000'}`,
            borderRadius: `${borderData?.radius}`,
            background: `${
              backgroundData?.style === 'Color' ? backgroundColor : 'white'
            }`,
            backgroundImage:
              backgroundData?.style === 'Image'
                ? `url('${backgroundImage?.url}')`
                : '',
            height: `${height - 60}px`,
            minHeight: '45px',
          }}
        />
      </StyledFormControl>
    </>
  );
};

export default TextInputWidget;
