import styled from 'styled-components';
import { ContainerDiv } from 'shared/components/Div';

const SiteBody = styled(ContainerDiv)`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: flex-start;
  align-items: center;
  /* Workaround hack for IE11 sizing bug */
  min-height: 1px;
`;

export default SiteBody;
