import { User } from 'shared/state/misc/oidc';
import axios, { AxiosError } from 'axios';
import config from 'client/config';

const validate = (searchTerms: string, user: User, clientId: string) => {
  return axios
    .get<Array<IdentityProviderUser>>(
      config.authority + '/super-user/validate',
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
        params: { search: searchTerms, clientId: clientId },
      }
    )
    .then(response => response.data)
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export { validate };
