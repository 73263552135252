import { createUserManager } from 'redux-oidc';
import Oidc from 'oidc-client';
import config from 'builder/config';

const redirectUri = `${window.location.origin}${
  window.location.pathname
    ? window.location.pathname
        .split('/')
        .slice(0, 3)
        .join('/')
    : ''
}/callback`;

const silentRedirectUri = `${window.location.origin}/silent_renew.html`;

const userManager = createUserManager({
  client_id: 'duplo',
  redirect_uri: redirectUri,
  response_type: 'code',
  scope: 'openid profile',
  authority: config.authority,
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
  silent_redirect_uri: silentRedirectUri,
  monitorSession: true,
  userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
  post_logout_redirect_uri: window.location.href,
});

export default userManager;
