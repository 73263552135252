import undoable from 'redux-undo';

const undoRedo = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD_UNDOREDO':
      return {
        siteId: action.siteId,
        pageId: action.pageId,
        page: action.page,
      };
    default:
      return state;
  }
};

const undoRedos = (state: any = [], action: any) => {
  switch (action.type) {
    case 'ADD_UNDOREDO':
      return [...state, undoRedo(undefined, action)];
    case 'CLEAR_UNDOREDO':
      return [];
    default:
      return state;
  }
};

const undoableTodos = undoable(undoRedos);

export default undoableTodos;

export const clearTodo = () => {
  return {
    type: 'CLEAR_UNDOREDO',
  };
};

export const addUndoRedoState = (
  siteId: number,
  pageId: number,
  pageContent: any
) => {
  return {
    type: 'ADD_UNDOREDO',
    siteId,
    pageId,
    page: pageContent,
  };
};
