import { widgetTypes } from 'builder/util/constants';
import { ButtonWidgetProps, Config } from '../Button/types';
import { EmailAction, FormField } from './types';
import { submitData } from 'shared/api/form';
import { createFormName, submitFormData } from 'shared/api/formSubmission';
import { toast } from 'react-toastify';

export default function useFormSubmit(
  widgetProps: WidgetRenderProps<Config> & ButtonWidgetProps<Config>
) {
  const handleFormData = () => {
    const parentWidget =
      widgetProps.currentPage?.content[widgetProps.widgetState.id]?.config
        ?.parentId;
    if (parentWidget) {
      const formBlock = widgetProps.currentPage?.content[parentWidget];
      const formWidgets = Object.values(
        widgetProps?.currentPage?.content
      ).filter(
        widget =>
          widget?.type === widgetTypes.TextInput ||
          widget?.type === widgetTypes.Checkbox ||
          widget?.type === widgetTypes.RadioButton ||
          widget?.type === widgetTypes.Dropdown ||
          widget?.type === widgetTypes.FileUpload
      );
      const eligibleFormWidgets = formWidgets
        .map(widget => widget)
        .filter(widget =>
          widgetProps.currentPage?.content[parentWidget].children.includes(
            widget.id
          )
        );
      const horizonFields: FormField[] = eligibleFormWidgets.map(widget => {
        return {
          label: widget.config.label,
          value: widget.config.value,
          type: widget.type,
          key: widget.config.id,
          required: widget.config.required,
        };
      });
      const emailAction: EmailAction = {
        subject: formBlock?.config?.subject || '',
        emailTo: formBlock?.config?.emailTo || '',
        enableConditionalEmailTo: formBlock.config.conditionalEmailEnabled,
        templateName: 'DuploFormWidgetAutoResponseEmail',
        timeFrameUnit: formBlock.config.timeFrameUnit ?? '',
        timeFrameValue: formBlock.config.timeFrameValue ?? '',
      };

      submitData(
        horizonFields,
        'email',
        emailAction,
        widgetProps.widgetState.config.site,
        widgetProps.currentPage.pageId,
        parentWidget
      );

      //register form Name
      const formName: string = formBlock?.config?.formName;
      if (formName) {
        createFormName(
          formName,
          widgetProps.widgetState.config.site.id,
          widgetProps.currentPage.pageId,
          parentWidget
        )
          .then(() => {
            // if form name with unique widgetId doesn't exist, will register in form table
            // if form name exists and formName is changed, update formName else do nothing.
            toast('Form name created', { type: 'success', theme: 'colored' });
          })
          .finally(() => {
            // submit form metadata for this form in form submission table.
            const formMapData: FormMapData = eligibleFormWidgets.reduce(
              (acc, widget) => {
                acc[widget.config.label] =
                  widget.type === widgetTypes.RadioButton
                    ? widget.config.checkedValue
                    : widget.config.value;
                return acc;
              },
              {}
            );

            const formPayload = {
              widgetId: parentWidget,
              formData: formMapData,
            };

            submitFormData(formPayload);
          });
      }

      return;
    }
  };

  return { handleFormData };
}
