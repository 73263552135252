import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Config } from './types';

const StyledCardWidget = styled.div``;

const CardWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    borderData,
    backgroundData,
    backgroundImage,
    lineColor,
  } = props.widgetState.config;

  return (
    <StyledCardWidget
      id={'cardWidget'}
      className={'cardWidget'}
      style={{
        backgroundImage:
          backgroundData?.style === 'Image'
            ? `url('${backgroundImage?.url}')`
            : '',
        background: `${
          backgroundData?.style === 'Color' ? backgroundData.color : 'white'
        }`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderWidth: `${borderData?.thickness}px`,
        borderStyle: 'solid',
        borderColor: `${lineColor}`,
        borderRadius: `${borderData?.radius}px`,
        backgroundClip: 'content-box',
        height: '100%',
        width: '100%',
      }}
    ></StyledCardWidget>
  );
};
export default CardWidget;
