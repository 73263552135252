import { Reducer } from 'redux';
import { ThunkActionCreator } from 'shared/state';
import { fetchProvStores } from 'shared/api/provisioningStores';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export interface State {
  data: {
    provisioningStores: Array<ProvisioningStore>;
  };
  meta: StateMeta;
}

const defaultState: State = {
  data: {
    provisioningStores: [],
  },
  meta: {
    pending: false,
  },
};

export const FETCH_PROVISIONING_STORES_FULFILLED =
  'duplo/provisioningStores/FETCH_PROVISIONING_STORES_FULFILLED';
export const FETCH_PROVISIONING_STORES_PENDING =
  'duplo/provisioningStores/FETCH_PROVISIONING_STORES_PENDING';
export const FETCH_PROVISIONING_STORES_FAILED =
  'duplo/provisioningStores/FETCH_PROVISIONING_STORES_FAILED';

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_PROVISIONING_STORES_PENDING:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: true,
        },
      };
    case FETCH_PROVISIONING_STORES_FULFILLED:
      return {
        ...state,
        data: {
          provisioningStores: action.data,
        },
        meta: {
          ...state.meta,
          pending: false,
          successful: true,
          error: null,
        },
      };
    case FETCH_PROVISIONING_STORES_FAILED:
      return {
        ...state,
        data: {
          provisioningStores: [],
        },
        meta: {
          ...state.meta,
          pending: false,
          successful: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default reducer;

export const fetchProvisioningStores: ThunkActionCreator = () => dispatch => {
  dispatch({ type: FETCH_PROVISIONING_STORES_PENDING });
  return fetchProvStores()
    .then((response: Array<ProvisioningStore>) => {
      dispatch({ type: FETCH_PROVISIONING_STORES_FULFILLED, data: response });
    })
    .catch(() => {
      dispatch({
        type: FETCH_PROVISIONING_STORES_FAILED,
      });
      toast('Error fetching provisioning stores', {
        type: 'error',
        theme: 'colored',
      });
    });
};
