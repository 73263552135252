import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Label,
  HelperText,
  TextInput,
  Checkbox,
  FormControl,
  IDropdownItem,
  Dropdown,
} from '@uitk/react';
import { Config } from 'shared/widgets/Form/TextInput/types';
import { validationTypes } from 'builder/util/constants';

export const FieldWrapper = styled.div`
  padding: 0.75rem 0;
`;

export const RequiredFieldWrapper = styled.div`
  & .uitk-label {
    align-self: center;
  }
  & .uitk-form-control {
    width: 50%;
    & .uitk-checkbox-container {
      margin-top: 0;
    }
  }
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
`;

const DropdownStyles = styled(Dropdown)`
  & button {
    font-family: 'OptumSans';
    padding: 0.5rem;
  }
`;

export const StyledTextInput = styled(TextInput)`
  font-family: 'OptumSans';
  padding: 0.5rem;
  font-size: 14px;
  width: 100%;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const StyledLabel = styled(Label)`
  color: #1d1929;
  font-size: 14px;
  font-family: 'OptumSans';
  padding-right: 10px;
  font-weight: 500;
`;

export const StyledTextLabelInput = styled(TextInput)`
  width: 100%;
`;

export const OptionalLabel = styled(HelperText)`
  font-style: italic;
  font-size: 12px;
  margin: 0;
`;

export interface TextInputPanelProps<T> {
  pages?: Array<Page>;
  site?: Site;
  widgetState?: Widget<T>;
  updateSite?: (site: Site) => void;
  saveSite?: () => void;
  handleLineColor?: (data: string) => void;
  handleColorEditClick?: () => void;
  handleFieldRequirement?: (data: boolean) => void;
  handleFieldLabel?: (data: string) => void;
  handleFieldValidation?: (data: string) => void;
  handlePlaceholderLabel?: (data: string) => void;
}

const TextInputPanel: React.FC<TextInputPanelProps<Config>> = (
  props: TextInputPanelProps<Config>
) => {
  const {
    handleFieldRequirement,
    handleFieldLabel,
    handlePlaceholderLabel,
    widgetState,
  } = props;
  const [checked, setChecked] = useState(widgetState.config.required);

  useEffect(() => {
    setChecked(widgetState.config.required);
  }, [widgetState.config]);

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formFieldValidationDropdownValues = () => {
    let items: IDropdownItem[] = [];
    let i = 0;
    validationTypes.map(style => {
      if (style === 'optumemail') {
        items.push({
          id: i++,
          label: 'Optum Email',
          value: style,
        });
      } else {
        items?.push({
          id: i++,
          label: capitalizeFirstLetter(style),
          value: style,
        });
      }
    });
    return items;
  };

  const getFormFieldValidation = () => {
    let returnValue = { id: 0, label: '', value: '' };
    formFieldValidationDropdownValues().find(style =>
      style.value === widgetState.config.validation
        ? (returnValue = {
            id: style.id as number,
            label: capitalizeFirstLetter(style.label) as string,
            value: style.value as string,
          })
        : ''
    );
    return returnValue;
  };

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    handleFieldRequirement(e.target.checked);
  };

  const handleLabel = (e: ChangeEvent<HTMLInputElement>) => {
    handleFieldLabel(e.target.value);
  };

  const handlePlaceHolder = (e: ChangeEvent<HTMLInputElement>) => {
    handlePlaceholderLabel(e.target.value);
  };
  return (
    <>
      <FieldWrapper>
        <LabelWrapper>
          <StyledLabel>Label</StyledLabel>
          <OptionalLabel>(Optional)</OptionalLabel>
        </LabelWrapper>
        <StyledTextInput
          data-test-id="text-input-label"
          value={props.widgetState.config.label}
          onChange={handleLabel}
        />
      </FieldWrapper>
      <FieldWrapper>
        <LabelWrapper>
          <StyledLabel>Validation</StyledLabel>
          <OptionalLabel>(Optional)</OptionalLabel>
        </LabelWrapper>
        <DropdownStyles
          type="single"
          items={formFieldValidationDropdownValues()}
          value={getFormFieldValidation()}
          onChange={({ value }) => props.handleFieldValidation(value)}
          data-test-id="field-validation-dropdown"
        />
      </FieldWrapper>
      <FieldWrapper>
        <LabelWrapper>
          <StyledLabel>Placeholder Text</StyledLabel>
          <OptionalLabel>(Optional)</OptionalLabel>
        </LabelWrapper>
        <StyledTextInput
          data-test-id="placeholder-input-label"
          value={props.widgetState.config.placeholder}
          onChange={handlePlaceHolder}
        />
      </FieldWrapper>
      <RequiredFieldWrapper>
        <StyledLabel>This field is required</StyledLabel>
        <FormControl id="checkbox-form-control">
          <Checkbox
            data-test-id="required-checkbox"
            checked={checked}
            onChange={handleCheck}
          />
        </FormControl>
      </RequiredFieldWrapper>
    </>
  );
};

export default TextInputPanel;
