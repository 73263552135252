import React, { ChangeEvent, useState } from 'react';
import { FormControl, Select } from '@uitk/react';
import { linkDeveloperTheme as theme } from 'link-ui-react';
import styled from 'styled-components';
import { getCombinedFontsForTheme } from 'builder/util/getCombinedFonts';
import { fontSizes } from 'builder/util/constants';
import ColorPicker, {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from 'builder/components/ColorPicker/ColorPicker';
import { MainLabelStyles } from '../Typography/Typography';
import { ColorButtonWrapper } from '../Background/Background';
import { ColorButton } from 'builder/components/ColorButton/ColorButton';

export const FontSelect = styled(Select)`
  font-family: 'OptumSans';
  width: 9.375rem;
  border-radius: 0.375rem;
  .kASQuK {
    font-family: OptumSans;
    line-height: 1.15rem;
    box-shadow: 0 0 0 1px ${theme.colors.aux.black};
    padding: 3px;
    padding-left: 10px;
    font-size: 14px;
  }
`;

const FontSizeSelect = styled(Select)`
  font-family: OptumSans;
  width: 5.1rem;
  padding: 0 0.5rem 0 0.5rem;
  border-radius: 0.375rem;
  .kASQuK {
    font-family: OptumSans;
    line-height: 1.15rem;
    box-shadow: 0 0 0 1px ${theme.colors.aux.black};
    padding: 3px;
    padding-left: 10px;
    font-size: 14px;
  }
`;

const FontDropdowns = styled.div`
  display: flex;
  padding-left: 50px;
  margin-top: -25px;
`;

const StyledWrapper = styled.div`
  padding-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TextIconWrapper = styled.div`
  cursor: pointer;
  font-family: 'OptumSans';
  text-align: center;
  padding-right: 5px;
  border-radius: 7px;
  &:hover {
    background: #d9f6fa;
  }
`;

export const StyledCustomIcon = styled.span`
  height: 1em;
`;

export interface AccordionTypographyProps<T> {
  handleFontSize: (data: string) => void;
  handleFont: (data: string) => void;
  handleTextColor: (data: string) => void;
  widgetState: Widget<T>;
  pages: Array<Page>;
  site?: Site;
  updateSite?: (site: Site) => void;
  saveSite?: () => void;
  color: string;
}

export const AccordionTypography: React.FC<AccordionTypographyProps<any>> = (
  accordionTypographyProps: AccordionTypographyProps<any>
) => {
  const {
    handleFontSize,
    handleFont,
    handleTextColor,
    widgetState,
    site,
    updateSite,
    saveSite,
  } = accordionTypographyProps;

  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen
    ? 'simple-popover-accordion-typography'
    : undefined;

  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  const handleFontChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    handleFont(e.target.value);
  };
  const handleFontSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    handleFontSize(e.target.value);
  };

  const handleColorEditClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    setPickerAnchorEl(e.currentTarget);
  };
  const handlePickerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <>
      <MainLabelStyles>Font</MainLabelStyles>
      <FontDropdowns>
        <FormControl
          data-test-id="font-select-dropdown"
          id={'controlled-select'}
        >
          <FontSelect
            onChange={handleFontChange}
            value={widgetState.config.font}
          >
            {Object.keys(getCombinedFontsForTheme()).map(f => {
              return (
                <option value={f} key={f}>
                  {f}
                </option>
              );
            })}
          </FontSelect>
        </FormControl>
        <FormControl data-test-id="fontsize-dropdown" id={'controlled-select'}>
          <FontSizeSelect
            onChange={handleFontSizeChange}
            value={widgetState.config.fontSize}
          >
            {fontSizes.map(f => (
              <option value={f}>{f}pt</option>
            ))}
          </FontSizeSelect>
        </FormControl>
      </FontDropdowns>
      <StyledWrapper>
        <MainLabelStyles>Color</MainLabelStyles>
        <ColorButtonWrapper
          id="text-color"
          data-test-id="text-color-icon"
          onClick={handleColorEditClick}
        >
          <ColorButton color={widgetState.config.textColor} />
        </ColorButtonWrapper>
        <StyledPickerPopover
          data-test-id="widget-color-picker-popover"
          id={pickerId}
          open={pickerOpen}
          onClick={handlePickerClick}
          onClose={handlePickerClose}
          anchorEl={pickerAnchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <StyledPickerWrapper>
            <StyledPickerHeader data-test-id="styled-picker-header">
              Color Selector
            </StyledPickerHeader>
            <StyledClose
              data-test-id="close-icon"
              onClick={handlePickerClose}
            />
          </StyledPickerWrapper>
          <ColorPicker
            site={site}
            handleColorChange={handleTextColor}
            updateSite={updateSite}
            saveSite={saveSite}
            anchorEl={pickerAnchorEl}
          ></ColorPicker>
        </StyledPickerPopover>
      </StyledWrapper>
    </>
  );
};
