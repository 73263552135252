import { PureComponent } from 'react';
import styled from 'styled-components';
import { Link } from 'link-ui-react';
import Typography from 'shared/components/Typography';
import track from 'react-tracking';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  line-height: 1.42857;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
`;

const Margin = styled.div`
  margin-top: 20px;
`;

@track(
  {
    page: '/terms',
    action: 'render',
  },
  { dispatchOnMount: true }
)
class Terms extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Wrapper>
        <Margin />
        <Typography variant="title">
          <b>
            Terms of Use
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="subheading">
          <b>
            Important Note Regarding Website Content
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="body1">
          The information and content (collectively, “Content”) on this website
          is for your general educational information only. The Content cannot,
          and is not intended to, replace the relationship that you have with
          your health care professionals. The Content on this website should not
          be considered medical advice and is not intended as medical advice.{' '}
          <i>
            If you are experiencing a medical emergency, you should not rely on
            any information on this website and should seek appropriate
            emergency medical assistance, such as calling “911”.{' '}
          </i>
          You should always talk to your health care professionals for diagnosis
          and treatment, including information regarding which drugs or
          treatment may be appropriate for you. None of the Content on this
          website represents or warrants that any particular drug or treatment
          is safe, appropriate or effective for you. Health information changes
          quickly. Therefore, you should always confirm information with your
          health care professionals.
          <br />
          <br />
          This website is intended for a United States audience. If you live
          outside the U.S., you may see information on this website about
          products or therapies that are not available or authorized in your
          country.
          <br />
          <br />
          The Content may include information regarding therapeutic and generic
          alternatives for certain prescription drugs, and may describe uses for
          products or therapies that have not been approved by the Food and Drug
          Administration. This Content is for informational, cost-comparison
          purposes only. It is not medical advice and does not replace
          consultation with a doctor, pharmacist or other health care
          professional. Talk to your health care provider to determine if an
          alternative prescription drug is right for you.
          <br />
          <br />
        </Typography>
        <Typography variant="subheading">
          <b>
            Agreement and Terms
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="body1">
          These website Terms of Use (“Terms”) describe the rules for using this
          website. These Terms constitute a legally binding agreement between
          you, the person using this website, and Optum. If you are helping
          another person use this website, these Terms constitute a legally
          binding agreement between both the helper and the person being helped
          and Optum. Terms such as “we” or “our” and “Company” refer to Optum
          and its affiliate entities.
          <br />
          <br />
          By using this website, you agree to the most-recent Terms as well as
          the most-recent version of our
          <Link inline href="/privacy" target="_self">
            Privacy Policies
          </Link>
          . We may change these Terms at any time, and such changes will be
          posted on this or a similar page of this website. It is your
          responsibility to review these Terms each time you use this website.
          By continuing to use this website, you consent to any changes to our
          Terms.
          <br />
          <br />
          By using this website, you acknowledge and agree that any activity on
          this website is subject to monitoring by the Company at any time, and
          that the Company may use the results of such monitoring without
          limitation, subject to applicable law.
          <br />
          <br />
          If you enter into any other agreement with the Company, for example
          regarding health plan coverage, then these Terms are in addition to
          the terms of such other agreement. Neither entering into this
          agreement, nor visiting this website, nor any of these Terms,
          guarantees that you are eligible to receive coverage under any plan
          offered through this website or otherwise.
          <br />
          <br />
        </Typography>
        <Typography variant="subheading">
          <b>
            License to Use this Website and Content Ownership
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="body1">
          Subject to these Terms, the Company grants you a personal,
          nontransferable, nonexclusive, revocable, limited license to view the
          Content on the website for the sole purpose of collecting information
          regarding our plan and related activities such as, if permitted on
          this website, applying for a plan. You may also print a reasonable
          number of copies of the Content for your personal use, but in such
          case you must reproduce all proprietary copyright and trademark
          notices. All rights, title and interest in and to the website,
          including the Content, and all intellectual property rights, including
          all copyright, trademark, patent and trade secret rights therein shall
          remain with the Company and our licensors and vendors, and no
          ownership interest is transferred to you or any other entity by virtue
          of making the Content available on the website, granting the foregoing
          licenses or entering into this Agreement.
          <br />
          <br />
          In the event you choose to provide us with any feedback, suggestions,
          or similar communications, all such messages (collectively, “Feedback
          Messages”) will be considered non-personal, non-confidential (except
          for personal information as described in our Privacy Policies) and
          nonproprietary. You hereby grant the Company a perpetual,
          sublicensable, assignable, unrestricted, worldwide, royalty-free,
          irrevocable license to use, reproduce, display, perform, practice,
          modify, create derivative or collective works, transmit and distribute
          your Feedback Messages, in whole or in part, and including all
          intellectual property rights therein.
          <br />
          <br />
          Some websites operated by the Company include trademarks or logos
          belonging to other third-party licensors and are used pursuant to an
          agreement with such third parties.
          <br />
          <br />
          We may terminate this license at any time for any reason. If you
          breach any of these Terms, your license to the Content terminates
          immediately. Upon the termination of this license you must stop using
          this website, including all Content, and return or destroy all copies,
          including electronic copies, of the Content in your possession or
          control.
          <br />
          <br />
        </Typography>
        <Typography variant="subheading">
          <b>
            Restrictions on Use of this Website
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="body1">
          You agree:
          <br />
          <br />
        </Typography>
        <Typography variant="unorderedlist">
          <Typography data-test-id="terms-list1-01" variant="listitem">
            not to use this website or Content in any way not explicitly
            permitted by these Terms or the text of the website itself;
          </Typography>
          <Typography data-test-id="terms-list1-02" variant="listitem">
            not to copy, modify or create derivative works involving the
            Content, except you may print a reasonable number of copies for your
            personal use, provided that you reproduce all proprietary copyright
            and trademark notices;
          </Typography>
          <Typography data-test-id="terms-list1-03" variant="listitem">
            not to misrepresent your identity or provide us with any false
            information in any information-collection portion of this website,
            such as a registration or application page;
          </Typography>
          <Typography data-test-id="terms-list1-04" variant="listitem">
            not to take any action intended to interfere with the operation of
            this website;
          </Typography>
          <Typography data-test-id="terms-list1-05" variant="listitem">
            not to access or attempt to access any portion of this website to
            which you have not been explicitly granted access;
          </Typography>
          <Typography data-test-id="terms-list1-06" variant="listitem">
            not to share any password assigned to or created by you with any
            third parties or use any password granted to or created by a third
            party;
          </Typography>
          <Typography data-test-id="terms-list1-07" variant="listitem">
            not to directly or indirectly authorize anyone else to take actions
            prohibited in this section;
          </Typography>
          <Typography data-test-id="terms-list1-08" variant="listitem">
            to comply with all applicable laws and regulations while using this
            website or the Content. You represent and warrant that you are at
            least 13 years of age, and that if you are between 13 and 18 years
            of age a parent and/or guardian agrees to these Terms of Use on your
            behalf.
            <br />
            <br />
          </Typography>
        </Typography>
        <Typography variant="subheading">
          <b>
            Posting Messages, Comments or Content
            <br />
            <br />
          </b>
        </Typography>
        <Typography data-test-id="terms-list2-01" variant="body1">
          Certain portions of this website may be configured to permit users to
          post messages, comments, or other content. Any such content is only
          the opinion of the poster, is no substitute for your own research, and
          should not be relied upon for any purpose. You agree not to post any
          content:
          <br />
          <br />
        </Typography>
        <Typography variant="unorderedlist">
          <Typography data-test-id="terms-list2-02" variant="listitem">
            which is profane, libelous, defamatory, obscene, pornographic,
            indecent, lewd, harassing, threatening, harmful, invasive of privacy
            or publicity rights, abusive, inflammatory, or otherwise
            objectionable; or
          </Typography>
          <Typography data-test-id="terms-list2-03" variant="listitem">
            the transmission of which could violate, or facilitate the violation
            of, any applicable law, regulation, or intellectual property rights.
            <br />
            <br />
          </Typography>
        </Typography>
        <Typography variant="body1">
          You are solely responsible for the content of any postings you submit
          and the Company assumes no responsibility or liability for any content
          submitted by you or any other website visitor. We may, but are not
          obligated to, restrict or remove any and all content from a message
          that we determine in our sole discretion violates these Terms or is
          otherwise harmful to us, our customers, or any third party. We reserve
          the right to remove the content you provide at any time, but you
          understand that we may preserve and access a backup-copy, and we may
          disclose the content if required to do so by law or in a good faith
          belief that such access, preservation, or disclosure is required by
          law or in the best interests of the Company.
          <br />
          <br />
          Any content you submit is for non-commercial use only. Any content you
          submit will be routed through the Internet and you understand and
          acknowledge that you have no expectation of privacy with regard to any
          content you submit. Never assume that you are anonymous and cannot be
          identified by your posts.
          <br />
          <br />
          If you do post content or submit material, and unless we indicate
          otherwise, you grant the Company a nonexclusive, royalty-free,
          perpetual, irrevocable, and fully sublicensable right to use,
          reproduce, modify, adapt, publish, translate, create derivative works
          from, distribute, and display such content throughout the world in any
          media. You grant the Company and any of its sublicensees the right to
          use the name that you submit in connection with such content, if they
          choose. You represent and warrant that you own or otherwise control
          all of the rights to the content that you post; that the content is
          accurate; that use of the content you supply does not violate these
          Terms and Conditions and will not cause injury to any person or
          entity; and that you will indemnify the Company or its affiliates for
          all claims resulting from content you supply.
          <br />
          <br />
        </Typography>
        <Typography variant="subheading">
          <b>
            Copyright Infringement - DMCA Notice
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="body1">
          The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides
          recourse for copyright owners who believe that material appearing on
          the Internet infringes their rights under US copyright law. If you
          believe in good faith that content or material on this website
          infringes a copyright owned by you, you (or your agent) may send the
          Company a notice requesting that the material be removed, or access to
          it blocked. This request should be sent to:{' '}
          <a href="mailto:DMCARegisteredAgent2@optum.com">
            DMCARegisteredAgent2@optum.com
          </a>
          ; or, alternatively to: UnitedHealth Group, Attn: DMCA Registered
          Agent, 9900 Bren Road East, Minnetonka, MN 55343.
          <br />
          <br />
          The notice must include the following information: (a) a physical or
          electronic signature of a person authorized to act on behalf of the
          owner of an exclusive right that is allegedly infringed; (b)
          identification of the copyrighted work claimed to have been infringed;
          (c) identification of the material that is claimed to be infringing or
          the subject of infringing activity; (d) the name, address, telephone
          number, and email address of the complaining party; (e) a statement
          that the complaining party has a good faith belief that use of the
          material in the manner complained of is not authorized by the
          copyright owner, its agent or the law; and (f) a statement that the
          information in the notification is accurate and, under penalty of
          perjury, that the complaining party is authorized to act on behalf of
          the owner of an exclusive right that is allegedly infringed&nbsp; If
          you believe in good faith that a notice of copyright infringement has
          been wrongly filed against you, the DMCA permits you to send us a
          counter-notice. Notices and counter-notices must meet the then-current
          statutory requirements imposed by the DMCA.&nbsp; Notices and
          counter-notices with respect to the website should be sent to the
          address above.
          <br />
          <br />
        </Typography>
        <Typography variant="subheading">
          <b>
            Changes to Website Content
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="body1">
          We may change, add or remove some or all of the Content on this
          website at any time. In addition, please note that although our goal
          is to provide accurate information, certain features that may be
          offered through this website, such as participating healthcare
          providers, pricing information or other Content, may not be accurate
          or up to date. In addition, please note that features of any plan or
          plans described in this website may change over time as permitted by
          law, including benefit levels, items included in any formulary,
          pricing or lists of participating providers or other associated
          vendors.
          <br />
          <br />
        </Typography>
        <Typography variant="subheading">
          <b>
            Links
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="body1">
          While visiting this website you may click on a link to other websites
          and leave this website. We provide links to other websites that may
          contain information that may be useful or interesting to you. We do
          not endorse, and are not responsible for, the content and accuracy of
          linked websites operated by third parties or for any of your dealings
          with such third parties. You are solely responsible for your dealings
          with such third parties and we encourage you to read the terms of use
          and privacy policies on such third-party websites.
          <br />
          <br />
        </Typography>
        <Typography variant="subheading">
          <b>
            No Warranties
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="body1">
          <i>
            All content on this website is provided to you on an “as is”, “as
            available” basis. The company, all third parties, if any, providing
            content for this website, and all third parties providing support or
            information for this website (collectively,
            “website-related-parties”) hereby disclaim all warranties of any
            kind, either express or implied, statutory or otherwise including
            but not limited to the implied warranties of merchantability,
            fitness for a particular purpose and non-infringement.
          </i>

          <br />
          <br />
          <i>
            The website-related-parties make no warranty as to the accuracy,
            completeness, timeliness, correctness, or reliability of any content
            available through this website. Without limiting the foregoing, the
            website-related-parties specifically do not represent or warrant
            that any information regarding particular plan benefits or scope of
            coverage is accurate or complete. The website-related-parties make
            no representations or warranties that use of this website will be
            uninterrupted or error-free, that defects will be corrected, or that
            this website or the technology that makes it available are free of
            viruses or other harmful components. You are responsible for taking
            all precautions necessary to ensure that any content you may obtain
            from this website is free of viruses and any other potentially
            destructive computer code.
          </i>

          <br />
          <br />
          <i>
            Some states do not allow limitations on implied warranties, so one
            or more of the above limitations may not apply to you.&nbsp; Check
            your local laws for any restrictions or limitations regarding the
            exclusion of implied warranties.
          </i>
          <br />
          <br />
        </Typography>
        <Typography variant="subheading">
          <b>
            Limitation of Liability
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="body1">
          <i>
            You agree that none of the website-related-parties shall be liable
            for any damage resulting from your use or inability to use this
            website or the content. This protection covers claims based on
            warranty, contract, tort, strict liability, and any other legal
            theory. This protection covers the website-related-parties,
            including all affiliates, and their officers, directors, employees,
            and agents. This protection covers all losses including, without
            limitation, direct or indirect, special, incidental, consequential,
            exemplary, and punitive damages, personal injury/wrongful death,
            lost profits, or damages resulting from lost data or business
            interruption. The total, cumulative, liability of the
            website-related-parties, including all affiliates, and their
            officers, directors, employees, and agents, if any, for losses or
            damages shall be limited to the amount of your actual damages, not
            to exceed u.s. $100.00. The limit of liability may not be effective
            in some states. In no event shall the website-related-parties,
            including all affiliates, and their officers, directors, employees,
            and agents, be liable to you for any losses or damages other than
            the amount described above. All other damages, direct or indirect,
            special, incidental, consequential, exemplary, or punitive,
            resulting from any use of the website or content are excluded even
            if the website-related-parties have been advised of the possibility
            of such damages. You agree that you use this website at your own
            risk. If you are dissatisfied with this website or the content, your
            sole and exclusive remedy is to discontinue using the website.
          </i>
          <br />
          <br />
        </Typography>
        <Typography variant="subheading">
          <b>
            Governing Law and Statute of Limitations
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="body1">
          The laws of the State of Minnesota govern these Terms and any cause of
          action arising under or relating to your use of the website, without
          reference to its choice-of-law principles. You agree that the only
          proper jurisdiction and venue for any dispute with the Company, or in
          any way relating to your use of this website, is in the state and
          federal courts in the State of Minnesota, U.S.A. You further agree and
          consent to the exercise of personal jurisdiction in these courts in
          connection with any dispute involving the Company or its employees,
          officers, directors, agents and providers. If any provision of these
          Terms is determined to be invalid under any applicable statute or rule
          of law, such provision is to that extent to be deemed omitted, and the
          balance of the Agreement shall remain enforceable.
          <br />
          <br />
          Before seeking legal recourse for any harm you believe you have
          suffered arising from or related to your use of this website, you
          agree to inform us in writing and to give us 30 days to cure the harm
          before initiating any action. You must initiate any cause of action
          within one year after the claim has arisen, or you will be barred from
          pursuing any cause of action.
          <br />
          <br />
        </Typography>
        <Typography variant="subheading">
          <b>
            Contact us
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="body1">
          To contact us regarding these Terms or the operation of the website
          itself, contact us at{' '}
          <a href="mailto:info@optum.com">info@optum.com</a>.
        </Typography>
        <Typography variant="subheading">
          <b>
            Effective date
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="body1">
          The Effective Date of these Terms is December 14, 2012.
          <br />
          <br />
        </Typography>
        <Typography variant="subheading">
          <b>
            Additional terms
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="body1">
          Your obligations under the following sections survive termination of
          this Agreement: Important Note Regarding Website Content; Agreement
          and Terms; portions of License to use this website and content
          ownership; Restrictions on use of this website; Posting messages,
          comments or content; Copyright Infringement – DMCA Notice; Changes to
          website content; Links; NO WARRANTIES; LIMITATION OF LIABILITY;
          Governing law and statute of limitations; Additional terms. If any
          provision of these Terms is found to be invalid by any court having
          competent jurisdiction, the invalidity of such provision shall not
          affect the validity of the remaining provisions of these Terms, which
          shall remain in full force and effect. No waiver of any of these Terms
          shall be deemed a further or continuing waiver of such term or
          condition or any other term or condition. You agree to defend and
          indemnify, Website-Related-Parties and their subsidiaries, affiliates,
          officers, directors, employees, and agents, harmless from any claim,
          demand, or damage, including reasonable attorneys' fees, arising out
          of or related to your breach of this Agreement or your use or misuse
          of the Content or website. You may not transfer or assign any rights
          or obligations under this Agreement. The Company may transfer or
          assign its rights and obligations under this Agreement.
        </Typography>
        <Margin />

        <Link
          underline
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          Back to Top
        </Link>
        <Margin />
      </Wrapper>
    );
  }
}

export default Terms;
