import React, { PureComponent } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './Trigger-Styles.css';

import Modal from './Modal';
import FeedbackIcon from './feedback.svg';
import CloseIcon from './close.svg';
import { FeedbackFormField } from 'shared/widgets/SimpleForm/types';
import styled from 'styled-components';
import { getColorValue } from 'shared/util/theme';
import { feedbackStyles } from 'builder/util/constants';
import { Button } from '@uitk/react';

const StyledTriggerDiv = styled.div<{
  backgroundColor: string;
  textColor: string;
  zIndex: number;
}>`
  background-color: ${p => getColorValue(p.backgroundColor, p.theme)};
  color: ${p => getColorValue(p.textColor, p.theme)};
  zindex: ${p => p.zIndex};
`;

const StyledButton = styled(Button)<{
  backgroundColor: string;
  textColor: string;
}>`
  width: 1%;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  white-space: nowrap;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: -10px;
  bottom: 150px;
  border-radius: 5px;
  font-family: 'OptumSans';
  color: ${p => getColorValue(p.textColor, p.theme)};
  background: ${p => getColorValue(p.backgroundColor, p.theme)};
`;

export interface TriggerProps {
  feedbackTypes: Array<string>;
  buttonColor: string;
  textColor: string;
  hoverBorderColor: string;
  fontFamily: string;
  feedbackStyle: string;
  issueFeedBackTypeEmailList: string;
  ideaFeedbackTypeEmailList: string;
  bugFeedbackTypeEmailList: string;
  postSubmitButtonMsg: string;
  submitButtonMsg: string;
  formfields: Array<FeedbackFormField>;
  zIndex: number;
  siteId: number;
  siteName: string;
}

class Trigger extends PureComponent<TriggerProps> {
  state = {
    modal: false,
  };

  triggerModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  render() {
    let { modal } = this.state;
    let { props } = this;

    return (
      <React.Fragment>
        {props.feedbackStyle === feedbackStyles[1] && (
          <StyledTriggerDiv
            className="frf-trigger-button"
            data-test-id={'feedback-trigger-button'}
            backgroundColor={props.buttonColor}
            textColor={props.textColor}
            zIndex={props.zIndex}
            onClick={this.triggerModal}
          >
            <div
              className={modal ? 'frf-feedback-icon-open' : 'frf-feedback-icon'}
              style={{
                fill: props.textColor,
              }}
            >
              {modal ? <CloseIcon /> : <FeedbackIcon />}
            </div>
          </StyledTriggerDiv>
        )}
        {props.feedbackStyle === feedbackStyles[0] && (
          <StyledButton
            backgroundColor={props.buttonColor}
            textColor={props.textColor}
            onClick={this.triggerModal}
          >
            Feedback
          </StyledButton>
        )}

        <TransitionGroup component={null}>
          {modal && (
            <CSSTransition
              in={modal}
              classNames="frf-dialog"
              timeout={{
                enter: 300,
                exit: 300,
              }}
            >
              <Modal
                feedbackTypes={props.feedbackTypes}
                buttonColor={props.buttonColor}
                textColor={props.textColor}
                fontFamily={props.fontFamily}
                hoverBorderColor={props.hoverBorderColor}
                postSubmitButtonMsg={props.postSubmitButtonMsg}
                submitButtonMsg={props.submitButtonMsg}
                triggerModal={this.triggerModal}
                modalOpen={this.state.modal}
                zIndex={props.zIndex}
                issueFeedBackTypeEmailList={props.issueFeedBackTypeEmailList}
                ideaFeedbackTypeEmailList={props.ideaFeedbackTypeEmailList}
                bugFeedbackTypeEmailList={props.bugFeedbackTypeEmailList}
                formfields={props.formfields}
                siteId={props.siteId}
                siteName={props.siteName}
              />
            </CSSTransition>
          )}
        </TransitionGroup>
      </React.Fragment>
    );
  }
}

export default Trigger;
