import { Dispatch } from 'shared/state';
import { connect } from 'react-redux';
import AccordionWidget from './AccordionWidget';
import { closeAccordionPanel, openAccordionPanel } from 'shared/state/ducks/ui';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openAccordionPanel: (widgetId: string, height: number) => {
    dispatch(openAccordionPanel(widgetId, height));
  },
  closeAccordionPanel: (widgetId: string, height: number) => {
    dispatch(closeAccordionPanel(widgetId, height));
  },
});

export default connect(null, mapDispatchToProps)(AccordionWidget);
