import axios from 'axios';
import { Middleware, MiddlewareAPI } from 'redux';
import { Dispatch, RootState } from 'shared/state';

const instance = axios.create({
  baseURL: '/api/link/',
});

const bindInterceptors = (
  getState: MiddlewareAPI<Dispatch, RootState>['getState']
) => {
  instance.interceptors.request.use(config => {
    const state = getState();
    const token = state.oidc && state.oidc.user && state.oidc.user.access_token;
    const siteId =
      state.sites &&
      state.sites.data &&
      state.sites.data[0] &&
      state.sites.data[0].id;

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    if (siteId) {
      config.headers = {
        ...config.headers,
        siteId,
      };
    }
    config.headers = {
      ...config.headers,
      isDuplo: false,
    };

    return config;
  });
};

const middleware: Middleware<{}, RootState> = store => {
  bindInterceptors(store.getState);
  return next => action => next(action);
};

export { middleware };
export default instance;
