import axiosRaw from 'axios';
import axios from 'shared/util/axios';
import { AppRegistrationStatus } from 'types/types';

export interface TilesResponse {
  appTiles: Array<Application>;
  errors: Array<string>;
}

interface AppRegistrationRequest {
  businessKey: string;
}

interface AppRegistrationResponse {
  requestId: number;
  status: AppRegistrationStatus;
}

export default {
  get(published: boolean, orgId?: string) {
    const param = orgId ? `?orgId=${orgId}` : '';
    return published
      ? axios
          .get<TilesResponse>(
            `portal-provisioning/applications/tilesAsync${param}`,
            {
              responseType: 'json',
            }
          )
          .then(response => {
            return response.data;
          })
          .catch(error => {
            return Promise.reject(error);
          })
      : axiosRaw
          .get<TilesResponse>(
            'https://cdn-stage.linkhealth.com/common/apps.json',
            {
              responseType: 'json',
            }
          )
          .then(response => {
            return response.data;
          });
  },
  postStepup(data: AppRegistrationRequest) {
    return axios
      .post<AppRegistrationResponse>(
        `portal-provisioning/stepup/provisioning`,
        data,
        {
          responseType: 'json',
        }
      )
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  getStepupStatus(businessKey: string) {
    return axios
      .get<AppRegistrationResponse>(
        `portal-provisioning/stepup/provisioning?businessKey=${businessKey}`,
        {
          responseType: 'json',
        }
      )
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
};
