import { TabbedPanel } from '@uitk/react';
import { widgetTypes } from 'builder/util/constants';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Config } from './types';

const StyledDivContainer = styled.div``;

const StyledTabbedPanel = styled(TabbedPanel)<{
  hoverFocusColor: string;
  tabsTextColor: string;
  fontFamily: string;
  fontSize: number;
}>`
  & > *:first-child {
    padding: 0;
  }
  & button {
    cursor: default;
    color: ${p => p.tabsTextColor} !important;
    font-family: ${p => p.fontFamily} !important;
    font-size: ${p => p.fontSize}pt !important;
  }
  & button:after {
    box-shadow: 0 4px 0 0 ${p => p.hoverFocusColor} !important;
  }
  & button:focus,
  & button:hover {
    box-shadow: 0 0 0 3px ${p => p.hoverFocusColor} !important;
  }
  & button:active {
    box-shadow: 0 0 0 3px ${p => p.hoverFocusColor} !important;
    background-image: linear-gradient(rgb(0 0 0 /5%) 0 0);
  }
`;
const TabsWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    tabs,
    currentTabIndex,
    hoverFocusColor,
    tabsTextColor,
    font,
    fontSize,
    borderData,
    backgroundData,
    backgroundImage,
    lineColor,
  } = props.widgetState.config;
  function background() {
    if (backgroundData.style === 'Color') {
      return backgroundData?.color;
    }
    if (backgroundData.style === 'Image') {
      return `url('${backgroundImage?.url}')`;
    }
    return '';
  }
  const getFormattedTabs = () => {
    const formatTabs = [];
    tabs.forEach(tab => {
      formatTabs.push({ header: tab.name, content: tab.content });
    });
    return formatTabs;
  };

  const { value, onChange, currentPage } = props;
  const activeWidgetId = props.widgetState.id;
  React.useEffect(() => {
    if (activeWidgetId !== 'root') {
      const tabWidgetChildren = currentPage.content[activeWidgetId].children;
      tabWidgetChildren.forEach(child => {
        if (currentPage.content[child].tabIndex !== currentTabIndex) {
          document.getElementById(child).style.visibility = 'hidden';
        } else {
          document.getElementById(child).style.visibility = 'visible';
          if (currentPage.content[child].type !== widgetTypes.Image) {
            if (currentPage.content[child].type === widgetTypes.Card) {
              document.getElementById(child).style.zIndex = '1';
            } else {
              document.getElementById(child).style.zIndex = '2';
            }
          }
        }
      });
    }
  }, [currentTabIndex]);
  const handleTabClick = (panelId: number) => {
    onChange(
      {
        ...value,
        currentTabIndex: panelId,
      },
      activeWidgetId,
      false
    );
  };
  if (tabs.length > 0) {
    return (
      <StyledDivContainer
        style={{
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          borderWidth: `${borderData?.thickness}px`,
          borderStyle: 'solid',
          borderColor: `${lineColor}`,
          borderRadius: `${borderData?.radius}px`,
          backgroundClip: 'content-box',
          background: background(),
          height: '100%',
          width: '100%',
        }}
      >
        <StyledTabbedPanel
          hoverFocusColor={hoverFocusColor}
          tabsTextColor={tabsTextColor}
          fontFamily={font}
          fontSize={fontSize}
          panels={getFormattedTabs()}
          onHeaderPress={handleTabClick}
          selectedPanel={currentTabIndex}
          titlesAs="h2"
          data-test-id="tabbed-panel"
        />
      </StyledDivContainer>
    );
  } else {
    return null;
  }
};
export default TabsWidget;
