import { PureComponent, FormEvent, ChangeEvent, Fragment } from 'react';
import { Button, Input } from 'link-ui-react';
import { User } from 'shared/state/misc/oidc';
import config from 'client/config';
import { withRouter } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { doImpersonationRequest } from 'client/util/userManager';
import { defaultTheme as theme } from 'link-ui-react';
import styled from 'styled-components';
import ClickOutsideComponent from 'shared/util/clickOutside';
import { track } from 'shared/util/analytics/track';
import Select from 'shared/components/Select';
import Typography from 'shared/components/Typography';
import { validate } from 'shared/api/impersonation';
import {
  createRedirectUrl,
  createOption,
  createError,
  checkUserImpersonationRequirements,
  ErrorMessageType,
} from './helpers';
import { Clear } from '@material-ui/icons';
import { RouteComponentProps } from 'react-router';

const StyledDialogContent = styled(DialogContent)`
  min-width: 480px;
`;

const ImpersonationButton = styled(Button)<{ isBeingImpersonated: boolean }>`
  background: ${p =>
    p.isBeingImpersonated ? theme.colors.aux.red : theme.colors.aux.blue};
  &:hover {
    background: ${p =>
      p.isBeingImpersonated
        ? theme.colors.aux.lightRed
        : theme.colors.aux.lightBlue};
    color: ${theme.colors.aux.white};
  }
`;

const ImpersonationWrapper = styled.div<{ superuser?: boolean }>`
  padding: 10px;
  ${p => (!p.superuser ? 'display:none' : null)};
  background: ${theme.colors?.aux?.lightestGrey};
  width: 100%;
  box-shadow: 0px 2px 6px 0px ${theme.colors.aux.grey};
  text-align: center;
  border-style: solid;
  border-color: ${theme.colors.aux.grey};
  border-width: 1px;
`;

const ImpersonationButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const SearchInfo = styled.div`
  margin: 15px 0px 15px 0px;
  display: flex;
  flex-direction: column;
  aligh-items: flex-start;
`;

const ClearIcon = styled.a`
  cursor: pointer;
  margin: 3px;
  color: ${theme.colors.aux.darkGrey};
  &:hover {
    color: ${theme.colors.aux.black};
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const SelectLabel = styled(Typography)`
  color: ${theme.colors.aux.black};
`;

const UserResultText = styled(Typography)`
  color: ${theme.colors.aux.lightBlue};
`;

const UserResultErrorText = styled(Typography)`
  color: ${theme.colors.aux.red};
`;

const SearchResultText = styled(Typography)`
  color: ${theme.colors.aux.black};
`;

const ImpersonationText = styled(Typography)`
  color: ${theme.colors.aux.black};
  padding: 15px;
`;

interface UserImpersonationProps extends RouteComponentProps {
  user: User;
  staticContext: {};
  clientId: string;
  initiateImpersonation: () => void;
  endImpersonation: () => void;
  impersonationRequested: boolean;
  endImpersonationRequested: boolean;
}

export interface SelectOption {
  label: string;
  value: string;
}

enum AcceptanceStatus {
  ACCEPTED = 'Y',
  NOT_ACCEPTED = 'N',
}

export interface UserWithTerms {
  username: string;
  acceptedTerms: boolean;
  isSuperUser: boolean;
}

interface UserImpersonationState {
  redirectUrl: string;
  open: boolean;
  searchTerms: string;
  userMap: Map<string, Array<UserWithTerms>>;
  numUsers: number;
  searching: boolean;
  canImpersonate: boolean;
  errorMessage: string;
  idpSelection: SelectOption;
  userSelection: UserWithTerms;
  idpList: Array<SelectOption>;
}

@track()
class UserImpersonation extends PureComponent<
  UserImpersonationProps,
  UserImpersonationState
> {
  constructor(props: UserImpersonationProps) {
    super(props);
    this.state = {
      redirectUrl: '',
      canImpersonate: false,
      open: false,
      idpList: [],
      searchTerms: '',
      userMap: null,
      numUsers: 0,
      searching: false,
      errorMessage: '',
      idpSelection: createOption(''),
      userSelection: null,
    };
  }

  @track({ action: 'start_impersonation' })
  startImpersonation = (e: FormEvent) => {
    const { initiateImpersonation } = this.props;
    e.preventDefault();
    e.stopPropagation();
    initiateImpersonation();
    doImpersonationRequest(this.state.redirectUrl);
  };

  @track({ action: 'end_impersonation' })
  endImpersonation = () => {
    const { endImpersonation } = this.props;
    endImpersonation();
    doImpersonationRequest(
      config.authority +
        `/super-user/end?redirect_uri=` +
        window.location.origin +
        `/impersonation`
    );
  };
  createOption = (text: string) =>
    ({
      label: text,
      value: text,
    } as SelectOption);

  updateSearchText = ({
    target: { value: executeSearch },
  }: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchTerms: executeSearch.trim(),
      userSelection: null,
      canImpersonate: false,
      errorMessage: '',
      numUsers: 0,
    });
  };

  handleModal = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
      userMap: null,
      idpList: [],
      numUsers: 0,
      searchTerms: '',
      userSelection: null,
      idpSelection: createOption(''),
      canImpersonate: false,
      errorMessage: '',
      searching: false,
    });
  };

  //adds a 'link-id' to usernames with no associated idp's
  //solves a bug where usernames with only 'link-id' don't show
  addLinkIdp = (users: Array<IdentityProviderUser>) => {
    users.flatMap(user => {
      const newIdp: IdentityProvider = {
        userId: null,
        identityProvider: 'link-id',
        userName: user.username,
      };
      !user.idp.length && user.idp.push(newIdp);
      return user.idp.map(idp => idp.identityProvider);
    });
  };

  //optimally structures and returns the user data
  reduceUsers = (users: Array<IdentityProviderUser>) => {
    this.addLinkIdp(users);
    const idpMap = new Map<string, Array<UserWithTerms>>();
    users.forEach((user: IdentityProviderUser) => {
      user.idp.forEach(idp => {
        if (!idpMap.get(idp.identityProvider)) {
          idpMap.set(idp.identityProvider, []);
        }
        idpMap.get(idp.identityProvider).push({
          username: user.username,
          isSuperUser: user.isSuperUser,
          acceptedTerms: !user.terms.some(
            term => term.acceptanceStatus === AcceptanceStatus.NOT_ACCEPTED
          ),
        });
      });
    });
    return idpMap;
  };

  executeSearch = (e: FormEvent) => {
    e.preventDefault();
    const { searchTerms } = this.state;
    const { user, clientId } = this.props;
    this.setState({
      searching: true,
      numUsers: 0,
      userMap: null,
      userSelection: null,
      canImpersonate: false,
    });
    //performs executeSearch and sets default values of dropdowns
    validate(searchTerms, user, clientId)
      .then(users => {
        users = users.filter(
          it => it.username !== user.profile.preferred_username
        );
        const userMap = this.reduceUsers(users);
        this.setState({
          searching: false,
          numUsers: users.length,
          redirectUrl:
            users.length === 1 &&
            createRedirectUrl(users[0].username, clientId),
          idpSelection: createOption(userMap.keys().next().value),
          userMap,
          idpList: [...userMap.keys()].map(key => {
            return createOption(key);
          }),
          errorMessage: !userMap.size && createError(ErrorMessageType.NO_USERS),
        });
        users.length === 1 && this.selectUser(createOption(users[0].username));
      })
      .catch(error => {
        this.setState({
          errorMessage: createError(ErrorMessageType.SEARCH_ERROR) + error,
          searching: false,
        });
      });
  };

  selectIdp = ({ value }: { label: string; value: string }) => {
    const { userSelection } = this.state;
    this.setState({
      idpSelection: createOption(value),
      userSelection: userSelection && null,
      canImpersonate: false,
      errorMessage: '',
    });
  };

  selectUser = ({ value }: { label: string; value: string }) => {
    const { clientId } = this.props;
    const { userMap, idpSelection } = this.state;
    const userSelection = userMap
      .get(idpSelection.value)
      .find(user => user.username === value);
    this.setState({
      canImpersonate: userSelection.acceptedTerms && !userSelection.isSuperUser,
      userSelection,
      redirectUrl: createRedirectUrl(value, clientId),
      errorMessage: checkUserImpersonationRequirements(userSelection),
    });
  };

  render() {
    const { user } = this.props;
    const {
      open,
      userMap,
      numUsers,
      idpSelection,
      userSelection,
      idpList,
      searching,
      searchTerms,
      errorMessage,
      canImpersonate,
    } = this.state;
    const roles = user?.profile?.roles;
    const isSuperUser = roles?.includes('superuser');
    const isBeingImpersonated = user?.profile.superuser;
    return (
      <ImpersonationWrapper superuser={isSuperUser || isBeingImpersonated}>
        {isSuperUser && !isBeingImpersonated && (
          <ImpersonationButtonContainer>
            <ImpersonationText variant="body1">
              No impersonation session in progress
            </ImpersonationText>
            <ImpersonationButton
              onClick={this.handleModal}
              isBeingImpersonated={isBeingImpersonated}
              data-test-id="impersonate-button"
            >
              Impersonate A User
            </ImpersonationButton>
          </ImpersonationButtonContainer>
        )}
        {isBeingImpersonated && (
          <ImpersonationButtonContainer>
            <ImpersonationText variant="body1">
              You are viewing the portal as {user.profile.given_name}{' '}
              {user.profile.family_name}
            </ImpersonationText>
            <ImpersonationButton
              onClick={this.endImpersonation}
              isBeingImpersonated={isBeingImpersonated}
            >
              Stop Impersonation
            </ImpersonationButton>
          </ImpersonationButtonContainer>
        )}

        <Dialog open={open} data-test-id="impersonation-modal">
          {user && isSuperUser && (
            <ClickOutsideComponent onClickOutside={this.handleModal}>
              <form
                onSubmit={
                  !userSelection ? this.executeSearch : this.startImpersonation
                }
              >
                <Title>
                  <DialogTitle>Search for a User</DialogTitle>
                  <ClearIcon onClick={this.handleModal}>
                    <Clear />
                  </ClearIcon>
                </Title>
                <StyledDialogContent>
                  <Input
                    label="Search"
                    placeholder="Search for username, email, or name"
                    value={searchTerms}
                    onChange={this.updateSearchText}
                    inline={true}
                    data-test-id="input"
                  />
                  {!numUsers && (
                    <SearchInfo>
                      <UserResultText
                        variant="body1"
                        gutterBottom
                        data-test-id="error"
                      >
                        Enter a minimum of 3 characters
                      </UserResultText>
                    </SearchInfo>
                  )}
                  {!!numUsers && (
                    <Fragment>
                      <SearchInfo>
                        <UserResultText variant="body1" gutterBottom>
                          {numUsers} Users Found
                        </UserResultText>
                        <SearchResultText variant="caption">
                          Select the user's identity provider and username
                        </SearchResultText>
                      </SearchInfo>
                      <SelectWrapper>
                        <SelectSubWrapper>
                          <SelectLabel variant="caption" gutterBottom>
                            Identity Provider
                          </SelectLabel>
                          <Select
                            menuPortalTarget={document.querySelector(
                              '.MuiDialogTitle-root'
                            )}
                            menuPosition={'fixed'}
                            menuContainerStyle={{ zIndex: 999 }}
                            value={idpSelection}
                            options={idpList}
                            onChange={this.selectIdp}
                            backspaceRemovesValue={false}
                            isLoading={searching}
                            data-test-id="idp-select"
                          />
                        </SelectSubWrapper>
                        <SelectSubWrapper>
                          <SelectLabel variant="caption" gutterBottom>
                            Username
                          </SelectLabel>
                          <Select
                            menuPortalTarget={document.querySelector(
                              '.MuiDialogTitle-root'
                            )}
                            menuPosition={'fixed'}
                            onChange={this.selectUser}
                            isLoading={searching}
                            value={createOption(userSelection?.username)}
                            options={userMap
                              .get(idpSelection.value)
                              .map(user => {
                                return createOption(user.username);
                              })}
                            data-test-id="user-select"
                          />
                        </SelectSubWrapper>
                      </SelectWrapper>
                    </Fragment>
                  )}
                  {errorMessage && (
                    <SearchInfo>
                      <UserResultErrorText
                        variant="body1"
                        gutterBottom
                        data-test-id="error"
                      >
                        {errorMessage}
                      </UserResultErrorText>
                    </SearchInfo>
                  )}
                </StyledDialogContent>
                <DialogActions>
                  <Button
                    type={!userSelection ? 'button' : 'submit'}
                    data-test-id="start-impersonation"
                    disabled={!canImpersonate}
                  >
                    Impersonate
                  </Button>
                  <Button
                    type={!userSelection ? 'submit' : 'button'}
                    secondary={!!userSelection}
                    disabled={!searchTerms || searchTerms.length < 4}
                    loading={searching}
                    data-test-id="search"
                  >
                    Search
                  </Button>
                </DialogActions>
              </form>
            </ClickOutsideComponent>
          )}
        </Dialog>
      </ImpersonationWrapper>
    );
  }
}

export { UserImpersonationProps, UserImpersonation, UserImpersonationState };
export default withRouter<UserImpersonationProps>(UserImpersonation);
