export const widgetSchema = {
  $ref: '#/definitions/Widget',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    Widget: {
      properties: {
        id: {
          type: 'string',
        },
        type: {
          type: 'string',
        },
        children: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        config: {
          type: 'object',
        },
      },
      type: 'object',
    },
  },
};
