import { connect } from 'react-redux';
import { RootState, Dispatch } from 'shared/state';
import {
  addChild,
  updateWidgetConfig,
  selectWidget,
  setActiveWidgetId,
} from 'shared/state/ducks/sites';
import { WidgetWrapper } from './WidgetWrapper';
import { initiateLogout } from 'shared/state/ducks/session';

interface WidgetWrapperContainerProps {
  siteId: number;
  pageId: number;
  site?: Site;
  page?: Page;
  widgetId: string;
}

const mapStateToProps = (
  state: RootState,
  ownProps: WidgetWrapperContainerProps
) => ({
  widgetState: selectWidget(
    ownProps.siteId,
    ownProps.pageId,
    ownProps.widgetId
  )(state),
  activeWidgetId: state.sites.activeWidgetId,
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: WidgetWrapperContainerProps
) => ({
  initiateLogout: () => {
    dispatch(initiateLogout());
  },
  addChild: (type: string, initialConfig: any, location: number) => {
    dispatch(
      addChild(
        ownProps.siteId,
        ownProps.pageId,
        ownProps.widgetId,
        type,
        initialConfig,
        location
      )
    );
  },
  onUpdateConfig: (config: any) => {
    dispatch(
      updateWidgetConfig(
        ownProps.siteId,
        ownProps.pageId,
        ownProps.widgetId,
        config
      )
    );
  },
  setActiveWidgetId: (widgetId: string) => {
    dispatch(setActiveWidgetId(widgetId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetWrapper);
