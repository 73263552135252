// import { getFeatures } from 'shared/api/cdn';
import { Reducer } from 'redux';
import { RootState } from '../reducer';
import { Dispatch } from '..';
import { getIdps, IDP } from 'shared/api/idx';

// Actions
export const FETCH_IDPS_PENDING = 'duplo/idps/FETCH_PENDING';
export const FETCH_IDPS_FULFILLED = 'duplo/idps/FETCH_FULFILLED';
export const FETCH_IDPS_FAILED = 'duplo/idps/FETCH_FAILED';

export interface State {
  data: Array<IDP>;
  meta: StateMeta;
}

export const defaultState: State = {
  data: [],
  meta: {
    pending: false,
  },
};

// Reducer
const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_IDPS_PENDING:
      return {
        ...state,
        meta: {
          pending: true,
        },
      };
    case FETCH_IDPS_FULFILLED:
      return {
        // keep default value if feature is not defined in config
        data: action.idps,
        meta: {
          pending: false,
          retrievedAt: Date.now(),
        },
      };
    case FETCH_IDPS_FAILED:
      return {
        ...state,
        meta: {
          pending: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export const shouldFetchIdps = (rootState: RootState): boolean => {
  const meta: StateMeta = selectIdpsMeta()(rootState);
  return Date.now() - (meta.retrievedAt ?? 0) >= 600000;
};

// Action creators
export const fetchIdps = () => (dispatch: Dispatch) => {
  dispatch({
    type: FETCH_IDPS_PENDING,
  });
  getIdps()
    .then((idps: Array<IDP>) => {
      dispatch({
        type: FETCH_IDPS_FULFILLED,
        idps,
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_IDPS_FAILED,
        error: 'Could not fetch idps',
      });
    });
};

export const fetchIdpsIfNeeded = () => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  if (shouldFetchIdps(getState())) {
    dispatch(fetchIdps());
  }
};

export const selectIdpsState = () => (state: RootState): State => {
  return state.idps;
};

export const selectIdpsMeta = () => (state: RootState): StateMeta => {
  return selectIdpsState()(state).meta;
};

export const selectIdps = () => (state: RootState): Array<IDP> => {
  return selectIdpsState()(state).data;
};

export default reducer;
