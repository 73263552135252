import NavWidget from './NavWidget';
import NavWidgetControl from './NavWidgetControl';
import { Config } from './types';
import {
  defaultBackgroundData,
  defaultBackgroundImageData,
  defaultBorderData,
  defaultNavMenuItems,
  defaultPaddingData,
} from 'builder/util/constants';

export default {
  type: 'Navigation',
  displayName: 'Navigation',
  icon: 'bars',
  initialConfig: {
    height: 75,
    width: 450,
    xLocation: 10,
    yLocation: 10,
    minWidth: 70,
    minHeight: 70,
    ...defaultBackgroundData,
    ...defaultBackgroundImageData,
    ...defaultBorderData,
    ...defaultPaddingData,
    menuItems: defaultNavMenuItems,
    uniqueId: '123abc',
    lineColor: 'black',
    parentId: '',
    iconColor: 'black',
    showDividerLines: true,
    dividerLineColor: '#DADBDC',
    font: 'OptumSans',
    fontSize: 12,
    textColor: 'black',
    sectionFont: 'OptumSans',
    sectionFontSize: 12,
    sectionTextColor: 'black',
    sectionBackgroundColor: 'white',
    treeOrder: [1, 2, 3, 4],
    treeData: undefined,
    style: 'Horizontal',
    enableFullScreen: false,
    allowedIdps: [],
    isDifferentTextColorOnHover: false,
    hoverTextColor: 'black',
  },
  components: {
    render: NavWidget,
    control: NavWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
