import axios from 'shared/util/axios';

const getAskids = (search: string, page: number) => {
  return axios
    .get<RootObject>(
      `/site-builder/v3/apis/aideid/search?search=${search}&page=${page}&per_page=25`,
      {
        responseType: 'json',
      }
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export { getAskids };
