import { PureComponent } from 'react';
import styled from 'styled-components';
import { Link } from 'link-ui-react';
import Typography from 'shared/components/Typography';
import track from 'react-tracking';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  line-height: 1.42857;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
`;

const Margin = styled.div`
  margin-top: 20px;
`;

@track(
  {
    page: '/privacy',
    action: 'render',
  },
  { dispatchOnMount: true }
)
class PrivacyPolicy extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Wrapper>
        <Margin />
        <Typography variant="title">
          <b>
            Optum Privacy
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="subheading">
          <b>
            IT'S PERSONAL &trade;
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="body1">
          Protecting our consumer and customer information is very important. We
          safeguard the information of those we serve. Optum handles and
          safeguards personal information, and we understand the information we
          hold represents real people and life events.
          <br />
          <br />
          Optum Privacy is dedicated to the responsible and compliant
          collection, use, maintenance and disclosure of information for the
          individuals and customers we serve. The Optum Privacy Program is
          designed to protect information and to comply with applicable privacy
          rules and regulations. Our
          <Link inline href="/privacy" target="_self">
            Privacy Policy
          </Link>
          &nbsp;explains how we handle information collected from optum.com.
          Additional privacy policies (such as our notice of privacy practices)
          may apply depending on the specific product or service and outline how
          we handle information collected in other ways.
          <br />
          <br />
          If you have questions about the Optum Privacy Program, please{' '}
          <a href="mailto:privacy@optum.com">e-mail</a> or write to Optum
          Privacy Office, MN101-E013, 11000 Optum Circle, Eden Prairie, Minn.,
          55344.
          <br />
          <br />
        </Typography>
        <Typography variant="title">
          <b>
            Privacy policy
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="subheading">
          <b>
            Introduction
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="body1">
          We recognize that the privacy of your personal information is
          important. The purpose of this policy is to let you know how we handle
          the information collected through the use of this website. Portions of
          this website may describe privacy practices applicable to specific
          types of information or to information provided on specific web pages.
          <br />
          <br />
          This policy does not apply to information collected through other
          means such as by telephone or in person, although that information may
          be protected by other privacy policies. As used in this policy, terms
          such as “we” or “our” and “Company” refer to Optum and its current and
          future affiliated entities, including our parent company UnitedHealth
          Group.
          <br />
          <br />
          This website is intended for a United States audience. Any information
          you provide, including any personal information, will be transferred
          to and processed by a computer server located within the United
          States.
          <br />
          <br />
        </Typography>
        <Typography variant="subheading">
          <b>
            Cookies and Tracking
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="body1">
          The Company uses various technologies, which may include “cookie”
          technology, to gather information from our website visitors such as
          pages visited and how often they are visited, and to enable certain
          features on this website. “Cookies” are small text files that may be
          placed on your computer when you visit a website or click on a URL.
          Cookies may include “single-session cookies” which generally record
          information during only a single visit to a website and then are
          erased, and “persistent” cookies, which are generally stored on a
          computer unless or until they are deleted or are set to expire.
          <br />
          <br />
          You may disable cookies and similar items by adjusting your browser
          preferences at any time; however, this may limit your ability to take
          advantage of all the features on this website. You may also manage the
          use of “flash” technologies, with the{' '}
          <Link
            inline
            href="https://www.adobe.com/devnet/security.html"
            target="_blank"
          >
            Flash management tools
          </Link>{' '}
          available at Adobe's website. Note that we do not currently respond to
          web browser “Do Not Track” signals that provide a method to opt out of
          the collection of information about online activities over time and
          across third-party websites or online services because, among other
          reasons, there is no common definition of such signals and no
          industry-accepted standards for how such signals should be
          interpreted.
          <br />
          <br />
          We may also allow third party service providers to use cookies and
          other technologies to collect information and to track browsing
          activity over time and across third party websites such as web
          browsers used to read our websites, which websites are referring
          traffic or linking to our websites, and to deliver targeted
          advertisements to you. We do not control these third party
          technologies and their use is governed by the privacy policies of
          third parties using such technologies. For more information about
          third party advertising networks and similar entities that use these
          technologies, see{' '}
          <Link
            inline
            href="https://www.aboutads.info/consumers"
            target="_blank"
          >
            https://www.aboutads.info/consumers
          </Link>
          , and to opt-out of such ad networks’ and services’ advertising
          practices, go to
          <Link inline href="https://www.aboutads.info/choices" target="_blank">
            https://www.aboutads.info/choices
          </Link>
          . Once you click the link, you may choose to opt-out of such
          advertising from all participating advertising companies or only
          advertising provided by specific advertising companies.
          <br />
          <br />
          We may use analytics companies to gather information and aggregate
          data from our website visitors such as which pages are visited and how
          often they are visited, and to enable certain features on our
          websites. Information is captured using various technologies and may
          include cookies. If such technology is used, you may opt out of it by
          clicking on the “Opt Out” link at the bottom of the home web page.
          <br />
          <br />
          <Typography variant="body1">
            We may use and disclose your activity information unless restricted
            by this policy or by law. Some examples of the ways we use your
            activity information include:
            <br />
            <br />
          </Typography>
          <Typography variant="unorderedlist">
            <Typography data-test-id="terms-list1-01" variant="listitem">
              Customizing your experience on the website including managing and
              recording your preferences.
            </Typography>
            <Typography data-test-id="terms-list1-02" variant="listitem">
              Marketing, product development, and research purposes.
            </Typography>
            <Typography data-test-id="terms-list1-03" variant="listitem">
              Tracking resources and data accessed on the website.
            </Typography>
            <Typography data-test-id="terms-list1-04" variant="listitem">
              Developing reports regarding website usage, activity, and
              statistics.
            </Typography>
            <Typography data-test-id="terms-list1-05" variant="listitem">
              Assisting users experiencing website problems.
            </Typography>
            <Typography data-test-id="terms-list1-06" variant="listitem">
              Enabling certain functions and tools on this website.
            </Typography>
            <Typography data-test-id="terms-list1-07" variant="listitem">
              Tracking paths of visitors to this website and within this
              website.
              <br />
              <br />
            </Typography>
          </Typography>
        </Typography>
        <Typography variant="subheading">
          <b>
            Your Personal Information
            <br />
            <br />
          </b>
        </Typography>
        <Typography variant="body1">
          This website may include web pages that give you the opportunity to
          provide us with personal information about yourself. You do not have
          to provide us with personal information if you do not want to;
          however, that may limit your ability to use certain functions of this
          website or to request certain services or information.
          <br />
          <br />
          We may use personal information for a number of purposes such as:
          <br />
          <br />
        </Typography>
        <Typography variant="unorderedlist">
          <Typography data-test-id="terms-list1-01" variant="listitem">
            To respond to an email or particular request from you.
          </Typography>
          <Typography data-test-id="terms-list1-02" variant="listitem">
            To personalize the website for you.
          </Typography>
          <Typography data-test-id="terms-list1-03" variant="listitem">
            To process an application as requested by you.
          </Typography>
          <Typography data-test-id="terms-list1-04" variant="listitem">
            To administer surveys and promotions.
          </Typography>
          <Typography data-test-id="terms-list1-05" variant="listitem">
            To provide you with information that we believe may be useful to
            you, such as information about health products or services provided
            by us or other businesses.
          </Typography>
          <Typography data-test-id="terms-list1-06" variant="listitem">
            To perform analytics and to improve our products, websites, and
            advertising.
          </Typography>
          <Typography data-test-id="terms-list1-07" variant="listitem">
            To comply with applicable laws, regulations, and legal process.
          </Typography>
          <Typography data-test-id="terms-list1-08" variant="listitem">
            To protect someone's health, safety, or welfare.
          </Typography>
          <Typography data-test-id="terms-list1-09" variant="listitem">
            To protect our rights, the rights of affiliates or related third
            parties, or take appropriate legal action, such as to enforce our
            Terms of Use.
          </Typography>
          <Typography data-test-id="terms-list1-10" variant="listitem">
            To keep a record of our transactions and communications.
          </Typography>
          <Typography data-test-id="terms-list1-11" variant="listitem">
            As otherwise necessary or useful for us to conduct our business, so
            long as such use is permitted by law.
            <br />
            <br />
          </Typography>
          <Typography variant="body1">
            We may use personal information to contact you through any contact
            information you provide through this website, including any email
            address, telephone number, cell phone number, text message number,
            or fax number. Please see the section below titled “Our Online
            Communications Practices.”
            <br />
            <br />
            We may also share personal information within the Company, and we
            may combine personal information that you provide us through this
            website with other information we have received from you, whether
            online or offline, or from other sources such as from our vendors.
            For example, if you have purchased a product or service from us, we
            may combine personal information you provide through this website
            with information regarding your receipt of the product or service.
            <br />
            <br />
          </Typography>
          <Typography variant="subheading">
            <b>
              Sharing Personal Information
              <br />
              <br />
            </b>
          </Typography>
          <Typography variant="body1">
            We will only share your personal information with third parties as
            outlined in this policy and as otherwise permitted by law.
            <br />
            <br />
            We may share personal information if all or part of the Company is
            sold, merged, dissolved, acquired, or in a similar transaction.
            <br />
            <br />
            We may share personal information in response to a court order,
            subpoena, search warrant, law or regulation. We may cooperate with
            law enforcement authorities in investigating and prosecuting
            activities that are illegal, violate our rules, or may be harmful to
            other visitors.
            <br />
            <br />
            If you submit information or a posting to a chat room, bulletin
            board, or similar “chat” related portion of this website, the
            information you submit along with your screen name will be visible
            to all visitors, and such visitors may share with others. Therefore,
            please be thoughtful in what you write and understand that this
            information may become public.
            <br />
            <br />
            We may also share personal information with other third party
            companies that we collaborate with or hire to perform services on
            our behalf. For example, we may hire a company to help us send and
            manage email, and we might provide the company with your email
            address and certain other information in order for them to send you
            an email message on our behalf. Similarly, we may hire companies to
            host or operate some of our websites and related computers and
            software applications.
            <br />
            <br />
            This website may permit you to view your visitor profile and related
            personal information and to request changes to such information. If
            this function is available, we will include a link on this website
            with a heading such as “My Profile” or similar words. Clicking on
            the link will take you to a page through which you may review your
            visitor profile and related personal information.
            <br />
            <br />
          </Typography>
          <Typography variant="subheading">
            <b>
              Website and Information Security
              <br />
              <br />
            </b>
          </Typography>
          <Typography variant="body1">
            We maintain reasonable administrative, technical and physical
            safeguards designed to protect the information that you provide on
            this website. However, no security system is impenetrable and we
            cannot guarantee the security of our website, nor can we guarantee
            that the information you supply will not be intercepted while being
            transmitted to us over the Internet, and we are not liable for the
            illegal acts of third parties such as criminal hackers.
            <br />
            <br />
          </Typography>
          <Typography variant="subheading">
            <b>
              Our Online Communication Practices
              <br />
              <br />
            </b>
          </Typography>
          <Typography variant="body1">
            We may send electronic newsletters, notification of account status,
            and other communications, such as marketing communications, on a
            periodic basis to various individuals and organizations. We may also
            send email communications regarding topics such as general health
            benefits, website updates, health conditions, and general health
            topics. We offer you appropriate consent mechanisms, such as
            opt-out, for marketing and certain other communications. As
            examples, you may opt-out as provided for in a specific email
            communication or contact us as described below in the section
            “Contact Us.” Please be aware that opt-outs may not apply to certain
            types of communications, such as account status, website updates, or
            other communications.
            <br />
            <br />
          </Typography>
          <Typography variant="subheading">
            <b>
              Social Security Number Protection Policy
              <br />
              <br />
            </b>
          </Typography>
          <Typography variant="body1">
            Protecting personal information is important to Optum. It is our
            policy to protect the confidentiality of Social Security numbers
            ("SSNs”) that we receive or collect in the course of business. We
            secure the confidentiality of SSNs through various means, including
            physical, technical, and administrative safeguards that are designed
            to protect against unauthorized access. It is our policy to limit
            access to SSNs to that which is lawful, and to prohibit unlawful
            disclosure of SSNs.
            <br />
            <br />
          </Typography>
          <Typography variant="subheading">
            <b>
              Information for Children Under 13
              <br />
              <br />
            </b>
          </Typography>
          <Typography variant="body1">
            We will not intentionally collect any personal information from
            children under the age of 13 through this website without receiving
            parental consent. If you think that we have collected personal
            information from a child under the age of 13 through this website,
            please contact us.
            <br />
            <br />
          </Typography>
          <Typography variant="subheading">
            <b>
              Contact Us
              <br />
              <br />
            </b>
          </Typography>
          <Typography variant="body1">
            To contact us regarding this policy and our related privacy
            practices, please email or write to us at:{' '}
            <a href="mailto:privacy@optum.com">privacy@optum.com</a> or Optum
            Privacy Office, MN101-E013, 11000 Optum Circle, Eden Prairie, MN
            55344. If you believe we or any company associated with us has
            misused any of your information please contact us immediately and
            report such misuse.
            <br />
            <br />
          </Typography>
          <Typography variant="subheading">
            <b>
              Effective Date
              <br />
              <br />
            </b>
          </Typography>
          <Typography variant="body1">
            The effective date of this policy is September 30, 2016.
            <br />
            <br />
          </Typography>
          <Typography variant="subheading">
            <b>
              Changes to this Website Privacy Policy
              <br />
              <br />
            </b>
          </Typography>
          <Typography variant="body1">
            We may change this policy. If we do so, such change will appear on
            this page of our website. We will also provide appropriate notice
            and choices to you, on this website and in other appropriate
            locations, based on the scope and extent of changes. You may always
            visit this policy to learn of any updates.
            <br />
            <br />
          </Typography>
        </Typography>

        <Margin />

        <Link
          underline
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          Back to Top
        </Link>
        <Margin />
      </Wrapper>
    );
  }
}

export default PrivacyPolicy;
