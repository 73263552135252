import React from 'react';
import axios, { AxiosResponse } from 'axios';
import {
  initializeMultipart,
  getMultipartPreSignedUrls,
  finalizeMultipart,
  Part,
  abortMultipart,
} from 'shared/api/s3';
import config from 'builder/config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Progress {
  sent: number;
  total: number;
  percentage: number;
}

interface UploaderProps {
  chunkSize?: number;
  threadsQuantity?: number;
  file: File;
  siteId?: string;
  fileName?: string;
  onProgress?: (progress: Progress) => void;
  onError?: (error: Error) => void;
  onFinish?: (signedUrl: string) => void;
}

interface UploaderState {
  aborted: boolean;
  uploadedSize: number;
  progressCache: Record<number, number>;
  parts: Part[];
  uploadedParts: Part[];
  fileId: string | null;
  fileKey: string | null;
}

class Uploader extends React.Component<UploaderProps, UploaderState> {
  static defaultProps = {
    chunkSize: 1024 * 1024 * 5,
  };

  constructor(props: UploaderProps) {
    super(props);
    this.state = {
      aborted: false,
      uploadedSize: 0,
      progressCache: {},
      parts: [],
      uploadedParts: [],
      fileId: null,
      fileKey: null,
    };
  }

  componentDidMount() {
    const { file, siteId } = this.props;
    // Initialize the multipart upload process
    this.startUpload(file, siteId);
  }

  async startUpload(file: File, siteId: string) {
    try {
      // Initialize the multipart upload request
      await this.initialize(siteId, file);
    } catch (error) {
      console.error('Error during upload initialization:', error);
    }
  }

  async initialize(siteId: string, file: File) {
    try {
      // Perform the initialization of the multipart upload
      const initializeResponse = await initializeMultipart(file, siteId);
      this.setState({
        fileId: initializeResponse.uploadId,
        fileKey: initializeResponse.key,
      });

      const numberOfParts = Math.ceil(file.size / this.props.chunkSize);

      // Retrieve pre-signed URLs for uploading each part
      const urlsResponse = await getMultipartPreSignedUrls(
        siteId,
        initializeResponse.key,
        numberOfParts,
        initializeResponse.uploadId
      );

      const newParts = urlsResponse.parts;

      this.setState(prevState => ({
        parts: [...prevState.parts, ...newParts],
      }));

      // Start uploading the parts
      await this.sendNext(siteId);
    } catch (error) {
      this.completeUpload(error);
    }
  }

  async sendNext(siteId: string) {
    const numberOfParts = Math.ceil(
      this.props.file.size / this.props.chunkSize
    );

    if (
      this.state.parts.length === 0 &&
      this.state.uploadedParts.length === numberOfParts
    ) {
      // All parts have been uploaded, complete the upload process and stop the recursion
      this.completeUpload();
      return;
    }

    const part = this.state.parts.pop();

    if (part) {
      const { file } = this.props;
      const sentSize = (part.partNumber - 1) * this.props.chunkSize;
      const chunk = file.slice(sentSize, sentSize + this.props.chunkSize);

      const sendChunkStarted = () => {
        // The current chunk has started uploading, proceed to the next one
        this.sendNext(siteId);
      };

      this.sendChunk(chunk, part, sendChunkStarted).catch(error => {
        // If an error occurs, add the failed part back to the parts list and complete the upload
        this.setState(prevState => ({
          parts: [...prevState.parts, part],
        }));

        this.completeUpload(error);
      });
    }
  }

  async completeUpload(error?: Error) {
    try {
      const { fileId, fileKey } = this.state;
      const { onFinish } = this.props;

      if (!fileId || !fileKey) {
        throw new Error('Invalid file ID or file key.');
      }

      if (error) {
        throw error;
      }

      const { onProgress } = this.props;
      if (onProgress) {
        onProgress({
          sent: this.props.file.size,
          total: this.props.file.size,
          percentage: 100,
        });
      }

      // Finalize the multipart upload
      await finalizeMultipart(fileId, fileKey, this.state.uploadedParts);

      if (typeof onFinish === 'function') {
        // Call the onFinish callback with the signed URL
        onFinish(config.cdn + '/' + this.state.fileKey);
      }
    } catch (error) {
      const { onError } = this.props;
      if (onError) {
        onError(error);
      }
    }
  }

  async sendChunk(chunk: Blob, part: Part, callback: () => void) {
    const { signedUrl } = part;
    const partSize = chunk.size;

    try {
      // Upload the chunk to the signed URL using a PUT request
      const response: AxiosResponse = await axios.put(signedUrl, chunk, {
        headers: {
          'Content-Type': chunk.type,
        },
        onUploadProgress: progressEvent => {
          // Calculate and update the upload progress
          const sent =
            progressEvent.loaded + this.state.uploadedSize - partSize;
          const progress = (sent / partSize) * 100;
          this.setState(prevState => ({
            progressCache: {
              ...prevState.progressCache,
              [part.partNumber]: progress,
            },
          }));

          /*   This will fetch the overall progress of upload
          const overallProgress = this.calculateOverallProgress();
          const { onProgress } = this.props;
          if (onProgress) {
            onProgress({
              sent: sent + this.state.uploadedSize,
              total: this.props.file.size,
              percentage: overallProgress,
            });
          } */
        },
      });

      const headers = response.headers;
      const unparsedEtag = headers['etag'];
      const eTag = unparsedEtag.replace(/^"|"$/g, '');

      this.setState(prevState => {
        const uploadedParts = [...prevState.uploadedParts, { ...part, eTag }];
        uploadedParts.sort((a, b) => a.partNumber - b.partNumber); // Sort the uploaded parts by part number
        return {
          uploadedSize: prevState.uploadedSize + partSize,
          uploadedParts,
        };
      });

      callback();
    } catch (error) {
      this.setState({ aborted: true });
      this.abortUpload();

      const errorMessage = `Failed to upload part ${part.partNumber}: ${error.message}`;
      const uploadError = new Error(errorMessage);
      this.completeUpload(uploadError);
    }
  }

  abortUpload() {
    abortMultipart(this.state.fileId, this.state.fileKey);

    this.setState({
      aborted: true,
    });
    toast('Video upload aborted', { type: 'error', theme: 'colored' });
  }

  /* This piece will calculate the progress percentage
  calculateOverallProgress(): number {
    const { progressCache } = this.state;
    const completedParts = Object.keys(progressCache).length;

    if (completedParts > 0) {
      const totalProgress = Object.values(progressCache).reduce(
        (sum, progress) => sum + progress,
        0
      );
      return totalProgress / completedParts;
    }

    return 0;
  }
  */

  render(): JSX.Element {
    const { aborted } = this.state;
    if (aborted) {
      toast('Video upload aborted', { type: 'error', theme: 'colored' });
    }
    return null;
  }
}

export default Uploader;
