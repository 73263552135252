import { PureComponent } from 'react';
import { Route, RouteProps } from 'react-router';
import styled from 'styled-components';
import { User } from 'shared/state/misc/oidc';
import { getUserManager, doSignInWithLocation } from 'client/util/userManager';
import Loading from 'shared/components/Loading';
import { track } from 'shared/util/analytics/track';
import LoadingWrapper from 'client/components/LoadingWrapper';
import { Redirect } from 'react-router-dom';
import { StyledLoadingWrapper } from 'builder/routers/AuthenticatedRouter/AuthenticatedRouter';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export interface AuthenticatedRouteProps extends RouteProps {
  user: User;
  logoutPending: boolean;
  loginPending: boolean;
  initiateLogin: () => void;
  tracking?: any;
  impersonationRequested: boolean;
  endImpersonationRequested: boolean;
  currentPage?: Partial<PageVersion>;
  homepageSlug?: string;
}

export const shouldPageBeVisible = (
  allowedIdps: Array<string>,
  userIdp: string
) => {
  // if there is no allowedIdps restriction, page is visible for all IDPs
  if (!(allowedIdps && allowedIdps.length)) {
    return true;
  }

  return allowedIdps.includes(userIdp);
};

@track<AuthenticatedRouteProps>(props => {
  return {
    page: props.path,
    authenticated: !!props.user,
    user: props.user && props.user.profile.sub,
    uuid: props.user && props.user.profile.sub,
    data: { optumId: props.user && props.user.profile.preferred_username },
    idpType: props.user && (props.user.profile.identity_provider || 'link-id'),
    superuser:
      props.user &&
      props.user.profile.superuser &&
      props.user.profile.superuser.username,
    impersonation_active: props.user && !!props.user.profile.superuser,
    impersonated_user:
      props.user &&
      props.user.profile.superuser &&
      props.user.profile.preferred_username,
  };
})
class AuthenticatedRoute extends PureComponent<AuthenticatedRouteProps> {
  componentDidMount() {
    this.check(true);
  }

  componentDidUpdate() {
    this.check(false);
  }

  check = (track: boolean) => {
    const userManager = getUserManager();
    const {
      location,
      logoutPending,
      loginPending,
      initiateLogin,
      impersonationRequested,
      endImpersonationRequested,
      tracking,
    } = this.props;
    if (
      !logoutPending &&
      !loginPending &&
      !impersonationRequested &&
      !endImpersonationRequested
    ) {
      userManager
        .getUser()
        .then(user => {
          if (!user || user.expired) {
            initiateLogin();
            doSignInWithLocation(location);
          } else if (track) {
            tracking.trackEvent({
              action: 'render',
            });
          }
        })
        .catch(() => {
          initiateLogin();
          doSignInWithLocation(location);
        });
    }
  };

  render() {
    const {
      user,
      impersonationRequested,
      endImpersonationRequested,
      currentPage,
      homepageSlug,
      ...props
    } = this.props;
    if (impersonationRequested || endImpersonationRequested) {
      return (
        <LoadingWrapper>
          <Loading message="Impersonation Request Pending" />
        </LoadingWrapper>
      );
    } else if (!user) {
      return (
        <Wrapper>
          <StyledLoadingWrapper>
            <Loading message="Authenticating" />
          </StyledLoadingWrapper>
        </Wrapper>
      );
    } else if (
      !shouldPageBeVisible(
        currentPage.allowedIdps,
        user?.profile.identity_provider
      )
    ) {
      return <Redirect to={`/${homepageSlug}`} />;
    }
    return <Route {...props} />;
  }
}

export default AuthenticatedRoute;
