export enum UserAccessRequest {
  UHCG_NAME = 'U.S Network & Administrative Service Portal',
  PIC_NAME = 'Payment Integrity Compass',
  MBM_NAME = 'MBMNow Application',
  LD_NAME = 'Optum Insight Developer Account',
  SMAP_NAME = 'Specialty Management Access Portal',
  NEW_ACCESS_TEMPLATE_NAME = 'UserAccessRequest',
  REACTIVATE_ACCESS_TEMPLATE_NAME = 'UserReactivateRequest',
  PIC_DOMAIN = 'pic',
  UHCG_DOMAIN = 'uhcglobal',
  MBM_DOMAIN = 'mbm',
  LD_DOMAIN = 'link-developer',
  SMAP_DOMAIN = 'smap',
  DEFAULT_REACTIVATION_REASON = 'Please reactivate my account',
  DEFAULT_NEW_ACCESS_REASON = 'I would like to request access',
  REACTIVATE_POPUP_TITLE = 'Reactivate your account',
  NEW_ACCESS_POPUP_TITLE = 'Request Access',
  DEFAULT_ADMIN_EMAIL = 'darshanaben.amin@optum.com',
  UHCG_TA_ACCOUNT = 'UHC Global Tenant Account',
  AZURE_URL = 'https://secure.uhc.com/',
  LD_APPLICATION_ON_SECURE = 'Link Developer',
  LD_DEFAULT_ADMIN_EMAIL = 'AppsDashTeam_DL@ds.uhc.com',
  MBM_DEFAULT_ADMIN_EMAIL = 'LinkTenantAdminDL@ds.uhc.com',
  UHCG_DEFAULT_ADMIN_EMAIL = 'usnasuhcglinktenantadmin@ds.uhc.com',
}

export const SELF_REGISTRATION_SUPPORTED_TENANCIES = [
  'smap',
  'mbm',
  'link-developer',
];
