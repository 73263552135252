import { getFeatures } from 'shared/api/cdn';
import { Reducer } from 'redux';
import { RootState } from '../reducer';
import { Dispatch } from '..';

// Actions
export const FETCH_FEATURES_PENDING = 'duplo/features/FETCH_PENDING';
export const FETCH_FEATURES_FULFILLED = 'duplo/features/FETCH_FULFILLED';
export const FETCH_FEATURES_FAILED = 'duplo/features/FETCH_FAILED';

export interface Features {
  idps: {
    healthcareIdEnabled: boolean;
  };
}
export interface State {
  data: Features;
  meta: StateMeta;
}

export const defaultState: State = {
  data: {
    idps: {
      healthcareIdEnabled: true,
    },
  },
  meta: {
    pending: false,
  },
};

// Reducer
const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_FEATURES_PENDING:
      return {
        ...state,
        meta: {
          pending: true,
        },
      };
    case FETCH_FEATURES_FULFILLED:
      return {
        // keep default value if feature is not defined in config
        data: { ...state.data, ...action.features },
        meta: {
          pending: false,
          retrievedAt: Date.now(),
        },
      };
    case FETCH_FEATURES_FAILED:
      return {
        ...state,
        meta: {
          pending: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export const shouldFetchFeatures = (rootState: RootState): boolean => {
  const meta: StateMeta = selectFeaturesMeta()(rootState);
  return Date.now() - (meta.retrievedAt ?? 0) >= 60000;
};

// Action creators
export const fetchFeatures = () => (dispatch: Dispatch) => {
  dispatch({
    type: FETCH_FEATURES_PENDING,
  });
  getFeatures()
    .then((features: Features) => {
      dispatch({
        type: FETCH_FEATURES_FULFILLED,
        features,
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_FEATURES_FAILED,
        error: 'Could not fetch feature toggles',
      });
    });
};

export const fetchFeaturesIfNeeded = () => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  if (shouldFetchFeatures(getState())) {
    dispatch(fetchFeatures());
  }
};

export const selectFeaturesState = () => (state: RootState): State => {
  return state.features;
};

export const selectFeaturesMeta = () => (state: RootState): StateMeta => {
  return selectFeaturesState()(state).meta;
};

export const selectFeatures = () => (state: RootState): Features => {
  return selectFeaturesState()(state).data;
};

export default reducer;
