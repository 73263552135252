import axios from 'shared/util/axios';

export default {
  sendEmailToAdmin(adminRequestForm: AdminEmailForm) {
    return axios
      .post<AdminEmailForm>(`portal-notification/v2/form`, adminRequestForm)
      .then(response => {
        return response.status === 200
          ? 'email sent successfull to admin'
          : 'email to admin failed';
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
};
