interface Config {
  authority: string;
  cdn: string;
  baseDomains: Array<string>;
  businessKey?: string;
  permissionsEnabled?: boolean;
  requestAccessUrl: string;
  alertsEnabled: boolean;
  navWidgetEnabled: boolean;
  createSiteWizardEnabled: boolean;
  undoRedoEnabled: boolean;
  inStage: boolean;
  userManagementBaseUrl: string;
  stelliumBaseUrl?: string;
}

export const permissionConstants = {
  permissionNames: {
    all: 'All-Sites',
    site: 'Site',
  },
  permissionActions: {
    read: 'Read',
    edit: 'Edit',
    delete: 'Delete',
    create: 'Create',
  },
};

const DEFAULT_BUSINESS_KEY = 'LNK-LINKPOR-3436';
const BASE_DOMAINS_STAGE = ['optumoi-stage.com'];

const envs: { [index: string]: Config } = {
  dev: {
    authority: 'https://idx-dev.linkhealth.com/auth/realms/developer-platform',
    cdn: 'cdn-test.linkhealth.com',
    baseDomains: BASE_DOMAINS_STAGE,
    businessKey: DEFAULT_BUSINESS_KEY,
    permissionsEnabled: true,
    requestAccessUrl: 'https://developer-dev.optumoi.com/apps/registration',
    alertsEnabled: true,
    navWidgetEnabled: true,
    createSiteWizardEnabled: true,
    undoRedoEnabled: true,
    inStage: true,
    userManagementBaseUrl: 'https://developer-dev.linkhealth.com/apps/security',
    stelliumBaseUrl: 'https://developer-dev.optumoi.com/apps/stellium-beta',
  },
  test: {
    authority: 'https://idx-dev.linkhealth.com/auth/realms/developer-platform',
    cdn: 'cdn-test.linkhealth.com',
    businessKey: DEFAULT_BUSINESS_KEY,
    permissionsEnabled: true,
    baseDomains: BASE_DOMAINS_STAGE,
    requestAccessUrl: 'https://developer-dev.optumoi.com/apps/registration',
    alertsEnabled: true,
    navWidgetEnabled: true,
    createSiteWizardEnabled: true,
    undoRedoEnabled: true,
    inStage: true,
    userManagementBaseUrl: 'https://developer-dev.linkhealth.com/apps/security',
    stelliumBaseUrl: 'https://developer-dev.optumoi.com/apps/stellium-beta',
  },
  stage: {
    authority:
      'https://idx-stage.linkhealth.com/auth/realms/developer-platform',
    cdn: 'cdn-stage.linkhealth.com',
    baseDomains: BASE_DOMAINS_STAGE,
    businessKey: DEFAULT_BUSINESS_KEY,
    permissionsEnabled: true,
    requestAccessUrl: 'https://developer-stage.optumoi.com/apps/registration',
    alertsEnabled: true,
    navWidgetEnabled: true,
    createSiteWizardEnabled: true,
    undoRedoEnabled: true,
    inStage: true,
    userManagementBaseUrl:
      'https://developer-stage.linkhealth.com/apps/security',
    stelliumBaseUrl: 'https://developer-stage.optumoi.com/apps/stellium-beta',
  },
  prod: {
    authority: 'https://idx.linkhealth.com/auth/realms/developer-platform',
    cdn: 'cdn.linkhealth.com',
    baseDomains: ['optumoi.com'],
    businessKey: 'LNK-LINKPORT-444',
    permissionsEnabled: true,
    requestAccessUrl: 'https://developer.optumoi.com/request-access',
    alertsEnabled: false,
    navWidgetEnabled: true,
    createSiteWizardEnabled: true,
    undoRedoEnabled: true,
    inStage: false,
    userManagementBaseUrl: 'https://developer.linkhealth.com/apps/security',
    stelliumBaseUrl: 'https://developer.optumoi.com/apps/stellium-beta',
  },
};

export default envs[process.env.LINK_ENV] || envs.stage;
