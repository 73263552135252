import { Button } from 'link-ui-react';
import styled from 'styled-components';
import { space, width } from 'styled-system';

export const PaginationWrapper = styled.div`
  padding: 0.625em 0em 0.625em 0.625em;
  justify-content: space-between;
  display: flex;
`;

export const PaginationButtonsWrapper = styled.div`
  display: flex;
`;

export const PaginationActionButtonsWrapper = styled.div`
  display: inline-table;
  margin-left: 0.3215em;
`;

export const TextWrapper = styled.span<{
  isBold?: boolean;
  reducePadding?: boolean;
}>`
  white-space: nowrap;
  font-family: 'OptumSans';
  font-weight: ${props => (props.isBold ? 'bold' : 'normal')};
  padding: ${props => (props.reducePadding ? '0.5rem 0.25rem' : '0.5rem 1rem')};
`;

export const RawActionButton = styled.button<{
  isPreviousButton: boolean;
  tertiary: boolean;
}>`
  ${space};
  ${width};
  box-sizing: border-box;
  min-height: ${props => (props.tertiary ? '1.875em' : '2.5em')};
  padding: 0 1.25em;
  margin-right: ${props => (props.isPreviousButton ? '0.5em' : 0)};
  border-top-left-radius: 3.125em;
  border-bottom-left-radius: 3.125em;
  border-top-right-radius: 3.125em;
  border-bottom-right-radius: 3.125em;
  border: 0.0625 solid;
  background: ${props => props.theme.colors.aux.white};
  cursor: pointer;
  font-family: 'OptumSans';
  font-weight: 300;
  font-size: 1em;
  letter-spacing: 0.025em;
  text-align: center;
  outline: none;
  position: relative;
  &:active,
  &:focus,
  &:hover {
    color: #ffffff;
    background: #333333;
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px #333333;
    outline: none;
    border-color: transparent;
  }

  &:disabled {
    background: ${props => props.theme.colors.tertiary};
    color: ${props => props.theme.colors.aux.darkGrey};
    cursor: not-allowed;
    fill: ${props => props.theme.colors.aux.darkGrey};
  }
`;

export const RawButton = styled.button<{
  first?: boolean;
  last?: boolean;
  tertiary?: boolean;
}>`
  ${space};
  ${width};
  z-index: 0;
  font-weight: 400;
  padding: 0;
  border: 0.0625em solid;
  background: ${props => props.theme.colors.aux.white};
  box-sizing: border-box;
  min-height: ${props => (props.tertiary ? '1.875em' : '2.5em')};
  border-top-left-radius: ${props => (props.first ? '1.5rem' : '0')};
  border-bottom-left-radius: ${props => (props.first ? '1.5rem' : '0')};
  border-top-right-radius: ${props => (props.last ? '1.5rem' : '0')};
  border-bottom-right-radius: ${props => (props.last ? '1.5rem' : '0')};
  cursor: pointer;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 1rem;
  letter-spacing: 0.025em;
  text-align: center;
  outline: none;
  &:hover {
    background-color: #002677 !important;
    border-color: transparent !important;
    color: #ffffff;
    font-weight: 700;
    box-shadow: inset 0 0 0 3px #002677, inset 0 0 0 6px #ffffff !important;
    outline: none;
    position: relative;
    border-right: 2px solid #002677;
  }
  &:focus {
    background-color: #d9f6fa;
    font-weight: 700;
    border: 2px solid #002677;
    box-shadow: 0 0 0 3px #002677;
    position: relative;
    z-index: 1;
  }

  &:disabled {
    background: ${props => props.theme.colors.tertiary};
    color: ${props => props.theme.colors.aux.darkGrey};
    cursor: not-allowed;
    fill: ${props => props.theme.colors.aux.darkGrey};
  }
`;

export const StyledButton = styled(Button)`
  margin: 0.25em;
`;

export const StyledSelect = styled.select`
  min-width: 120px;
  width: 100%;
  height: 40px;
  color: ${p => p.theme.colors.aux.black};
  background: ${p => p.theme.colors.aux.white};
  margin-bottom: 0.5em;
`;

export const createButtons = (maxSlicedButtons, setPageSize) => {
  var buttons = [];
  var size = 25;
  for (var i = 1; i <= maxSlicedButtons; i++) {
    if (i === 1) {
      buttons.push(
        <RawButton
          key={i}
          first={true}
          value={size}
          onClick={e => {
            setPageSize(Number(e.currentTarget.value));
          }}
        >
          <TextWrapper>{size}</TextWrapper>
        </RawButton>
      );
    } else if (i === maxSlicedButtons) {
      buttons.push(
        <RawButton
          key={i}
          value={size}
          last={true}
          onClick={e => {
            setPageSize(Number(e.currentTarget.value));
          }}
        >
          <TextWrapper>{size}</TextWrapper>
        </RawButton>
      );
    } else {
      buttons.push(
        <RawButton
          key={i}
          value={size}
          onClick={e => {
            setPageSize(Number(e.currentTarget.value));
          }}
        >
          <TextWrapper>{size}</TextWrapper>
        </RawButton>
      );
    }
    size = size + size;
  }
  return buttons;
};
