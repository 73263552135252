import React, { ChangeEvent, useState } from 'react';
import { FormControl, Label, TextInput } from '@uitk/react';
import styled from 'styled-components';
import _ from 'lodash';
import {
  getMaxXYLocation,
  getValue,
} from 'builder/scenes/SiteBuilder/SiteBuilderHelper';
import { Notification } from '@uitk/react';

const SizeLocationWrapper = styled.div`
  display: inline-block;
  align-items: center;
`;

export const StyledTextInput = styled(TextInput)`
  width: 4.375em;
  height: 1.5em;
  margin-right: 0.75rem;
  color: #4b4d4f;
  font-family: 'OptumSans';
  font-size: 0.75rem;
`;

const AltLabelStyles = styled(Label)`
  color: #1d1929;
  font-size: 14px;
  font-family: OptumSans;
  font-weight: 500;
  padding-right: 0.25rem;
`;

export interface SizeLocationData {
  width: number;
  height: number;
  xLocation: number;
  yLocation: number;
  uniqueId: string;
}

export interface WidgetSizeLocationProps {
  sizeLocation: SizeLocationData;
  updateWidgetSizeLoction: (sizeLoc: SizeLocationData) => void;
  activeWidgetId: string;
}
export const WidgetSizeLocation: React.FC<WidgetSizeLocationProps> = (
  props: WidgetSizeLocationProps
) => {
  const sizeLocationData: SizeLocationData = {
    width: props.sizeLocation.width,
    height: props.sizeLocation.height,
    xLocation: props.sizeLocation.xLocation,
    yLocation: props.sizeLocation.yLocation,
    uniqueId: props.sizeLocation.uniqueId,
  };
  const [value, setValue] = useState(sizeLocationData);
  const [showError, setShowError] = useState([]);
  React.useEffect(() => {
    setValue(props.sizeLocation);
  }, [props.sizeLocation]);

  const onDataChange = (e: ChangeEvent<HTMLInputElement>, attr: string) => {
    e.preventDefault();
    const newState = _.cloneDeep(value);
    newState[attr] = Number(e.target.value);
    setValue(newState);
    props.updateWidgetSizeLoction(newState);
  };

  const validateLocation = (
    e: ChangeEvent<HTMLInputElement>,
    attribute: string
  ) => {
    const inputValue = Number(e.target.value);
    const maxXYLocation = getMaxXYLocation(
      'editingContainerId',
      props.activeWidgetId,
      inputValue
    );
    const maxXlocation = maxXYLocation[0];
    const maxYlocation = maxXYLocation[1];
    let locError = [...showError];
    switch (attribute) {
      case 'xLocation':
      case 'width':
        if (inputValue > maxXlocation) {
          locError = getLocError(locError, attribute, maxXlocation);
        } else {
          locError = locError.filter(error => {
            return error.type !== attribute;
          });
        }
        break;
      case 'yLocation':
      case 'height':
        if (inputValue > maxYlocation) {
          locError = getLocError(locError, attribute, maxYlocation);
        } else {
          locError = locError.filter(error => {
            return error.type !== attribute;
          });
        }
        break;
      default:
        locError = [];
        break;
    }
    setShowError(locError);
  };
  const getLocError = (locError: any, attr: string, maxLocation: number) => {
    const errorIndex = locError.findIndex(item => item.type === attr);
    if (errorIndex !== -1) {
      locError[errorIndex] = {
        type: attr,
        message: `Please enter ${attr} between 0 and ${maxLocation}`,
      };
    } else {
      locError.push({
        type: attr,
        message: `Please enter ${attr} between 0 and ${maxLocation}`,
      });
    }
    return locError;
  };
  return (
    <>
      {showError.map((error, key) => {
        return (
          <Notification
            className="mv-m"
            variant={'error'}
            id={'notification-error'}
            key={key}
          >
            {error.message}
          </Notification>
        );
      })}
      <SizeLocationWrapper>
        <FormControl id={'pattern-text-input'}>
          <AltLabelStyles>W</AltLabelStyles>
          <StyledTextInput
            data-test-id="width-textbox"
            min={0}
            type={'number'}
            value={getValue(value.width)}
            onChange={e => {
              onDataChange(e, 'width');
            }}
            onBlur={e => {
              validateLocation(e, 'width');
            }}
          />
        </FormControl>
      </SizeLocationWrapper>
      <SizeLocationWrapper>
        <FormControl id={'pattern-text-input'}>
          <AltLabelStyles>H</AltLabelStyles>
          <StyledTextInput
            data-test-id="height-textbox"
            min={0}
            type={'number'}
            value={getValue(value.height)}
            onChange={e => {
              onDataChange(e, 'height');
            }}
            onBlur={e => {
              validateLocation(e, 'height');
            }}
          />
        </FormControl>
      </SizeLocationWrapper>
      <SizeLocationWrapper>
        <FormControl id={'pattern-text-input'}>
          <AltLabelStyles>X</AltLabelStyles>
          <StyledTextInput
            data-test-id="x-location-textbox"
            min={0}
            type={'number'}
            value={value.xLocation}
            onChange={e => {
              onDataChange(e, 'xLocation');
            }}
            onBlur={e => {
              validateLocation(e, 'xLocation');
            }}
          />
        </FormControl>
      </SizeLocationWrapper>
      <SizeLocationWrapper>
        <FormControl id={'pattern-text-input'}>
          <AltLabelStyles>Y</AltLabelStyles>
          <StyledTextInput
            data-test-id="y-location-textbox"
            min={0}
            type={'number'}
            value={value.yLocation}
            onChange={e => {
              onDataChange(e, 'yLocation');
            }}
            onBlur={e => {
              validateLocation(e, 'yLocation');
            }}
          />
        </FormControl>
      </SizeLocationWrapper>
    </>
  );
};
