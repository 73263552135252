import { Reducer, ActionCreator, AnyAction } from 'redux';
import { ThunkActionCreator, Dispatch } from 'shared/state';
import {
  createAlert,
  fetchAlerts,
  removeAlert,
  updateAlert,
} from 'shared/api/alerts';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { selectSite } from './sites';
import { Alerts } from 'builder/util/constants';
import { isEqual, reverse, uniqBy } from 'lodash';

export interface State {
  data: Array<Alert>;
  meta: StateMeta;
}

export const defaultState: State = {
  data: [],
  meta: {
    pending: false,
  },
};

export const FETCH_ALERTS_FULFILLED = 'duplo/alerts/FETCH_ALERTS_FULFILLED';
export const FETCH_ALERTS_PENDING = 'duplo/alerts/FETCH_ALERTS_PENDING';
export const FETCH_ALERTS_FAILED = 'duplo/alerts/FETCH_ALERTS_FAILED';
export const SAVE_ALERTS_FAILED = 'duplo/alerts/SAVE_ALERTS_FAILED';
export const SAVE_ALERTS_FULFILLED = 'duplo/alerts/SAVE_ALERTS_FULFILLED';
export const SAVE_ALERTS_PENDING = 'duplo/alerts/SAVE_ALERTS_PENDING';

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ALERTS_PENDING:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: true,
        },
      };
    case FETCH_ALERTS_FULFILLED:
      return {
        ...state,
        data: action.data,
        meta: {
          ...state.meta,
          pending: false,
          successful: true,
          error: null,
        },
      };
    case FETCH_ALERTS_FAILED:
      return {
        ...state,
        data: [],
        meta: {
          ...state.meta,
          pending: false,
          successful: false,
          error: action.error,
        },
      };
    case SAVE_ALERTS_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: false,
          successful: false,
          error: action.error,
        },
      };
    case SAVE_ALERTS_PENDING:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: true,
        },
      };
    case SAVE_ALERTS_FULFILLED:
      return {
        ...state,
        data: action.data,
        meta: {
          ...state.meta,
          pending: false,
          successful: true,
          error: null,
        },
      };
    default:
      return state;
  }
};

export default reducer;

export const fetchSiteAlerts: ThunkActionCreator = (
  siteId: number
) => dispatch => {
  dispatch({ type: FETCH_ALERTS_PENDING });
  return fetchAlerts(siteId)
    .then((response: Array<Alert>) => {
      dispatch({ type: FETCH_ALERTS_FULFILLED, data: response });
    })
    .catch(() => {
      dispatch({
        type: FETCH_ALERTS_FAILED,
      });
      toast('Error fetching site alerts', { type: 'error', theme: 'colored' });
    });
};

export const saveAlertsFailed: ActionCreator<AnyAction> = (error?: string) => {
  return { type: SAVE_ALERTS_FAILED, error };
};

export const saveAlertsPending: ActionCreator<AnyAction> = () => {
  return { type: SAVE_ALERTS_PENDING };
};

export const fetchAlertsFullfiled: ActionCreator<AnyAction> = (
  alerts: Array<Alert>
) => {
  return { type: FETCH_ALERTS_FULFILLED, data: alerts };
};

export const saveAlertsFulfilled: ActionCreator<AnyAction> = (
  alerts: Array<Alert>
) => {
  return { type: SAVE_ALERTS_FULFILLED, data: alerts };
};

export const pushRemoveAlerts = (
  toRemoveAlerts: Array<Alert>,
  promises: Array<Promise<Alert[]>>,
  site: Site,
  prevAlerts: Array<Alert>,
  errors: any
) => {
  return toRemoveAlerts.map((alert: Alert) => {
    promises.push(
      removeAlert(site.id, alert.id)
        .then(() => {
          return prevAlerts.filter(a => a.id != alert.id);
        })
        .catch(() => {
          // catch error to allow other calls to still complete
          errors.siteAlerts.isError = true;
          errors.siteAlerts.message = Alerts.ALERT_ERROR_MESSAGE;
          // return site without alerts removed
          return prevAlerts;
        })
    );
  });
};

export const pushAddAlerts = (
  toAddAlerts: Array<Alert>,
  promises: Array<Promise<Alert[]>>,
  site: Site,
  prevAlerts: Array<Alert>,
  errors: any,
  toRemoveAlerts?: Array<Alert>
) => {
  return toAddAlerts.map((alert: Alert) => {
    promises.push(
      createAlert(site.id, alert)
        .then((alert: Alert) => {
          const deletedAlertIds = (toRemoveAlerts || []).map(a => a.id);
          const mergedAlerts = [...prevAlerts].filter(
            a => deletedAlertIds.indexOf(a.id) === -1
          );
          return [...mergedAlerts, alert];
        })
        .catch(() => {
          // catch error to allow other calls to still complete
          errors.siteAlerts.isError = true;
          errors.siteAlerts.message = Alerts.ALERT_ERROR_MESSAGE;
          // return site without provisiong stores removed
          return prevAlerts;
        })
    );
  });
};

export const callDispatcher = (
  data: Array<Alert>,
  errors: any,
  dispatch: Dispatch
) => {
  if (errors.siteAlerts.isError) {
    dispatch(saveAlertsFailed(errors.siteAlerts.isError));
    toast(errors.siteAlerts.message, { type: 'error', theme: 'colored' });
  } else {
    dispatch(saveAlertsFulfilled(data));
    toast('Alerts saved', { type: 'success', theme: 'colored' });
  }
};

export const pushUpdateAlerts = (
  toUpdateAlerts: Array<Alert>,
  promises: Array<Promise<Alert[]>>,
  site: Site,
  prevAlerts: Array<Alert>,
  errors: any,
  alerts: Array<Alert>,
  toRemoveAlerts?: Array<Alert>
) => {
  return toUpdateAlerts.map((alert: Alert) => {
    promises.push(
      updateAlert(site.id, alert.id, alert)
        .then((alert: Alert) => {
          const deletedAlertIds = (toRemoveAlerts || []).map(a => a.id);
          const mergedAlerts = [...prevAlerts].filter(
            a => deletedAlertIds.indexOf(a.id) === -1
          );
          mergedAlerts.splice(
            alerts.findIndex(a => a.id === alert.id),
            1,
            alert
          );
          return mergedAlerts;
        })
        .catch(() => {
          // catch error to allow other calls to still complete
          errors.siteAlerts.isError = true;
          errors.siteAlerts.message = Alerts.ALERT_ERROR_MESSAGE;
          // return site without provisiong stores removed
          return prevAlerts;
        })
    );
  });
};

export const saveAlerts: ThunkActionCreator = (siteId: number) => (
  dispatch,
  getState
) => {
  const site = selectSite(siteId)(getState());
  const promises: Array<Promise<Alert[]>> = [];
  const errors: any = {
    siteAlerts: { isError: false, message: '' },
  };

  // Create/Update/Delete Alerts
  const { alerts, prevAlerts } = site;
  const toAddAlerts: Array<Alert> = addAlerts(alerts, prevAlerts);
  const toRemoveAlerts: Array<Alert> = removeAlerts(alerts, prevAlerts);
  const existingAlerts: Array<Alert> = existingAlert(alerts, prevAlerts);
  const toUpdateAlerts: Array<Alert> = updateAlerts(existingAlerts, prevAlerts);

  if (toRemoveAlerts?.length) {
    pushRemoveAlerts(toRemoveAlerts, promises, site, prevAlerts, errors);
  }

  if (toAddAlerts?.length) {
    pushAddAlerts(
      toAddAlerts,
      promises,
      site,
      prevAlerts,
      errors,
      toRemoveAlerts
    );
  }

  if (toUpdateAlerts?.length) {
    pushUpdateAlerts(
      toUpdateAlerts,
      promises,
      site,
      prevAlerts,
      errors,
      alerts,
      toRemoveAlerts
    );
  }

  dispatch(saveAlertsPending());
  Promise.all(promises).then(results => {
    const data: Array<Alert> = getLatestUniqueAlerts(
      results.reduce((prev, current) => prev.concat(current), [])
    );
    if (promises.length >= 1) {
      callDispatcher(data, errors, dispatch);
    }
  });
};

export const addAlerts = (alerts: Array<Alert>, prevAlerts: Array<Alert>) => {
  return alerts.filter(
    alert =>
      !prevAlerts
        .reduce((k, v) => k.concat(v), [])
        .map(p => p.id)
        .includes(alert.id)
  );
};

export const removeAlerts = (
  alerts: Array<Alert>,
  prevAlerts: Array<Alert>
) => {
  return prevAlerts.filter(
    alert =>
      !alerts
        .reduce((k, v) => k.concat(v), [])
        .map(p => p.id)
        .includes(alert.id)
  );
};

export const existingAlert = (
  alerts: Array<Alert>,
  prevAlerts: Array<Alert>
) => {
  return alerts.filter(alert =>
    prevAlerts
      .reduce((k, v) => k.concat(v), [])
      .map(p => p.id)
      .includes(alert.id)
  );
};

export const updateAlerts = (
  existingAlerts: Array<Alert>,
  prevAlerts: Array<Alert>
) => {
  return existingAlerts.filter(
    alert =>
      !isEqual(
        [alert],
        prevAlerts.filter(a => a.id === alert.id)
      )
  );
};

export const getLatestUniqueAlerts = (results: Array<Alert>) => {
  return reverse(uniqBy(reverse(results.filter(alert => alert?.id)), 'id'));
};
