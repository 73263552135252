import { PureComponent } from 'react';
import { Redirect, Route, Switch, RouteProps } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SiteList from 'builder/scenes/SiteList';
import userManager from 'builder/util/userManager';
import { User } from 'shared/state/misc/oidc';
import SiteRouter from 'builder/routers/SiteRouter';
import { persistLocation } from 'shared/util/locationPersistence';
import Loading from 'builder/scenes/Loading';
import Failure from 'builder/scenes/Failure';
import env from 'builder/config';
import { Location } from 'history';
import styled from 'styled-components';

export const StyledLoadingWrapper = styled.span`
  & > div > h6 {
    font-family: OptumSans;
  }
`;

export interface AuthenticatedRouterProps extends RouteProps {
  user?: User;
  logoutPending?: boolean;
  loginPending?: boolean;
  isLoadingUser: boolean;
  builderPermissions?: string[];
  initiateLogin: () => void;
  fetchSites: () => void;
  fetchSitesByUuid: () => void;
  sites: Array<Site>;
  sitesMeta: StateMeta;
}

class AuthenticatedRouter extends PureComponent<AuthenticatedRouterProps> {
  redirectInProgress: boolean = false;

  hasAccess = () => {
    return true;
  };

  checkUser = () => {
    const {
      user,
      logoutPending,
      loginPending,
      location,
      isLoadingUser,
    } = this.props;
    if (
      //valid user
      user &&
      !user.expired &&
      user.expires_at > new Date().valueOf() / 1000
    ) {
      if (!this.hasAccess() && !this.redirectInProgress) {
        // user hasn't been granted access to duplo/builder UI, send them to request access page
        this.redirectInProgress = true;
        toast(
          "You don't have access to this application, redirecting to access request page...",
          {
            onClose: () => {
              window.location.href = env.requestAccessUrl;
            },
            type: 'warning',
            autoClose: 3000,
            theme: 'colored',
          }
        );
      }
    } else if (!isLoadingUser && !logoutPending && !loginPending) {
      this.startLogin(location);
    }
  };

  startLogin = (location: Location) => {
    persistLocation(location);
    this.props.initiateLogin();
    userManager.signinRedirect();
  };

  componentDidMount() {
    this.checkUser();
    this.loadSites();
  }

  componentDidUpdate() {
    this.checkUser();
    this.loadSites();
  }

  loadSites = () => {
    const { user, sitesMeta } = this.props;
    if (
      user &&
      this.hasAccess() &&
      !sitesMeta.pending &&
      !sitesMeta.successful
    ) {
      this.props.fetchSitesByUuid();
    }
  };

  render() {
    const {
      user,
      logoutPending,
      loginPending,
      sitesMeta,
      sites,
      isLoadingUser,
    } = this.props;
    if (user && this.hasAccess() && sitesMeta.successful) {
      return (
        <Switch>
          <Route path="/sites" exact component={SiteList} />
          <Route
            path="/sites/:siteId"
            render={props => {
              const siteId = parseInt(props.match.params.siteId, 0);

              if (
                isNaN(siteId) ||
                (env.permissionsEnabled &&
                  !sites.some(
                    site => site.id === siteId && site.access.read
                  )) ||
                (!env.permissionsEnabled &&
                  !sites.some(site => site.id === siteId))
              ) {
                return <Redirect to="/sites" />;
              }

              return <SiteRouter siteId={siteId} {...props} />;
            }}
          />
          <Route path="/forms" exact component={SiteList} />
          <Route
            path="/forms/:formName"
            render={props => {
              const formName = props.match.params.formName;

              if (!formName) {
                return <Redirect to="/forms" />;
              }

              return <SiteList {...props} />;
            }}
          />
          <Route>
            <Redirect to="/sites" />
          </Route>
        </Switch>
      );
    } else if (logoutPending) {
      return (
        <StyledLoadingWrapper>
          <Loading message="Logging Out" />
        </StyledLoadingWrapper>
      );
    } else if (isLoadingUser || loginPending || !this.hasAccess()) {
      return (
        <StyledLoadingWrapper>
          <Loading message="Authenticating" />
        </StyledLoadingWrapper>
      );
    } else if (sitesMeta.pending) {
      return (
        <StyledLoadingWrapper>
          <Loading message="Loading sites" />
        </StyledLoadingWrapper>
      );
    } else {
      return <Failure />;
    }
  }
}

export default AuthenticatedRouter;
