import { TextField } from '@material-ui/core';
import { Button } from '@uitk/react';
import { SiteStylesProps } from 'builder/scenes/SiteBuilder/SiteStylesPanel/SiteStylesPanel';
import { useState } from 'react';
import { HexAlphaColorPicker } from 'react-colorful';
import styled from 'styled-components';

const StyledPicker = styled.div`
  .react-colorful {
    width: 16rem;
    margin: 0 0.25rem;
  }
  .react-colorful__pointer {
    width: 1.375rem;
    height: 1.375rem;
  }
`;

const StyledInput = styled(TextField)`
  width: 6rem;
  flex-direction: row;
  justify-items: space-evenly;
  .MuiOutlinedInput-input {
    padding: 0.35rem 0 0.35rem 0.35rem;
    font-family: OptumSans;
    font-size: 0.8rem;
  }
  .MuiFormHelperText-root {
    font-family: OptumSans;
    font-size: 0.65rem;
    line-height: 1;
    letter-spacing: 0;
  }
  .MuiFormHelperText-contained {
    margin: 0.3rem 0 0 0;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  .MuiFormControl-root {
    margin: 0.5rem;
  }
`;

const ColorPip = styled.div`
  margin: 0.5rem 0.25rem 0.5rem 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
`;

const ButtonWrapper = styled.div`
  margin: 0.5rem;
  .uitk-button {
    font-family: OptumSans;
    font-weight: 700;
  }
`;

export interface StylePanelColorPickerProps {
  activeColor: string;
  handleColorFunction: (
    currentColor: string,
    currentColorName: string,
    currentColorType: string,
    props: SiteStylesProps
  ) => void;
  props: any;
  activeColorName: string;
  activeColorType: string;
  colors: any;
}

export const StylePanelColorPicker: React.FC<StylePanelColorPickerProps> = (
  props: StylePanelColorPickerProps
) => {
  const [currentColor, setCurrentColor] = useState(props.activeColor);
  const [currentColorName, setCurrentColorName] = useState(
    props.activeColorName
  );
  const [currentColorType, _] = useState(
    props.activeColorType ? props.activeColorType : undefined
  );
  const [isValidHex, setIsValidHex] = useState(
    currentColor === undefined || '' ? false : true
  );
  const [isValidName, setIsValidName] = useState(
    currentColorName === undefined || '' ? false : true
  );

  const HEX_REGEX = new RegExp(
    '^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8})$'
  );

  const handleColorChange = (newColor: string) => {
    if (props.colors.map(c => c.value).includes(newColor)) {
      setIsValidHex(false);
    } else {
      setIsValidHex(true);
      setCurrentColor(newColor);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  const handleHexInput = (e: React.ChangeEvent<any>) => {
    setCurrentColor(e.currentTarget.value);
    if (HEX_REGEX.test(e.currentTarget.value)) {
      setIsValidHex(true);
    } else {
      setIsValidHex(false);
    }
  };

  const handleNameInput = (e: React.ChangeEvent<any>) => {
    setCurrentColorName(e.currentTarget.value);
    if (e.currentTarget.value !== '') {
      setIsValidName(true);
    } else {
      setIsValidName(false);
    }
  };

  return (
    <>
      <StyledPicker data-test-id="style-color-picker" onClick={handleClick}>
        <HexAlphaColorPicker
          data-test-id="horizon-color-picker"
          color={currentColor}
          onChange={handleColorChange}
        />
      </StyledPicker>
      <InputWrapper onClick={handleClick}>
        <ColorPip
          data-test-id="color-display-pip"
          style={{ background: `${currentColor}` }}
        />
        <StyledInput
          data-test-id="color-title-input"
          variant="outlined"
          value={currentColorName}
          disabled={currentColorType === 'Default' ? true : false}
          onChange={handleNameInput}
          placeholder="Name"
          error={isValidName ? false : true}
          helperText={isValidName ? '' : 'Please enter a name'}
        />
        <StyledInput
          data-test-id="color-hex-input"
          variant="outlined"
          defaultValue={props.activeColor}
          onChange={handleHexInput}
          value={currentColor}
          placeholder="(#) Hex"
          error={isValidHex ? false : true}
          helperText={
            isValidHex
              ? ''
              : 'Please enter a valid hex code or use the selector above'
          }
        />
      </InputWrapper>
      <ButtonWrapper>
        <Button
          data-test-id="save-color-button"
          disabled={(isValidHex ? false : true) || (isValidName ? false : true)}
          onPress={() =>
            props.handleColorFunction(
              currentColor,
              currentColorName,
              currentColorType,
              props.props
            )
          }
          block
        >
          Save Color
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default StylePanelColorPicker;
