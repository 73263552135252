import { PureComponent } from 'react';
import styled from 'styled-components';
import { LinearProgress, Typography } from '@material-ui/core';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledProgress = styled(LinearProgress)`
  width: calc(100% / 3);
`;

const Message = styled(Typography)``;

interface LoadingProps {
  message?: string;
}

class Loading extends PureComponent<LoadingProps> {
  render() {
    const { message } = this.props;
    return (
      <Wrapper>
        {message && (
          <Message variant="subtitle1" gutterBottom>
            {message}
            ...
          </Message>
        )}
        <StyledProgress variant="indeterminate" />
      </Wrapper>
    );
  }
}

export default Loading;
