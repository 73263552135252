export const ANALYTICS_STORAGE_KEY = 'ELASTIC-DATA';

let bulkObj: string;

const persistData = (trackingData: any) => {
  if (checkForData()) {
    const existingData = JSON.parse(getExistingData());
    bulkObj = JSON.stringify(Object.assign({}, existingData, trackingData));
  } else {
    bulkObj = JSON.stringify(trackingData);
  }
  window.localStorage.setItem(ANALYTICS_STORAGE_KEY, bulkObj);
};
const getExistingData = () => {
  return window.localStorage.getItem(ANALYTICS_STORAGE_KEY);
};
const removeExistingData = () => {
  window.localStorage.removeItem(ANALYTICS_STORAGE_KEY);
};
const checkForData = (): boolean => {
  return !!window.localStorage.getItem(ANALYTICS_STORAGE_KEY);
};

export { persistData, getExistingData, removeExistingData, checkForData };
