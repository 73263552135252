import React, { useState, useEffect, ChangeEvent } from 'react';
import { Panel, Label, Button } from '@uitk/react';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import { Config } from './types';
import {
  SizeLocationPanel,
  StyledPanel,
  StyledPanelTitle,
} from '../Text/TextWidgetControl';
import ButtonPanel from 'builder/components/WidgetConfigurations/Button/ButtonPanel';
import {
  MainLabelStyles,
  Typography,
} from 'builder/components/WidgetConfigurations/Typography/Typography';
import {
  Background,
  BackgroundData,
  ColorButtonWrapper,
} from 'builder/components/WidgetConfigurations/Background/Background';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';
import styled from 'styled-components';
import {
  StyledCheckBox,
  StyledForm,
  StyledFormControl,
  StyledInnerDiv,
  StyledOuterDiv,
  StyledSelect,
} from '../Link/LinkWidgetControl';
import ColorPicker, {
  StyledPickerPopover,
  StyledPickerWrapper,
  StyledPickerHeader,
  StyledClose,
} from 'builder/components/ColorPicker/ColorPicker';
import S3UploadFile from 'builder/components/S3UploadFile';
import { PDF_AND_WORD_REGEX, VALID_UPLOAD_FILES } from 'builder/util/constants';
import { UploadedFile } from '../shared/types';
import { ColorButton } from 'builder/components/ColorButton/ColorButton';
import { StyledTextInput } from 'builder/components/WidgetConfigurations/TextInput/TextInputPanel';

const TypographyPanel = styled(Panel)``;

export const ButtonContainer = styled.div`
  display: flex;
  background: #ffffff;
  padding: 1rem;
  display: ruby-text;
  width: 100%;
`;

export const RegularButton = styled(Button)<{ currentTab: number }>`
  font-family: OptumSans;
  border: solid 1px #64636f;
  border-radius: 5px 0 0 5px;
  border-color: #64636f;
  background: #ffffff;
  color: #64636f;
  height: 2rem;
  width: 45%;
  display: block;
  padding: 0rem;
  font-weight: normal;
  :hover {
    ${p =>
      p.currentTab === 0
        ? 'box-shadow: none; background: #64636f; font-weight: bold; color: #ffffff;'
        : 'box-shadow: none; background: #ffffff; font-weight: normal; color: #64636f; border: solid 1px #64636f;'}
  }
  :focus {
    box-shadow: none;
    background: #64636f;
    font-weight: bold;
    color: #ffffff;
  }
  ${p =>
    p.currentTab === 0
      ? 'box-shadow: none; background: #64636f; font-weight: bold; color: #ffffff;'
      : ''}
`;

export const HoverButton = styled(Button)<{ currentTab: number }>`
  font-family: OptumSans;
  border: solid 1px #64636f;
  border-radius: 0 5px 5px 0;
  border-color: #64636f;
  background: #ffffff;
  color: #64636f;
  height: 2rem;
  width: 45%;
  display: block;
  padding: 0rem;
  font-weight: normal;
  :hover {
    ${p =>
      p.currentTab === 1
        ? 'box-shadow: none; background: #64636f; font-weight: bold; color: #ffffff;'
        : 'box-shadow: none; background: #ffffff; font-weight: normal; color: #64636f; border: solid 1px #64636f;'}
  }
  :focus {
    box-shadow: none;
    background: #64636f;
    font-weight: bold;
    color: #ffffff;
  }
  ${p =>
    p.currentTab === 1
      ? 'box-shadow: none; background: #64636f; font-weight: bold; color: #ffffff;'
      : ''}
`;

export const StyledPanelGroup = styled(Panel.Group)`
  margin-top: -1.5rem;
`;

const LinkOptionsWrapper = styled.div`
  padding-top: 1.5rem;
  display: inline-flex;
  flex-direction: column;
`;

export const ButtonWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const { value, onChange, widgetState, pages } = props;
  const { displayText } = widgetState.config;
  const [currentTab, setCurrentTab] = useState(widgetState.config.currentTab);
  const [checked, setChecked] = useState(widgetState.config.checked);
  const [pageValue, setPageValue] = useState(
    widgetState.config.selectedPage?.id.toString()
  );
  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover' : undefined;

  useEffect(() => {
    if (checked === 'None') {
      onChange({
        ...value,
        checked: 'None',
        url: '',
        newTab: false,
        iconColor: '',
        selectedPage: null,
      });
      setPageValue('');
    }
  }, [checked]);

  useEffect(() => {
    setChecked(value.checked);
    setCurrentTab(value.currentTab);
    setPageValue(value.selectedPage?.id.toString());
  }, [widgetState.config]);

  const handleCurrentTab = e => {
    switch (e.target.id) {
      case 'regular':
        setCurrentTab(0);
        onChange({
          ...value,
          currentTab: 0,
        });
        break;
      case 'hover':
        setCurrentTab(1);
        onChange({
          ...value,
          currentTab: 1,
        });
        break;
      default:
    }
  };

  const updateWidgetSizeLoction = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };

  const handleLineColor = data => {
    switch (currentTab) {
      case 0:
        onChange({
          ...value,
          lineColor: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            lineColor: data,
          },
        });
        break;
      default:
    }
  };

  const handleIconStyle = data => {
    switch (currentTab) {
      case 0:
        onChange({
          ...value,
          iconStyle: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            iconStyle: data,
          },
        });
        break;
      default:
    }
  };

  const handleIcon = (data: Image) => {
    switch (currentTab) {
      case 0:
        onChange({
          ...value,
          icon: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            icon: data,
          },
        });
        break;
      default:
    }
  };

  const handleIconPlacement = data => {
    switch (currentTab) {
      case 0:
        onChange({
          ...value,
          iconPlacement: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            iconPlacement: data,
          },
        });
        break;
      default:
    }
  };

  const handleIconAltText = data => {
    switch (currentTab) {
      case 0:
        onChange({
          ...value,
          iconAltText: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            iconAltText: data,
          },
        });
        break;
      default:
    }
  };

  const handleBoldData = data => {
    switch (currentTab) {
      case 0:
        onChange({
          ...value,
          bold: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            bold: data,
          },
        });
        break;
      default:
    }
  };

  const handleItalic = data => {
    switch (currentTab) {
      case 0:
        onChange({
          ...value,
          italic: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            italic: data,
          },
        });
        break;
      default:
    }
  };

  const handleAlignment = data => {
    switch (currentTab) {
      case 0:
        onChange({
          ...value,
          align: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            align: data,
          },
        });
        break;
      default:
    }
  };

  const handleTextColorData = (data: string) => {
    switch (currentTab) {
      case 0:
        onChange({
          ...value,
          textColor: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            textColor: data,
          },
        });
        break;
      default:
    }
  };

  const handleTextHighlightColorData = data => {
    onChange({
      ...value,
      textHighlightColor: data,
    });
  };

  const handleFontSize = data => {
    switch (currentTab) {
      case 0:
        onChange({
          ...value,
          fontSize: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            fontSize: data,
          },
        });
        break;
      default:
    }
  };

  const handleFont = data => {
    switch (currentTab) {
      case 0:
        onChange({
          ...value,
          font: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            font: data,
          },
        });
        break;
      default:
    }
  };

  const handleLineSpacing = data => {
    {
      switch (currentTab) {
        case 0:
          onChange({
            ...value,
            lineSpacing: data,
          });
          break;
        case 1:
          onChange({
            ...value,
            hoverData: {
              ...value.hoverData,
              lineSpacing: data,
            },
          });
          break;
        default:
      }
    }
  };

  const handleCharacterSpacing = data => {
    {
      switch (currentTab) {
        case 0:
          onChange({
            ...value,
            characterSpacing: data,
          });
          break;
        case 1:
          onChange({
            ...value,
            hoverData: {
              ...value.hoverData,
              characterSpacing: data,
            },
          });
          break;
        default:
      }
    }
  };

  const handleUnderline = data => {
    {
      switch (currentTab) {
        case 0:
          onChange({
            ...value,
            underline: data,
          });
          break;
        case 1:
          onChange({
            ...value,
            hoverData: {
              ...value.hoverData,
              underline: data,
            },
          });
          break;
        default:
      }
    }
  };

  const handleStrikeThrough = data => {
    {
      switch (currentTab) {
        case 0:
          onChange({
            ...value,
            strikeThrough: data,
          });
          break;
        case 1:
          onChange({
            ...value,
            hoverData: {
              ...value.hoverData,
              strikeThrough: data,
            },
          });
          break;
        default:
      }
    }
  };

  const updateBorderData = (data: BorderData) => {
    switch (currentTab) {
      case 0:
        onChange({
          ...value,
          borderData: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            borderData: data,
          },
        });
        break;
      default:
    }
  };

  const onDisplayTextChange = (e: ChangeEvent<any>) => {
    e.preventDefault();
    const { config } = props.widgetState;
    onChange({
      ...config,
      displayText: e.target.value,
    });
  };

  const handleEmailAddress = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      emailAddress: e.target.value,
    });
  };

  const handleEmailSubject = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      emailSubject: e.target.value,
    });
  };

  const setValue = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.value);
    onChange({
      ...value,
      checked: e.target.value,
    });
  };

  const handlePage = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setPageValue(e.target.value);
    if (
      e.target.value &&
      pages.find(page => page.id === parseInt(e.target.value))
    ) {
      onChange({
        ...value,
        selectedPage: pages.find(page => page.id === parseInt(e.target.value)),
        url: `https://${props.site.domain}/${
          pages.find(page => page.id === parseInt(e.target.value)).current.slug
        }`,
      });
    } else {
      setPageValue('');
    }
  };

  const handleUrl = (e: ChangeEvent<HTMLInputElement>) => {
    if (checked === 'URL') {
      onChange({
        ...value,
        url: e.target.value,
      });
    }
  };

  const handleUpload = (file: UploadedFile) => {
    onChange({
      ...value,
      url: file.url,
      uploadedFile: file,
    });
  };

  const handleNewTabCheck = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      newTab: e.target.checked,
    });
  };

  const handleColorEditClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    setPickerAnchorEl(e.currentTarget);
  };

  const handlePickerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  const handleIconColor = data => {
    onChange({
      ...value,
      iconColor: data,
    });
  };

  const handleColorChange = (newColor: string) => {
    handleIconColor(newColor);
  };

  const handleBackgroundColor = (newColor: string) => {
    switch (currentTab) {
      case undefined:
      case 0:
        onChange({
          ...value,
          backgroundData: {
            ...value.backgroundData,
            color: newColor,
          },
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            backgroundData: {
              ...value.hoverData.backgroundData,
              color: newColor,
            },
          },
        });
        break;
      default:
    }
  };

  const updateBackgroundData = (data: BackgroundData) => {
    switch (currentTab) {
      case undefined:
      case 0:
        onChange({
          ...value,
          backgroundData: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            backgroundData: data,
          },
        });
        break;
      default:
    }
  };

  return (
    <>
      <ButtonContainer>
        <RegularButton
          id="regular"
          onPress={handleCurrentTab}
          currentTab={currentTab}
          data-test-id="regular-button"
        >
          Regular
        </RegularButton>
        <HoverButton
          id="hover"
          onPress={handleCurrentTab}
          currentTab={currentTab}
          data-test-id="hover-button"
        >
          Hover
        </HoverButton>
      </ButtonContainer>
      {currentTab === 0 ? (
        <StyledPanelGroup>
          <StyledPanel>
            <Panel
              title={<Label style={StyledPanelTitle}>General</Label>}
              icon={Arrow}
              data-test-id="regular-general-panel"
            >
              <ButtonPanel
                data-test-id="general-section-for-button-component-regular"
                pages={props.pages}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
                widgetState={widgetState}
                currentTab={currentTab}
                handleIcon={handleIcon}
                handleLineColor={handleLineColor}
                handleIconStyle={handleIconStyle}
                handleIconPlacement={handleIconPlacement}
                handleIconAltText={handleIconAltText}
              />
              <MainLabelStyles>Display text</MainLabelStyles>
              <StyledTextInput
                name="displayText"
                value={displayText}
                onChange={onDisplayTextChange}
                data-test-id="display-text-input"
              />
              <LinkOptionsWrapper>
                <MainLabelStyles data-test-id="link-actions-label">
                  What do you want to link to?
                </MainLabelStyles>
                <StyledSelect
                  onChange={e => setValue(e)}
                  value={checked}
                  data-test-id="link-options-dropdown"
                >
                  <option value="None">None</option>
                  <option value="Email">Email</option>
                  <option value="File">File</option>
                  <option value="Page">Page</option>
                  <option value="URL">URL</option>
                </StyledSelect>
              </LinkOptionsWrapper>
              {checked === 'Email' && (
                <>
                  <StyledTextInput
                    name="emailAddress"
                    value={widgetState.config.emailAddress}
                    onChange={handleEmailAddress}
                    placeholder="Type email address here"
                    data-test-id="email-address-input"
                  />

                  <StyledTextInput
                    name="emailSubject"
                    value={widgetState.config.emailSubject}
                    onChange={handleEmailSubject}
                    placeholder="Type email subject line here"
                    style={{ marginTop: '10px' }}
                    data-test-id="email-subject-input"
                  />
                </>
              )}
              {checked === 'Page' && (
                <StyledFormControl id={'controlled-select'}>
                  <StyledSelect
                    onChange={handlePage}
                    value={pageValue}
                    data-test-id="page-select-dropdown"
                  >
                    <option value="none">Select a Page</option>
                    {pages.map(
                      page =>
                        page.current.slug !== 'defaultContentPage' && (
                          <option key={page.id} value={page.id}>
                            {page.current.title}
                          </option>
                        )
                    )}
                  </StyledSelect>
                </StyledFormControl>
              )}
              {checked === 'URL' && (
                <StyledTextInput
                  name="webaddress"
                  value={widgetState.config.url}
                  onChange={handleUrl}
                  placeholder="http:// or https://"
                  data-test-id="web-address-input"
                />
              )}
              {checked === 'File' && (
                <S3UploadFile
                  label={'File (.pdf, .doc, .docx format only)'}
                  onUpload={handleUpload}
                  file={widgetState.config.uploadedFile}
                  accept={VALID_UPLOAD_FILES}
                  icon={'Upload'}
                  iconProps={{
                    width: '1.2em',
                    height: '1.3em',
                    viewBox: '0 0 24 24',
                  }}
                  fileFormatRegex={PDF_AND_WORD_REGEX}
                  data-test-id={`file-uploader`}
                />
              )}
              {(checked === 'Page' ||
                checked === 'URL' ||
                checked === 'File') && (
                <>
                  <StyledOuterDiv>
                    <StyledForm id="webaddress-checkbox">
                      <StyledCheckBox
                        checked={widgetState.config.newTab}
                        onChange={handleNewTabCheck}
                      >
                        Open in new tab
                      </StyledCheckBox>
                    </StyledForm>
                    {widgetState.config.newTab && (
                      <StyledInnerDiv>
                        Icon color
                        <ColorButtonWrapper
                          id="icon-color"
                          onClick={handleColorEditClick}
                        >
                          <ColorButton color={widgetState.config.iconColor} />
                        </ColorButtonWrapper>
                      </StyledInnerDiv>
                    )}
                    <StyledPickerPopover
                      data-test-id="widget-color-picker-popover"
                      id={pickerId}
                      open={pickerOpen}
                      onClick={handlePickerClick}
                      onClose={handlePickerClose}
                      anchorEl={pickerAnchorEl}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                    >
                      <StyledPickerWrapper>
                        <StyledPickerHeader data-test-id="styled-picker-header">
                          Color Selector
                        </StyledPickerHeader>
                        <StyledClose
                          data-test-id="close-icon"
                          onClick={handlePickerClose}
                        />
                      </StyledPickerWrapper>
                      <ColorPicker
                        data-test-id="background-color"
                        site={props.site}
                        handleColorChange={handleColorChange}
                        updateSite={props.updateSite}
                        saveSite={props.saveSite}
                        anchorEl={pickerAnchorEl}
                      ></ColorPicker>
                    </StyledPickerPopover>
                  </StyledOuterDiv>
                </>
              )}
            </Panel>
          </StyledPanel>
          <StyledPanel>
            <TypographyPanel
              title={<Label style={StyledPanelTitle}>Typography</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="regular-typography-panel"
            >
              <Typography
                data-test-id="typography-component-regular"
                boldData={handleBoldData}
                handleFontSize={handleFontSize}
                handleFont={handleFont}
                handleCharacterSpacing={handleCharacterSpacing}
                handleLineSpacing={handleLineSpacing}
                handleItalicData={handleItalic}
                handleUnderLine={handleUnderline}
                handleStrikeThrough={handleStrikeThrough}
                handleTextColor={handleTextColorData}
                handleTextHighlightColor={handleTextHighlightColorData}
                leftAlignData={handleAlignment}
                centerAlignData={handleAlignment}
                rightAlignData={handleAlignment}
                pages={props.pages}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
                widgetState={widgetState}
                currentTab={currentTab}
              />
            </TypographyPanel>
          </StyledPanel>
          <StyledPanel>
            <SizeLocationPanel
              title={<Label style={StyledPanelTitle}>Size and Location</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="button-size-location-panel"
            >
              <WidgetSizeLocation
                data-test-id="widget-size-location-regular"
                sizeLocation={{
                  xLocation: value.xLocation,
                  yLocation: value.yLocation,
                  width: value.width,
                  height: value.height,
                  uniqueId: value.uniqueId,
                }}
                updateWidgetSizeLoction={updateWidgetSizeLoction}
                activeWidgetId={widgetState.id}
              />
            </SizeLocationPanel>
          </StyledPanel>
          <StyledPanel>
            <Panel
              key={'regular-background-panel'}
              title={<Label style={StyledPanelTitle}>Background</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="regular-background-panel"
            >
              <Background
                key={'regular-background-component'}
                data-test-id="regular-background-component"
                backgroundData={updateBackgroundData}
                widgetState={widgetState}
                handleLineColor={handleLineColor}
                handleBackgroundColor={handleBackgroundColor}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
                currentTab={currentTab}
              />
            </Panel>
          </StyledPanel>
          <StyledPanel>
            <Panel
              title={<Label style={StyledPanelTitle}>Border</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="regular-border-panel"
            >
              <Border
                data-test-id="border-data-regular"
                borderData={updateBorderData}
                handleLineColor={handleLineColor}
                widgetState={widgetState}
                pages={props.pages}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
                currentTab={currentTab}
              />
            </Panel>
          </StyledPanel>
        </StyledPanelGroup>
      ) : (
        <StyledPanelGroup>
          <StyledPanel>
            <Panel
              title={<Label style={StyledPanelTitle}>General</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="hover-general-panel"
            >
              <ButtonPanel
                data-test-id="general-section-for-button-component-hover"
                pages={props.pages}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
                widgetState={widgetState}
                currentTab={currentTab}
                handleIcon={handleIcon}
                handleLineColor={handleLineColor}
                handleIconStyle={handleIconStyle}
                handleIconPlacement={handleIconPlacement}
                handleIconAltText={handleIconAltText}
              />
            </Panel>
          </StyledPanel>
          <StyledPanel>
            <TypographyPanel
              title={<Label style={StyledPanelTitle}>Typography</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="hover-typography-panel"
            >
              <Typography
                data-test-id="typography-component-hover"
                boldData={handleBoldData}
                handleFontSize={handleFontSize}
                handleFont={handleFont}
                handleCharacterSpacing={handleCharacterSpacing}
                handleLineSpacing={handleLineSpacing}
                handleItalicData={handleItalic}
                handleUnderLine={handleUnderline}
                handleStrikeThrough={handleStrikeThrough}
                handleTextColor={handleTextColorData}
                handleTextHighlightColor={handleTextHighlightColorData}
                leftAlignData={handleAlignment}
                centerAlignData={handleAlignment}
                rightAlignData={handleAlignment}
                pages={props.pages}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
                widgetState={widgetState}
                currentTab={currentTab}
              />
            </TypographyPanel>
          </StyledPanel>
          <StyledPanel>
            <Panel
              key={'hover-background-panel'}
              title={<Label style={StyledPanelTitle}>Background</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="hover-background-panel"
            >
              <Background
                key={'hover-background-component'}
                data-test-id="hover-background-component"
                backgroundData={updateBackgroundData}
                widgetState={widgetState}
                handleLineColor={handleLineColor}
                handleBackgroundColor={handleBackgroundColor}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
                currentTab={currentTab}
              />
            </Panel>
          </StyledPanel>
          <StyledPanel>
            <Panel
              title={<Label style={StyledPanelTitle}>Border</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="hover-border-panel"
            >
              <Border
                data-test-id="border-data-hover"
                borderData={updateBorderData}
                handleLineColor={handleLineColor}
                widgetState={widgetState}
                currentTab={currentTab}
                pages={props.pages}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
              />
            </Panel>
          </StyledPanel>
        </StyledPanelGroup>
      )}
    </>
  );
};

export default ButtonWidgetControl;
