import styled from 'styled-components';
import Typography from 'shared/components/Typography';

const Error = styled(Typography)`
  color: ${p => p.theme.colors.aux.red};
`;

export const validateField = (required: boolean, validation?: string) => (
  field: any
) => {
  if (required && !field) {
    return <Error variant="caption">Field is required</Error>;
  } else if (
    validation === 'alphabet' &&
    field &&
    !field.match(/^[a-zA-z]+$/)
  ) {
    return <Error variant="caption">Field must contain only alphabet</Error>;
  } else if (
    validation === 'alphanumeric' &&
    field &&
    !field.match(/^[a-zA-Z0-9]+$/)
  ) {
    return (
      <Error variant="caption">
        Field must contain only alphabets and numbers
      </Error>
    );
  } else if (
    validation === 'date' &&
    field &&
    !field.match(/^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/)
  ) {
    return (
      <Error variant="caption">Field must contain valid date(mm/dd/yyyy)</Error>
    );
  } else if (
    validation === 'email' &&
    field &&
    !field.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/)
  ) {
    return (
      <Error variant="caption">Field must contain valid email address</Error>
    );
  } else if (
    validation === 'optumemail' &&
    field &&
    !field.match(/^[a-zA-Z0-9._%+-]+@(optum|uhc)\.com$/)
  ) {
    return (
      <Error variant="caption">Field must contain valid email address</Error>
    );
  } else if (validation === 'number' && field && !field.match(/^[0-9]+$/)) {
    return <Error variant="caption">Field must contain only number</Error>;
  }
};
