import { Reducer } from 'redux';
import { ThunkActionCreator, Selector } from 'shared/state';

const LOGOUT_PENDING = 'duplo/session/LOGOUT_PENDING';
const LOGIN_PENDING = 'duplo/session/LOGIN_PENDING';
const SILENT_SIGNIN_PENDING = 'duplo/session/SILENT_SIGNIN_PENDING';
const SILENT_SIGNIN_FULFILLED = 'duplo/session/SILENT_SIGNIN_FULFILLED';
const IMPERSONATION_REQUESTED = 'duplo/session/IMPERSONATION_REQUESTED';
const IMPERSONATION_END = 'duplo/session/IMPERSONATION_END';

export interface State {
  logoutPending: boolean;
  loginPending: boolean;
  silentSigninPending: boolean;
  impersonationRequested: boolean;
  endImpersonation: boolean;
}

const defaultState: State = {
  logoutPending: false,
  loginPending: false,
  silentSigninPending: false,
  impersonationRequested: false,
  endImpersonation: false,
};

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case LOGOUT_PENDING:
      return {
        ...state,
        logoutPending: true,
      };
    case SILENT_SIGNIN_PENDING:
      return {
        ...state,
        silentSigninPending: true,
      };
    case SILENT_SIGNIN_FULFILLED:
      return {
        ...state,
        silentSigninPending: false,
      };
    case LOGIN_PENDING:
      return {
        ...state,
        loginPending: true,
      };
    case IMPERSONATION_REQUESTED:
      return {
        ...state,
        impersonationRequested: true,
      };
    case IMPERSONATION_END:
      return {
        ...state,
        endImpersonation: true,
      };
    default:
      return state;
  }
};

export default reducer;

export const initiateLogout: ThunkActionCreator = () => dispatch => {
  dispatch({ type: LOGOUT_PENDING });
};

export const initiateLogin: ThunkActionCreator = () => dispatch => {
  dispatch({ type: LOGIN_PENDING });
};

export const initiateSilentSignin: ThunkActionCreator = () => dispatch => {
  dispatch({ type: SILENT_SIGNIN_PENDING });
};

export const silentSigninFulfilled: ThunkActionCreator = () => dispatch => {
  dispatch({ type: SILENT_SIGNIN_FULFILLED });
};

export const initiateImpersonation: ThunkActionCreator = () => dispatch => {
  dispatch({ type: IMPERSONATION_REQUESTED });
};

export const endImpersonation: ThunkActionCreator = () => dispatch => {
  dispatch({ type: IMPERSONATION_END });
};

export const selectSlice: Selector<State> = () => state => {
  return state.session;
};

export const selectLogoutPending: Selector<boolean> = () => state => {
  return selectSlice()(state).logoutPending;
};

export const selectLoginPending: Selector<boolean> = () => state => {
  return selectSlice()(state).loginPending;
};
