import { createUserManager } from 'redux-oidc';
import {
  persistLocation,
  persistPostLoginPage,
} from 'shared/util/locationPersistence';
import config from 'client/config';
import { Location } from 'history';
import Oidc, { UserManager } from 'oidc-client';

let userManager: UserManager;
let idpHint: string;

/**
 * Initialize the user manager.
 */
const initUserManager = (
  clientId: string,
  customIdpHint?: string,
  postLogoutPage?: string
) => {
  userManager = createUserManager({
    client_id: clientId,
    redirect_uri: `${window.location.origin}/callback`,
    response_type: 'code',
    scope: 'openid profile',
    authority: config.authority,
    filterProtocolClaims: true,
    loadUserInfo: true,
    automaticSilentRenew: false, // Conditionally enabled in SessionObserver.
    monitorSession: false,
    silent_redirect_uri: `${window.location.origin}/silent_renew.html`,
    post_logout_redirect_uri: postLogoutPage,
    userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
  });
  idpHint = customIdpHint;
};

const doSignInWithPostLoginPage = (location: Location) => {
  persistPostLoginPage(location);
  doSignIn();
};

const doSignInWithPostLoginPageAndIdp = (
  location: Location,
  target: string
) => {
  persistPostLoginPage(location);
  if (target === 'portal-login') {
    doSignIn();
  } else {
    doSignInWithIdp(target);
  }
};

const doSignInWithLocation = (location: Location) => {
  persistLocation(location);
  doSignIn();
};
const doSignInWithLocationAndIdp = (location: Location, target: string) => {
  persistLocation(location);

  if (target === 'portal-login') {
    doSignIn();
  } else {
    doSignInWithIdp(target);
  }
};

const doSignIn = () => {
  idpHint
    ? getUserManager().signinRedirect({
        extraQueryParams: {
          kc_idp_hint: idpHint,
        },
      })
    : getUserManager().signinRedirect();
};

const doSignInWithIdp = (idp: string) => {
  if (idp !== 'custom') {
    getUserManager().signinRedirect({
      extraQueryParams: {
        kc_idp_hint: idp,
      },
    });
  }
};

const doImpersonationRequest = (impersonationUrl: string) => {
  getUserManager()
    .removeUser()
    .then(() => {
      window.location.replace(impersonationUrl);
    });
};

/**
 * Retrieve the user manager.
 *
 * **Note:** `initUserManager` must have already been invoked.
 */
const getUserManager = () => {
  return userManager;
};

export {
  doImpersonationRequest,
  doSignInWithPostLoginPage,
  doSignInWithLocationAndIdp,
  doSignInWithPostLoginPageAndIdp,
  doSignInWithLocation,
  initUserManager,
  getUserManager,
};
