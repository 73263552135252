import TabsWidget from './TabsWidget';
import { Config } from './types';
import TabsWidgetControl from './TabsWidgetControl';
import {
  defaultBackgroundData,
  defaultBackgroundImageData,
} from 'builder/util/constants';

export default {
  type: 'Tabs',
  displayName: 'Tabs',
  icon: 'tablet-rugged',
  initialConfig: {
    ...defaultBackgroundData,
    ...defaultBackgroundImageData,
    borderData: { thickness: 0, radius: 5 },
    width: 1200,
    height: 600,
    xLocation: 10,
    yLocation: 10,
    uniqueId: 'tabs345',
    lineColor: '#828485',
    tabs: [
      {
        id: 0,
        name: 'Tab 1',
        content: '',
      },
      {
        id: 1,
        name: 'Tab 2',
        content: '',
      },
    ],
    currentTabIndex: 0,
    hoverFocusColor: '#ff612b',
    tabsTextColor: '#333333',
    fontSize: 12,
    font: 'OptumSans',
    backgroundData: { style: 'Color', color: 'white' },
  },
  components: {
    render: TabsWidget,
    control: TabsWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
