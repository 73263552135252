import { ActionCreator, AnyAction, Reducer } from 'redux';
import { Selector, ThunkActionCreator } from 'shared/state';
import api from 'shared/api/userSites';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const FETCH_USER_SITES_PENDING =
  'duplo/userSites/FETCH_USER_SITES_PENDING';
export const FETCH_USER_SITES_FULFILLED =
  'duplo/userSites/FETCH_USER_SITES_FULFILLED';
export const FETCH_USER_SITES_FAILED =
  'duplo/userSites/FETCH_USER_SITES_FAILED';

export interface State {
  data: UserSites;
  meta: StateMeta;
}

const defaultState: State = {
  data: {
    site: [],
    error: [],
  },
  meta: {
    pending: false,
  },
};

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_USER_SITES_PENDING:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: true,
        },
      };
    case FETCH_USER_SITES_FULFILLED:
      return {
        ...state,
        data: action.userSites as UserSites,
        meta: {
          ...state.meta,
          pending: false,
          successful: true,
          error: null,
        },
      };
    case FETCH_USER_SITES_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: false,
          successful: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export const fetchUserSitesPending: ActionCreator<AnyAction> = () => {
  return { type: FETCH_USER_SITES_PENDING };
};

export const fetchUserSitesFulfilled: ActionCreator<AnyAction> = (
  userSites: UserSites
) => {
  return { type: FETCH_USER_SITES_FULFILLED, userSites };
};

export const fetchUserSitesFailed: ActionCreator<AnyAction> = () => {
  return { type: FETCH_USER_SITES_FAILED };
};

export default reducer;

export const fetchUserSites: ThunkActionCreator = () => dispatch => {
  dispatch(fetchUserSitesPending());
  api
    .get()
    .then(userSites => {
      dispatch(fetchUserSitesFulfilled(userSites));
    })
    .catch(_ => {
      dispatch(fetchUserSitesFailed());
      toast('Error occurred while fetching sites for the user', {
        type: 'error',
        theme: 'colored',
      });
    });
};

export const selectSlice: Selector<State> = () => (state): State => {
  return state.userSites;
};

export const selectMeta: Selector<StateMeta> = () => state => {
  return selectSlice()(state).meta;
};

export const selectUserSites: Selector<UserSites> = () => state => {
  return selectSlice()(state).data;
};
