import MyApps from './MyAppsContainer';
import { Config } from './types';
import React from 'react';

const MyAppsWidget: React.FC<WidgetRenderProps<Config>> = props => {
  const ChildMemo = React.memo(MyApps);
  return <ChildMemo {...props} />;
};

export default MyAppsWidget;
