import CardWidget from './CardWidget';
import { Config } from './types';
import CardWidgetControl from './CardWidgetControl';
import {
  defaultBackgroundData,
  defaultBackgroundImageData,
} from 'builder/util/constants';

export default {
  type: 'Card',
  displayName: 'Card',
  icon: 'cards-blank',
  initialConfig: {
    ...defaultBackgroundData,
    ...defaultBackgroundImageData,
    borderData: { thickness: 0, radius: 5 },
    width: 420,
    height: 580,
    xLocation: 10,
    yLocation: 10,
    uniqueId: 'card345',
    lineColor: '#828485',
    backgroundColor: 'white',
  },
  components: {
    render: CardWidget,
    control: CardWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
