import { Reducer } from 'redux';
import { ThunkActionCreator, Selector, Selector1 } from 'shared/state';
import api from 'shared/api/notifications';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export interface State {
  data: Array<Notification>;
  meta: StateMeta;
}

const defaultState: State = {
  data: [],
  meta: {
    pending: false,
  },
};

export const FETCH_NOTIFICATIONS_FULFILLED =
  'duplo/notifications/FETCH_NOTIFICATIONS_FULFILLED';
export const FETCH_NOTIFICATIONS_PENDING =
  'duplo/notifications/FETCH_NOTIFICATIONS_PENDING';
export const FETCH_NOTIFICATIONS_FAILED =
  'duplo/notifications/FETCH_NOTIFICATIONS_FAILED';

export const DELETE_NOTIFICATION_FULFILLED =
  'duplo/notifications/DELETE_NOTIFICATION_FULFILLED';
export const DELETE_NOTIFICATION_PENDING =
  'duplo/notifications/DELETE_NOTIFICATION_PENDING';
export const DELETE_NOTIFICATION_FAILED =
  'duplo/notifications/DELETE_NOTIFICATION_FAILED';

export const READ_NOTIFICATION_FULFILLED =
  'duplo/notifications/READ_NOTIFICATION_FULFILLED';
export const READ_NOTIFICATION_PENDING =
  'duplo/notifications/READ_NOTIFICATION_PENDING';
export const READ_NOTIFICATION_FAILED =
  'duplo/notifications/READ_NOTIFICATION_FAILED';

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_PENDING:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: true,
        },
      };
    case FETCH_NOTIFICATIONS_FULFILLED:
      return {
        ...state,
        data: action.data,
        meta: {
          ...state.meta,
          pending: false,
          successful: true,
          error: null,
        },
      };
    case FETCH_NOTIFICATIONS_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: false,
          successful: false,
          error: action.error,
        },
      };
    case DELETE_NOTIFICATION_FULFILLED:
      return {
        ...state,
        data: state.data.filter(n => !action.ids.includes(n.id)),
      };
    case DELETE_NOTIFICATION_FAILED:
    case READ_NOTIFICATION_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          error: action.error,
        },
      };
    case READ_NOTIFICATION_FULFILLED:
      return {
        ...state,
        data: [
          ...state.data.filter(n => !action.ids.includes(n.id)),
          ...state.data
            .filter(n => action.ids.includes(n.id))
            .map(n => {
              return {
                ...n,
                status: {
                  id: 2,
                  name: 'read',
                },
              };
            }),
        ],
      };
    default:
      return state;
  }
};

export default reducer;

export const fetchNotifications: ThunkActionCreator = (
  published: boolean
) => dispatch => {
  dispatch({ type: FETCH_NOTIFICATIONS_PENDING });
  api
    .get(published)
    .then(response => {
      const sortedNotification = response.sort(
        (n1: Notification, n2: Notification) => n2.id - n1.id
      );
      dispatch({
        type: FETCH_NOTIFICATIONS_FULFILLED,
        data: sortedNotification,
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_NOTIFICATIONS_FAILED,
        error: 'Could not fetch notifications',
      });
      toast('Error fetching notifications', {
        type: 'error',
        theme: 'colored',
      });
    });
};

export const readNotification: ThunkActionCreator = (
  ids: Array<number>,
  published: boolean
) => dispatch => {
  dispatch({ type: READ_NOTIFICATION_PENDING });
  const promises: Array<Promise<any>> = [];
  ids.forEach(id => {
    promises.push(api.read(id, published));
  });
  Promise.all(promises)
    .then(response => {
      response && dispatch({ type: READ_NOTIFICATION_FULFILLED, ids });
    })
    .catch(() => {
      dispatch({
        type: READ_NOTIFICATION_FAILED,
        error: 'Failed to read notification',
      });
      toast('Error marking notification read', {
        type: 'error',
        theme: 'colored',
      });
    });
};

export const deleteNotification: ThunkActionCreator = (
  ids: Array<number>,
  published: boolean
) => dispatch => {
  dispatch({ type: DELETE_NOTIFICATION_PENDING });
  const promises: Array<Promise<any>> = [];
  ids.forEach(id => {
    promises.push(api.delete(id, published));
  });
  Promise.all(promises)
    .then(response => {
      if (response) {
        dispatch({
          type: DELETE_NOTIFICATION_FULFILLED,
          ids,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: DELETE_NOTIFICATION_FAILED,
        error: 'Error deleting notification',
      });
      toast('Error deleting notification', { type: 'error', theme: 'colored' });
    });
};

const selectSlice: Selector<State> = () => state => {
  return state.notifications;
};

export const selectNotifications: Selector<Array<
  Notification
>> = () => state => {
  return selectSlice()(state).data;
};

export const selectMeta: Selector<StateMeta> = () => state => {
  return selectSlice()(state).meta;
};

export const selectNotificationByType: Selector1<
  Array<Notification>,
  string
> = (type: string) => state => {
  return selectSlice()(state).data.filter(n => n.type.name == type);
};

export const selectUnreadNotificationsByType: Selector1<
  Array<Notification>,
  string
> = (type: string) => state => {
  return selectNotificationByType(type)(state).filter(
    n => n.status.name === 'unread'
  );
};

export const selectNotificationCountByType: Selector1<number, string> = (
  type: string
) => state => {
  return selectUnreadNotificationsByType(type)(state).length;
};
