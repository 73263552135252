import { ChangeEvent, FormEvent, Fragment, PureComponent } from 'react';
import styled from 'styled-components';
import { Button, Icon, Input } from 'link-ui-react';
import Cookies from 'js-cookie';
import Typography from 'shared/components/Typography';

const COOKIE_NAME = 'LINK_PORTAL_VERSION';

const CustomVersionWrapper = styled.form`
  padding: 8px 0;
  display: flex;
  align-items: flex-end;
  & > * {
    margin-right: 16px;
  }
`;

interface VersionProps {
  domain: string;
  codeVersion?: string;
  cookieVersion?: string;
  customVersion?: string;
}
/**
 * Utility component to view and set the LINK_PORTAL_VERSION.
 */
class Version extends PureComponent<VersionProps> {
  constructor(props: VersionProps) {
    super(props);
  }

  state = {
    codeVersion: process.env.LINK_VERSION,
    cookieVersion: '',
    customVersion: '',
  };

  componentDidMount() {
    this.updateCookieVersionState();
  }

  updateCookieVersionState = () => {
    const cookieVersion = Cookies.get(COOKIE_NAME);
    if (cookieVersion) {
      this.setState({
        cookieVersion,
      });
    }
  };

  handleVersionInputChange = ({
    target: { value: customVersion },
  }: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      customVersion,
    });
  };

  handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const { customVersion } = this.state;
    if (customVersion) {
      Cookies.set(COOKIE_NAME, customVersion, { domain: this.props.domain });
      window.location.reload();
    }
  };

  handleClear = () => {
    Cookies.remove(COOKIE_NAME, { domain: this.props.domain });
    window.location.reload();
  };

  render() {
    const { codeVersion, cookieVersion, customVersion } = this.state;
    return (
      <Fragment>
        <Typography variant="body1">
          <strong>Code Version:</strong> {codeVersion}
        </Typography>
        <Typography variant="body1">
          <strong>Cookie Version:</strong> {cookieVersion || <em>None</em>}
        </Typography>
        <CustomVersionWrapper onSubmit={this.handleSubmit}>
          <Input
            label="Custom Version"
            placeholder="PR-123 or 1.0.4"
            value={customVersion}
            onChange={this.handleVersionInputChange}
            w={350}
            rightIcon={
              <Icon
                icon="ArrowForward"
                onClick={this.updateCookieVersionState}
              />
            }
            rightIconBorder
          />
          <Button type="submit" disabled={!customVersion}>
            Update
          </Button>
          <Button secondary type="button" onClick={this.handleClear}>
            Clear
          </Button>
        </CustomVersionWrapper>
      </Fragment>
    );
  }
}

export default Version;
