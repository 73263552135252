import { connect } from 'react-redux';
import { RootState, Dispatch } from 'shared/state';
import {
  fetchSite,
  fetchSites,
  fetchSitesByUuid,
  publishSite,
  selectSites,
} from 'shared/state/ducks/sites';
import {
  openCreateSiteDialog,
  openSiteListDialog,
} from 'shared/state/ducks/ui';
import { initiateLogout } from 'shared/state/ducks/session';
import {
  selectUserHasCreatePermission,
  selectHasRole,
  selectBuilderPermissions,
} from 'shared/state/misc/oidc';
import SiteList from './SiteList';
import env from 'builder/config';
import { ROLES } from 'shared/constants';

const mapStateToProps = (state: RootState) => ({
  user: state.oidc.user,
  sites: selectSites()(state),
  builderPermissions: selectBuilderPermissions()(state),
  createPermission: env.permissionsEnabled
    ? selectUserHasCreatePermission()(state)
    : selectHasRole(ROLES.siteCreator)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openCreateSiteDialog: () => {
    dispatch(openCreateSiteDialog());
  },
  publishSite: (id: number) => {
    return dispatch(publishSite(id));
  },
  fetchSites: () => {
    dispatch(fetchSites());
  },
  fetchSitesByUuid: () => {
    dispatch(fetchSitesByUuid());
  },
  openSiteListDialog: (
    dialogType: string,
    siteId: number,
    siteName: string
  ) => {
    dispatch(openSiteListDialog(dialogType, siteId, siteName));
  },
  initiateLogout: () => {
    dispatch(initiateLogout());
  },
  fetchSite: (id: number, openDialog: boolean) => {
    dispatch(fetchSite(id, openDialog));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteList);
