import styled, { keyframes } from 'styled-components';
import Typography from 'shared/components/Typography';
import { linkDeveloperTheme as theme } from 'link-ui-react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Ring = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
`;

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Inner = styled.div`
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: ${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${theme.colors.primary} transparent transparent transparent;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }
  &:nth-child(2) {
    animation-delay: -0.3s;
  }
  &:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const Loading = ({ message }: { message?: string }) => (
  <Wrapper>
    {message && (
      <Typography variant="subheading" gutterBottom>
        {message}
        ...
      </Typography>
    )}
    <Ring>
      <Inner />
      <Inner />
      <Inner />
      <Inner />
    </Ring>
  </Wrapper>
);

export default Loading;
