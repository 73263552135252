import FileUploaderWidget from './FileUploaderWidget';
import { Config } from './types';
import FileUploaderWidgetControl from 'shared/widgets/Form/FileUploader/FileUploaderWidgetControl';
import {
  defaultBorderData,
  defaultSizeLocationData,
} from 'builder/util/constants';

export default {
  type: 'FileUploader',
  displayName: 'FileUploader',
  icon: '',
  initialConfig: {
    ...defaultSizeLocationData,
    width: 345,
    height: 125,
    minHeight: 125,
    ...defaultBorderData,
    uniqueId: '123abc',
    lineColor: 'red',
    enablePDF: true,
    enableDOC: true,
    enablePNG: true,
    enableJPG: true,
    uploadedFile: {
      name: '',
      url: '',
    },
    label: 'Attachments',
    parentId: '',
  },
  components: {
    render: FileUploaderWidget,
    control: FileUploaderWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
