import React, { ChangeEvent, useEffect } from 'react';
import { Panel, Label, RadioButton, Tooltip, FormControl } from '@uitk/react';
import {
  Arrow,
  StyledPanelTitle,
} from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import {
  Padding,
  PaddingData,
} from 'builder/components/WidgetConfigurations/Padding';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';
import { StyledPanel, SizeLocationPanel } from '../Text/TextWidgetControl';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from 'builder/components/WidgetSizeLocation';
import { Config } from './types';
import { Input } from 'link-ui-react';
import styled from 'styled-components';
import { InfoHollow } from '@uitk/react-icons/';

const PanelTop = styled.div`
  .jSWwIc {
    padding: 0;
    border: none;
  }
`;

const StyledInfo = styled.span`
  display: flex;
  width: inherit;
  justify-content: right;
  .Tooltipcomponent__TooltipContent-exjipk-2 {
    font-family: 'OptumSans';
  }
  }
`;

const HtmlWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const [checked, setChecked] = React.useState('');
  const { value, onChange, widgetState } = props;

  useEffect(() => {}, [checked, props.widgetState]);

  const handleOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.value);
    if (checked === 'web-address') {
      onChange({
        ...value,
        inputUrl: '',
      });
    }
  };

  // const handleCodeChange = (editorName: string, editorValue: string) => {
  //   if (editorName === 'template') {
  //     onChange({
  //       ...value,
  //       htmlValue: editorValue,
  //     });
  //   }
  //   if (editorName === 'script') {
  //     onChange({
  //       ...value,
  //       jsValue: editorValue,
  //     });
  //   }
  //   if (editorName === 'stylesheet') {
  //     onChange({
  //       ...value,
  //       cssValue: editorValue,
  //     });
  //   }
  // };
  const handleUrlData = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      inputUrl: e.target.value,
    });
  };

  const handleLineColor = data => {
    onChange({
      ...value,
      lineColor: data,
    });
  };
  const updateWidgetSizeLocation = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };
  const updatePaddingData = (data: PaddingData) => {
    onChange({
      ...value,
      paddingData: data,
    });
  };
  const updateBorderData = (data: BorderData) => {
    onChange({
      ...value,
      borderData: data,
    });
  };

  return (
    <>
      <Panel.Group>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>General</Label>}
            icon={Arrow}
            data-test-id="general-panel"
          >
            <PanelTop>
              <FormControl id={'radio-group-controlled'}>
                <RadioButton
                  data-test-id="radio-url"
                  name={'radio-group-controlled'}
                  value={'web-address'}
                  onChange={handleOptionChange}
                  checked={checked === 'web-address'}
                >
                  Web Address
                </RadioButton>
                <RadioButton
                  data-test-id="radio-snippet"
                  name={'radio-group-controlled'}
                  value={'snippet'}
                  onChange={handleOptionChange}
                  checked={checked === 'snippet'}
                >
                  Code Snippet
                </RadioButton>
              </FormControl>
              {checked === 'web-address' && (
                <Input
                  data-test-id="url-input"
                  type="url"
                  onChange={handleUrlData}
                />
              )}
              {checked === 'snippet' && (
                <div>
                  <StyledInfo>
                    <Tooltip
                      data-test-id="code-sandbox-tooltip"
                      placement={'left'}
                      content={
                        'HTML, JavaScript, and CSS are currently supported.'
                      }
                      children={<InfoHollow size="m" />}
                    />
                  </StyledInfo>
                </div>
              )}
            </PanelTop>
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <SizeLocationPanel
            title={<Label style={StyledPanelTitle}>Size and Location</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="html-size-location-panel"
          >
            <WidgetSizeLocation
              data-test-id="html-widget-size-location"
              sizeLocation={{
                xLocation: value.xLocation,
                yLocation: value.yLocation,
                width: value.width,
                height: value.height,
                uniqueId: value.uniqueId,
              }}
              updateWidgetSizeLoction={updateWidgetSizeLocation}
              activeWidgetId={widgetState.id}
            />
          </SizeLocationPanel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            data-test-id="html-border-panel"
            title={<Label style={StyledPanelTitle}>Border</Label>}
            initiallyClosed
            icon={Arrow}
          >
            <Border
              handleLineColor={handleLineColor}
              data-test-id="html-border"
              borderData={updateBorderData}
              widgetState={widgetState}
              pages={props.pages}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            data-test-id="html-padding-panel"
            title={<Label style={StyledPanelTitle}>Padding</Label>}
            initiallyClosed
            icon={Arrow}
          >
            <Padding
              data-test-id="html-padding"
              paddingData={updatePaddingData}
              widgetState={widgetState}
            />
          </Panel>
        </StyledPanel>
      </Panel.Group>
    </>
  );
};

export default HtmlWidgetControl;
