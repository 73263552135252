import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'shared/state';
import { setActiveOrgId } from 'shared/state/ducks/org';
import OrgObserver from './OrgObserver';

interface OrgObserverContainerProps extends RouteComponentProps<{}> {}

const mapStateToProps = () => ({});

const mapDispatchToProps = (
  dispatch: Dispatch,
  _: OrgObserverContainerProps
) => ({
  setActiveOrgId: (id: string) => {
    dispatch(setActiveOrgId(id));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrgObserver)
);
