import config from 'client/config';
import { SelectOption, UserWithTerms } from './UserImpersonation';

enum ErrorMessageType {
  TERMS_ERROR = 'ERROR_NO_TERMS',
  SUPERUSER_ERROR = 'ERROR_EXISTING_SUPERUSER',
  SEARCH_ERROR = 'ERROR_USER_SEARCH',
  NO_USERS = 'SEARCH_NO_USERS',
}

const createRedirectUrl = (user: string, clientId: string) => {
  return (
    config.authority +
    `/super-user/start/` +
    user +
    `?redirect_uri=` +
    window.location.origin +
    `/impersonation&clientId=` +
    clientId
  );
};

const createOption = (text: string): SelectOption =>
  ({
    label: text,
    value: text,
  } as SelectOption);

const createError = (text: string): string => {
  switch (text) {
    case 'ERROR_NO_TERMS':
      return 'User has not accepted terms and conditions';
    case 'ERROR_EXISTING_SUPERUSER':
      return 'Cannot impersonate another superuser';
    case 'ERROR_USER_SEARCH':
      return 'Error searching for users - ';
    case 'SEARCH_NO_USERS':
      return 'No Users Found';
    case 'NONE':
      return '';
  }
};

const checkUserImpersonationRequirements = (
  userSelection: UserWithTerms
): string => {
  if (!userSelection.acceptedTerms) {
    return createError(ErrorMessageType.TERMS_ERROR);
  }
  if (userSelection.isSuperUser) {
    return createError(ErrorMessageType.SUPERUSER_ERROR);
  }
};

export {
  createRedirectUrl,
  createOption,
  createError,
  checkUserImpersonationRequirements,
  ErrorMessageType,
};
