import { Reducer } from 'redux';
import {
  createResponsiveStateReducer,
  IBrowser as Browser,
} from 'redux-responsive';

import { ScreenSizeBreakpoints as breakpoints } from 'shared/constants';

const reducer: Reducer<Browser> = createResponsiveStateReducer(breakpoints);

export default reducer;
export type State = Browser;
