import { ChangeEvent, useState } from 'react';
import { Config } from './types';
import { Label, FormControl, Select } from '@uitk/react';
import { csvRegex } from 'shared/util/regex';
import styled from 'styled-components';

const StyledFormControl = styled(FormControl)`
  font-family: 'OptumSans';
  padding: 0.25rem;
  align-content: center;
  #user-dropdown-err {
    display: none;
  }
`;

const StyledSelect = styled(Select)<{ dropdownIconColor: string }>`
  > svg > path {
    fill: ${p => p.dropdownIconColor};
  }
`;

export const DropdownWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
) => {
  const {
    required,
    label,
    value,
    lineColor,
    borderData,
    height,
    buttonColor,
  } = props.widgetState.config;
  const [values, setValues] = useState('-- None --');
  const [blurred, setBlurred] = useState(false);

  function handleError() {
    if (required && blurred && values === '-- None --') {
      return `Please select an option`;
    }
    return '';
  }

  const handleSelectionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues(e.target.value);
  };

  return (
    <>
      <StyledFormControl
        id={'user-dropdown'}
        error={handleError()}
        required={required ? required : false}
      >
        <Label>{label || label === '' ? label : 'My Label'}</Label>
        <StyledSelect
          data-test-id={'dropdown-widget-select'}
          value={values}
          onChange={handleSelectionChange}
          onBlur={() => setBlurred(true)}
          style={{
            cursor: 'default',
            overflow: 'hidden',
            borderWidth: `${borderData?.thickness}px`,
            borderStyle: 'solid',
            borderColor: `${lineColor}`,
            borderRadius: `${borderData?.radius}`,
            height: `${height - 60}px`,
            minHeight: '45px',
            fontFamily: 'OptumSans',
          }}
          dropdownIconColor={buttonColor}
        >
          {<option>-- None --</option>}
          {value &&
            value.split(csvRegex).map(item => (
              <option id={`${item}-option`} key={item} value={item}>
                {item}
              </option>
            ))}
        </StyledSelect>
      </StyledFormControl>
    </>
  );
};

export default DropdownWidget;
