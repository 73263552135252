import React, { ReactElement } from 'react';
import { Config } from './types';
import styled from 'styled-components';
import { default as NukaCarousel } from 'nuka-carousel';
import { Icon } from 'link-ui-react';

const ArrowWrapper = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  cursor: default;
  :hover,
  :focus {
    outline: 3px solid #2e65d8;
    border-radius: 10px;
    background-color: transparent;
  }
  :active {
    outline: 3px solid #2e65d8;
    border-radius: 10px;
    background-image: linear-gradient(rgb(0 0 0 /5%) 0 0);
  }
`;

const ArrowIcon = styled(Icon)`
  fill: ${p => p.theme.colors.primary};
`;

const SlideShowWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    backgroundData,
    backgroundImage,
    paddingData,
    borderData,
    lineColor,
    slides,
    slideArrowColor,
    slideSelectedDotsColor,
    autoPlayInterval,
    currentSlideIndex,
    autoPlayChecked,
    arrowsCheck,
    // slideUnselectedDotsColor,
    positionalDotsCheck,
    height,
  } = props.widgetState.config;
  const { onChange, value, currentPage } = props;
  const activeWidgetId = props.widgetState.id;
  React.useEffect(() => {
    if (activeWidgetId !== 'root') {
      const tabWidgetChildren = currentPage.content[activeWidgetId].children;
      tabWidgetChildren.forEach(child => {
        if (currentPage.content[child].tabIndex !== currentSlideIndex) {
          document.getElementById(child).style.visibility = 'hidden';
        }
      });
    }
  }, [currentSlideIndex]);

  const handleSlideClick = (panelId: number) => {
    onChange(
      {
        ...value,
        currentSlideIndex: panelId,
      },
      activeWidgetId,
      // false
      props.widgetState.defaultWidget
    );
  };

  return (
    <div
      data-test-id="nuka-caraousal-div"
      style={{
        borderWidth: `${borderData?.thickness}px`,
        borderStyle: 'solid',
        borderColor: `${lineColor}`,
        borderRadius: `${borderData?.radius}px`,
        backgroundImage:
          backgroundData?.style === 'Image'
            ? `url('${backgroundImage?.url}')`
            : '',
        background:
          backgroundData?.color !== '' ? backgroundData?.color : 'none',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingTop: `${paddingData?.top}px`,
        paddingRight: `${paddingData?.right}px`,
        paddingBottom: `${paddingData?.bottom}px`,
        paddingLeft: `${paddingData?.left}px`,
      }}
    >
      <NukaCarousel
        renderCenterLeftControls={({ previousSlide }) => (
          <ArrowWrapper
            onClick={previousSlide}
            data-test-id="previous-icon"
            tabIndex={0}
          >
            <ArrowIcon
              icon="ArrowLeftOpen"
              width="20px"
              style={{
                fill: slideArrowColor,
                visibility: arrowsCheck ? 'visible' : 'hidden',
              }}
            />
          </ArrowWrapper>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <ArrowWrapper
            onClick={nextSlide}
            data-test-id="next-icon"
            tabIndex={0}
          >
            <ArrowIcon
              icon="ArrowRightOpen"
              width="20px"
              style={{
                fill: slideArrowColor,
                visibility: arrowsCheck ? 'visible' : 'hidden',
              }}
            />
          </ArrowWrapper>
        )}
        autoplayInterval={Number(autoPlayInterval)}
        afterSlide={currentSlide => {
          handleSlideClick(currentSlide);
        }}
        slideIndex={currentSlideIndex}
        autoplay={autoPlayChecked ? true : false}
        defaultControlsConfig={{
          pagingDotsStyle: {
            cursor: 'default',
            fill: slideSelectedDotsColor,
            marginTop: '1000px',
            visibility: positionalDotsCheck ? 'visible' : 'hidden',
          },
        }}
        wrapAround
      >
        {slides.map(() => (
          <div
            style={{ paddingTop: height - 10 }}
            data-test-id="slides-div"
          ></div>
        ))}
      </NukaCarousel>
    </div>
  );
};
export default SlideShowWidget;
