import React from 'react';
import { Panel, Label } from '@uitk/react';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../../builder/components/WidgetSizeLocation';
import { Config } from './types';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';
import FileUploaderPanel from 'builder/components/WidgetConfigurations/FileUploader/FileUploaderPanel';
import {
  SizeLocationPanel,
  StyledPanel,
  StyledPanelTitle,
} from '../../Text/TextWidgetControl';

const FileUploaderWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const { value, onChange, widgetState } = props;
  const handleLineColor = data => {
    onChange({
      ...value,
      lineColor: data,
    });
  };

  const updateWidgetSizeLoction = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };

  const updateBorderData = (data: BorderData) => {
    onChange({
      ...value,
      borderData: data,
    });
  };

  const handleFieldLabel = (data: string) => {
    onChange({
      ...value,
      label: data,
    });
  };

  const determineNumberOfEnabledFileTypes = (pdf, doc, png, jpg) => {
    let enabledFileTypeCount = 0;
    if (pdf) {
      enabledFileTypeCount++;
    }
    if (doc) {
      enabledFileTypeCount++;
    }
    if (png) {
      enabledFileTypeCount++;
    }
    if (jpg) {
      enabledFileTypeCount++;
    }
    return enabledFileTypeCount;
  };

  const updateAcceptedFileTypes = (key: string, data: boolean) => {
    onChange({
      ...value,
      [key]: data,
    });
  };

  return (
    <>
      <Panel.Group>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>General</Label>}
            icon={Arrow}
          >
            <FileUploaderPanel
              data-test-id="file-uploader-panel"
              handleFieldLabel={handleFieldLabel}
              updateAcceptedFileTypes={updateAcceptedFileTypes}
              determineNumberOfEnabledFileTypes={
                determineNumberOfEnabledFileTypes
              }
              widgetState={widgetState}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <SizeLocationPanel
            title={<Label style={StyledPanelTitle}>Size and Location</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="authentication-panel"
          >
            <WidgetSizeLocation
              data-test-id="widget-size-location"
              sizeLocation={{
                xLocation: value.xLocation,
                yLocation: value.yLocation,
                width: value.width,
                height: value.height,
                uniqueId: value.uniqueId,
              }}
              updateWidgetSizeLoction={updateWidgetSizeLoction}
              activeWidgetId={widgetState.id}
            />
          </SizeLocationPanel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Border</Label>}
            initiallyClosed
            icon={Arrow}
          >
            <Border
              data-test-id="border-data"
              borderData={updateBorderData}
              handleLineColor={handleLineColor}
              widgetState={widgetState}
              pages={props.pages}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
      </Panel.Group>
    </>
  );
};

export default FileUploaderWidgetControl;
