import { useEffect } from 'react';
import EmptyFormTable from './EmptyFormTypeTable';
import FormTypesListTable from './FormTypesListTable';
import Loading from 'shared/components/Loading';

export interface FormSubmissionProps {
  formTypes: Array<FormTypesData>;
  formTypesMeta?: StateMeta;
  fetchFormTypes?: (siteIds: Array<number>) => void;
  sites?: Array<Site>;
}
const FormTypesList = (props: FormSubmissionProps) => {
  const {
    formTypes,
    formTypesMeta: { pending: fetchFormsPending, successful, error },
    fetchFormTypes,
    sites,
  } = props;

  useEffect(() => {
    const siteIds = sites.map(site => site.id);
    fetchFormTypes(siteIds);
  }, []);

  const renderForms = () => {
    if (fetchFormsPending) {
      return <Loading message="Loading form types..." />;
    } else if (successful) {
      return (
        <>
          {formTypes.length > 0 && <FormTypesListTable formTypes={formTypes} />}
          {formTypes.length === 0 && <EmptyFormTable />}
        </>
      );
    } else if (error) {
      return <EmptyFormTable />;
    }
  };

  return <>{renderForms()}</>;
};

export default FormTypesList;
