import { User as OIDCUser, Profile as OidcProfile } from 'oidc-client';
import { UserState as OIDCState } from 'redux-oidc';
import { Selector, Selector1 } from 'shared/state';
import alphasort from 'shared/util/alphasort';
import env, { permissionConstants } from 'builder/config';

export interface Organization {
  name: string;
  /**
   * Represents the linkAccountId. Exists, but isn't currently needed and may
   * cause confusion with the `orgId` property
   */
  // id: string;
  orgId: string;
  permissions: {
    [index: string]: Array<string>;
  };
}

export interface UserAccount {
  accountNumber: number;
  accountStatusCode: string;
  accountStatusDesc: string;
  isInternalUser: boolean;
  secureFlag: boolean;
  tenantName: string;
  orgClaim: AccountOrganization;
  adminEmail: string;
  hasSecureNontaRole: boolean;
}

interface AccountOrganization {
  name: string;
  orgId: string;
}

export interface Profile extends OidcProfile {
  /**
   * *Note:* For some reason, oidc-client-js replaces single-element arrays
   * with the element itself. When reading the organizations, use the
   * selectOrganizations selector; it normalizes the structure.
   */
  organizations?: Organization[] | Organization;
  roles?: string[];
  [index: string]: any;
  userAccounts?: UserAccount[];
}

export interface User extends OIDCUser {
  profile: Profile;
}

const selectSlice: Selector<OIDCState> = () => state => {
  return state.oidc;
};

export const selectUser: Selector<User> = () => state => {
  return selectSlice()(state).user;
};

export const selectIsLoadingUser: Selector<boolean> = () => state => {
  return selectSlice()(state).isLoadingUser;
};

export const selectHasRole: Selector1<boolean, string> = role => state => {
  const user = selectUser()(state);
  if (!user || !user.profile || !user.profile.roles) {
    return false;
  }

  return user.profile.roles.includes(role);
};

export const selectOrganizations: Selector<Array<
  Organization
>> = () => state => {
  const user = selectUser()(state);
  if (!user || !user.profile || !user.profile.organizations) {
    return [];
  }

  if (user.profile.organizations instanceof Array) {
    return user.profile.organizations.sort((a, b) => alphasort(a.name, b.name));
  } else {
    return [user.profile.organizations];
  }
};

export const selectUserAccounts: Selector<Array<UserAccount>> = () => state => {
  const user = selectUser()(state);
  if (!user || !user.profile || !user.profile.userAccounts) {
    return [];
  }

  if (user.profile.userAccounts instanceof Array) {
    return user.profile.userAccounts;
  } else {
    return [user.profile.userAccounts];
  }
};

export const selectPermissions: Selector1<
  string[],
  string
> = buisnessKey => state => {
  const organization = selectOrganizations()(state).find(o =>
    o.permissions.hasOwnProperty(buisnessKey)
  );
  return organization ? organization.permissions[buisnessKey] : [];
};

export const selectBuilderPermissions: Selector<string[]> = () => state => {
  const businessKey = env.businessKey;
  return selectPermissions(businessKey)(state);
};

export const selectUserHasCreatePermission: Selector<boolean> = () => state => {
  const createPermission = `${permissionConstants.permissionNames.all}.${permissionConstants.permissionActions.create}`;
  return selectBuilderPermissions()(state).includes(createPermission);
};
