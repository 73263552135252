import { connect } from 'react-redux';
import { RootState, Dispatch } from 'shared/state';
import {
  selectSites,
  createSite,
  selectCreateSitePending,
  selectNewSite,
} from 'shared/state/ducks/sites';
import {
  closeCreateSiteDialog,
  selectCreateSiteDialogOpen,
} from 'shared/state/ducks/ui';
import CreateSiteDialog from './CreateSiteDialog';
import { fetchIdpsIfNeeded, selectIdps } from 'shared/state/ducks/idps';

const mapStateToProps = (state: RootState) => ({
  open: selectCreateSiteDialogOpen()(state),
  sites: selectSites()(state),
  idps: selectIdps()(state),
  createSiteMeta: selectCreateSitePending()(state),
  newSiteId: selectNewSite()(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose: () => {
    dispatch(closeCreateSiteDialog());
  },
  createSite: (site: Partial<Site>, content) => {
    dispatch(createSite(site, content));
  },
  fetchIdps: () => {
    dispatch(fetchIdpsIfNeeded());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateSiteDialog);
