import React, { Fragment, PureComponent } from 'react';
import styled from 'styled-components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from '@material-ui/core';
import AccessForm from 'shared/widgets/MyApps/AccessForm';
import { Close } from '@material-ui/icons';
import { User } from 'oidc-client';
import { UserAccessRequest } from 'client/constants';
import env from 'client/config';

const StyledDialogContent = styled(DialogContent)`
  min-width: 480px;
`;

const StyledIconButton = styled(IconButton)`
  right: 0;
  top: 9px;
  &.MuiButtonBase-root {
    position: absolute;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    justify-content: flex-start;
    padding: 0.5em 1.5em;
  }
`;

const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${p => p.theme.colors.aux.lightGrey};
  padding-bottom: 15px;
  padding-top: 10px;
`;

const StyledListItemAvatar = styled(ListItemAvatar)`
  &.MuiListItemAvatar-root {
    min-width: 18px;
  }
`;

const StyledBullet = styled.p`
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
`;

export interface AccessRequestDialogProps {
  open: boolean;
  onClose: () => void;
  dialogTitle: string;
  accessReason: string;
  sendEmailToAdmin: (adminEmailForm: AdminEmailForm) => string;
  user: User;
  orgName?: string;
  orgUrl: string;
  templateName: string;
  tenancy: string;
  adminEmail?: string;
  shouldShowInstructionsPopUp: boolean;
}

class AccessRequestDialog extends PureComponent<AccessRequestDialogProps> {
  getDefaultAdminEmail() {
    const { tenancy } = this.props;
    if (tenancy === UserAccessRequest.UHCG_DOMAIN) {
      return UserAccessRequest.UHCG_DEFAULT_ADMIN_EMAIL;
    }
    if (tenancy === UserAccessRequest.LD_DOMAIN) {
      return UserAccessRequest.LD_DEFAULT_ADMIN_EMAIL;
    }
    if (tenancy === UserAccessRequest.MBM_DOMAIN) {
      return UserAccessRequest.MBM_DEFAULT_ADMIN_EMAIL;
    }
    if (tenancy === UserAccessRequest.PIC_DOMAIN) {
      return env.picDefaultAdminEmail;
    }
    return UserAccessRequest.DEFAULT_ADMIN_EMAIL;
  }

  getSubject(templateName: string, siteName: string) {
    if (templateName === UserAccessRequest.NEW_ACCESS_TEMPLATE_NAME) {
      return 'Action Required – Request submitted for access to ' + siteName;
    }
    return 'Action Required – Request to reactivate account for ' + siteName;
  }

  getSiteName = (tenancy: string) => {
    if (tenancy === UserAccessRequest.UHCG_DOMAIN) {
      return UserAccessRequest.UHCG_NAME;
    }
    if (tenancy === UserAccessRequest.LD_DOMAIN) {
      return UserAccessRequest.LD_NAME;
    }
    if (tenancy === UserAccessRequest.MBM_DOMAIN) {
      return UserAccessRequest.MBM_NAME;
    }
    if (tenancy === UserAccessRequest.PIC_DOMAIN) {
      return UserAccessRequest.PIC_NAME;
    }
    if (tenancy === UserAccessRequest.SMAP_DOMAIN) {
      return UserAccessRequest.SMAP_NAME;
    }
    return '';
  };

  getRequest = (siteName: string) => {
    const { orgName, orgUrl, accessReason, user } = this.props;
    const profile = user.profile;
    const request: AccessRequest = {
      userName: profile.preferred_username,
      userEmail: profile.email,
      name: profile.name,
      accessReason: accessReason,
      orgUrl: 'https://' + orgUrl,
      organization: orgName,
      siteName: siteName,
    };
    return request;
  };

  getTenant = () => {
    const { tenancy } = this.props;
    if (
      tenancy === UserAccessRequest.PIC_DOMAIN ||
      tenancy === UserAccessRequest.MBM_DOMAIN
    ) {
      return tenancy.toLocaleUpperCase();
    } else if (tenancy === UserAccessRequest.LD_DOMAIN) {
      return UserAccessRequest.LD_APPLICATION_ON_SECURE;
    } else {
      return '';
    }
  };

  handleRequestUserAccess = () => {
    const { sendEmailToAdmin, adminEmail, templateName, tenancy } = this.props;
    const siteName = this.getSiteName(tenancy);
    const subject = this.getSubject(templateName, siteName);
    const adminEmailForm = {
      formActionType: 'userAccessRequestToAdmin',
      accessRequestForm: this.getRequest(siteName),
      emailAction: {
        subject: subject,
        templateName: templateName,
        emailTo: [adminEmail ? adminEmail : this.getDefaultAdminEmail()],
      },
    };
    sendEmailToAdmin(adminEmailForm);
  };

  renderInstructionsPopUp = () => {
    const { open, onClose, dialogTitle } = this.props;

    const tenant = this.getTenant();

    return (
      <Dialog
        fullWidth
        maxWidth={'md'}
        open={open}
        onClose={onClose}
        data-test-id="instructions-popup"
      >
        <DialogTitle>
          {dialogTitle}
          <StyledIconButton onClick={onClose}>
            <Close />
          </StyledIconButton>
        </DialogTitle>
        <Divider />
        <List>
          <ListItem>
            <StyledListItemAvatar>
              <StyledBullet>1.</StyledBullet>
            </StyledListItemAvatar>
            <ListItemText
              primary={
                <React.Fragment>
                  {'Visit Secure at '}
                  <a href={UserAccessRequest.AZURE_URL} target="_blank">
                    {UserAccessRequest.AZURE_URL}
                  </a>
                  {'.'}
                </React.Fragment>
              }
            />
          </ListItem>
          <ListItem>
            <StyledListItemAvatar>
              <StyledBullet>2.</StyledBullet>
            </StyledListItemAvatar>
            <ListItemText
              primary={'Click on Application under Request New Access.'}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <StyledListItemAvatar>
              <StyledBullet>3.</StyledBullet>
            </StyledListItemAvatar>
            <ListItemText
              primary={`Select "Link Platform" and then "Link Platform (${tenant})" from the Application list then click Next.`}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <StyledListItemAvatar>
              <StyledBullet>4.</StyledBullet>
            </StyledListItemAvatar>
            <ListItemText
              primary={
                'Select the appropriate environment if available for the access you need and then click Next.'
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <StyledListItemAvatar>
              <StyledBullet>5.</StyledBullet>
            </StyledListItemAvatar>
            <ListItemText
              primary={
                'Read the Request Instructions if available to see if additional information is needed and then click Next'
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <StyledListItemAvatar>
              <StyledBullet>6.</StyledBullet>
            </StyledListItemAvatar>
            <ListItemText primary={'Select your MS ID and then click Next.'} />
          </ListItem>
          <ListItem alignItems="flex-start">
            <StyledListItemAvatar>
              <StyledBullet>7.</StyledBullet>
            </StyledListItemAvatar>
            <ListItemText
              primary={'Select appropriate role(s) needed and then click Next.'}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <StyledListItemAvatar>
              <StyledBullet>8</StyledBullet>
            </StyledListItemAvatar>
            <ListItemText
              primary={
                'Note: If you do not know the appropriate role(s), reach out to your supervisor.'
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <StyledListItemAvatar>
              <StyledBullet>9.</StyledBullet>
            </StyledListItemAvatar>
            <ListItemText
              primary={
                'Verify the information you entered, enter "Business Justification" and then click Submit.'
              }
            />
          </ListItem>
        </List>
      </Dialog>
    );
  };

  renderAccessForm = () => {
    const { open, onClose, dialogTitle, tenancy } = this.props;
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {dialogTitle}
          <StyledIconButton onClick={onClose}>
            <Close />
          </StyledIconButton>
        </DialogTitle>
        <Divider />
        <AccessForm
          onSubmit={this.handleRequestUserAccess}
          reasonMessageTitle={
            dialogTitle === UserAccessRequest.NEW_ACCESS_POPUP_TITLE
              ? 'Reason for new access request'
              : 'Reason for reactivation'
          }
          accessRequest={this.getRequest(this.getSiteName(tenancy))}
          render={({ form, invalid }) => (
            <Fragment>
              <StyledDialogContent>{form}</StyledDialogContent>
              <StyledDialogActions>
                <Button
                  type="submit"
                  data-test-id="access-request-submit"
                  color="primary"
                  variant="outlined"
                  disabled={invalid}
                >
                  Submit
                </Button>
                <Button type="button" onClick={onClose} color="primary">
                  Cancel
                </Button>
              </StyledDialogActions>
            </Fragment>
          )}
        />
      </Dialog>
    );
  };

  render() {
    const { shouldShowInstructionsPopUp } = this.props;

    return shouldShowInstructionsPopUp
      ? this.renderInstructionsPopUp()
      : this.renderAccessForm();
  }
}

export default AccessRequestDialog;
