const fonts = require('google-fonts-complete');
import { defaultOptumFontTypes } from 'types/types';

const getCombinedFontsForLogo = () => {
  const tempFonts = { ...fonts };
  Object.values(defaultOptumFontTypes).forEach(value => {
    tempFonts[value] = {};
  });

  return Object.keys(tempFonts)
    .sort()
    .reduce((obj, key) => {
      obj[key] = tempFonts[key];
      return obj;
    }, {});
};

const getCombinedFontsForTheme = () => {
  const tempFonts = { ...fonts };
  //add new optum sans fonts.
  Object.values(defaultOptumFontTypes)
    .slice(0, 1)
    .forEach(value => {
      tempFonts[value] = {};
    });

  return Object.keys(tempFonts)
    .sort()
    .reduce((obj, key) => {
      obj[key] = tempFonts[key];
      return obj;
    }, {});
};

export { getCombinedFontsForLogo, getCombinedFontsForTheme };
