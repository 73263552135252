import { connect } from 'react-redux';
import { RootState, Dispatch } from 'shared/state';
import {
  updateWidgetConfig,
  selectWidget,
  setActiveWidgetId,
  removeWidget,
  selectPages,
  updatePageContent,
  updateDefaultWidgetConfig,
} from 'shared/state/ducks/sites';
import { addUndoRedoState } from 'shared/state/ducks/todos';
import { setResetToFalse } from 'shared/state/ducks/ui';
import WidgetControlWrapperNew from './WidgetControlWrapperNew';

interface WidgetControlWrapperContainerNewProps {
  siteId: number;
  pageId: number;
  site?: Site;
  page?: Page;
  widgetId: string;
}

const mapStateToProps = (
  state: RootState,
  ownProps: WidgetControlWrapperContainerNewProps
) => ({
  widgetState: selectWidget(
    ownProps.siteId,
    ownProps.pageId,
    ownProps.widgetId
  )(state),
  activeWidgetId: state.sites.activeWidgetId,
  pagesnew: selectPages(ownProps.site.id)(state),
  undoState:
    state.undoabletodos.present[state.undoabletodos.present.length - 1],
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: WidgetControlWrapperContainerNewProps
) => ({
  onUpdateConfig: (config: any, defaultWidget: boolean) => {
    dispatch(
      updateWidgetConfig(
        ownProps.siteId,
        ownProps.pageId,
        ownProps.widgetId,
        config,
        defaultWidget
      )
    );
  },
  onUpdateDefaultConfig: (config: any) => {
    dispatch(
      updateDefaultWidgetConfig(
        ownProps.siteId,
        ownProps.pageId,
        ownProps.widgetId,
        config
      )
    );
  },
  setActiveWidgetId: (widgetId: string) => {
    dispatch(setActiveWidgetId(widgetId));
  },
  removeWidget: (widgetId: string) => {
    dispatch(removeWidget(ownProps.siteId, ownProps.pageId, widgetId));
  },
  addTodoState: (pageContent: any) => {
    dispatch(addUndoRedoState(ownProps.site.id, ownProps.page.id, pageContent));
  },
  setResetToFalse: () => dispatch(setResetToFalse()),
  updatePageContent: (pageContent: any) => {
    dispatch(
      updatePageContent(ownProps.site.id, ownProps.page.id, pageContent)
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetControlWrapperNew);
