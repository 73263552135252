import { Component } from 'react';

import config from 'client/config';
import uuid from 'uuid/v4';
import {
  checkForData,
  getExistingData,
  removeExistingData,
} from '../analyticsPersistence';
import { exportEvent } from 'shared/api/analytics';

interface AnalyticsMessage {
  id: string;
  producingAskId: string;
  producingCustomerId: string;
  consumingAskId: string;
  consumingCustomerId: string;
  source: string;
  environment: string;
  timestamp: number;
  metadata: {
    ClientId?: string;
    organization?: string;
    info: any; // For dynamic properties from `parsedmap`
  };
  audit: boolean;
}
const SESSION_CHECK_INTERVAL = 30; // seconds
interface props {
  site: Site;
}
class SendEvents extends Component<props, any> {
  intervalId: number;

  componentDidMount() {
    this.intervalId = this.scheduleDataSend();
  }

  componentDidUpdate() {
    this.intervalId = this.scheduleDataSend();
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalId);
  }

  sendRecord = () => {
    const dataToSend = getExistingData();

    if (checkForData()) {
      const parsedmap = JSON.parse(dataToSend); // converting string to map
      const event: AnalyticsMessage = {
        id: uuid(),
        producingAskId: config.askId,
        consumingAskId: this.props.site?.aideId
          ? this.props.site.aideId
          : 'AskId Not Registered',
        producingCustomerId: config.productId,
        consumingCustomerId: this.props.site.product?.productId
          ? this.props.site.product?.productId
          : 'Product Not Registered',
        source: 'HORIZON_SELF_SERVICE',
        environment: config.env,
        timestamp: new Date().getTime(),
        metadata: {
          ClientId: this.props.site?.clientId,
          organization: this.props.site?.orgAccount?.name,
          info: parsedmap,
        },
        audit: false,
      };
      if (typeof dataToSend !== 'undefined') {
        this.handleSendEvent(event);
        removeExistingData();
      }
    }
  };

  async handleSendEvent(event: any) {
    await exportEvent(event);
  }

  scheduleDataSend = () => {
    this.sendRecord();
    return window.setInterval(this.sendRecord, SESSION_CHECK_INTERVAL * 1000);
  };

  render() {
    return null as null;
  }
}
export default SendEvents;
