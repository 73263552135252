import React, { useState, ReactElement } from 'react';
import { Button } from '@uitk/react';
import { Icon } from 'link-ui-react';
import { ButtonWidgetProps, Config } from './types';
import styled from 'styled-components';
import useFormSubmit from '../Form/useFormSubmit';
import {
  getLineColor,
  getRadius,
  getThickness,
} from 'builder/components/WidgetConfigurations/Border';
import { StyledDiv, StyledLink } from '../Link/LinkWidget';
import { buttonRadiusMultiplier } from 'builder/util/constants';
import {
  getAlign,
  getBold,
  getCharacterSpacing,
  getFontFamily,
  getFontSize,
  getItalic,
  getLineSpacing,
  getStrikeThrough,
  getTextColor,
  getUnderline,
} from 'builder/components/WidgetConfigurations/Typography/Typography';
import { getBackgroundColor } from 'builder/components/WidgetConfigurations/Background/Background';

const StyledButton = styled(Button)<{
  align: string;
  borderColor: string;
  borderThickness: number;
  borderRadius: number;
  backgroundColor: string;
  hoverBold: boolean;
  hoverItalic: boolean;
  hoverUnderline: boolean;
  hoverStrikeThrough: boolean;
  hoverFontFamily: string;
  hoverTextColor: string;
  hoverFontSize: number;
  hoverCharacterSpacing: any;
  hoverLineSpacing: any;
  hoverAlign: string;
  hoverBorderColor: string;
  hoverBorderThickness: number;
  hoverBorderRadius: number;
  hoverBackgroundColor: string;
}>`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${p =>
    p.align === 'left'
      ? 'left'
      : p => (p.align === 'right' ? 'right' : 'center')};
  border-style: solid;
  border-width: ${p => p.borderThickness}px;
  border-color: ${p => p.borderColor};
  border-radius: ${p => p.borderRadius}px;
  background-color: ${p => p.backgroundColor};
  width: 100%;
  height: 100%;
  :hover,
  :focus {
    font-weight: ${p => (p.hoverBold ? 'bold' : 'normal')} !important;
    font-style: ${p => (p.hoverItalic ? 'italic' : 'normal')} !important;
    text-decoration-line: ${p => (p.hoverUnderline ? 'underline' : '')}
      ${p => (p.hoverStrikeThrough ? 'line-through' : '')} !important;
    font-family: ${p => p.hoverFontFamily} !important;
    font-size: ${p => p.hoverFontSize}pt !important;
    color: ${p => p.hoverTextColor} !important;
    letter-spacing: ${p => p.hoverCharacterSpacing}px !important;
    line-height: ${p => p.hoverLineSpacing} !important;
    justify-content: ${p =>
      p.hoverAlign === 'left'
        ? 'left'
        : p => (p.hoverAlign === 'right' ? 'right' : 'center')};
    border-style: solid;
    border-width: ${p => p.hoverBorderThickness}px;
    border-color: ${p => p.hoverBorderColor};
    border-radius: ${p => p.hoverBorderRadius}px;
    background-color: ${p => p.hoverBackgroundColor};
    box-shadow: 0 0 0 2px #ffffff,
      0 0 0 5px
        ${p => (p.hoverBackgroundColor ? p.hoverBackgroundColor : '#002667')};
  }
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem;
`;

const IconImage = styled.img<{ maxWidth: number; maxHeight: number }>`
  max-width: ${p => p.maxWidth}px;
  max-height: ${p => p.maxHeight}px;
`;

const IconLeft = styled.span`
  padding-right: 0.25rem;
`;
const IconRight = styled.span`
  padding-left: 0.25rem;
`;

export const ButtonWidget: React.FC<WidgetRenderProps<Config> &
  ButtonWidgetProps<Config>> = (
  props: WidgetRenderProps<Config> & ButtonWidgetProps<Config>
): ReactElement => {
  const { editing } = props;
  const {
    action,
    displayText,
    hoverData,
    url,
    newTab,
    iconColor,
    currentTab,
    bold,
    iconAltText,
  } = props.widgetState.config;
  const { widgetState } = props;
  const [hover, setHover] = useState(false);
  const { handleFormData } = useFormSubmit(props);

  function submitAction() {
    if (action === 'submit' && !props.editing) {
      handleFormData();
    } else {
      return;
    }
  }

  function renderIconLeft(widgetState, tab, hover) {
    const { icon, iconStyle, iconPlacement, hoverData } = widgetState.config;
    if (tab === 0) {
      switch (hover) {
        case true:
          return (
            hoverData.icon &&
            hoverData.iconStyle === 'With Icon' &&
            hoverData.iconPlacement === 'Left' && (
              <IconLeft>
                {hoverData.icon.url && (
                  <IconImage
                    src={hoverData.icon.url}
                    maxWidth={50}
                    maxHeight={50}
                    draggable={false}
                    aria-label={hoverData.iconAltText}
                    alt={hoverData.iconAltText}
                  />
                )}
              </IconLeft>
            )
          );
        case false:
          return (
            icon &&
            iconStyle === 'With Icon' &&
            iconPlacement === 'Left' && (
              <IconLeft>
                {icon.url && (
                  <IconImage
                    src={icon.url}
                    maxWidth={50}
                    maxHeight={50}
                    draggable={false}
                    aria-label={iconAltText}
                    alt={iconAltText}
                  />
                )}
              </IconLeft>
            )
          );
        default:
      }
    }
    if (tab === 1) {
      return (
        hoverData.icon &&
        hoverData.iconStyle === 'With Icon' &&
        hoverData.iconPlacement === 'Left' && (
          <IconLeft>
            {hoverData.icon.url && (
              <IconImage
                src={hoverData.icon.url}
                maxWidth={50}
                maxHeight={50}
                draggable={false}
                aria-label={hoverData.iconAltText}
                alt={hoverData.iconAltText}
              />
            )}
          </IconLeft>
        )
      );
    }
  }

  function renderIconRight(widgetState, tab, hover) {
    const { icon, iconStyle, iconPlacement, hoverData } = widgetState.config;
    if (tab === 0) {
      switch (hover) {
        case true:
          return (
            hoverData.icon &&
            hoverData.iconStyle === 'With Icon' &&
            hoverData.iconPlacement === 'Right' && (
              <IconRight>
                {hoverData.icon.url && (
                  <IconImage
                    src={hoverData.icon.url}
                    maxWidth={50}
                    maxHeight={50}
                    draggable={false}
                    aria-label={hoverData.iconAltText}
                    alt={hoverData.iconAltText}
                  />
                )}
              </IconRight>
            )
          );
        case false:
          return (
            icon &&
            iconStyle === 'With Icon' &&
            iconPlacement === 'Right' && (
              <IconRight>
                {icon.url && (
                  <IconImage
                    src={icon.url}
                    maxWidth={50}
                    maxHeight={50}
                    draggable={false}
                    aria-label={iconAltText}
                    alt={iconAltText}
                  />
                )}
              </IconRight>
            )
          );
        default:
      }
    }
    if (tab === 1) {
      return (
        hoverData.icon.url &&
        hoverData.iconStyle === 'With Icon' &&
        hoverData.iconPlacement === 'Right' && (
          <IconRight>
            {hoverData.icon.url && (
              <IconImage
                src={hoverData.icon.url}
                maxWidth={50}
                maxHeight={50}
                draggable={false}
                aria-label={hoverData.iconAltText}
                alt={hoverData.iconAltText}
              />
            )}
          </IconRight>
        )
      );
    }
  }

  function displayButtonText(bold) {
    return bold ? <strong>{displayText}</strong> : displayText;
  }

  return (
    <>
      <StyledButtonWrapper>
        <StyledButton
          tabIndex={0}
          onClick={submitAction}
          as={StyledLink}
          data-test-id="button-widget-with-link"
          icon={null}
          href={editing || url === '' ? null : url}
          target={newTab ? '_blank' : ''}
          font={getFontFamily(widgetState, currentTab)}
          fontSize={getFontSize(widgetState, currentTab)}
          textColor={getTextColor(widgetState, currentTab)}
          characterSpacing={getCharacterSpacing(widgetState, currentTab)}
          lineSpacing={getLineSpacing(widgetState, currentTab)}
          strikeThrough={getStrikeThrough(widgetState, currentTab)}
          italic={getItalic(widgetState, currentTab)}
          bold={getBold(widgetState, currentTab)}
          underline={getUnderline(widgetState, currentTab)}
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          align={getAlign(widgetState, currentTab)}
          borderColor={getLineColor(widgetState, currentTab)}
          borderThickness={getThickness(widgetState, currentTab)}
          borderRadius={
            getRadius(widgetState, currentTab) * buttonRadiusMultiplier
          }
          backgroundColor={getBackgroundColor(widgetState, currentTab)}
          hoverFontFamily={hoverData.font}
          hoverTextColor={hoverData.textColor}
          hoverFontSize={hoverData.fontSize}
          hoverBold={hoverData.bold}
          hoverItalic={hoverData.italic}
          hoverUnderline={hoverData.underline}
          hoverStrikeThrough={hoverData.strikeThrough}
          hoverCharacterSpacing={hoverData.characterSpacing}
          hoverLineSpacing={hoverData.lineSpacing}
          hoverAlign={hoverData.align}
          hoverBorderColor={hoverData.lineColor}
          hoverBorderThickness={hoverData.borderData.thickness}
          hoverBorderRadius={
            hoverData.borderData.radius * buttonRadiusMultiplier
          }
          hoverBackgroundColor={hoverData.backgroundData.color}
        >
          {renderIconLeft(widgetState, currentTab, hover)}
          {displayButtonText(bold)}
          {newTab && (
            <StyledDiv>
              <Icon
                icon="NewWindowDarkArrow"
                width={'1em'}
                height={'.9em'}
                viewBox="0 0 15 15"
                style={{ fill: `${iconColor}` }}
              />
            </StyledDiv>
          )}
          {renderIconRight(widgetState, currentTab, hover)}
        </StyledButton>
      </StyledButtonWrapper>
    </>
  );
};

export default ButtonWidget;
