import { connect } from 'react-redux';
import { Dispatch, RootState } from 'shared/state';
import UserImpersonation from './UserImpersonation';
import {
  initiateImpersonation,
  endImpersonation,
} from 'shared/state/ducks/session';

const mapStateToProps = (state: RootState) => ({
  impersonationRequested: state.session && state.session.impersonationRequested,
  endImpersonationRequested: state.session && state.session.endImpersonation,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initiateImpersonation: () => {
    dispatch(initiateImpersonation());
  },
  endImpersonation: () => {
    dispatch(endImpersonation());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserImpersonation);
