import axios from 'shared/util/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  FormField,
  ModifiedFormData,
  Field,
  EmailAction,
} from 'shared/widgets/Form/types';
import {
  DEFAULT_FOLLOW_UP_MESSAGE,
  FOLLOW_UP_MESSAGE,
} from 'builder/util/constants';

const modifyFormData = (
  formFields: Array<FormField>,
  formActionType: string,
  emailAction: EmailAction,
  site: Site,
  pageId: number,
  widgetId: string
): ModifiedFormData => {
  const modifiedFields: Field[] = [];
  const siteId = site.id;
  formFields.forEach(field => {
    const modifiedField: Field = {
      label: field.label,
      values: field.value || '',
    };
    modifiedFields.push(modifiedField);
  });
  const emailList: (string | string[])[] = [];
  const emailToList: string[] = emailAction.emailTo?.split(',');
  emailToList.forEach(email => {
    emailList.push(email.trim());
  });

  let timeFramValueAvailable: boolean = true;
  if (
    emailAction.timeFrameValue === undefined ||
    emailAction.timeFrameValue.length === 0
  ) {
    timeFramValueAvailable = false;
  }

  const modifiedData: ModifiedFormData = {
    formActionType,
    formData: {
      formfields: modifiedFields,
      siteName: site.name,
      timeFrame:
        timeFramValueAvailable === true
          ? FOLLOW_UP_MESSAGE +
            emailAction.timeFrameValue +
            (emailAction.timeFrameUnit || ' day(s)')
          : DEFAULT_FOLLOW_UP_MESSAGE,
    },
    emailAction: {
      subject: emailAction.subject || '',
      templateName: emailAction.templateName || 'DeveloperAccessRequest',
      enableConditionalEmailTo: emailAction.enableConditionalEmailTo || false,
      conditionalEmailList: emailList,
      pageId,
      widgetId,
    },
    siteId,
  };
  return modifiedData;
};

export const submitData = (
  formFields: Array<FormField>,
  formActionType: string,
  emailAction: EmailAction,
  site: Site,
  pageId: number,
  widgetId: string
) => {
  const formData = modifyFormData(
    formFields,
    formActionType,
    emailAction,
    site,
    pageId,
    widgetId
  );

  return axios({
    method: 'POST',
    url: '/public-form',
    data: formData,
  })
    .then(response => {
      toast('Form submitted', { type: 'success', theme: 'colored' });
      return Promise.resolve(response);
    })
    .catch(error => {
      toast('Error occured while submitting form', {
        type: 'error',
        theme: 'colored',
      });
      return Promise.reject(error);
    });
};
