import { connect } from 'react-redux';
import { RootState, Dispatch } from 'shared/state';
import ProfileSettings from './ProfileSettings';
import { selectUser } from 'shared/state/misc/oidc';
import {
  selectFeatures,
  fetchFeaturesIfNeeded,
} from 'shared/state/ducks/features';

interface ProfileSettingsContainerProps {
  domain: string;
}

const mapStateToProps = (
  state: RootState,
  props: ProfileSettingsContainerProps
) => ({
  user: selectUser()(state),
  features: selectFeatures()(state),
  domain: props.domain,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFeatures: () => {
    dispatch(fetchFeaturesIfNeeded());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
