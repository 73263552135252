import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Config } from './types';

const StyledFormWidget = styled.div``;

const FormWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    borderData,
    backgroundData,
    backgroundImage,
    lineColor,
  } = props.widgetState.config;

  function background() {
    if (backgroundData.style === 'Color') {
      return backgroundData?.color;
    }
    if (backgroundData.style === 'Image') {
      return `url('${backgroundImage?.url}')`;
    }
    return 'transparent';
  }

  return (
    <StyledFormWidget
      id={'formWidget'}
      className={'formWidget'}
      style={{
        background: background(),
        backgroundImage:
          backgroundData?.style === 'Image'
            ? `url('${backgroundImage?.url}')`
            : '',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderWidth: `${borderData?.thickness}px`,
        borderStyle: 'solid',
        borderColor: `${lineColor}`,
        borderRadius: `${borderData?.radius}px`,
        backgroundClip: 'content-box',
        width: '100%',
        height: '100%',
      }}
    />
  );
};
export default FormWidget;
