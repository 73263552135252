import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import S3Image from 'builder/components/S3Image/S3Image';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LogoPreview from '../Background/LogoPreview';
import { MainLabelStyles } from '../Typography/Typography';

const UploadWrapper = styled.div`
  display: flex;
`;

const S3ImagePreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  padding-left: 10px;
`;

const S3ImageControlsWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: flex-start;
  padding-left: 23.5px;
`;

export interface ImageUploadProps<T> {
  backgroundImage: (data: Image) => void;
  widgetState: Widget<T>;
}

export const ImageUpload: React.FC<ImageUploadProps<any>> = (
  props: ImageUploadProps<any>
) => {
  const { widgetState, backgroundImage } = props;
  const [uploadedImage, setUploadedImage] = useState({
    url: widgetState.config.backgroundImage.url,
    alt: widgetState.config.backgroundImage.alt,
  });
  useEffect(() => {
    setUploadedImage({
      url: widgetState.config.backgroundImage.url,
      alt: widgetState.config.backgroundImage.alt,
    });
  }, [widgetState.config.backgroundImage?.url]);

  const handleImageChange = (image: Image) => {
    setUploadedImage(image);
    backgroundImage(image);
    toast('Image upload succeeded', { type: 'success', theme: 'colored' });
  };
  return (
    <>
      <UploadWrapper>
        <MainLabelStyles>Image</MainLabelStyles>
        <S3ImageControlsWrapper>
          <S3Image
            image={uploadedImage}
            label="Click to Upload"
            data-test-id="background-image"
            onUpload={handleImageChange}
          />
          <S3ImagePreviewWrapper>
            {uploadedImage && (
              <LogoPreview
                url={uploadedImage.url}
                maxWidth={50}
                maxHeight={50}
              />
            )}
          </S3ImagePreviewWrapper>
        </S3ImageControlsWrapper>
      </UploadWrapper>
    </>
  );
};
