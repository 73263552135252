import React, { useState } from 'react';
import { getWidget } from 'shared/widgets';
import styled from 'styled-components';
import { Icon, Link } from 'link-ui-react';
import { Label } from '@uitk/react';
import { StyledPanelTitle } from 'shared/widgets/Text/TextWidgetControl';

const SidebarNav = styled.nav`
  width: 365px;
  height: auto;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 60px;
  transition: 850ms;
  z-index: 10;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;
`;

const NavIcon = styled(Link)`
  font-size: 2rem;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #cccac9;
  border-bottom: 1px #888888;
  width: 100%;
  > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const StyledWidgetType = styled.span`
  font-family: OptumSans;
  font-size: 16px;
  color: #0c55b8;
  border-left: 5px;
`;

export interface WidgetControlWrapperNewProps<T> {
  widgetState: Widget<T>;
  onUpdateConfig: (config: any, defaultWidget: boolean) => void;
  onUpdateDefaultConfig: (config: any, defaultWidget: boolean) => void;
  pages: Array<Page>;
  site: Site;
  updateSite: (site: Site) => void;
  saveSite: () => void;
  openPopup: (open: boolean) => void;
  addChild: (
    type: string,
    initialConfig: T,
    location: number,
    widgetId: string,
    newParentWidgetId: string,
    tabIndex: number
  ) => void;
  currentPage: PageVersion;
  updateCurrentPage: (pageVersion: PageVersion) => void;
  removeWidget: (widgetId: string) => void;
  addTodoState?: (config: any) => void;
  undoState?: any;
  setResetToFalse?: () => void;
  updatePageContent?: (page: any) => void;
  updateWidgetPosition?: (
    bannerWidgetId: string,
    height: number,
    oldHeight: number
  ) => void;
}

const WidgetControlWrapperNew: React.FC<WidgetControlWrapperNewProps<any>> = (
  props: WidgetControlWrapperNewProps<any>
) => {
  const {
    onUpdateConfig,
    pages,
    widgetState,
    updateSite,
    saveSite,
    site,
    openPopup,
    currentPage,
    updateCurrentPage,
    addChild,
    removeWidget,
    setResetToFalse,
  } = props;
  const [sidebar, setSidebar] = useState(true);
  const WidgetControl = getWidget(widgetState.type, true).components.control;
  const handleUpdateConfig = (config: any) => {
    onUpdateConfig(config, widgetState.defaultWidget);
    setResetToFalse();
  };
  const showSidebar = () => {
    openPopup(!sidebar);
    setSidebar(!sidebar);
  };
  return (
    <SidebarNav style={{ right: sidebar ? '5px' : '-100%' }}>
      <SidebarWrap>
        <NavIcon>
          <Label
            style={StyledPanelTitle}
            data-test-id="configuration-panel-heading"
          >
            Configuration for:{' '}
            <StyledWidgetType> {widgetState?.type}</StyledWidgetType>
          </Label>
          <Icon
            icon="Close"
            color="#000000"
            width={'1.6em'}
            height={'1.1em'}
            onClick={showSidebar}
            style={{ margin: '.2rem .5rem 0 0' }}
            data-test-id="close-icon"
          />
        </NavIcon>
        <WidgetControl
          onChange={handleUpdateConfig}
          pages={pages}
          value={widgetState?.config}
          widgetState={widgetState}
          site={site}
          updateSite={updateSite}
          saveSite={saveSite}
          currentPage={currentPage}
          updateCurrentPage={updateCurrentPage}
          addChild={addChild}
          removeWidget={removeWidget}
          updateWidgetPosition={props.updateWidgetPosition}
          closeConfigPanel={showSidebar}
        />
      </SidebarWrap>
    </SidebarNav>
  );
};

export default WidgetControlWrapperNew;
