import { linkDeveloperTheme as theme } from 'link-ui-react';

const dropdownFont = 'OptumSans';

export const selectStyles = {
  control: (base: any) => ({
    ...base,
    borderWidth: '0.1em',
    borderColor: theme.colors.aux.black,
    boxShadow: '0 0 0 1px #909090',
    borderStyle: 'none',
    width: '110%',
    marginLeft: '-0.5rem',
    '&:hover': 'none',
  }),
  menu: () => ({
    zIndex: 9999,
    border: '0.1em solid',
    backgroundColor: theme.colors.aux.white,
  }),
  option: (styles: any, state: any) => ({
    ...styles,
    zIndex: 9999,
    cursor: 'pointer',
    width: '100%',
    fontFamily: dropdownFont,
    fontSize: '1em',
    outline: state.isFocused && '0.2em solid' + theme.colors.aux.lightBlue,
    outlineOffset: state.isFocused && '0.2em',
    color: state.isFocused ? theme.colors.aux.white : theme.colors.aux.black,
    borderStyle: 'solid',
    borderLeft: state.isSelected
      ? '0.25em solid ' + theme.colors.aux.lightBlue
      : '0.25em solid ' + theme.colors.aux.white,
    borderRight: state.isSelected
      ? '0.30em solid ' + theme.colors.aux.lightBlue
      : '0.30em solid ' + theme.colors.aux.white,
    fontWeight: state.isSelected ? 'bolder' : 'normal',
    backgroundColor:
      state.isFocused || state.isActive
        ? theme.colors.aux.lightBlue + ' !important'
        : theme.colors.aux.white,
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  placeholder: (styles: any) => {
    return {
      ...styles,
      fontFamily: 'OptumSans, ArialMT,Arial,sans-serif',
      fontSize: '1em',
      color: theme.colors.aux.grey,
      textAlign: 'center',
    };
  },
};
