import React, { useEffect, useState } from 'react';
import { menuClasses, SubMenu } from 'react-pro-sidebar';
import styled from 'styled-components';
import { Icon } from 'link-ui-react';
import { linkDeveloperTheme as theme } from 'link-ui-react';
import { Label, Panel } from '@uitk/react/';
import { Arrow, StyledPanelTitle } from '../SettingsPanel/SettingsPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getWidget } from 'shared/widgets';
import uuidv4 from 'uuid/v4';
import {
  bannerWidgetDefaultBodyText,
  cardWidgetDefaultBodyText,
  slideShowWidgetDefaultBodyText,
  tabsCardBodyText,
  widgetTypes,
} from 'builder/util/constants';
import config from 'builder/config';
import { getLeftTopPosition } from '../SiteBuilderHelper';

const Widgets = (
  <img
    // @ts-ignore
    class="fa-light fa-square-bolt"
    style={{ width: '34px', height: '34px', color: '#2E3034' }}
  ></img>
);

const StyledMenuItem = styled(SubMenu)`
  margin-top: 16px;
`;
StyledMenuItem.displayName = 'submenu';

const StyledPanel = styled.div`
  & button[class^='Panelcomponent__HeaderSection'] {
    height: 2.5em;
    background: rgba(141, 145, 160, 0.07);
    border-radius: 0;
    &: hover,&: focus {
      box-shadow: none;
    }
  }
  & span[class^='Panelcomponent__HeaderMainContent'] {
    height: 2.5em;
  }
  & div[class^='uitk-panel Panelcomponent__Panel'] {
    margin-bottom: 0rem;
    border: none;
  }
  & div[class^='Panelcomponent__Content'] {
    padding: 0rem;
  }
`;

const StyledHeading = styled.p`
  color: ${theme.colors.aux.black};
  font-weight: 900;
  font-size: 21px;
  font-family: 'OptumSans';
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  padding: 1.125rem 0 0.5rem 1.25rem;
`;
TopSection.displayName = 'topsection';

const IconGroupWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const IconWrapper = styled.div`
  padding: 1em 0;
  cursor: pointer;
  border-width: 0.5px 1px 0.5px 0;
  border-style: solid;
  border-color: ${theme.colors.aux.lightGrey};
  font-family: 'OptumSans';
  font-size: 0.9rem;
  text-align: center;
  & :hover {
    font-weight: 600;
  }
  & path[class^='fa-primary'] {
    opacity: var(--fa-primary-opacity, 0.5);
  }
  & path[class^='fa-secondary'] {
    opacity: var(--fa-secondary-opacity, 0.3);
  }
  & :hover {
    & path[class^='fa-primary'] {
      color: var(--fa-primary-color, #0c55b8);
      opacity: var(--fa-primary-opacity, 1);
    }
    & path[class^='fa-secondary'] {
      color: var(--fa-secondary-color, #ff612b);
      opacity: var(--fa-secondary-opacity, 1);
    }
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  width: 100%;
  height: 2em;
`;
interface WidgetsPanelProps<T> {
  site: Site;
  pages: Array<Page>;
  updateSite: (site: Site) => void;
  getLocationData?: any;
  currentPage?: PageVersion;
  setResetToFalse: () => void;
  addChild?: (
    type: string,
    initialConfig: T,
    location: number,
    widgetId: string,
    newParentWidgetId: string,
    tabIndex: number
  ) => void;
  updateWidgetPosition?: (
    bannerWidgetId: string,
    height: number,
    oldHeight: number
  ) => void;
  widgetState: Widget<T>;
  onUpdateConfig: (config: any) => void;
}
export const WidgetsPanel: React.FC<WidgetsPanelProps<any>> = (
  props: WidgetsPanelProps<any>
) => {
  const { addChild, setResetToFalse, site } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [locData, setLocData] = useState(undefined);
  const toggle = (isopen: boolean) => {
    setIsOpen(isopen);
  };
  const headerHeight =
    props.currentPage?.content['headerSection']?.config.height;

  useEffect(() => {
    props.getLocationData(locData);
  }, [locData]);

  function createChildWidgets(
    parentWidgetId,
    childConfigs,
    e,
    isBannerWidgetChild: boolean = false
  ) {
    const elementLeftPosition = getLeftTopPosition('editingContainerId')[0];
    const elementTopPosition = getLeftTopPosition('editingContainerId')[1];
    childConfigs.forEach(childWidget => {
      const widget = getWidget(childWidget.widgetType, true);
      const yLocOffset = e.clientY - elementTopPosition;
      const yLoc = yLocOffset > 0 ? yLocOffset : 0;
      let defaultConfig = {
        ...widget.initialConfig,
        xLocation: e.clientX + childWidget.x - elementLeftPosition,
        yLocation: yLoc + childWidget.y,
        width: childWidget.width,
        height: childWidget.height,
        minWidth: childWidget.minWidth,
        minHeight: childWidget.minHeight,
        parentId: childWidget.parentId,
      };
      if (isBannerWidgetChild) {
        defaultConfig = {
          ...widget.initialConfig,
          xLocation: 216 + childWidget.x,
          yLocation: headerHeight
            ? headerHeight + childWidget.y
            : 0 + childWidget.y,
          width: childWidget.width,
          height: childWidget.height,
          minWidth: childWidget.minWidth,
          minHeight: childWidget.minHeight,
          parentId: childWidget.parentId,
        };
      }
      let widgetConfig;
      switch (childWidget.widgetType) {
        case widgetTypes.Text:
          widgetConfig = {
            ...defaultConfig,
            text: childWidget.text,
            bold: childWidget.bold,
            align: childWidget.align,
            fontSize: childWidget.fontSize,
            backgroundData: childWidget?.backgroundData,
            textColor: childWidget?.textColor,
            lineSpacing: childWidget.lineSpacing,
          };
          break;
        case widgetTypes.Image:
          widgetConfig = {
            ...defaultConfig,
            backgroundImage: childWidget.backgroundImage,
          };
          break;
        case widgetTypes.Button:
          widgetConfig = {
            ...defaultConfig,
            text: childWidget.text,
            bold: childWidget.bold,
            align: childWidget.align,
            paddingData: childWidget.paddingData,
            backgroundData: childWidget.backgroundData,
            textColor: childWidget.textColor,
            borderData: childWidget.borderData,
            lineColor: childWidget.lineColor,
            action: childWidget.action,
            site: childWidget.site ? childWidget.site : site,
          };
          break;
        case widgetTypes.TextInput:
          widgetConfig = {
            ...defaultConfig,
            label: childWidget.label,
            required: childWidget.required,
          };
          break;
        case widgetTypes.RadioButton:
          widgetConfig = {
            ...defaultConfig,
            label: childWidget.label,
            value: childWidget.value,
            required: childWidget.required,
          };
          break;
        case widgetTypes.Divider:
          widgetConfig = {
            ...defaultConfig,
            text: childWidget.text,
            bold: childWidget.bold,
            align: childWidget.align,
            lineColor: childWidget.lineColor,
            direction: childWidget.direction,
            thickness: childWidget.thickness,
          };
          break;
        case widgetTypes.Card:
          widgetConfig = {
            ...defaultConfig,
          };
          break;
        case widgetTypes.Link:
          widgetConfig = {
            ...defaultConfig,
            displayText: childWidget.displayText,
            url: childWidget.url,
            iconColor: childWidget.iconColor,
            newTab: childWidget.newTab,
            bold: childWidget.bold,
            underline: childWidget.underline,
            fontSize: childWidget.fontSize,
            textColor: childWidget.textColor,
            backgroundData: childWidget.backgroundData,
          };
          break;
        case widgetTypes.LoginButton:
          widgetConfig = {
            ...defaultConfig,
          };
          break;
        default:
          widgetConfig = { ...defaultConfig };
          break;
      }
      const widgetId = uuidv4();
      addChild(
        childWidget.widgetType,
        widgetConfig,
        0,
        widgetId,
        parentWidgetId,
        childWidget.tabIndex
      );
      setLocData({
        x: e.clientX + childWidget.x - elementLeftPosition,
        y: yLoc + childWidget.y,
        widgetType: childWidget.widgetType,
      });
    });
  }
  // Add and update widget config when dragged
  const onDragItem = (e, widgetType) => {
    const eleLeft = getLeftTopPosition('editingContainerId')[0];
    const eleTop = getLeftTopPosition('editingContainerId')[1];
    const xLoc = e.clientX - eleLeft;
    const yLocOrig = e.clientY - eleTop;
    const yLoc = yLocOrig > 0 ? yLocOrig : 0;
    e.preventDefault();
    setResetToFalse();
    const widget = getWidget(widgetType, true);
    // Create Parent widget
    const newParentWidgetId = uuidv4();
    let widgetConfig = {
      ...widget.initialConfig,
      xLocation: xLoc,
      yLocation: yLoc,
    };
    if (widgetType === widgetTypes.Banner) {
      widgetConfig = {
        ...widget.initialConfig,
        xLocation: 216,
        yLocation: headerHeight ? headerHeight : 0,
      };
    }
    if (widgetType === widgetTypes.Application) {
      widgetConfig = {
        ...widgetConfig,
        uniqueId: newParentWidgetId,
        siteId: site.id,
        pageId: props.currentPage.pageId,
      };
    }
    addChild(widgetType, widgetConfig, 0, newParentWidgetId, 'root', -1);
    setLocData({ x: xLoc, y: yLoc, widgetType: widgetType });
    if (widgetType === widgetTypes.Tabs) {
      const tabsWidgets = [
        {
          widgetType: widgetTypes.Image,
          x: 5,
          y: 95,
          width: 1190,
          height: 498,
          minWidth: 100,
          minHeight: 40,
          backgroundImage: {
            url:
              'https://cdn-stage.linkhealth.com/site-builder/horizon/Tab1Background.png',
            alt: '',
          },
          tabIndex: 0,
        },
        {
          widgetType: widgetTypes.Text,
          x: 44,
          y: 190,
          text: 'Get the support you need in person, virtually or digitally',
          bold: true,
          width: 260,
          height: 110,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          fontSize: 20,
          tabIndex: 0,
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Card,
          x: 44,
          y: 360,
          width: 350,
          height: 194,
          minWidth: 180,
          minHeight: 40,
          tabIndex: 0,
        },
        {
          widgetType: widgetTypes.Text,
          x: 54,
          y: 374,
          text: 'Text Category 1',
          bold: true,
          width: 200,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 0,
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Text,
          x: 54,
          y: 420,
          text: tabsCardBodyText,
          bold: false,
          width: 300,
          height: 75,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 0,
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Text,
          x: 54,
          y: 506,
          text: 'Getting Started >',
          bold: true,
          width: 200,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 0,
          textColor: '#196ece',
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Card,
          x: 421,
          y: 360,
          width: 350,
          height: 194,
          minWidth: 180,
          minHeight: 40,
          tabIndex: 0,
        },
        {
          widgetType: widgetTypes.Text,
          x: 431,
          y: 374,
          text: 'Text Category 1',
          bold: true,
          width: 200,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 0,
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Text,
          x: 431,
          y: 420,
          text: tabsCardBodyText,
          bold: false,
          width: 300,
          height: 75,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 0,
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Text,
          x: 431,
          y: 506,
          text: 'Getting Started >',
          bold: true,
          width: 200,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 0,
          textColor: '#196ece',
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Card,
          x: 800,
          y: 360,
          width: 350,
          height: 194,
          minWidth: 180,
          minHeight: 40,
          tabIndex: 0,
        },
        {
          widgetType: widgetTypes.Text,
          x: 810,
          y: 374,
          text: 'Text Category 1',
          bold: true,
          width: 200,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 0,
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Text,
          x: 810,
          y: 420,
          text: tabsCardBodyText,
          bold: false,
          width: 300,
          height: 75,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 0,
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Text,
          x: 810,
          y: 506,
          text: 'Getting Started >',
          bold: true,
          width: 200,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 0,
          textColor: '#196ece',
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Image,
          x: 5,
          y: 95,
          width: 1190,
          height: 498,
          minWidth: 100,
          minHeight: 40,
          backgroundImage: {
            url:
              'https://cdn-stage.linkhealth.com/site-builder/horizon/Tab2Background.png',
            alt: '',
          },
          tabIndex: 1,
        },
        {
          widgetType: widgetTypes.Text,
          x: 44,
          y: 190,
          text: 'Helping you prepare and save for health care expenses',
          bold: true,
          width: 300,
          height: 110,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          fontSize: 20,
          tabIndex: 1,
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Card,
          x: 44,
          y: 360,
          width: 350,
          height: 194,
          minWidth: 180,
          minHeight: 40,
          tabIndex: 1,
        },
        {
          widgetType: widgetTypes.Text,
          x: 54,
          y: 374,
          text: 'Text Category 1',
          bold: true,
          width: 200,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 1,
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Text,
          x: 54,
          y: 420,
          text: tabsCardBodyText,
          bold: false,
          width: 300,
          height: 75,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 1,
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Text,
          x: 54,
          y: 506,
          text: 'Getting Started >',
          bold: true,
          width: 200,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 1,
          textColor: '#196ece',
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Card,
          x: 421,
          y: 360,
          width: 350,
          height: 194,
          minWidth: 180,
          minHeight: 40,
          tabIndex: 1,
        },
        {
          widgetType: widgetTypes.Text,
          x: 431,
          y: 374,
          text: 'Text Category 1',
          bold: true,
          width: 200,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 1,
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Text,
          x: 431,
          y: 420,
          text: tabsCardBodyText,
          bold: false,
          width: 300,
          height: 75,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 1,
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Text,
          x: 431,
          y: 506,
          text: 'Getting Started >',
          bold: true,
          width: 200,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 1,
          textColor: '#196ece',
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Card,
          x: 800,
          y: 360,
          width: 350,
          height: 194,
          minWidth: 180,
          minHeight: 40,
          tabIndex: 1,
        },
        {
          widgetType: widgetTypes.Text,
          x: 810,
          y: 374,
          text: 'Text Category 1',
          bold: true,
          width: 200,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 1,
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Text,
          x: 810,
          y: 420,
          text: tabsCardBodyText,
          bold: false,
          width: 300,
          height: 75,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 1,
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
        {
          widgetType: widgetTypes.Text,
          x: 810,
          y: 506,
          text: 'Getting Started >',
          bold: true,
          width: 200,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          tabIndex: 1,
          textColor: '#196ece',
          backgroundData: {
            style: 'Color',
            color: 'transparent',
          },
          backgroundColor: 'transparent',
        },
      ];
      createChildWidgets(newParentWidgetId, tabsWidgets, e);
    }
    if (widgetType === widgetTypes.Card) {
      const childWidgets = [
        {
          widgetType: widgetTypes.Text,
          x: 10,
          y: 20,
          text: 'Heading',
          bold: true,
          width: 400,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          backgroundData: { style: 'Color', color: 'white' },
          fontSize: 20,
        },
        {
          widgetType: widgetTypes.Image,
          x: 10,
          y: 70,
          width: 400,
          height: 215,
          minWidth: 100,
          minHeight: 40,
          backgroundImage: {
            url:
              'https://cdn-stage.linkhealth.com/site-builder/horizon/CardTemplateImage.png',
            alt: '',
          },
        },
        {
          widgetType: widgetTypes.Text,
          x: 10,
          y: 292,
          text: 'Subheading',
          bold: true,
          width: 400,
          height: 30,
          minWidth: 180,
          minHeight: 30,
          align: 'none',
          backgroundData: { style: 'Color', color: 'white' },
        },
        {
          widgetType: widgetTypes.Text,
          x: 10,
          y: 329,
          text: cardWidgetDefaultBodyText,
          bold: false,
          width: 400,
          height: 95,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          backgroundData: { style: 'Color', color: 'white' },
        },
        {
          widgetType: widgetTypes.Button,
          x: 10,
          y: 433,
          text: 'Button Label',
          bold: true,
          width: 400,
          height: 60,
          minWidth: 180,
          minHeight: 60,
          align: 'center',
          paddingData: { top: 5, bottom: 5, left: 5, right: 5 },
          textColor: 'white',
          backgroundData: { style: 'Color', color: '#002677' },
          borderData: { thickness: 0, radius: 50 },
          lineColor: 'red',
        },
        {
          widgetType: widgetTypes.Button,
          x: 10,
          y: 502,
          text: 'Button Label',
          bold: true,
          width: 400,
          height: 60,
          minWidth: 180,
          minHeight: 60,
          align: 'center',
          paddingData: { top: 5, bottom: 5, left: 5, right: 5 },
          backgroundData: { style: 'Color', color: 'white' },
          textColor: '#002677',
          borderData: { thickness: 2, radius: 50 },
          lineColor: '#002677',
        },
      ];
      createChildWidgets(newParentWidgetId, childWidgets, e);
    }
    if (widgetType === widgetTypes.Form) {
      const childWidgets = [
        {
          widgetType: widgetTypes.Text,
          x: 10,
          y: 20,
          text: 'Your Form Title',
          fontSize: 24,
          bold: true,
          width: 400,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          backgroundData: { style: 'Color', color: 'white' },
        },
        {
          widgetType: widgetTypes.Text,
          x: 10,
          y: 70,
          text: 'All fields with an asterisk (*) are required',
          fontSize: 12,
          bold: false,
          width: 400,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          backgroundData: { style: 'Color', color: 'white' },
        },
        {
          widgetType: widgetTypes.TextInput,
          x: 10,
          y: 120,
          label: 'Name',
          required: true,
          width: 400,
          height: 85,
          minWidth: 100,
          minHeight: 40,
        },
        {
          widgetType: widgetTypes.TextInput,
          x: 10,
          y: 220,
          label: 'E-Mail Address',
          required: true,
          width: 400,
          height: 85,
          minWidth: 180,
          minHeight: 30,
        },
        {
          widgetType: widgetTypes.TextInput,
          x: 10,
          y: 320,
          label: 'Contact Number',
          required: false,
          width: 400,
          height: 85,
          minWidth: 180,
          minHeight: 30,
        },
        {
          widgetType: widgetTypes.RadioButton,
          x: 10,
          y: 420,
          label: 'Preferred Contact Method',
          required: false,
          width: 400,
          height: 85,
          minWidth: 180,
          minHeight: 30,
          value: 'Call, Text, E-Mail',
        },
        {
          widgetType: widgetTypes.Button,
          x: 120,
          y: 520,
          text: 'Submit',
          action: 'submit',
          bold: true,
          width: 180,
          height: 60,
          minWidth: 180,
          minHeight: 60,
          align: 'center',
          paddingData: { top: 5, bottom: 5, left: 5, right: 5 },
          textColor: 'white',
          backgroundData: { style: 'Color', color: '#002677' },
          borderData: { thickness: 0, radius: 50 },
          lineColor: '',
          parentId: newParentWidgetId,
          site: site,
        },
      ];
      createChildWidgets(newParentWidgetId, childWidgets, e);
    }
    if (widgetType === widgetTypes.Banner) {
      const childWidgets = [
        {
          widgetType: widgetTypes.Image,
          x: 10,
          y: 30,
          width: 30,
          height: 30,
          minWidth: 10,
          minHeight: 10,
          backgroundImage: {
            url:
              'https://cdn-stage.linkhealth.com/site-builder/horizon/Flag.png',
            alt: '',
          },
        },
        {
          widgetType: widgetTypes.Text,
          x: 60,
          y: 10,
          text: bannerWidgetDefaultBodyText,
          bold: false,
          width: 900,
          height: 80,
          minWidth: 180,
          minHeight: 40,
          align: 'none',
          backgroundData: { style: 'Color', color: '#EEF4FF' },
        },
        {
          widgetType: widgetTypes.Divider,
          x: 0,
          y: 93,
          bold: false,
          width: 1000,
          height: 5,
          minWidth: 1,
          minHeight: 1,
          align: 'none',
          lineColor: '#224AA1',
        },
      ];
      props.updateWidgetPosition(newParentWidgetId, widgetConfig.height, 0);
      createChildWidgets(newParentWidgetId, childWidgets, e, true);
    }
    if (widgetType === widgetTypes.SlideShow) {
      const childWidgets = [
        {
          widgetType: widgetTypes.Image,
          x: 79,
          y: 36,
          width: 413,
          height: 232,
          minWidth: 10,
          minHeight: 10,
          backgroundImage: {
            url:
              'https://cdn-stage.linkhealth.com/site-builder/horizon/image-placeholder.png',
            alt: '',
          },
          tabIndex: 0,
        },

        {
          widgetType: widgetTypes.Text,
          x: 532,
          y: 36,
          text: 'Slideshow Heading 1',
          bold: true,
          width: 600,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          fontSize: 18,
          align: 'none',
          tabIndex: 0,
        },

        {
          widgetType: widgetTypes.Text,
          x: 532,
          y: 78,
          text: slideShowWidgetDefaultBodyText,
          bold: false,
          width: 600,
          height: 135,
          minWidth: 180,
          minHeight: 40,
          fontSize: 11,
          align: 'none',
          tabIndex: 0,
        },

        {
          widgetType: widgetTypes.Button,
          x: 530,
          y: 213,
          text: 'Button',
          bold: true,
          width: 150,
          height: 50,
          minWidth: 100,
          minHeight: 10,
          align: 'center',
          paddingData: { top: 5, bottom: 5, left: 5, right: 5 },
          textColor: 'white',
          backgroundData: { style: 'Color', color: '#002677' },
          borderData: { thickness: 0, radius: 50 },
          lineColor: 'red',
          tabIndex: 0,
        },

        {
          widgetType: widgetTypes.Image,
          x: 79,
          y: 36,
          width: 413,
          height: 232,
          minWidth: 10,
          minHeight: 10,
          backgroundImage: {
            url:
              'https://cdn-stage.linkhealth.com/site-builder/horizon/image-placeholder.png',
            alt: '',
          },
          tabIndex: 1,
        },

        {
          widgetType: widgetTypes.Text,
          x: 532,
          y: 36,
          text: 'Slideshow Heading 2',
          bold: true,
          width: 600,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          fontSize: 18,
          align: 'none',
          tabIndex: 1,
        },

        {
          widgetType: widgetTypes.Text,
          x: 532,
          y: 78,
          text: slideShowWidgetDefaultBodyText,
          bold: false,
          width: 600,
          height: 135,
          minWidth: 180,
          minHeight: 40,
          fontSize: 11,
          align: 'none',
          tabIndex: 1,
        },

        {
          widgetType: widgetTypes.Button,
          x: 530,
          y: 213,
          text: 'Button',
          bold: true,
          width: 150,
          height: 50,
          minWidth: 100,
          minHeight: 10,
          align: 'center',
          paddingData: { top: 5, bottom: 5, left: 5, right: 5 },
          textColor: 'white',
          backgroundData: { style: 'Color', color: '#002677' },
          borderData: { thickness: 0, radius: 50 },
          lineColor: 'red',
          tabIndex: 1,
        },

        {
          widgetType: widgetTypes.Image,
          x: 79,
          y: 36,
          width: 413,
          height: 232,
          minWidth: 10,
          minHeight: 10,
          backgroundImage: {
            url:
              'https://cdn-stage.linkhealth.com/site-builder/horizon/image-placeholder.png',
            alt: '',
          },
          tabIndex: 2,
        },

        {
          widgetType: widgetTypes.Text,
          x: 532,
          y: 36,
          text: 'Slideshow Heading 3',
          bold: true,
          width: 600,
          height: 40,
          minWidth: 180,
          minHeight: 40,
          fontSize: 18,
          align: 'none',
          tabIndex: 2,
        },

        {
          widgetType: widgetTypes.Text,
          x: 532,
          y: 78,
          text: slideShowWidgetDefaultBodyText,
          bold: false,
          width: 600,
          height: 135,
          minWidth: 180,
          minHeight: 40,
          fontSize: 11,
          align: 'none',
          tabIndex: 2,
        },

        {
          widgetType: widgetTypes.Button,
          x: 530,
          y: 213,
          text: 'Button',
          bold: true,
          width: 150,
          height: 50,
          minWidth: 100,
          minHeight: 10,
          align: 'center',
          paddingData: { top: 5, bottom: 5, left: 5, right: 5 },
          textColor: 'white',
          backgroundData: { style: 'Color', color: '#002677' },
          borderData: { thickness: 0, radius: 50 },
          lineColor: 'red',
          tabIndex: 2,
        },
      ];
      createChildWidgets(newParentWidgetId, childWidgets, e);
    }
  };

  const handleToggledNav = e => {
    if (config.navWidgetEnabled) {
      onDragItem(e, widgetTypes.Navigation);
    }
  };

  return (
    <>
      <StyledMenuItem
        icon={Widgets}
        onClick={() => toggle(true)}
        rootStyles={{
          ['& > .' + menuClasses.button]: {
            color: theme.colors.aux.white,
            '&:hover': {
              backgroundColor: theme.colors.aux.white,
            },
            '&:focus': {
              backgroundColor: '#D9F6FA',
            },
          },
          ['.' + menuClasses.subMenuContent]: {
            backgroundColor: theme.colors.aux.white,
            width: isOpen ? '264px' : '0px',
            margin: '-10px',
          },
          [`.${menuClasses.icon}`]: {
            width: '34px',
            minWidth: '34px',
            height: '34px',
            color: '#2E3034',
            '&:hover': {
              backgroundColor: theme.colors.aux.skyBlue,
              fill: theme.colors.aux.darkNavyBlue,
            },
          },
        }}
        title="Widgets"
        data-test-id="widgets-menu-icon"
      >
        <div style={{ height: '775px', overflowY: 'scroll' }}>
          <TopSection data-test-id="widgets-heading-section">
            <StyledHeading>Widgets</StyledHeading>
            <Icon
              icon="Collapse"
              onClick={() => toggle(false)}
              width="22px"
              style={{ marginLeft: '114px' }}
            />
          </TopSection>
          <Panel.Group>
            <StyledPanel>
              <Panel
                data-test-id="widget-visual-elements-panel"
                title={<Label style={StyledPanelTitle}>Visual Elements</Label>}
                initiallyClosed
                icon={Arrow}
              >
                <IconGroupWrapper
                  style={
                    !site.access.edit
                      ? { pointerEvents: 'none', opacity: '0.4' }
                      : {}
                  }
                >
                  <IconWrapper
                    draggable={true}
                    onDragEnd={e => onDragItem(e, widgetTypes.Text)}
                    data-test-id="text-widget-icon"
                  >
                    <StyledIcon icon={duotone('text')} title="Text" />
                    Text
                  </IconWrapper>
                  <IconWrapper
                    draggable={true}
                    onDragEnd={e => onDragItem(e, widgetTypes.Image)}
                    id="image-widget-icon"
                  >
                    <StyledIcon
                      data-test-id="image-widget-icon"
                      icon={duotone('image')}
                      title="Image"
                    />
                    Image
                  </IconWrapper>
                  {/* <IconWrapper
                    draggable={true}
                    onDragEnd={e => onDragItem(e, widgetTypes.VideoUpload)}
                  >
                    <StyledIcon
                      data-test-id="video-widget-icon"
                      icon={duotone('video-camera')}
                      title="VideoUpload"
                    />
                    VideoUpload
                  </IconWrapper> */}
                  <IconWrapper
                    draggable={true}
                    onDragEnd={e => onDragItem(e, 'Button')}
                  >
                    <StyledIcon
                      data-test-id="button-widget-icon"
                      icon={duotone('link-simple')}
                      title="Button"
                    />
                    Button
                  </IconWrapper>
                  <IconWrapper
                    draggable={true}
                    onDragEnd={e => onDragItem(e, widgetTypes.Link)}
                  >
                    <StyledIcon
                      data-test-id="link-widget-icon"
                      icon={duotone('link')}
                      title="Link"
                    />
                    Link
                  </IconWrapper>
                  <IconWrapper
                    draggable={true}
                    onDragEnd={e => onDragItem(e, widgetTypes.Divider)}
                    data-test-id="divider-widget-icon"
                  >
                    <StyledIcon
                      data-test-id="divider-widget-icon"
                      icon={duotone('horizontal-rule')}
                      title="Divider"
                    />
                    Divider
                  </IconWrapper>
                  <IconWrapper
                    draggable={true}
                    onDragEnd={e => onDragItem(e, widgetTypes.Html)}
                  >
                    <StyledIcon
                      data-test-id="html-widget-icon"
                      icon={duotone('code')}
                      title="HTML"
                    />
                    HTML
                  </IconWrapper>
                  <IconWrapper
                    draggable={true}
                    onDragEnd={e => onDragItem(e, 'Section')}
                  >
                    <StyledIcon
                      data-test-id="section-widget-icon"
                      icon={duotone('pipe-section')}
                      title="Section"
                    />
                    Section
                  </IconWrapper>

                  <IconWrapper
                    draggable={true}
                    onDragEnd={e => onDragItem(e, widgetTypes.uploadWidget)}
                  >
                    <StyledIcon
                      data-test-id="upload-widget-icon"
                      icon={duotone('plus-square')}
                      title="Upload Widget"
                    />
                    Upload a widget
                  </IconWrapper>
                </IconGroupWrapper>
              </Panel>
            </StyledPanel>
            <StyledPanel>
              <Panel
                data-test-id="widget-components-panel"
                title={<Label style={StyledPanelTitle}>Components</Label>}
                initiallyClosed
                icon={Arrow}
              >
                <IconGroupWrapper
                  style={
                    !site.access.edit
                      ? { pointerEvents: 'none', opacity: '0.4' }
                      : {}
                  }
                >
                  <IconWrapper
                    data-test-id="accordion-widget-icon-wrapper"
                    draggable={true}
                    onDragEnd={e => onDragItem(e, widgetTypes.AccordionWidget)}
                  >
                    <StyledIcon icon={duotone('list')} title="Accordion" />
                    Accordion
                  </IconWrapper>
                  <IconWrapper
                    data-test-id="application-widget-icon-wrapper"
                    draggable={true}
                    onDragEnd={e => onDragItem(e, widgetTypes.Application)}
                  >
                    <StyledIcon
                      data-test-id="application-widget-icon"
                      icon={duotone('browsers')}
                      title="Application"
                    />
                    Application
                  </IconWrapper>
                  <IconWrapper
                    data-test-id="card-widget-icon"
                    draggable={true}
                    onDragEnd={e => onDragItem(e, widgetTypes.Card)}
                  >
                    <StyledIcon icon={duotone('cards-blank')} title="Card" />
                    Card
                  </IconWrapper>
                  <IconWrapper
                    data-test-id="slideshow-widget-icon"
                    draggable={true}
                    onDragEnd={e => onDragItem(e, widgetTypes.SlideShow)}
                  >
                    <StyledIcon
                      icon={duotone('gallery-thumbnails')}
                      title="Slideshow"
                    />
                    Slideshow
                  </IconWrapper>
                  <IconWrapper
                    draggable={true}
                    onDragEnd={e => onDragItem(e, widgetTypes.Banner)}
                    data-test-id="banner-widget-icon"
                  >
                    <StyledIcon
                      data-test-id="banner-widget-icon"
                      icon={duotone('flag-pennant')}
                      title="Banner"
                    />
                    Banner
                  </IconWrapper>
                  <IconWrapper
                    data-test-id="tabs-widget-icon"
                    draggable={true}
                    onDragEnd={e => onDragItem(e, widgetTypes.Tabs)}
                  >
                    <StyledIcon icon={duotone('tablet-rugged')} title="Tabs" />
                    Tabs
                  </IconWrapper>
                  <IconWrapper
                    draggable={true}
                    onDragEnd={e => handleToggledNav(e)}
                  >
                    <StyledIcon
                      data-test-id="navbar-widget-icon"
                      icon={duotone('bars')}
                      title="Navbar"
                    />
                    Navbar
                  </IconWrapper>
                </IconGroupWrapper>
              </Panel>
            </StyledPanel>
            <StyledPanel>
              <Panel
                data-test-id="widget-forms-panel"
                title={<Label style={StyledPanelTitle}>Forms</Label>}
                initiallyClosed
                icon={Arrow}
              >
                <IconGroupWrapper
                  style={
                    !site.access.edit
                      ? { pointerEvents: 'none', opacity: '0.4' }
                      : {}
                  }
                >
                  <IconWrapper
                    draggable={true}
                    onDragEnd={e => onDragItem(e, widgetTypes.Form)}
                  >
                    <StyledIcon
                      data-test-id="form-block-widget-icon"
                      icon={duotone('file-invoice')}
                      title="Form Block"
                    />
                    Form Block
                  </IconWrapper>
                  <IconWrapper
                    draggable={true}
                    onDragEnd={e => onDragItem(e, 'TextInput')}
                  >
                    <StyledIcon
                      data-test-id="text-input-widget-icon"
                      icon={duotone('input-text')}
                      title="Text Input"
                    />
                    Text Input
                  </IconWrapper>
                  <IconWrapper
                    draggable={true}
                    onDragEnd={e => onDragItem(e, 'Checkbox')}
                  >
                    <StyledIcon
                      data-test-id="checkbox-widget-icon"
                      icon={duotone('square-check')}
                      title="Checkbox"
                    />
                    Checkbox
                  </IconWrapper>
                  <IconWrapper
                    draggable={true}
                    onDragEnd={e => onDragItem(e, 'Radio')}
                  >
                    <StyledIcon
                      data-test-id="radio-widget-icon"
                      icon={duotone('circle-dot')}
                      title="Radio"
                    />
                    Radio
                  </IconWrapper>
                  <IconWrapper
                    draggable={true}
                    onDragEnd={e => onDragItem(e, widgetTypes.Dropdown)}
                  >
                    <StyledIcon
                      data-test-id="dropdown-widget-icon"
                      icon={duotone('list-dropdown')}
                      title="Dropdown"
                    />
                    Dropdown
                  </IconWrapper>
                  <IconWrapper
                    draggable={true}
                    onDragEnd={e => onDragItem(e, 'FileUploader')}
                  >
                    <StyledIcon
                      data-test-id="file-uploader-widget-icon"
                      icon={duotone('file-import')}
                      title="File Uploader"
                    />
                    File Uploader
                  </IconWrapper>
                </IconGroupWrapper>
              </Panel>
            </StyledPanel>
          </Panel.Group>
        </div>
      </StyledMenuItem>
    </>
  );
};
