import React, { ChangeEvent, FC } from 'react';
import Switch from '@material-ui/core/Switch';
import { useLovelySwitchStyles } from '@mui-treasury/styles/switch/lovely';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Label, IDropdownItem } from '@uitk/react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { DropdownStyles } from './SettingsPanel/SettingsPanel';

const StyledText = styled(Typography)`
  font-family: 'OptumSans';
`;

export const StyledSwitch = styled(Switch)`
  & + span {
    font-family: 'OptumSans';
  }
`;

const labelStyles = {
  color: '#323334',
  'font-size': '14px',
  'font-family': 'OptumSans',
  'line-height': '20px',
  'margin-left': '-8px',
  'padding-top': '7px',
};

const StyledDropdown = styled.p``;

const styles = {
  width: '231px',
  height: '40px',
  'margin-left': '-8px',
  'font-family': 'OptumSans',
};

export interface LovelySwitchStyleProps {
  siteIdp: string;
  idpFlag: string;
  idpValue: IDropdownItem[];
  idps: Array<any>;
  onChangeIdentityProvider: (e: any) => void;
}

export const LovelySwitchStyle: FC<LovelySwitchStyleProps> = (
  props: LovelySwitchStyleProps
) => {
  const { siteIdp, idpFlag, idpValue, onChangeIdentityProvider } = props;

  let toggleValue = idpFlag === 'enable' ? true : false;
  const [toggled, setToggled] = React.useState(toggleValue);
  const lovelyStyles = useLovelySwitchStyles();

  const handleToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setToggled(e.target.checked);
    if (e.target.checked === false) {
      onChangeIdentityProvider('');
    }
  };

  let getValue = () => {
    let returnValue = [];
    idpValue.map(idp =>
      idp.value === siteIdp
        ? returnValue.push({
            id: idp.id,
            label: idp.label,
            value: idp.value,
          })
        : ''
    );
    return returnValue[0];
  };

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div>
      <FormGroup data-test-id="toggle-form-group">
        <FormControlLabel
          control={
            <StyledSwitch
              classes={lovelyStyles}
              checked={toggled}
              onChange={handleToggleChange}
              // value={idpFlag}
              data-test-id="idpflag-toggle-switch"
            />
          }
          label={toggled ? 'Enabled' : 'Disabled'}
        />
      </FormGroup>
      <div>
        <Label style={labelStyles}>Identity Provider Selection</Label>
        <StyledDropdown style={styles} onClick={handleClick}>
          <DropdownStyles
            type="single"
            items={idpValue}
            value={toggled ? getValue() : ''}
            disabled={!toggled}
            placeholderText={<StyledText>Please Select</StyledText>}
            data-test-id="identity-provider-dropdown"
            onChange={onChangeIdentityProvider}
          />
        </StyledDropdown>
      </div>
    </div>
  );
};
