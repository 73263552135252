import {
  StylesProvider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';

const HeaderCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-size: x-large;
    font-family: OptumSans;
    font-weight: 700;
    border: '1px solid black',
    textAlign: 'left',
    & > input {
      font-family: OptumSans;
    }
  }
`;

const StyledCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-family: OptumSans;
    font-size: medium;
    padding: '8px',
    textAlign: 'center',
  }
`;

const EmptyFormTypeTable = () => {
  const data = [
    {
      header: <b>Your website forms with count zero</b>,
      content: (
        <div style={{ textAlign: 'center' }}>
          <div style={{ margin: '15px' }}>
            <b>You do not have any forms on your site.</b>
          </div>
          <div style={{ color: '#8d91a0' }}>
            To create form on your site, add form block to any of your pages.
            Your submissions will appear here when they are made.
          </div>
        </div>
      ),
    },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: 'Your website Forms (0)',
        accessor: 'content',
        Cell: ({ cell: { value } }) => value,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <StylesProvider injectFirst>
      <Table
        {...getTableProps()}
        style={{
          border: '1px solid #d3d3d3',
          borderCollapse: 'collapse',
          width: '100%',
        }}
      >
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <HeaderCell
                  {...column.getHeaderProps()}
                  style={{ color: '#002677', border: '1px solid black' }}
                >
                  {column.render('Header')}
                </HeaderCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                style={{ border: '1px solid #d3d3d3' }}
              >
                {row.cells.map(cell => {
                  return (
                    <StyledCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </StyledCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </StylesProvider>
  );
};

export default EmptyFormTypeTable;
