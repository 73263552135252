import TextInputWidgetControl from 'shared/widgets/Form/TextInput/TextInputWidgetControl';
import { Config } from './types';

import {
  defaultBackgroundData,
  defaultBackgroundImageData,
  defaultBorderData,
  defaultSizeLocationData,
  defaultTypographyData,
} from 'builder/util/constants';
import TextInputWidget from './TextInputWidget';

export default {
  type: 'TextInput',
  displayName: 'TextInput',
  icon: 'TextSize',
  initialConfig: {
    ...defaultTypographyData,
    ...defaultSizeLocationData,
    ...defaultBackgroundData,
    ...defaultBackgroundImageData,
    ...defaultBorderData,
    uniqueId: '123abc',
    lineColor: 'red',
    backgroundColor: 'white',
    required: false,
    label: 'Label',
    height: 100,
    width: 345,
    minWidth: 100,
    minHeight: 80,
    parentId: '',
    value: '',
    validation: 'none',
    placeholder: '',
  },
  components: {
    render: TextInputWidget,
    control: TextInputWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
