import { ActionCreator, AnyAction, Reducer } from 'redux';
import { Selector, RootState, ThunkActionCreator } from 'shared/state';
import { USER_FOUND } from 'redux-oidc';
import { User, selectOrganizations } from 'shared/state/misc/oidc';
import alphasort from 'shared/util/alphasort';

const SET_ACTIVE = 'duplo/org/SET_ACTIVE';
export interface State {
  activeId?: string;
}

const defaultState: State = {
  activeId: null,
};

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ACTIVE:
      return {
        ...state,
        activeId: action.id,
      };
    case USER_FOUND:
      const user: User = action.payload;

      if (user.profile.organizations) {
        const orgs = user.profile.organizations;
        // Maintain the currently active org if possible
        if (
          orgs instanceof Array
            ? orgs.some(org => org.orgId === state.activeId)
            : orgs.orgId === state.activeId
        ) {
          return state;
        }

        // Make the first org active
        return {
          ...state,
          activeId:
            orgs instanceof Array
              ? (orgs.length > 0 &&
                  orgs.sort((a, b) => alphasort(a.name, b.name))[0].orgId) ||
                null
              : orgs.orgId,
        };
      }
      return state;
    default:
      return state;
  }
};

export default reducer;

// if organizations have already loaded, activeOrg can only be set to one of the user's orgs
const shouldUpdateActiveId = (orgId: string, state: RootState) => {
  const organizations = selectOrganizations()(state);
  return (
    (organizations.length === 0 ||
      organizations.some(org => org.orgId === orgId)) &&
    selectActiveId()(state) !== orgId
  );
};

export const setActiveId: ActionCreator<AnyAction> = (id: string) => ({
  type: SET_ACTIVE,
  id,
});

export const setActiveOrgId: ThunkActionCreator = (orgId: string) => (
  dispatch,
  getState
) => {
  const state = getState();
  if (shouldUpdateActiveId(orgId, state)) {
    dispatch(setActiveId(orgId));
  }
};

const selectSlice: Selector<State> = () => (state): State => {
  return state.org;
};

export const selectActiveId: Selector<string> = () => state => {
  return selectSlice()(state).activeId;
};
