interface Config {
  env: string;
  askId: string;
  productId: string;
  analyticsIndex: string;
  authority: string;
  links: Links;
  /**
   * Base URL for third-party cookie checking pages.
   */
  tpc: string;
  cdnUrl: string;
  /**
   * Base portal domain.
   */
  baseDomain: string;
  accessMessageEnabled: boolean;
  mbmSelfRegistrationUrl?: string;
  smapSelfRegistrationUrl?: string;
  picDefaultAdminEmail?: string;
  ocClientId?: string;
}

interface Links {
  privacy: string;
  terms: string;
  optumid: OptumId;
  relyingAppId: string;
}

interface OptumId {
  healthcareId: optumIdp;
  onprem: optumIdp;
}
interface optumIdp {
  links: optumIdLink;
  displayName: string;
}

const OptumIdpName = {
  HEALTHCARE_ID: 'One Healthcare ID',
  ON_PREM: 'Optum ID',
};

interface optumIdLink {
  profile: string;
  passReset?: string;
  security?: string;
}

const healthcareIdProfile =
  'https://identity.nonprod.onehealthcareid.com/web/redirect?to=manage-profile&target=https://';

const envs: { [index: string]: Config } = {
  dev: {
    env: 'DEV',
    askId: 'UHGWM110-020255',
    productId: '78',
    analyticsIndex: 'optumoi-stage.com',
    authority: 'https://idx-dev.linkhealth.com/auth/realms/developer-platform',
    baseDomain: 'sites.link-np.o360.cloud',
    links: {
      privacy:
        'https://provider-stage.linkhealth.com/content/odin/en/provider-dashboard/public/privacy-policy/linkprivacy-policy.html',
      terms:
        'https://provider-stage.linkhealth.com/content/odin/en/provider-dashboard/public/privacy-policy/linkterms-of-use.html',
      relyingAppId: '&relyingAppId=lnkdv67876',
      optumid: {
        healthcareId: {
          displayName: OptumIdpName.HEALTHCARE_ID,
          links: {
            profile: healthcareIdProfile,
          },
        },
        onprem: {
          displayName: OptumIdpName.ON_PREM,
          links: {
            profile:
              'https://stg-healthid.optum.com/tb/link/update-profile?TARGET=https://',
            passReset:
              'https://stg-healthid.optum.com/tb/link/change-password?TARGET=https://',
            security:
              'https://stg-healthid.optum.com/tb/link/update-security-questions?TARGET=https://',
          },
        },
      },
    },
    tpc: 'https://cdn-stage.linkhealth.com/common/cookies',
    cdnUrl: 'https://cdn-test.linkhealth.com',
    accessMessageEnabled: true,
  },
  test: {
    env: 'DEV',
    askId: 'UHGWM110-020255',
    productId: '78',
    analyticsIndex: 'optumoi-stage.com',
    authority: 'https://idx-dev.linkhealth.com/auth/realms/developer-platform',
    baseDomain: 'sites.link-np.o360.cloud',

    links: {
      privacy:
        'https://provider-stage.linkhealth.com/content/odin/en/provider-dashboard/public/privacy-policy/linkprivacy-policy.html',
      terms:
        'https://provider-stage.linkhealth.com/content/odin/en/provider-dashboard/public/privacy-policy/linkterms-of-use.html',
      relyingAppId: '&relyingAppId=lnkdv67876',
      optumid: {
        healthcareId: {
          displayName: OptumIdpName.HEALTHCARE_ID,
          links: {
            profile: healthcareIdProfile,
          },
        },
        onprem: {
          displayName: OptumIdpName.ON_PREM,
          links: {
            profile:
              'https://stg-healthid.optum.com/tb/link/update-profile?TARGET=https://',
            passReset:
              'https://stg-healthid.optum.com/tb/link/change-password?TARGET=https://',
            security:
              'https://stg-healthid.optum.com/tb/link/update-security-questions?TARGET=https://',
          },
        },
      },
    },
    tpc: 'https://cdn-stage.linkhealth.com/common/cookies',
    cdnUrl: 'https://cdn-test.linkhealth.com',
    accessMessageEnabled: true,
    mbmSelfRegistrationUrl:
      'https://developer-stage.optumoi.com/apps/security2/MBM/selfRegistration',
    smapSelfRegistrationUrl:
      'https://developer-stage.optumoi.com/apps/security2/SMAP/selfRegistration',
  },
  stage: {
    env: 'STAGE',
    askId: 'UHGWM110-020255',
    productId: '78',
    analyticsIndex: 'optumoi-stage.com',
    authority:
      'https://idx-stage.linkhealth.com/auth/realms/developer-platform',
    baseDomain: 'linkplatform-stage.com',

    links: {
      privacy:
        'https://provider-stage.linkhealth.com/content/odin/en/provider-dashboard/public/privacy-policy/linkprivacy-policy.html',
      terms:
        'https://provider-stage.linkhealth.com/content/odin/en/provider-dashboard/public/privacy-policy/linkterms-of-use.html',
      relyingAppId: '&relyingAppId=lnkdv67876',
      optumid: {
        healthcareId: {
          displayName: OptumIdpName.HEALTHCARE_ID,
          links: {
            profile: healthcareIdProfile,
          },
        },
        onprem: {
          displayName: OptumIdpName.ON_PREM,
          links: {
            profile:
              'https://stg-healthid.optum.com/tb/link/update-profile?TARGET=https://',
            passReset:
              'https://stg-healthid.optum.com/tb/link/change-password?TARGET=https://',
            security:
              'https://stg-healthid.optum.com/tb/link/update-security-questions?TARGET=https://',
          },
        },
      },
    },
    tpc: 'https://cdn-stage.linkhealth.com/common/cookies',
    cdnUrl: 'https://cdn-stage.linkhealth.com',
    accessMessageEnabled: true,
    mbmSelfRegistrationUrl:
      'https://developer-stage.optumoi.com/apps/security2/MBM/selfRegistration',
    smapSelfRegistrationUrl:
      'https://developer-stage.optumoi.com/apps/security2/SMAP/selfRegistration',
    picDefaultAdminEmail: 'Pic_Link_Security_Stage_Admin@ds.uhc.com',
    ocClientId: 'optumcare_ocnpauth_nonprod',
  },
  prod: {
    env: 'PROD',
    askId: 'UHGWM110-020255',
    productId: '78',
    analyticsIndex: 'optumoi.com',
    authority: 'https://idx.linkhealth.com/auth/realms/developer-platform',
    baseDomain: 'linkplatform.com',
    links: {
      privacy:
        'https://provider.linkhealth.com/content/odin/en/provider-dashboard/public/privacy-policy/linkprivacy-policy.html',
      terms:
        'https://provider.linkhealth.com/content/odin/en/provider-dashboard/public/privacy-policy/linkterms-of-use.html',
      relyingAppId: '&relyingAppId=lnkdv67876',
      optumid: {
        healthcareId: {
          displayName: OptumIdpName.HEALTHCARE_ID,
          links: {
            profile:
              'https://identity.onehealthcareid.com/web/redirect?to=manage-profile&target=https://',
          },
        },
        onprem: {
          displayName: OptumIdpName.ON_PREM,
          links: {
            profile:
              'https://healthid.optum.com/tb/link/update-profile?TARGET=https://',
            passReset:
              'https://healthid.optum.com/tb/link/change-password?TARGET=https://',
            security:
              'https://healthid.optum.com/tb/link/update-security-questions?TARGET=https://',
          },
        },
      },
    },
    tpc: 'https://cdn.linkhealth.com/common/cookies',
    cdnUrl: 'https://cdn.linkhealth.com',
    accessMessageEnabled: true,
    mbmSelfRegistrationUrl:
      'https://developer.optumoi.com/apps/security2/MBM/selfRegistration',
    smapSelfRegistrationUrl:
      'https://developer.optumoi.com/apps/security2/MSPN/selfRegistration',
    picDefaultAdminEmail: 'HCTServicesTMA@advisory.com',
    ocClientId: 'optumproportal',
  },
};

export default envs[process.env.LINK_ENV] || envs.stage;
