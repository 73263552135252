import styled from 'styled-components';
import { ScreenSizeBreakpoints as breakpoints } from 'shared/constants';

const Div = styled.div`
  @media (max-width: ${breakpoints.small}px) {
    margin: 0 0.5rem;
  }
  @media (min-width: ${breakpoints.small +
      1}px) and (max-width: ${breakpoints.medium}px) {
    margin: 0 0.75rem;
  }
  @media (min-width: ${breakpoints.medium + 1}px) {
    margin: 0 1rem;
  }
`;

export const ContainerDiv = styled.div`
  @media (max-width: ${breakpoints.small}px) {
    margin: 0 1rem;
  }
  @media (min-width: ${breakpoints.small +
      1}px) and (max-width: ${breakpoints.medium}px) {
    margin: 0 1.25rem;
  }
  @media (min-width: ${breakpoints.medium + 1}px) {
    margin: 0 1.5rem;
  }
`;

export default Div;
