import { PureComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageContext from 'shared/util/PageContext';
import ThemeContext from 'shared/util/ThemeContext';
import WidgetConnectorNew from '../SiteBuilder/WidgetConnector';
import styled from 'styled-components';
import BuilderTopMenu from 'builder/components/BuilderTopMenu';
import { containerHeight } from '../SiteBuilder/SiteLandingPage';
import Feedback from 'shared/components/Feedback/Feedback';
import { User } from 'shared/state/misc/oidc';

interface PreviewProps {
  site: Site;
  pages: Array<Page>;
  basePath: string;
  user: User;
  isAccordionPanelOpen?: boolean;
  accordionHeightShift?: number;
}

const PreviewContainer = styled.div<{
  scrollHeight: number;
}>`
  height: ${p => p.scrollHeight - 76}px;
  position: relative;
  max-width: 1280px;
  margin: 2px auto;
  @media only screen and (max-width: 1280px) {
    overflow-x: hidden;
    width: 1280px;
  }
`;

class Preview extends PureComponent<PreviewProps> {
  render() {
    const {
      site,
      pages,
      user,
      basePath,
      isAccordionPanelOpen,
      accordionHeightShift,
    } = this.props;
    if (!site) {
      return <Redirect to="/sites" />;
    }

    const siteRoutes = pages.map(page => (
      <Route
        key={page.id}
        path={`${basePath}/${page.current.slug}`}
        exact
        render={() => (
          <PageContext.Provider
            value={{
              site,
              pages,
              page,
            }}
          >
            <BuilderTopMenu
              siteId={site.id}
              pageId={page.id}
              isPreviewMode={true}
            />
            <PreviewContainer
              scrollHeight={containerHeight(
                page.current,
                isAccordionPanelOpen,
                accordionHeightShift
              )}
            >
              <WidgetConnectorNew
                currentPage={page.current}
                siteId={site.id}
                pageId={page.id}
                widgetId={''}
                page={page}
              />
              <Feedback
                value={site.header.feedback}
                siteId={site.id}
                siteName={site.name}
                user={user}
              />
            </PreviewContainer>
          </PageContext.Provider>
        )}
      />
    ));

    return (
      <ThemeContext.Provider
        value={{
          theme: site.theme,
        }}
      >
        <Switch>
          {siteRoutes}
          <Route>
            <Redirect
              to={`${basePath}/${
                pages.find(page => page.id === site.horizonhomepageId)?.current
                  .slug
              }`}
            />
          </Route>
        </Switch>
      </ThemeContext.Provider>
    );
  }
}

export default Preview;
