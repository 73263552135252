import { Row } from 'react-table';

export const defaultSortBy = (rowA: Row, rowB: Row, id: string) => {
  const a = String(rowA.values[id]).toLowerCase();
  const b = String(rowB.values[id]).toLowerCase();
  return a === b ? 0 : a > b ? 1 : -1;
};

export const numberSortBy = (rowA: Row, rowB: Row, id: string) => {
  const a = Number(rowA.values[id]);
  const b = Number(rowB.values[id]);
  return a === b ? 0 : a > b ? 1 : -1;
};

export const sortByDateFunction = (rowA: Row, rowB: Row, id: string) => {
  const a = rowA.values[id].date;
  const b = rowB.values[id].date;
  return a === b ? 0 : a > b ? 1 : -1;
};

export const filterByContains = (rows, id, filterValue) => {
  return rows.filter(row => {
    const value = row.values[id];
    return (
      value &&
      String(value)
        .toLowerCase()
        .includes(String(filterValue).toLowerCase())
    );
  });
};
