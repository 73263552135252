import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';

const OuterWrapper = styled.div`
  position: absolute;
  top: 35px;
  left: -80px;
  display: ${props => (props.showTooltip ? 'block' : 'none')};
  width: 200px;

  white-space: normal;

  background-color: #475f6a;
  color: white;

  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  z-index: 3;
`;

const InnerWrapper = styled.div`
  position: absolute;
  top: -14px;
  left: 80px;

  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;

  border-bottom: 16px solid #475f6a;
`;

const TextWrapper = styled.p`
  @font-face {
    font-family: 'OptumSans';
    src: url('https://cdn.linkhealth.com/common/fonts/OptumSans/OptumSans-Regular.ttf');
    font-weight: 100;
    font-style: normal;
  }
  padding: 1rem;
  font-family: 'OptumSans';
`;

const InfoBox = props => {
  return (
    <OuterWrapper showTooltip={props.showTooltip}>
      <InnerWrapper />
      <TextWrapper>{props.text}</TextWrapper>
    </OuterWrapper>
  );
};

InfoBox.defaultProps = {
  showTooltip: false,
};

InfoBox.propTypes = {
  showTooltip: PropTypes.bool,
};

export default InfoBox;
