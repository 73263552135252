import React from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';
import {
  StylesProvider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Button as UITKButton } from '@uitk/react';
import { useHistory } from 'react-router-dom';

const StyledButton = styled(UITKButton)`
  font-family: 'OptumSans';
  height: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const FormNameSpan = styled.span`
  font-weight: bold;
  font-family: 'OptumSans';
  font-size: 16px;
`;

const StyledSpan = styled.span`
  font-family: 'OptumSans';
  font-size: 16px;
  color: #8d91a0;
`;

const StyledBoldSpan = styled.span`
  font-family: 'OptumSans';
  font-weight: 400;
  color: #000;
  font-size: 16px;
`;

const HeaderCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-size: x-large;
    font-family: OptumSans;
    font-weight: 700;
    border: '1px solid black',
    textAlign: 'left',
    & > input {
      font-family: OptumSans;
    }
  }
`;

export interface FormTypeTableProps {
  formTypes: Array<FormTypesData>;
}

const FormTypesListTable = (props: FormTypeTableProps) => {
  const { formTypes } = props;
  const history = useHistory();
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const handleViewSubmissions = (formId: number, formName: string) => {
    history.push({
      pathname: `/forms/${formId}`,
      state: { formName: formName, formId: formId },
    });
  };

  const data = formTypes.map(formType => {
    return {
      header: 'Your website forms with count zero',
      content: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '10px 20px',
              width: '40%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '10px',
                justifyContent: 'space-between',
              }}
            >
              <FormNameSpan>{formType.formName}</FormNameSpan>
              <StyledSpan style={{ position: 'absolute', left: '18%' }}>
                URL - <StyledBoldSpan>{formType.siteUrl}</StyledBoldSpan>
              </StyledSpan>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '10px',
              }}
            >
              <StyledSpan>{`Created on ${new Date(
                formType.createdTime
              ).toLocaleDateString('en-US', options)} -`}</StyledSpan>
              <span>&nbsp;</span>
              <StyledBoldSpan>{formType.createdBy}</StyledBoldSpan>
            </div>
          </div>
          <div>
            <StyledButton
              onClick={() =>
                handleViewSubmissions(formType.formId, formType.formName)
              }
            >
              View Submissions
            </StyledButton>
          </div>
        </div>
      ),
    };
  });

  const columns = React.useMemo(
    () => [
      {
        Header: `Your website forms (${formTypes.length})`,
        accessor: 'content',
        Cell: ({ cell: { value } }) => value,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <StylesProvider injectFirst>
      <Table
        {...getTableProps()}
        style={{
          border: '1px solid #d3d3d3',
          borderCollapse: 'collapse',
          width: '98%',
        }}
      >
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <HeaderCell
                  {...column.getHeaderProps()}
                  style={{
                    border: '1px solid #d3d3d3',
                    padding: '8px',
                    textAlign: 'left',
                    fontWeight: 'bold',
                    fontFamily: 'OptumSans',
                    color: '#002677',
                    fontSize: '25px',
                  }}
                >
                  {column.render('Header')}
                </HeaderCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                style={{ border: '1px solid #d3d3d3' }}
              >
                {row.cells.map(cell => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      style={{
                        border: '1px solid #d3d3d3',
                        padding: '8px',
                        textAlign: 'center',
                      }}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </StylesProvider>
  );
};

export default FormTypesListTable;
