import { Reducer } from 'redux';
import { Selector, AsyncThunkActionCreator } from 'shared/state';
import api, { TilesResponse } from 'shared/api/myapps';
import { selectActiveId } from 'shared/state/ducks/org';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export interface State {
  data: {
    applications: Array<Application>;
    errors: Array<string>;
  };
  meta: StateMeta;
}

const defaultState: State = {
  data: {
    applications: [],
    errors: [],
  },
  meta: {
    pending: false,
  },
};

export const FETCH_MYAPPS_FULFILLED = 'duplo/myapps/FETCH_MYAPPS_FULFILLED';
export const FETCH_MYAPPS_PENDING = 'duplo/myapps/FETCH_MYAPPS_PENDING';
export const FETCH_MYAPPS_FAILED = 'duplo/myapps/FETCH_MYAPPS_FAILED';

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_MYAPPS_PENDING:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: true,
        },
      };
    case FETCH_MYAPPS_FULFILLED:
      return {
        ...state,
        data: {
          applications: (action.data as TilesResponse).appTiles,
          errors: (action.data as TilesResponse).errors,
        },
        meta: {
          ...state.meta,
          pending: false,
          successful: true,
          error: null,
        },
      };
    case FETCH_MYAPPS_FAILED:
      return {
        ...state,
        data: {
          applications: [],
          errors: [],
        },
        meta: {
          ...state.meta,
          pending: false,
          successful: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default reducer;

export const fetchMyApps: AsyncThunkActionCreator<Promise<TilesResponse>> = (
  published: boolean
) => (dispatch: any, getState: any): Promise<TilesResponse> => {
  const orgId = selectActiveId()(getState());
  dispatch({ type: FETCH_MYAPPS_PENDING, orgId });
  return api
    .get(published, orgId)
    .then(response => {
      dispatch({ type: FETCH_MYAPPS_FULFILLED, data: response, orgId });
      return response;
    })
    .catch(e => {
      dispatch({
        type: FETCH_MYAPPS_FAILED,
        error: 'Could not fetch applications',
        orgId,
      });
      toast('Error fetching applications', { type: 'error', theme: 'colored' });
      return Promise.reject(e);
    });
};

const selectSlice: Selector<State> = () => state => {
  return state.myapps;
};

export const selectApps: Selector<Array<Application>> = () => state => {
  return selectSlice()(state).data.applications;
};

export const selectErrors: Selector<Array<string>> = () => state => {
  return selectSlice()(state).data.errors;
};

export const selectMeta: Selector<StateMeta> = () => state => {
  return selectSlice()(state).meta;
};
