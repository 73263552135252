import { ChangeEvent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Label, Select, FormControl } from '@uitk/react';
import { linkDeveloperTheme as theme } from 'link-ui-react';
import S3Image from 'builder/components/S3Image/S3Image';
import LogoPreview from '../Background/LogoPreview';
import { MainLabelStyles } from '../Typography/Typography';
import {
  StyledLabel,
  StyledTextInput,
} from 'builder/components/WidgetConfigurations/EnableFullScreenWidth';

export interface ButtonPanelProps<T> {
  pages?: Array<Page>;
  site?: Site;
  widgetState?: Widget<T>;
  currentTab?: number;
  updateSite?: (site: Site) => void;
  saveSite?: () => void;
  handleLineColor?: (data: string) => void;
  handleColorEditClick?: () => void;
  handleIconStyle?: (data: string) => void;
  handleIcon?: (data: Image) => void;
  handleIconPlacement?: (data: string) => void;
  handleIconAltText?: (data: string) => void;
}

const IconStyleWrapper = styled.div`
  display: flex;
  align-items: baseline;
  padding: 0.5rem 0;
`;

const IconUploadWrapper = styled.div`
  padding-top: 20px;
  align-items: baseline;
  display: flex;
`;

const IconPlacementWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: baseline;
  display: flex;
`;

const ButtonStyleSelect = styled(Select)`
  font-family: 'OptumSans';
  width: 9.375rem;
  border-radius: 0.375rem;
  margin-left: 20px;
  .kASQuK {
    font-family: OptumSans;
    line-height: 1.15rem;
    box-shadow: 0 0 0 1px ${theme.colors.aux.black};
    padding: 3px;
    padding-left: 10px;
    font-size: 14px;
  }
`;

const StyleLabel = {
  color: '#1d1929',
  'font-size': '14px',
  'font-family': 'OptumSans',
  'padding-top': '10px',
  'font-weight': '500',
};

export const S3ImagePreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  padding-top: 10px;
  padding-left: 10px;
`;

export const S3ImageControlsWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: flex-start;
  padding-left: 23.5px;
`;

const IconAltTextWrapper = styled.div`
  display: block;
  margin: 0rem 0rem 1.5rem 0rem;
`;

export function getIconPlacement(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.iconPlacement;
    case 1:
      return widgetState.config.hoverData.iconPlacement;
    default:
  }
}

export function getIcon(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return {
        url: widgetState.config.icon.url,
        alt: widgetState.config.icon.alt,
      };
    case 1:
      return {
        url: widgetState.config.hoverData.icon.url,
        alt: widgetState.config.hoverData.icon.alt,
      };
    default:
  }
}

export function getIconStyle(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.iconStyle;
    case 1:
      return widgetState.config.hoverData.iconStyle;
    default:
  }
}

export function getIconAltText(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.iconAltText;
    case 1:
      return widgetState.config.hoverData.iconAltText;
    default:
  }
}

const ButtonPanel: React.FC<ButtonPanelProps<any>> = (
  buttonPanelProps: ButtonPanelProps<any>
) => {
  const {
    handleIconStyle,
    handleIconPlacement,
    handleIcon,
    handleIconAltText,
    widgetState,
    currentTab,
  } = buttonPanelProps;
  const iconStyleOptions = ['Without Icon', 'With Icon'];
  const iconPlacementOptions = ['Left', 'Right'];
  const [iconStyle, setIconStyle] = useState(
    getIconStyle(widgetState, currentTab)
  );
  const [iconPlacement, setIconPlacement] = useState(
    getIconPlacement(widgetState, currentTab)
  );
  const [icon, setIcon] = useState(getIcon(widgetState, currentTab));
  const [iconAltText, setIconAltText] = useState(
    getIconAltText(widgetState, currentTab)
  );

  useEffect(() => {
    handleIconStyle(iconStyle);
  }, [iconStyle]);

  useEffect(() => {
    handleIconPlacement(iconPlacement);
  }, [iconPlacement]);

  useEffect(() => {
    handleIcon(icon);
  }, [icon.url]);

  useEffect(() => {
    handleIconAltText(iconAltText);
  }, [iconAltText]);

  useEffect(() => {
    setIconStyle(getIconStyle(widgetState, currentTab));
    setIconPlacement(getIconPlacement(widgetState, currentTab));
    setIcon(getIcon(widgetState, currentTab));
    setIconAltText(getIconAltText(widgetState, currentTab));
  }, [widgetState.config]);

  function handleIconStyleChange(e: ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    setIconStyle(e.target.value);
  }

  function handleIconPlacementChange(e: ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    setIconPlacement(e.target.value);
  }

  function handleIconChange(icon: Image) {
    setIcon(icon);
  }

  const handleIconAltTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIconAltText(e.target.value);
  };

  return (
    <>
      <IconStyleWrapper>
        <MainLabelStyles>Style</MainLabelStyles>
        <FormControl
          data-test-id={'button-style-dropdown'}
          id={'button-controlled-select'}
        >
          <ButtonStyleSelect
            name="buttonStyle"
            data-test-id="button-style"
            value={iconStyle}
            onChange={handleIconStyleChange}
          >
            {iconStyleOptions.map(b => {
              return (
                <option value={b} key={b}>
                  {b}
                </option>
              );
            })}
          </ButtonStyleSelect>
        </FormControl>
      </IconStyleWrapper>
      {iconStyle === 'With Icon' && (
        <>
          <IconUploadWrapper>
            <MainLabelStyles>Icon</MainLabelStyles>
            <S3ImageControlsWrapper>
              <S3Image
                image={icon}
                label="Click to Upload"
                data-test-id="icon-image"
                onUpload={handleIconChange}
              />
              <S3ImagePreviewWrapper>
                {icon && (
                  <LogoPreview url={icon.url} maxWidth={50} maxHeight={50} />
                )}
              </S3ImagePreviewWrapper>
            </S3ImageControlsWrapper>
          </IconUploadWrapper>
          <IconPlacementWrapper>
            <Label style={StyleLabel}>Icon Placement</Label>
            <FormControl
              data-test-id={'button-icon-placement-dropdown'}
              id={'icon-controlled-select'}
            >
              <ButtonStyleSelect
                name="iconPlacement"
                data-test-id="icon-placement"
                value={iconPlacement}
                onChange={handleIconPlacementChange}
              >
                {iconPlacementOptions.map(b => {
                  return (
                    <option value={b} key={b}>
                      {b}
                    </option>
                  );
                })}
              </ButtonStyleSelect>
            </FormControl>
          </IconPlacementWrapper>
          <IconAltTextWrapper>
            <StyledLabel>Icon Alt Text</StyledLabel>
            <StyledTextInput
              data-test-id="alt-text-input"
              value={iconAltText ? iconAltText : ''}
              onChange={handleIconAltTextChange}
            />
          </IconAltTextWrapper>
        </>
      )}
    </>
  );
};

export default ButtonPanel;
