import { connect } from 'react-redux';
import { RootState, Dispatch } from 'shared/state';
import {
  selectAccessRequestOpen,
  closeAccessRequestDialog,
} from 'shared/state/ducks/ui';
import AccessRequestDialog from './AccessRequestDialog';
import { sendEmailToAdmin } from 'shared/state/ducks/accessrequesttoadmin';
import { selectUser } from 'shared/state/misc/oidc';

interface Props {
  dialogTitle: string;
}

const mapStateToProps = (state: RootState, ownProps: Props) => ({
  open: selectAccessRequestOpen()(state),
  dialogTitle: ownProps.dialogTitle,
  user: selectUser()(state),
  tenancy: state.sites.data[0].clientId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose: () => {
    dispatch(closeAccessRequestDialog());
  },
  sendEmailToAdmin: (adminEmailForm: AdminEmailForm) => {
    dispatch(sendEmailToAdmin(adminEmailForm));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccessRequestDialog);
