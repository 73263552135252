import { PureComponent } from 'react';
import Loading from 'shared/components/Loading';
import { getUserManager } from 'client/util/userManager';
import { Route, RouteProps, Redirect } from 'react-router';
import LoadingWrapper from 'client/components/LoadingWrapper';

interface ImpersonationProps extends RouteProps {
  postImpersonationPage: string;
}

interface ImpersonationState {
  loading: boolean;
}

class Impersonation extends PureComponent<
  ImpersonationProps,
  ImpersonationState
> {
  constructor(props: ImpersonationProps) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  componentDidMount() {
    const userManager = getUserManager();
    userManager.getUser().then(user => {
      if (!user) {
        userManager
          .signinSilent()
          .then(() => {
            this.setState({
              loading: false,
            });
          })
          .catch(error => {
            console.debug(error);
          });
      }
    });
  }

  render() {
    const { postImpersonationPage } = this.props;
    const { loading } = this.state;
    if (loading) {
      return (
        <LoadingWrapper>
          <Loading message="Impersonation Request Pending" />
        </LoadingWrapper>
      );
    } else {
      return (
        <Route>
          <Redirect to={`/${postImpersonationPage}`} />
        </Route>
      );
    }
  }
}

export default Impersonation;
