import { connect } from 'react-redux';
import { RootState } from 'shared/state';
import SiteFooter, { SiteFooterProps } from './SiteFooter';
import { selectUser } from 'shared/state/misc/oidc';

const mapStateToProps = (state: RootState, ownProps: SiteFooterProps) => ({
  browser: state.browser,
  user: selectUser()(state),
  ...ownProps,
});

const SiteFooterContainer = connect(mapStateToProps)(SiteFooter);
export default SiteFooterContainer;
