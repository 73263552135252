import BannerWidget from './BannerWidget';
import { Config } from './types';
import BannerWidgetControl from './BannerWidgetControl';
import { defaultBackgroundImageData } from 'builder/util/constants';

export default {
  type: 'Banner',
  displayName: 'Banner',
  icon: 'cards-blank',
  initialConfig: {
    ...defaultBackgroundImageData,
    borderData: { thickness: 0, radius: 5 },
    paddingData: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    width: 1000,
    height: 100,
    xLocation: 10,
    yLocation: 10,
    uniqueId: 'banner345',
    lineColor: '#828485',
    showonAllPage: false,
    browserCondition: false,
    browserList: '',
    backgroundData: { style: 'Color', color: '#EEF4FF' },
    backgroundColor: '#EEF4FF',
    enableFullScreen: false,
  },
  components: {
    render: BannerWidget,
    control: BannerWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
