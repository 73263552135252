import {
  applyMiddleware,
  createStore as rawCreateStore,
  compose,
  DeepPartial,
} from 'redux';
import thunk from 'redux-thunk';
import { rootReducer, RootState } from 'shared/state/reducer';
import { responsiveStoreEnhancer } from 'redux-responsive';
import { middleware as apiMiddlware } from 'shared/util/axios';

const composeEnhancers =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      name: 'Duplo',
    })) ||
  compose;

const createStore = (preloadedState?: DeepPartial<RootState>) =>
  rawCreateStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      responsiveStoreEnhancer,
      applyMiddleware(thunk, apiMiddlware)
    )
  );

export { createStore };
