import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'link-ui-react';
import InfoBox from './InfoBox';

const Info = styled(Icon)`
  fill: ${p => p.theme.colors.aux.blue};
`;

const InfoIcon = props => {
  const [showTooltip, setTooltipVisiblity] = useState(false);

  const handleOnEnter = () => {
    setTooltipVisiblity(true);
  };

  const handleOnLeave = () => {
    setTooltipVisiblity(false);
  };

  return (
    <>
      <Info
        icon={'Info'}
        height={'15px'}
        width={'15px'}
        onMouseEnter={handleOnEnter}
        onMouseLeave={handleOnLeave}
      />
      <InfoBox
        showTooltip={showTooltip ? true : false}
        text="Select to alter entries per page"
      />
    </>
  );
};

export default InfoIcon;
