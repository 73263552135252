import { PureComponent, Fragment } from 'react';
import { CallbackComponent } from 'redux-oidc';
import styled from 'styled-components';
import URI from 'urijs';
import { Redirect } from 'react-router';
import { History, Location } from 'history';
import { Button } from 'link-ui-react';
import { User } from 'shared/state/misc/oidc';
import { retrieveLocation } from 'shared/util/locationPersistence';
import SitePage from 'shared/components/SitePage';
import SiteBody from 'shared/components/SiteBody';
import SiteFooter from 'shared/components/SiteFooter';
import Loading from 'shared/components/Loading';
import Message from 'shared/components/Message';
import {
  getUserManager,
  doSignInWithPostLoginPage,
} from 'client/util/userManager';
import { track } from 'shared/util/analytics/track';
import { linkDeveloperTheme as theme } from 'link-ui-react';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 0;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: ${theme.colors.aux.white};
`;

export interface CallbackProps {
  history: History;
  location: Location;
  successRedirect: string;
  site: Site;
  pages: Array<Page>;
  user: User;
  tracking?: any;
}

interface CallbackState {
  error: Error;
  errorMessage: string;
}

@track()
class Callback extends PureComponent<CallbackProps, CallbackState> {
  constructor(props: CallbackProps) {
    super(props);
    this.state = {
      error: undefined,
      errorMessage: '',
    };
  }

  handleSuccess = () => {
    const { history, successRedirect, tracking, user } = this.props;
    const location = retrieveLocation();
    tracking.trackEvent({
      action: 'login',
      page: location ? location.pathname : successRedirect,
      user: user.profile.sub,
      uuid: user.profile.sub,
      data: { optumId: user.profile.preferred_username },
      idpType: user.profile.identity_provider || `link-id`,
    });
    if (location.pathname) {
      history.push(location);
    } else if (location.url) {
      window.location.href = location.url;
    } else {
      history.push(successRedirect);
    }
  };

  handleError = (error: Error) => {
    const { location } = this.props;
    const params = URI.parseQuery(location.search) as { error?: string };

    this.setState({
      error,
      errorMessage: params.error || 'Login failed. Please try again.',
    });
    console.error(error);
  };

  handleRetrySignIn = () => {
    const { location } = this.props;
    doSignInWithPostLoginPage(location);
  };

  render() {
    const { site, pages, user, successRedirect } = this.props;
    const { error, errorMessage } = this.state;
    return (
      <CallbackComponent
        userManager={getUserManager()}
        successCallback={this.handleSuccess}
        errorCallback={this.handleError}
      >
        <SitePage>
          <SiteBody>
            {error ? (
              <ErrorWrapper>
                {user ? (
                  <Redirect to={successRedirect} />
                ) : (
                  <Fragment>
                    <Message error title={error.message}>
                      {errorMessage}
                    </Message>
                    <Button onClick={this.handleRetrySignIn}>Try Again</Button>
                  </Fragment>
                )}
              </ErrorWrapper>
            ) : (
              <LoadingWrapper>
                <Loading message="Authenticating" />
              </LoadingWrapper>
            )}
          </SiteBody>
          <SiteFooter
            footer={site.footer}
            logo={site.theme.logo}
            pages={pages}
          />
        </SitePage>
      </CallbackComponent>
    );
  }
}

export default Callback;
