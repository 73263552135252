import crypto from 'crypto-js';
import config from 'builder/config';

export interface StelliumData {
  siteIds: Array<number>;
  tenantId: string;
  askId: string;
}

const stelliumRedirectionUrl = (data: StelliumData) => {
  const jsonData = JSON.stringify(data);

  // Create a SHA256 hash of the data
  const hash = crypto.SHA256(jsonData).toString(crypto.enc.Hex);

  // Base64 encode the data and hash
  const encodedData = btoa(jsonData);
  const encodedHash = btoa(hash);

  //Combine the data and hash as URL query parameters
  const encodedUrl = `${config.stelliumBaseUrl}?data=${encodedData}&hash=${encodedHash}`;

  return encodedUrl;
};

export { stelliumRedirectionUrl };
