import { PureComponent, ReactNode } from 'react';
import { FormGroup, FormControl, Typography } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import styled from 'styled-components';
import FormOutlinedTextField from 'builder/components/forms/FormOutlinedTextField';

const TypographyWrapper = styled.div`
  margin-top: 1em;
  margin-bottom: -1em;
  .MuiTypography-subtitle1 {
    font-weight: bold;
  }
`;

const TypographyWrapperFirstField = styled.div`
  margin-top: -1.5em;
  margin-bottom: -1em;
  .MuiTypography-subtitle1 {
    font-weight: bold;
  }
`;

export interface AccessFormProps {
  accessRequest: Partial<AccessRequest>;
  reasonMessageTitle: string;
  onSubmit: (accessRequestForm: Partial<AdminEmailForm>) => void;
  render: (props: {
    form: ReactNode;
    onSubmit: () => void;
    invalid: boolean;
    values: Partial<AccessRequest>;
  }) => ReactNode;
}

class AccessForm extends PureComponent<AccessFormProps> {
  validateAccessReason = (accessReason: string): string | undefined => {
    if (!accessReason) {
      return 'Reason is required';
    }
  };

  render() {
    const { onSubmit, render, accessRequest, reasonMessageTitle } = this.props;
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={accessRequest}
        render={({ handleSubmit, invalid, values }) => (
          <form onSubmit={handleSubmit}>
            {render({
              form: (
                <FormGroup>
                  <TypographyWrapperFirstField>
                    <Typography variant="subtitle1">User ID</Typography>
                  </TypographyWrapperFirstField>
                  <FormControl size="small" variant="outlined"></FormControl>
                  <Field
                    name="userName"
                    component={FormOutlinedTextField}
                    data-test-id="userName"
                    type="text"
                    margin="normal"
                    disabled
                  />
                  <TypographyWrapper>
                    <Typography variant="subtitle1">Name</Typography>
                  </TypographyWrapper>
                  <FormControl size="small" variant="outlined"></FormControl>
                  <Field
                    name="name"
                    component={FormOutlinedTextField}
                    data-test-id="name"
                    type="text"
                    margin="normal"
                    autoFocus
                    fullWidth
                    disabled
                  />
                  <TypographyWrapper>
                    <Typography variant="subtitle1">Email</Typography>
                  </TypographyWrapper>
                  <FormControl size="small" variant="outlined"></FormControl>
                  <Field
                    name="userEmail"
                    component={FormOutlinedTextField}
                    data-test-id="email"
                    type="text"
                    margin="normal"
                    autoFocus
                    fullWidth
                    disabled
                  />
                  <TypographyWrapper>
                    <Typography variant="subtitle1">
                      {reasonMessageTitle}
                    </Typography>
                  </TypographyWrapper>
                  <FormControl size="small" variant="outlined"></FormControl>
                  <Field
                    name="accessReason"
                    validate={this.validateAccessReason}
                    component={FormOutlinedTextField}
                    data-test-id="accessReason"
                    type="text"
                    margin="normal"
                    autoFocus
                    fullWidth
                    required
                  />
                </FormGroup>
              ),
              onSubmit: handleSubmit,
              invalid,
              values,
            })}
          </form>
        )}
      />
    );
  }
}

export default AccessForm;
