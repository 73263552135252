import { ActionCreator, AnyAction, Reducer } from 'redux';
import { Selector } from 'shared/state';

export const OPEN_CREATE_SITE_DIALOG = 'duplo/ui/OPEN_CREATE_SITE_DIALOG';
export const CLOSE_CREATE_SITE_DIALOG = 'duplo/ui/CLOSE_CREATE_SITE_DIALOG';
export const OPEN_CREATE_PAGE_DIALOG = 'duplo/ui/OPEN_CREATE_PAGE_DIALOG';
export const CLOSE_CREATE_PAGE_DIALOG = 'duplo/ui/CLOSE_CREATE_PAGE_DIALOG';
export const OPEN_ACCESS_REQUEST_DIALOG = 'duplo/ui/OPEN_ACCESS_REQUEST_DIALOG';
export const CLOSE_ACCESS_REQUEST_DIALOG =
  'duplo/ui/CLOSE_ACCESS_REQUEST_DIALOG';
export const OPEN_CONDITIONAL_EMAIL_DIALOG =
  'duplo/ui/OPEN_CONDITIONAL_EMAIL_DIALOG';
export const CLOSE_CONDITIONAL_EMAIL_DIALOG =
  'duplo/ui/CLOSE_CONDITIONAL_EMAIL_DIALOG';
export const OPEN_EDIT_PAGE_DIALOG = 'duplo/ui/OPEN_EDIT_PAGE_DIALOG';
export const CLOSE_EDIT_PAGE_DIALOG = 'duplo/ui/CLOSE_EDIT_PAGE_DIALOG';
export const UPDATE_LATEST_EDIT_PATH = 'duplo/ui/UPDATE_LATEST_EDIT_PATH';
export const OPEN_SITE_LIST_DIALOG = 'duplo/ui/OPEN_SITE_LIST_DIALOG';
export const CLOSE_SITE_LIST_DIALOG = 'duplo/ui/CLOSE_SITE_LIST_DIALOG';
export const TOGGLE_SHOW_GRID = 'duplo/ui/TOGGLE_SHOW_GRID';
export const UNDO_BUTTON_PRESSED = 'duplo/ui/UNDO_BUTTON_PRESSED';
export const REDO_BUTTON_PRESSED = 'duplo/ui/REDO_BUTTON_PRESSED';
export const RESET_UNDO_REDO = 'duplo/ui/RESET_UNDO_REDO';
export const SET_RESET_FALSE = 'duplo/ui/SET_RESET_FALSE';
export const ACCORDION_PANEL_OPEN = 'duplo/ui/ACCORDION_PANEL_OPEN';
export const ACCORDION_PANEL_CLOSE = 'duplo/ui/ACCORDION_PANEL_CLOSE';

export interface State {
  createSiteDialog: {
    open: boolean;
  };
  createPageDialog: {
    open: boolean;
  };
  accessRequestDialog: {
    open: boolean;
  };
  conditionalEmailDialog: {
    open: boolean;
  };
  editPageDialog: {
    open: boolean;
  };
  siteListDialog: {
    open: boolean;
    siteName: string;
    siteId: number;
    dialogType: string;
  };
  latestEditPath: string;
  showGrid: boolean;
  undoRedoPressedState: {
    undo: boolean;
    redo: boolean;
    reset: boolean;
  };
  panelWidget: {
    open: boolean;
    widgetId: string;
    height: number;
  };
}

const defaultState: State = {
  createSiteDialog: {
    open: false,
  },
  createPageDialog: {
    open: false,
  },
  accessRequestDialog: {
    open: false,
  },
  conditionalEmailDialog: {
    open: false,
  },
  editPageDialog: {
    open: false,
  },
  siteListDialog: {
    open: false,
    siteName: '',
    siteId: 0,
    dialogType: '',
  },
  latestEditPath: '',
  showGrid: false,
  undoRedoPressedState: {
    undo: false,
    redo: false,
    reset: false,
  },
  panelWidget: {
    open: false,
    widgetId: '',
    height: 0,
  },
};

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_CREATE_SITE_DIALOG:
      return {
        ...state,
        createSiteDialog: {
          ...state.createSiteDialog,
          open: true,
        },
      };
    case CLOSE_CREATE_SITE_DIALOG:
      return {
        ...state,
        createSiteDialog: {
          ...state.createSiteDialog,
          open: false,
        },
      };
    case OPEN_CREATE_PAGE_DIALOG:
      return {
        ...state,
        createPageDialog: {
          ...state.createPageDialog,
          open: true,
        },
      };
    case CLOSE_CREATE_PAGE_DIALOG:
      return {
        ...state,
        createPageDialog: {
          ...state.createPageDialog,
          open: false,
        },
      };
    case OPEN_ACCESS_REQUEST_DIALOG:
      return {
        ...state,
        accessRequestDialog: {
          ...state.accessRequestDialog,
          open: true,
        },
      };
    case CLOSE_ACCESS_REQUEST_DIALOG:
      return {
        ...state,
        accessRequestDialog: {
          ...state.accessRequestDialog,
          open: false,
        },
      };
    case OPEN_CONDITIONAL_EMAIL_DIALOG:
      return {
        ...state,
        conditionalEmailDialog: {
          ...state.conditionalEmailDialog,
          open: true,
        },
      };
    case CLOSE_CONDITIONAL_EMAIL_DIALOG:
      return {
        ...state,
        conditionalEmailDialog: {
          ...state.conditionalEmailDialog,
          open: false,
        },
      };
    case OPEN_EDIT_PAGE_DIALOG:
      return {
        ...state,
        editPageDialog: {
          ...state.editPageDialog,
          open: true,
        },
      };
    case CLOSE_EDIT_PAGE_DIALOG:
      return {
        ...state,
        editPageDialog: {
          ...state.editPageDialog,
          open: false,
        },
      };
    case OPEN_SITE_LIST_DIALOG:
      return {
        ...state,
        siteListDialog: {
          open: true,
          siteId: action.siteId,
          siteName: action.siteName,
          dialogType: action.dialogType,
        },
      };
    case CLOSE_SITE_LIST_DIALOG:
      return {
        ...state,
        siteListDialog: {
          ...state.siteListDialog,
          open: false,
        },
      };
    case UPDATE_LATEST_EDIT_PATH:
      return {
        ...state,
        latestEditPath: action.value,
      };
    case TOGGLE_SHOW_GRID:
      return {
        ...state,
        showGrid: !state.showGrid,
      };
    case UNDO_BUTTON_PRESSED:
      return {
        ...state,
        undoRedoPressedState: {
          undo: true,
          redo: false,
          reset: false,
        },
      };
    case REDO_BUTTON_PRESSED:
      return {
        ...state,
        undoRedoPressedState: {
          redo: true,
          undo: false,
          reset: false,
        },
      };
    case RESET_UNDO_REDO:
      return {
        ...state,
        undoRedoPressedState: {
          undo: false,
          redo: false,
          reset: true,
        },
      };
    case SET_RESET_FALSE:
      return {
        ...state,
        undoRedoPressedState: {
          ...state.undoRedoPressedState,
          reset: false,
        },
      };
    case ACCORDION_PANEL_OPEN:
      return {
        ...state,
        panelWidget: {
          ...state.panelWidget,
          open: true,
          widgetId: action.widgetId,
          height: action.height,
        },
      };
    case ACCORDION_PANEL_CLOSE:
      return {
        ...state,
        panelWidget: {
          ...state.panelWidget,
          open: false,
          widgetId: action.widgetId,
          height: action.height,
        },
      };
    default:
      return state;
  }
};

export default reducer;

export const setUndoButton: ActionCreator<AnyAction> = () => {
  return { type: UNDO_BUTTON_PRESSED };
};

export const setRedoButton: ActionCreator<AnyAction> = () => {
  return { type: REDO_BUTTON_PRESSED };
};

export const resetUndoRedo: ActionCreator<AnyAction> = () => {
  return { type: RESET_UNDO_REDO };
};

export const setResetToFalse: ActionCreator<AnyAction> = () => {
  return { type: SET_RESET_FALSE };
};

export const openCreateSiteDialog: ActionCreator<AnyAction> = () => {
  return { type: OPEN_CREATE_SITE_DIALOG };
};

export const closeCreateSiteDialog: ActionCreator<AnyAction> = () => {
  return { type: CLOSE_CREATE_SITE_DIALOG };
};

export const openCreatePageDialog: ActionCreator<AnyAction> = () => {
  return { type: OPEN_CREATE_PAGE_DIALOG };
};

export const openAccessRequestDialog: ActionCreator<AnyAction> = () => {
  return { type: OPEN_ACCESS_REQUEST_DIALOG };
};

export const closeAccessRequestDialog: ActionCreator<AnyAction> = () => {
  return { type: CLOSE_ACCESS_REQUEST_DIALOG };
};

export const closeCreatePageDialog: ActionCreator<AnyAction> = () => {
  return { type: CLOSE_CREATE_PAGE_DIALOG };
};

export const openEditPageDialog: ActionCreator<AnyAction> = () => {
  return { type: OPEN_EDIT_PAGE_DIALOG };
};

export const toggleShowGrid: ActionCreator<AnyAction> = () => {
  return { type: TOGGLE_SHOW_GRID };
};

export const openAccordionPanel: ActionCreator<AnyAction> = (
  widgetId: string,
  height: string
) => {
  return { type: ACCORDION_PANEL_OPEN, widgetId, height };
};

export const closeAccordionPanel: ActionCreator<AnyAction> = (
  widgetId: string,
  height: string
) => {
  return { type: ACCORDION_PANEL_CLOSE, widgetId, height };
};

export const openSiteListDialog: ActionCreator<AnyAction> = (
  dialogType: string,
  siteId: number,
  siteName: string
) => {
  return {
    type: OPEN_SITE_LIST_DIALOG,
    dialogType: dialogType,
    siteId: siteId,
    siteName: siteName,
  };
};

export const closeSiteListDialog: ActionCreator<AnyAction> = () => {
  return { type: CLOSE_SITE_LIST_DIALOG };
};

export const openConditionalEmailDialog: ActionCreator<AnyAction> = () => {
  return { type: OPEN_CONDITIONAL_EMAIL_DIALOG };
};

export const closeConditionalEmailDialog: ActionCreator<AnyAction> = () => {
  return { type: CLOSE_CONDITIONAL_EMAIL_DIALOG };
};

export const closeEditPageDialog: ActionCreator<AnyAction> = () => {
  return { type: CLOSE_EDIT_PAGE_DIALOG };
};

export const updateLatestEditPath: ActionCreator<AnyAction> = (
  newPath: string
) => {
  return { type: UPDATE_LATEST_EDIT_PATH, value: newPath };
};

export const getLatestEditPath: Selector<string> = () => state => {
  return state.ui.latestEditPath;
};

export const selectCreateSiteDialogOpen: Selector<boolean> = () => state => {
  return state.ui.createSiteDialog.open;
};

export const selectCreatePageDialogOpen: Selector<boolean> = () => state => {
  return state.ui.createPageDialog.open;
};

export const selectAccessRequestOpen: Selector<boolean> = () => state => {
  return state.ui.accessRequestDialog.open;
};

export const selectConditionalEmailDialogOpen: Selector<boolean> = () => state => {
  return state.ui.conditionalEmailDialog.open;
};

export const selectEditPageDialogOpen: Selector<boolean> = () => state => {
  return state.ui.editPageDialog.open;
};

export const selectSiteListDialogOpen: Selector<boolean> = () => state => {
  return state.ui.siteListDialog.open;
};

export const selectSiteListDialogSiteId: Selector<number> = () => state => {
  return state.ui.siteListDialog.siteId;
};

export const selectSiteListDialogSiteName: Selector<string> = () => state => {
  return state.ui.siteListDialog.siteName;
};

export const selectSiteListDialogDialogType: Selector<string> = () => state => {
  return state.ui.siteListDialog.dialogType;
};

export const getShowGridToggleValue: Selector<boolean> = () => state => {
  return state.ui.showGrid;
};
