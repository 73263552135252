import { RootState } from 'shared/state';
import { Config } from './types';
import { connect } from 'react-redux';
import LoginButton from './LoginButton';
import { selectUser } from 'shared/state/misc/oidc';
import { selectSite } from 'shared/state/ducks/sites';

export interface LoginButtonContainerProps {
  siteId: number;
  editing?: boolean;
  isPreview?: boolean;
  widgetState: Widget<Config>;
}

const mapStateToProps = (
  state: RootState,
  ownProps: LoginButtonContainerProps
) => ({
  user: selectUser()(state),
  published: state.published,
  site: selectSite(ownProps.siteId)(state),
  ...ownProps,
});

export default connect(mapStateToProps)(LoginButton);
