import { PureComponent } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Radium, { StyleRoot, Style } from 'radium';
import styled, { css } from 'styled-components';

import './Modal-Styles.css';
import axios from 'shared/util/axios';
import { toast } from 'react-toastify';
import { FeedbackFormField } from 'shared/widgets/SimpleForm/types';
import { Field, Form } from 'react-final-form';
import { validateField } from 'shared/widgets/Form/validations';
import { TextField } from 'final-form-material-ui';
import { FEEDBACK_PLACEHOLDER_MESSAGE } from 'builder/util/constants';
import { getColorValue } from 'shared/util/theme';
import CloseIcon from './close.svg';

const StyledSpan = styled.span<{
  selected: boolean;
  primaryColor: string;
  textColor: string;
  hoverBorderColor: string;
}>`
  ${props =>
    props.selected
      ? css`
          background-color: ${getColorValue(props.primaryColor, props.theme)};
          border-radius: 2px;
          color: ${getColorValue(props.textColor, props.theme)};
          border: 1px solid
            ${getColorValue(props.hoverBorderColor, props.theme)};
          box-shadow: ${getColorValue(props.hoverBorderColor, props.theme)} 0px
            0px 0px 1px;
          &:hover {
            border: 1px solid
              ${getColorValue(props.hoverBorderColor, props.theme)};
            box-shadow: ${getColorValue(props.hoverBorderColor, props.theme)}
              0px 0px 0px 1px;
          }
        `
      : css`
          background-color: hsla(240, 100%, 10%, 0.04);
          border-radius: 2px;
          &:hover {
            border: 1px solid
              ${getColorValue(props.hoverBorderColor, props.theme)};
            box-shadow: ${getColorValue(props.hoverBorderColor, props.theme)}0px
              0px 0px 1px;
          }
        `}
`;

const StyledFeedBackInput = styled.input<{
  fontfamily: string;
}>`
  width: 0;
  position: fixed;
  opacity: 0;
  &:hover {
    outline: none;
    box-shadow: 0 0 0 2px #4d1d1d;
  }
  font-family: ${props => props.fontfamily};
`;

const StyledFeedbackInputDiv = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ClosedIconDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const StyledTextField = styled(TextField)`
  border-radius: 5px;
`;

const StyledTitle = styled.h1<{ fontfamily: string }>`
  margin: 0 0 0.75rem;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  line-height: normal;
  font-family: ${props => props.fontfamily};
`;

const StyledSubmitButton = styled.button<{
  backgroundColor: string;
  textColor: string;
}>`
  background-color: ${p => getColorValue(p.backgroundColor, p.theme)};
  color: ${p => getColorValue(p.textColor, p.theme)};
`;

export interface ModalProps {
  feedbackTypes: string[];
  zIndex: string;
  buttonColor: string;
  textColor: string;
  hoverBorderColor: string;
  fontFamily: string;
  triggerModal: () => void;
  postSubmitButtonMsg: string;
  submitButtonMsg: string;
  issueFeedBackTypeEmailList: string;
  ideaFeedbackTypeEmailList: string;
  bugFeedbackTypeEmailList: string;
  formfields: Array<FeedbackFormField>;
  siteId: number;
  siteName: string;
}

export interface ModalState {
  feedbackType: string;
  feedbackMsg: string;
  loading: boolean;
  submitted: boolean;
  feedbackTypes: string[];
  initialLoad: boolean;
  feedBackMode: boolean;
  emailList: string;
  feedbackPlaceholdermsg: string;
}

let Textarea = Radium(TextareaAutosize);

class Modal extends PureComponent<ModalProps, ModalState> {
  mounted: boolean;

  constructor(props: ModalProps) {
    super(props);
    this.state = {
      feedbackType: this.props.feedbackTypes[0],
      feedbackMsg: '',
      loading: false,
      submitted: false,
      feedbackTypes: ['Issue', 'Idea', 'Other'],
      initialLoad: true,
      feedBackMode: false,
      emailList: '',
      feedbackPlaceholdermsg: FEEDBACK_PLACEHOLDER_MESSAGE[0],
    };
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;

    let tempArr: string[] = [];

    this.props.feedbackTypes.forEach((f, i) => {
      if (i < 3) {
        tempArr[i] = f.trim();
      }
    });

    this.setState({ feedbackTypes: tempArr });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  capitalize = (str: string): string => {
    return str.replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
  };

  handleChange = (e: any) => {
    this.setState({ feedbackMsg: e.target.value });
  };

  sendFeedBackEmail = (formUserData: any) => {
    return axios({
      method: 'POST',
      url: '/public-form',
      data: formUserData,
    })
      .then(response => {
        toast('Your feedback has been submitted.', { type: 'success' });
        return Promise.resolve(response);
      })
      .catch(error => {
        toast('Error occurred while sending feedback', {
          type: 'error',
        });
        return Promise.reject(error);
      })
      .finally(() => {
        setTimeout(() => {
          if (this.mounted) {
            this.setState({ loading: false });
            this.setState({ submitted: true });
            setTimeout(() => {
              if (this.mounted) {
                this.props.triggerModal();
              }
            }, 1000);
          }
        }, 1000);
      });
  };

  processSubmit = (formFields: Array<FeedbackFormField>) => (
    rawFormData: object
  ) => {
    // Prevent double+ submit
    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true });

    let { feedbackType, feedbackMsg, emailList } = this.state;

    const { siteId, siteName } = this.props;

    let payload = {
      formActionType: 'email',
      formData: {
        formfields: formFields
          .map(field => {
            return {
              label: field.placeholder,
              values: rawFormData[field.key],
            };
          })
          .concat({
            label: feedbackType,
            values: feedbackMsg,
          }),
        timeFrame:
          'Thanks for the feedback. Our team looks forward to being in touch soon',
        siteName: siteName,
      },
      emailAction: {
        subject: `Feedback: ${feedbackType}`,
        templateName: 'DuploFeedbackWidgetResponseEmail',
        enableConditionalEmailTo: true,
        conditionalEmailList: emailList.split(','),
      },
      siteId: siteId,
    };
    this.sendFeedBackEmail(payload);
  };

  handleFeedbackTypeChange = (index: number) => {
    this.setState({
      feedbackType: this.state.feedbackTypes[index],
      feedbackPlaceholdermsg: FEEDBACK_PLACEHOLDER_MESSAGE[index],
    });
  };

  render() {
    let {
      feedbackType,
      loading,
      submitted,
      feedbackTypes,
      initialLoad,
      feedBackMode,
    } = this.state;

    let {
      zIndex,
      issueFeedBackTypeEmailList,
      ideaFeedbackTypeEmailList,
      bugFeedbackTypeEmailList,
      buttonColor,
      hoverBorderColor,
      textColor,
      postSubmitButtonMsg,
      submitButtonMsg,
      formfields,
      fontFamily,
    } = this.props;

    const spanList = ['\u2757', '\uD83D\uDCA1', '\uD83D\uDCAD'];

    return (
      <StyleRoot>
        <Form
          onSubmit={this.processSubmit(formfields)}
          data-test-id="feedback-outer-form"
          render={({ handleSubmit, invalid, submitting }) => (
            <form
              className="frf-modal-container"
              style={{ zIndex: parseInt(zIndex) }}
              onSubmit={handleSubmit}
            >
              {initialLoad && (
                <>
                  <div className="frf-modal-content-container">
                    <ClosedIconDiv onClick={() => this.props.triggerModal()}>
                      <CloseIcon />
                    </ClosedIconDiv>
                    <StyledTitle
                      fontfamily={fontFamily}
                      id="feedbackfin__title"
                    >
                      Send feedback
                    </StyledTitle>
                    <StyledFeedbackInputDiv aria-label="Feedback type">
                      <StyledFeedBackInput
                        id="feedbackfin__radio--issue"
                        data-test-id={'feedbackfin-issue'}
                        fontfamily={fontFamily}
                        onClick={() =>
                          this.setState({
                            initialLoad: false,
                            feedBackMode: true,
                            feedbackType: feedbackTypes[0],
                            emailList: issueFeedBackTypeEmailList,
                            feedbackPlaceholdermsg:
                              FEEDBACK_PLACEHOLDER_MESSAGE[0],
                          })
                        }
                      />
                      <label
                        htmlFor="feedbackfin__radio--issue"
                        className="feedbackfin__button feedbackfin__radio-label"
                      >
                        <span className="feedbackfin__radio-icon">
                          &#x2757;
                        </span>{' '}
                        Issue
                      </label>
                      <StyledFeedBackInput
                        id="feedbackfin__radio--idea"
                        data-test-id={'feedbackfin-idea'}
                        fontfamily={fontFamily}
                        onClick={() =>
                          this.setState({
                            initialLoad: false,
                            feedBackMode: true,
                            feedbackType: feedbackTypes[1],
                            emailList: ideaFeedbackTypeEmailList,
                            feedbackPlaceholdermsg:
                              FEEDBACK_PLACEHOLDER_MESSAGE[1],
                          })
                        }
                      />
                      <label
                        htmlFor="feedbackfin__radio--idea"
                        className="feedbackfin__button feedbackfin__radio-label"
                      >
                        <span className="feedbackfin__radio-icon">
                          &#x1F4A1;
                        </span>{' '}
                        Idea
                      </label>
                      <StyledFeedBackInput
                        id="feedbackfin__radio--other"
                        data-test-id={'feedbackfin-other'}
                        fontfamily={fontFamily}
                        onClick={() =>
                          this.setState({
                            initialLoad: false,
                            feedBackMode: true,
                            feedbackType: feedbackTypes[2],
                            emailList: bugFeedbackTypeEmailList,
                            feedbackPlaceholdermsg:
                              FEEDBACK_PLACEHOLDER_MESSAGE[2],
                          })
                        }
                      />
                      <label
                        htmlFor="feedbackfin__radio--other"
                        className="feedbackfin__button feedbackfin__radio-label"
                      >
                        <span className="feedbackfin__radio-icon">
                          &#x1F4AD;
                        </span>{' '}
                        Other
                      </label>
                    </StyledFeedbackInputDiv>
                  </div>
                </>
              )}
              {feedBackMode && (
                <div className="frf-modal-content-container">
                  <ClosedIconDiv onClick={() => this.props.triggerModal()}>
                    <CloseIcon />
                  </ClosedIconDiv>
                  <StyledTitle fontfamily={fontFamily} id="feedbackfin__title">
                    Send feedback
                  </StyledTitle>
                  <div className="frf-modal-input-group">
                    <div className="frf-modal-feedback-types">
                      {this.state.feedbackTypes.map((_f, i) => (
                        <StyledSpan
                          className={
                            feedbackType === feedbackTypes[i]
                              ? 'frf-modal-feedback-type frf-modal-feedback-selected'
                              : 'frf-modal-feedback-type'
                          }
                          key={i + 2}
                          primaryColor={buttonColor}
                          textColor={textColor}
                          hoverBorderColor={hoverBorderColor}
                          selected={feedbackType === feedbackTypes[i]}
                          onClick={() => this.handleFeedbackTypeChange(i)}
                          data-test-id={`feedbackTab-type-${i}`}
                        >
                          {spanList[i]}
                          {'  '}
                          {this.capitalize(feedbackTypes[i])}
                        </StyledSpan>
                      ))}
                    </div>
                  </div>
                  {formfields &&
                    formfields.map((formField, index) => (
                      <div className="frf-modal-input-group">
                        <Field
                          name={formField.key}
                          key={index}
                          validate={validateField(
                            formField.required,
                            formField.validation
                          )}
                          component={StyledTextField}
                          placeholder={formField.placeholder || ''}
                        ></Field>
                      </div>
                    ))}

                  <div className="frf-modal-input-group">
                    <Style
                      key="5"
                      scopeSelector="textarea"
                      rules={{
                        ':hover': {
                          border: `1px solid ${hoverBorderColor}`,
                          boxShadow: `${hoverBorderColor} 0px 0px 0px 1px`,
                        },
                      }}
                    />
                    <Textarea
                      key="6"
                      className="frf-modal-input"
                      onChange={this.handleChange}
                      value={this.state.feedbackMsg}
                      required
                      name="feedbackMsg"
                      data-test-id={'feedback-textarea-msg'}
                      type="text"
                      style={{
                        'font-family': fontFamily,
                        ':hover': {
                          border: `1px solid ${hoverBorderColor}`,
                          boxShadow: `${hoverBorderColor} 0px 0px 0px 1px`,
                        },
                        ':focus': {
                          border: `1px solid ${hoverBorderColor}`,
                          boxShadow: `${hoverBorderColor} 0px 0px 0px 1px`,
                        },
                      }}
                      placeholder={this.state.feedbackPlaceholdermsg}
                    />
                  </div>
                  <StyledSubmitButton
                    className="frf-modal-button"
                    disabled={invalid || submitting}
                    key="7"
                    backgroundColor={buttonColor}
                    textColor={textColor}
                    type="submit"
                    data-test-id={'feedback-submit-button'}
                  >
                    {loading ? (
                      <div
                        key="8"
                        style={{
                          borderTop: `2.133px solid ${textColor}`,
                        }}
                        className="frf-modal-button-loader"
                      ></div>
                    ) : submitted ? (
                      <span>{postSubmitButtonMsg}</span>
                    ) : (
                      <span>{submitButtonMsg}</span>
                    )}
                  </StyledSubmitButton>
                </div>
              )}
            </form>
          )}
        />
      </StyleRoot>
    );
  }
}

export { Modal };
export default Radium(Modal);
