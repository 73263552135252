export const defaultTheme: Theme = {
  colors: {
    primary: '#92b6b1',
    secondary: '#b2c9ab',
    tertiary: '#e8ddb5',
    quaternary: '#e2c4a6',
    custom: [],
    text: {
      primary: '#000000',
      secondary: '#ffffff',
    },
  },
  font: {
    size: {
      body: {
        base: {
          value: 11,
        },
      },
    },
    weight: {
      base: {
        value: 'normal',
      },
    },
    family: {
      base: {
        value: 'OptumSans',
      },
    },
  },
};

export const horizonUhcTheme: Theme = {
  font: {
    size: {
      body: {
        base: {
          value: 11,
        },
      },
    },
    weight: {
      base: {
        value: 'normal',
      },
    },
    family: {
      base: {
        value: 'OptumSans',
      },
    },
  },
  colors: {
    primary: '#002677',
    secondary: '#E5F8FB',
    tertiary: '#004BA0',
    quaternary: '#D14600',
    custom: [
      { name: 'Brand 100', id: 'brand100', value: '#002677', type: 'Brand' },
      { name: 'Primary 5', id: 'primary5', value: '#E5F8FB', type: 'Brand' },
      { name: 'Primary 90', id: 'primary90', value: '#004BA0', type: 'Brand' },
      { name: 'Accent 03', id: 'accent03', value: '#D14600', type: 'Brand' },
      { id: 'primary10', value: '#D4FAFC', name: 'Primary 10', type: 'Brand' },
      { id: 'primary20', value: '#A5DFE7', name: 'Primary 20', type: 'Brand' },
      { id: 'primary70', value: '#196ECF', name: 'Primary 70', type: 'Brand' },
      {
        id: 'primary100',
        value: '#002677',
        name: 'Primary 100',
        type: 'Brand',
      },
      {
        id: 'primary110',
        value: '#001D5B',
        name: 'Primary 110',
        type: 'Brand',
      },
      {
        id: 'primary120',
        value: '#00184D',
        name: 'Primary 120',
        type: 'Brand',
      },
      { id: 'accent01', value: '#EFE0FA', name: 'Accent 01', type: 'Brand' },
      { id: 'accent02', value: '#422C88', name: 'Accent 02', type: 'Brand' },
      { id: 'accent04', value: '#AA3600', name: 'Accent 04', type: 'Brand' },
    ],
  },
};

export const uhcTheme: Array<Color> = [
  { id: 'primary', type: 'Brand', value: '#002677', name: 'Brand 100' },
  { id: 'secondary', type: 'Brand', value: '#E5F8FB', name: 'Primary 5' },
  { id: 'primary10', type: 'Brand', value: '#D4FAFC', name: 'Primary 10' },
  { id: 'primary20', type: 'Brand', value: '#A5DFE7', name: 'Primary 20' },
  { id: 'primary70', type: 'Brand', value: '#196ECF', name: 'Primary 70' },
  { id: 'tertiary', type: 'Brand', value: '#004BA0', name: 'Primary 90' },
  { id: 'primary100', type: 'Brand', value: '#002677', name: 'Primary 100' },
  { id: 'primary110', type: 'Brand', value: '#001D5B', name: 'Primary 110' },
  { id: 'primary120', type: 'Brand', value: '#00184D', name: 'Primary 120' },
  { id: 'accent01', type: 'Brand', value: '#EFE0FA', name: 'Accent 01' },
  { id: 'accent02', type: 'Brand', value: '#422C88', name: 'Accent 02' },
  { id: 'quaternary', type: 'Brand', value: '#D14600', name: 'Accent 03' },
  { id: 'accent04', type: 'Brand', value: '#AA3600', name: 'Accent 04' },
];

export const uhcThemeBar = [
  {
    value: 44,
    color: '#002677',
  },
  {
    value: 26,
    color: '#E5F8FB',
  },
  {
    value: 15,
    color: '#004BA0',
  },
  {
    value: 15,
    color: '#D14600',
  },
];

export const horizonOptumTheme: Theme = {
  font: {
    size: {
      body: {
        base: {
          value: 11,
        },
      },
    },
    weight: {
      base: {
        value: 'normal',
      },
    },
    family: {
      base: {
        value: 'OptumSans',
      },
    },
  },
  colors: {
    primary: '#FF612B',
    secondary: '#002667',
    tertiary: '#D9F6FA',
    quaternary: '#FBF9F4',
    custom: [
      { name: 'Brand 50', id: 'brand50', value: '#FF612B', type: 'Brand' },
      {
        name: 'Primary 100',
        id: 'primary100',
        value: '#002667',
        type: 'Brand',
      },
      { name: 'Primary 10', id: 'primary10', value: '#D9F6FA', type: 'Brand' },
      { name: 'Primary 5', id: 'primary5', value: '#FBF9F4', type: 'Brand' },
      { id: 'primary20', value: '#B0E3EA', name: 'Primary 20', type: 'Brand' },
      { id: 'primary70', value: '#0C55B8', name: 'Primary 70', type: 'Brand' },
      { id: 'primary90', value: '#003A8D', name: 'Primary 90', type: 'Brand' },
      {
        id: 'primary110',
        value: '#001D5B',
        name: 'Primary 110',
        type: 'Brand',
      },
      {
        id: 'primary120',
        value: '#00184D',
        name: 'Primary 120',
        type: 'Brand',
      },
      { id: 'accent01', value: '#EFE0FA', name: 'Accent 01', type: 'Brand' },
      { id: 'accent02', value: '#422C88', name: 'Accent 02', type: 'Brand' },
      { id: 'accent03', value: '#C83402', name: 'Accent 03', type: 'Brand' },
      { id: 'accent04', value: '#912400', name: 'Accent 04', type: 'Brand' },
    ],
  },
};

export const optumTheme: Array<Color> = [
  { id: 'primary', type: 'Brand', value: '#FF612B', name: 'Brand 50' },
  { id: 'quaternary', type: 'Brand', value: '#FBF9F4', name: 'Primary 5' },
  { id: 'tertiary', type: 'Brand', value: '#D9F6FA', name: 'Primary 10' },
  { id: 'primary20', type: 'Brand', value: '#B0E3EA', name: 'Primary 20' },
  { id: 'primary70', type: 'Brand', value: '#0C55B8', name: 'Primary 70' },
  { id: 'primary90', type: 'Brand', value: '#003A8D', name: 'Primary 90' },
  { id: 'secondary', type: 'Brand', value: '#002667', name: 'Primary 100' },
  { id: 'primary110', type: 'Brand', value: '#001D5B', name: 'Primary 110' },
  { id: 'primary120', type: 'Brand', value: '#00184D', name: 'Primary 120' },
  { id: 'accent01', type: 'Brand', value: '#EFE0FA', name: 'Accent 01' },
  { id: 'accent02', type: 'Brand', value: '#422C88', name: 'Accent 02' },
  { id: 'accent03', type: 'Brand', value: '#C83402', name: 'Accent 03' },
  { id: 'accent04', type: 'Brand', value: '#912400', name: 'Accent 04' },
];

export const optumThemeBar = [
  {
    value: 44,
    color: '#FF612B',
  },
  {
    value: 26,
    color: '#002667',
  },
  {
    value: 15,
    color: '#D9F6FA',
  },
  {
    value: 15,
    color: '#FBF9F4',
  },
];

export const horizonRallyTheme: Theme = {
  font: {
    size: {
      body: {
        base: {
          value: 11,
        },
      },
    },
    weight: {
      base: {
        value: 'normal',
      },
    },
    family: {
      base: {
        value: 'OptumSans',
      },
    },
  },
  colors: {
    primary: '#F26C32',
    secondary: '#E5F7FC',
    tertiary: '#007897',
    quaternary: '#003D4D',
    custom: [
      { name: 'Brand 50', id: 'brand50', value: '#F26C32', type: 'Brand' },
      { name: 'Primary 5', id: 'primary5', value: '#E5F7FC', type: 'Brand' },
      { name: 'Primary 70', id: 'primary70', value: '#007897', type: 'Brand' },
      {
        name: 'Primary 120',
        id: 'primary120',
        value: '#003D4D',
        type: 'Brand',
      },
      { id: 'primary10', value: '#D4F9FF', name: 'Primary 10', type: 'Brand' },
      { id: 'primary20', value: '#A5DBE9', name: 'Primary 20', type: 'Brand' },
      { id: 'primary90', value: '#006783', name: 'Primary 90', type: 'Brand' },
      {
        id: 'primary100',
        value: '#005469',
        name: 'Primary 100',
        type: 'Brand',
      },
      {
        id: 'primary110',
        value: '#004759',
        name: 'Primary 110',
        type: 'Brand',
      },
      { id: 'accent01', value: '#EFE0FA', name: 'Accent 01', type: 'Brand' },
      { id: 'accent02', value: '#422C88', name: 'Accent 02', type: 'Brand' },
      { id: 'accent03', value: '#BF3E07', name: 'Accent 03', type: 'Brand' },
      { id: 'accent04', value: '#8C2900', name: 'Accent 04', type: 'Brand' },
    ],
  },
};

export const rallyTheme: Array<Color> = [
  { id: 'primary', type: 'Brand', value: '#F26C32', name: 'Brand 50' },
  { id: 'secondary', type: 'Brand', value: '#E5F7FC', name: 'Primary 5' },
  { id: 'primary10', type: 'Brand', value: '#D4F9FF', name: 'Primary 10' },
  { id: 'primary20', type: 'Brand', value: '#A5DBE9', name: 'Primary 20' },
  { id: 'tertiary', type: 'Brand', value: '#007897', name: 'Primary 70' },
  { id: 'primary90', type: 'Brand', value: '#006783', name: 'Primary 90' },
  { id: 'primary100', type: 'Brand', value: '#005469', name: 'Primary 100' },
  { id: 'primary110', type: 'Brand', value: '#004759', name: 'Primary 110' },
  { id: 'quaternary', type: 'Brand', value: '#003D4D', name: 'Primary 120' },
  { id: 'accent01', type: 'Brand', value: '#EFE0FA', name: 'Accent 01' },
  { id: 'accent02', type: 'Brand', value: '#422C88', name: 'Accent 02' },
  { id: 'accent03', type: 'Brand', value: '#BF3E07', name: 'Accent 03' },
  { id: 'accent04', type: 'Brand', value: '#8C2900', name: 'Accent 04' },
];

export const rallyThemeBar = [
  {
    value: 44,
    color: '#F26C32',
  },
  {
    value: 26,
    color: '#007897',
  },
  {
    value: 15,
    color: '#E5F7FC',
  },
  {
    value: 15,
    color: '#003D4D',
  },
];
