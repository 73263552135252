import React, { useEffect } from 'react';
import { fetchFormsByformId } from 'shared/api/formSubmission';
import Loading from 'shared/components/Loading';
import FormDetailsTable from './FormDetailsTable';
import EmptyFormTypeTable from './EmptyFormTypeTable';
import { useLocation } from 'react-router';

const FormDetails = () => {
  const location = useLocation();
  const { formName, formId } = location.state || {};
  const [formDetails, setFormDetails] = React.useState({
    loading: true,
    data: [],
    error: null,
  });

  useEffect(() => {
    const fetchFormDetails = () => {
      try {
        fetchFormsByformId(formId).then(response => {
          setFormDetails({
            loading: false,
            data: response,
            error: null,
          });
        });
      } catch (error) {
        setFormDetails({
          loading: false,
          data: [],
          error: error,
        });
      }
    };

    fetchFormDetails();
  }, []);

  const renderFormDetails = () => {
    if (formDetails.loading) {
      return <Loading message="Loading form details..." />;
    } else if (formDetails.data.length > 0) {
      return (
        <FormDetailsTable
          formData={formDetails.data}
          formId={formId}
          formName={formName}
        />
      );
    } else if (!formDetails.loading && formDetails.data.length == 0) {
      return <EmptyFormTypeTable />;
    }
  };

  return <>{renderFormDetails()}</>;
};

export default FormDetails;
