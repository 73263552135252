import { Rnd } from 'react-rnd';
import '../WidgetStyles.css';
import React, { FC, useRef, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { SizeLocationData } from 'builder/components/WidgetSizeLocation';
import { getMaxXYLocation, getValue } from '../SiteBuilderHelper';
import {
  min_dy,
  widgetOutsideWarning,
  widgetTypes,
} from 'builder/util/constants';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import uuidv4 from 'uuid/v4';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { User } from 'oidc-client';

const StyledSpan = styled.span`
  width: 100%;
  height: 100%;
`;

const StyledRnd = styled(Rnd)`
  transform: ${p =>
    p.fullScreenEnabled
      ? `translate(0px, ${p.itemYLocation}px) !important`
      : `translate(${p.itemXLocation}px, ${p.itemYLocation}px)`};
`;

export const isWidgetInsideParent = (parentElement, childElement): boolean => {
  if (!childElement || childElement === parentElement) {
    return false;
  }
  const rect = parentElement.getBoundingClientRect();
  const rectWidget = childElement.getBoundingClientRect();
  return (
    rectWidget.top >= rect.top &&
    rectWidget.left >= rect.left &&
    rectWidget.right <= rect.right &&
    rectWidget.bottom <= rect.bottom
  );
};

const updateParentWidget = (
  currentPage: PageVersion,
  widgetId: string,
  parentId: string
) => {
  let newChildArray;
  if (
    currentPage.content[widgetId]?.tabIndex !==
    currentPage.content[parentId]?.tabIndex
  ) {
    newChildArray = [...(currentPage.content[parentId]?.children || [])];
    currentPage.content[parentId].children = newChildArray;
  } else if (
    currentPage.content[parentId]?.config.isWidgetDuplicated ===
    currentPage.content[widgetId]?.config.isWidgetDuplicated
  ) {
    newChildArray = [
      ...(currentPage.content[parentId]?.children || []),
      widgetId,
    ];
    currentPage.content[parentId].children = newChildArray;
  }

  if (
    currentPage.content[parentId]?.type === widgetTypes.Tabs &&
    currentPage.content[widgetId]?.tabIndex === -1
  ) {
    currentPage.content[widgetId].tabIndex =
      currentPage.content[parentId]?.config.currentTabIndex;
    currentPage.content[widgetId].config.parentId = parentId;
  }

  if (
    currentPage.content[parentId]?.type === widgetTypes.AccordionWidget &&
    currentPage.content[widgetId]?.tabIndex === -1
  ) {
    currentPage.content[widgetId].tabIndex =
      currentPage.content[parentId].config.currentTabIndex;
    currentPage.content[widgetId].config.parentId = parentId;
  }

  if (
    currentPage.content[parentId].type === widgetTypes.SlideShow &&
    currentPage.content[widgetId].tabIndex === -1
  ) {
    currentPage.content[widgetId].tabIndex =
      currentPage.content[parentId].config.currentSlideIndex;
    currentPage.content[widgetId].config.parentId = parentId;
  }
};

export const updateWidgetConfig = (
  currentPage: PageVersion,
  widgetId: string,
  parentId: string,
  pages: Array<Page>
) => {
  if (currentPage.content[parentId] === undefined) return;
  if (currentPage.content[parentId].defaultWidget) {
    pages?.forEach(page => {
      if (page.current.content[parentId] === undefined) return;
      const isChildInArray = page.current.content[parentId]?.children?.includes(
        widgetId
      );
      if (isChildInArray) return;
      else {
        updateParentWidget(page.current, widgetId, parentId);
      }
    });
  } else {
    const isChildInArray = currentPage.content[parentId].children?.includes(
      widgetId
    );
    if (isChildInArray) return;
    else {
      updateParentWidget(currentPage, widgetId, parentId);
    }
  }
};

export const handleDownloadWidget = (widgetState, currentPage) => {
  if (widgetState.children) {
    widgetState['childArray'] = widgetState.children.map(childId => {
      return currentPage.content[childId];
    });
  }
  const fileData = JSON.stringify(widgetState);
  const blob = new Blob([fileData], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = `${widgetState.type}Widget.json`;
  link.href = url;
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
};

export interface ItemData {
  width: string;
  height: string;
  x: number;
  y: number;
  content: React.ReactElement;
  uniqueId: string;
  widgetType: string;
}

export interface WidgetWrapperProps<T> {
  editingMode?: boolean;
  pages: Array<Page>;
  openPopup?: (open: boolean) => void;
  locationCoords?: any;
  getSizeLocation?: (sizeLoc: SizeLocationData) => void;
  updateSizeLocation?: SizeLocationData;
  widgetState: Widget<T>;
  widgetId: string;
  component: any;
  onUpdateConfig?: (
    config: any,
    widgetId: string,
    defaultWidget: boolean
  ) => void;
  getActiveWidgetId?: (widgetId: string) => void;
  activeWidgetId: string;
  currentPage: PageVersion;
  updateCurrentPage?: (pageVersion: PageVersion) => void;
  updatePageDefaultWidget: (
    widgetId: string,
    config: any,
    xDifference: number,
    yDifference: number
  ) => void;
  removeWidget?: (widgetId: string) => void;
  removeDefaultWidgets?: (widgetId: string) => void;
  updateCurrentPageWidgetConfig?: (config: any, widgetId: string) => void;
  addSibling?: (
    type: string,
    config: T,
    siblingWidgetId: string,
    children: string[],
    newWidgetId: string,
    tabIndex: number
  ) => void;
  closeConfigPanel?: (open: boolean) => void;
  shouldDisplayGridLines: boolean;
  setResetToFalse?: () => void;
  updateWidgetPosition?: (
    bannerWidgetId: string,
    height: number,
    oldHeight: number
  ) => void;
  user?: User;
  isAccordionPanelOpen: boolean;
  accordionShiftWidgetId: string;
  accordionHeightShift: number;
  initiateLogout: () => void;
}

export const WidgetWrapper: FC<WidgetWrapperProps<any>> = (
  props: WidgetWrapperProps<any>
) => {
  const [widgetList, setWidgetList] = useState([]);
  const [hoveredWidget, setHoveredWidget] = useState(null);
  const [parentElements, setParentElements] = React.useState<HTMLElement[]>([]);
  const [childElement, setChildElement] = React.useState<HTMLElement | null>(
    null
  );
  const [showLines, setShowLines] = useState(false);
  const [gridlinesPosition, setGridLinesPosition] = useState({ x: 0, y: 0 });
  const rndRef = useRef<Rnd>(null);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [currentWidget, setCurrentWidget] = useState('');
  const [bounds, setBounds] = useState('.editingContainer');

  const initialWidgetConfig = {
    width: 180,
    height: 80,
    minWidth: 175,
    minHeight: 80,
    x: 2,
    y: 10,
    content: props.component,
    uniqueId: 'Text',
    widgetType: 'Text',
    zIndex: 0,
    isWidgetDuplicated: false,
  };
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: -Number.MAX_SAFE_INTEGER,
    y: -Number.MIN_SAFE_INTEGER,
  });

  const getBorderStyles = () => {
    if (props.widgetState.id === hoveredWidget) {
      return 'solid 2px #DBF0FF';
    } else if (props.widgetState.id === props.activeWidgetId) {
      return 'solid 3px #0C55B8';
    } else {
      return 'solid 1px #000000';
    }
  };
  const getFullScreenEnabled = (widgetType: string) => {
    let isFullScreen = false;
    if (
      [
        widgetTypes.Image,
        widgetTypes.Divider,
        widgetTypes.Navigation,
        widgetTypes.Section,
        widgetTypes.Banner,
      ].includes(widgetType)
    ) {
      isFullScreen = props.widgetState.config.enableFullScreen;
    }
    return isFullScreen;
  };

  const getWidgetsIdsBelowAccordion = (widgetId: string) => {
    const headerSectionAndChildIds = props.currentPage.content.hasOwnProperty(
      'headerSection'
    )
      ? [
          ...props.currentPage.content['headerSection']?.children,
          'headerSection',
        ]
      : [];
    const widgetsExceptHeader = props.currentPage.content.root.children.filter(
      id => !headerSectionAndChildIds.includes(id)
    );
    let thresholdY =
      props.currentPage.content[widgetId]?.config?.yLocation +
      props.currentPage.content[widgetId]?.config?.height;
    const widgetsBelowAccordion = widgetsExceptHeader
      .map(widgetId => props.currentPage.content[widgetId])
      .filter(widget => widget?.config?.yLocation > thresholdY);
    return widgetsBelowAccordion.map(widget => widget.id);
  };

  const getShiftedYposition = (widgetId: string, yCordinates: number) => {
    const widgetsIdsToShift = getWidgetsIdsBelowAccordion(
      props.accordionShiftWidgetId
    );
    if (widgetsIdsToShift.includes(widgetId)) {
      return yCordinates + props.accordionHeightShift;
    }
    return yCordinates;
  };

  const transformStyle = 'translate(146px, 44px)';
  const style = {
    display: `${props.widgetState?.type === 'TextInput' ? 'flow' : 'flex'}`,
    alignItems: 'center',
    justifyContent: 'center',
    border: props.editingMode ? getBorderStyles() : 'none',
    transform: transformStyle,
    width: getFullScreenEnabled(props.widgetState?.type) ? '100vw' : 'auto',
    left: getFullScreenEnabled(props.widgetState?.type)
      ? props.editingMode
        ? 'calc(-50vw + 47.2%)'
        : 'calc(-50vw + 50%)'
      : '0px',
  };

  React.useEffect(() => {
    const widget = props?.currentPage?.content[props.widgetState?.id];
    widget?.config?.action === 'submit'
      ? setBounds('.formWidget')
      : setBounds('.editingContainer');
  }, [props.widgetState.id]);

  React.useEffect(() => {
    if (rndRef.current) {
      rndRef.current.offsetFromParent = { top: 0, left: 0 };
      rndRef.current.updateOffsetFromParent();
    }
  }, [rndRef.current]);

  React.useEffect(() => {
    if (props.locationCoords !== undefined) {
      if (
        props.locationCoords.widgetType === widgetTypes.Banner &&
        props.widgetState.config.dateSelection
      ) {
        const currentDate = new Date();
        const endDate = new Date(
          props.widgetState.config.dateSelection.endDate
        );
        if (currentDate > endDate) {
          handleDeleteWidget(props.widgetState.id, false);
        }
      }
      let widgetListArr = _.cloneDeep([...widgetList]);
      initialWidgetConfig.x = props.locationCoords.x;
      initialWidgetConfig.y = props.locationCoords.y;
      initialWidgetConfig.width = props.locationCoords.width;
      initialWidgetConfig.height = props.locationCoords.height;
      initialWidgetConfig.minWidth = props.locationCoords.minWidth;
      initialWidgetConfig.minHeight = props.locationCoords.minHeight;
      initialWidgetConfig.widgetType = props.locationCoords.widgetType;
      initialWidgetConfig.uniqueId = props.widgetId;
      initialWidgetConfig.content = props.component;
      initialWidgetConfig.zIndex = props.locationCoords.zIndex;
      initialWidgetConfig.isWidgetDuplicated = props.locationCoords
        .isWidgetDuplicated
        ? props.locationCoords.isWidgetDuplicated
        : false;
      widgetListArr.push(initialWidgetConfig);
      setWidgetList([...widgetListArr]);
    }
  }, [props.locationCoords]);

  //Section-specific logic for establishing parent/child
  React.useEffect(() => {
    const widgets = props.currentPage.content.root.children;
    const parentWidgets = widgets
      .map(widgetId => props.currentPage.content[widgetId])
      .filter(
        widget =>
          widget?.type === widgetTypes.Section ||
          widget?.type === widgetTypes.Card ||
          widget?.type === widgetTypes.AccordionWidget ||
          widget?.type === widgetTypes.Tabs ||
          widget?.type === widgetTypes.Form ||
          widget?.type === widgetTypes.Banner ||
          widget?.type === widgetTypes.SlideShow
      );
    const childElement = document.getElementById(props.widgetId);
    const newParentElements: HTMLElement[] = [];
    parentWidgets.forEach(parentWidget => {
      const parentElement = document.getElementById(parentWidget.id);
      if (parentElement) {
        newParentElements.push(parentElement);
      }
      const childWidgets = parentWidget.children;
      const childParentWidgets = childWidgets
        .map(widgetId => props.currentPage.content[widgetId])
        .filter(
          widget =>
            widget?.type === widgetTypes.Section ||
            widget?.type === widgetTypes.Card ||
            widget?.type === widgetTypes.Tabs ||
            widget?.type === widgetTypes.AccordionWidget ||
            widget?.type === widgetTypes.Form ||
            widget?.type === widgetTypes.Banner
        );
      childParentWidgets.forEach(childParentWidget => {
        const childParentElement = document.getElementById(
          childParentWidget.id
        );
        if (childParentElement) {
          newParentElements.push(childParentElement);
        }
      });
    });

    setParentElements(newParentElements);
    setChildElement(childElement);
  }, [widgetList]);

  React.useEffect(() => {
    if (!parentElements || !childElement) return;
    parentElements.forEach(parent => {
      const isInsideParent = isWidgetInsideParent(parent, childElement);
      if (isInsideParent) {
        updateWidgetConfig(
          props.currentPage,
          props.widgetId,
          parent.id,
          props.pages
        );
      } else {
        const parentChildren = props.currentPage.content[parent.id].children;
        const isChildInArray = parentChildren.includes(props.widgetId);
        if (isChildInArray) {
          const updatedChildren = parentChildren.filter(
            childId => childId !== props.widgetId
          );
          props.currentPage.content[parent.id].children = updatedChildren;
          const updatedChild = {
            ...props.currentPage.content[childElement.id],
            config: {
              ...props.currentPage.content[childElement.id].config,
              parentId: null,
            },
          };
          props.currentPage.content[childElement.id] = updatedChild;
        }
      }
    });
  }, [parentElements, childElement]);

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyboardEvent);
    return () => {
      document.removeEventListener('keydown', handleKeyboardEvent);
    };
  }, []);

  const handleKeyboardEvent = (event: KeyboardEvent) => {
    if (props.activeWidgetId === 'root') return;
    if ((event.metaKey || event.ctrlKey) && event.code === 'KeyD') {
      handleDuplicateWidget(props.activeWidgetId);
    } else if ((event.metaKey || event.ctrlKey) && event.code === 'Backspace') {
      handleDeleteWidget(props.activeWidgetId, true);
    }
  };
  const onDragWidget = (d, widgetId) => {
    setShowLines(true);
    setGridLinesPosition({
      x: d.x,
      y: d.y,
    });
    const { currentPage } = props;
    const childWidgets = currentPage.content[widgetId].children;
    if (childWidgets.length === 0) return;
    childWidgets.forEach(childWidget => {
      const updatedX =
        currentPage.content[childWidget].config.xLocation + d.deltaX;
      const updatedY =
        currentPage.content[childWidget].config.yLocation +
        (d.y < min_dy ? 0 : d.deltaY);
      const widgetConfigOld = currentPage.content[childWidget].config;
      const widgetConfigNew = {
        ...widgetConfigOld,
        ...{ xLocation: updatedX, yLocation: updatedY },
      };
      currentPage.content[childWidget].config = widgetConfigNew;
    });
  };

  const handleResize = d => {
    setShowLines(true);
    setGridLinesPosition({
      x: d.x,
      y: d.y,
    });
  };

  const validateLocation = (xLocation: number, widgetId: string) => {
    const maxXYLocation = getMaxXYLocation('editingContainerId', widgetId, 0);
    if (xLocation < -3 || maxXYLocation[0] < -1) {
      const customId = 'custom-id-yes';
      toast(widgetOutsideWarning, {
        toastId: customId,
        type: 'warning',
        autoClose: 5000,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored',
      });
    } else {
      toast.dismiss();
    }
  };
  // Update the state when the widget is moved
  const updateLocation = (d, index, widgetId, widgetType) => {
    if (
      ![
        widgetTypes.Image,
        widgetTypes.Divider,
        widgetTypes.Navigation,
        widgetTypes.Banner,
        widgetTypes.Section,
      ].includes(widgetType)
    ) {
      validateLocation(d.x, widgetId);
    }
    setShowLines(true);
    const itemsCopy = [...widgetList];
    if (itemsCopy[index].x === d.x && itemsCopy[index].y === d.y) return;
    itemsCopy[index].x = d.x;
    itemsCopy[index].y = d.y;
    setWidgetList(itemsCopy);

    const { currentPage } = props;
    const newCurrentPage = _.cloneDeep(currentPage);
    const widgetConfigOld = newCurrentPage.content[widgetId].config;
    const widgetConfigNew = {
      ...widgetConfigOld,
      ...{
        xLocation: d.x,
        yLocation: d.y < min_dy ? min_dy : d.y,
        marginTop: 0,
      },
    };
    newCurrentPage.content[widgetId].config = widgetConfigNew;
    const xLocationDiff = widgetConfigNew.xLocation - widgetConfigOld.xLocation;
    const yLocationDiff = widgetConfigOld.marginTop
      ? widgetConfigNew.yLocation -
        widgetConfigOld.yLocation -
        widgetConfigOld.marginTop
      : widgetConfigNew.yLocation - widgetConfigOld.yLocation;
    props.setResetToFalse();
    const headerSectionConfig = newCurrentPage.content['headerSection']?.config;
    const footerSectionConfig = newCurrentPage.content['footerSection']?.config;

    if (headerSectionConfig && footerSectionConfig) {
      const headerSectionBottomY =
        headerSectionConfig.xLocation + headerSectionConfig.height;
      const footerSectionTopY = footerSectionConfig.yLocation;
      const currentWidgetTopY = widgetConfigNew.yLocation;
      const currentWidgetBottomY =
        widgetConfigNew.yLocation + widgetConfigNew.height;
      if (
        currentWidgetTopY > headerSectionBottomY &&
        currentWidgetBottomY < footerSectionTopY
      ) {
        props.removeDefaultWidgets(widgetId);
        props.updateCurrentPageWidgetConfig(widgetConfigNew, widgetId);
        newCurrentPage.content[widgetId].defaultWidget = false;
      }
    }
    const headerChildren = newCurrentPage.content['headerSection']?.children;
    const footerChildren = newCurrentPage.content['footerSection']?.children;
    props.updateCurrentPage(newCurrentPage);
    if (
      newCurrentPage.content[widgetId].defaultWidget &&
      (headerChildren.includes(widgetId) || footerChildren.includes(widgetId))
    ) {
      props.updatePageDefaultWidget(
        widgetId,
        widgetConfigNew,
        xLocationDiff,
        yLocationDiff
      );
    }
  };

  const updateSize = (ref, position, index, widgetId, widgetType) => {
    if (
      ![
        widgetTypes.Image,
        widgetTypes.Divider,
        widgetTypes.Navigation,
        widgetTypes.Section,
        widgetTypes.Banner,
      ].includes(widgetType)
    ) {
      validateLocation(position.x, widgetId);
    }
    let refactorHeightOnResizeOverTopBorder = 0;
    if (position.y < min_dy) {
      refactorHeightOnResizeOverTopBorder =
        getValue(ref.style.height) + position.y;
    } else {
      refactorHeightOnResizeOverTopBorder = getValue(ref.style.height);
    }
    const itemsCopy = _.cloneDeep([...widgetList]);
    itemsCopy[index].width = getValue(ref.style.width);
    itemsCopy[index].height = refactorHeightOnResizeOverTopBorder;
    itemsCopy[index].x = position.x;
    itemsCopy[index].y = position.y < min_dy ? min_dy : position.y;
    setWidgetList(itemsCopy);

    const { currentPage } = props;
    const newCurrentPage = _.cloneDeep(currentPage);
    const widgetConfigOld = newCurrentPage.content[widgetId].config;
    const widgetConfigNew = {
      ...widgetConfigOld,
      ...{
        width: getValue(ref.style.width),
        height: refactorHeightOnResizeOverTopBorder,
        xLocation: position.x,
        yLocation: position.y < min_dy ? min_dy : position.y,
      },
    };
    const xLocationDiff = widgetConfigNew.xLocation - widgetConfigOld.xLocation;
    const yLocationDiff = widgetConfigNew.yLocation - widgetConfigOld.yLocation;
    newCurrentPage.content[widgetId].config = widgetConfigNew;
    props.setResetToFalse();
    props.updateCurrentPage(newCurrentPage);
    if (
      newCurrentPage.content[widgetId].defaultWidget &&
      (newCurrentPage.content[widgetId].id !== 'footerSection' ||
        xLocationDiff === 0 ||
        yLocationDiff === 0)
    ) {
      props.updatePageDefaultWidget(
        widgetId,
        widgetConfigNew,
        xLocationDiff,
        yLocationDiff
      );
    }
    if (widgetType === widgetTypes.Banner) {
      props.updateWidgetPosition(
        widgetId,
        getValue(ref.style.height),
        widgetConfigOld.height
      );
    }
  };
  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent the default browser context menu from showing
    setShowContextMenu(true);
  };

  const handleDeleteWidget = (
    widgetId: string,
    updatePosition: boolean = false
  ) => {
    if (
      props.currentPage.content[widgetId].type === widgetTypes.Banner &&
      updatePosition
    ) {
      const currentBannerHeight =
        props.currentPage.content[widgetId].config.height;
      props.updateWidgetPosition(widgetId, 0, currentBannerHeight);
    }
    props.closeConfigPanel(false);
    const temp = _.cloneDeep(widgetList);
    const updatedWidgetList = temp.filter(item => item.uniqueId !== widgetId);
    setWidgetList(updatedWidgetList);
    setShowContextMenu(false); // Hide the context menu
    props.removeWidget(widgetId);
    const childWidgets = props.currentPage.content[widgetId].children;
    if (childWidgets.length > 0) {
      childWidgets.forEach(childId => props.removeWidget(childId));
    }
    props.setResetToFalse();
    props.getActiveWidgetId('root');
  };

  const handleDuplicateWidget = (widgetId: string) => {
    props.closeConfigPanel(false);
    const { currentPage } = props;

    let widgetToDuplicate = currentPage.content[widgetId];

    if (widgetToDuplicate) {
      const unique = uuidv4();
      const duplicatedWidget = {
        ...widgetToDuplicate,
        id: unique,
        config: {
          ...widgetToDuplicate.config,
          xLocation: widgetToDuplicate.config.xLocation + 10,
          yLocation: widgetToDuplicate.config.yLocation + 10,
          isWidgetDuplicated: true,
        },
      };
      props.getActiveWidgetId(unique);
      const parentWidgetId = widgetToDuplicate.config.parentId;
      if (parentWidgetId) {
        const parentWidget = currentPage.content[parentWidgetId];
        const parentChildren = parentWidget.children;
        parentWidget.children = [...parentChildren, unique];
      }
      // duplicate widget children as well by generating new unique id
      if (duplicatedWidget.children && duplicatedWidget.children.length > 0) {
        duplicatedWidget.children = duplicatedWidget.children.map(childId => {
          const childWidget = currentPage.content[childId];
          const uniqueChild = uuidv4();
          const duplicatedChild = {
            ...childWidget,
            id: uniqueChild,
            config: {
              ...childWidget.config,
              xLocation: childWidget.config.xLocation + 10,
              yLocation: childWidget.config.yLocation + 10,
              zIndex: 1,
              isWidgetDuplicated: true,
            },
          };
          currentPage.content[uniqueChild] = duplicatedChild;
          return uniqueChild;
        });
      }
      props.addSibling(
        duplicatedWidget.type,
        duplicatedWidget.config,
        widgetId,
        duplicatedWidget.children,
        unique,
        duplicatedWidget.tabIndex
      );
    }
    props.setResetToFalse();
    setShowContextMenu(false); // Hide the context menu
  };

  function isDisabled(widgetId: string) {
    if (
      props.currentPage.content[widgetId].config?.action === 'submit' ||
      ['headerSection', 'footerSection', 'loginButton'].includes(
        props.currentPage.content[widgetId].id
      )
    ) {
      return true;
    }
  }

  const getWidgetWidth = (
    widgetType: string,
    width: number,
    direction: string,
    isFullScreenWidthEnabled: boolean
  ) => {
    const eleWidth =
      isFullScreenWidthEnabled &&
      ([
        widgetTypes.Image,
        widgetTypes.Navigation,
        widgetTypes.Section,
        widgetTypes.Banner,
      ].includes(widgetType) ||
        (widgetTypes.Divider === widgetType && direction !== 'Vertical'))
        ? '100vw'
        : width;
    return eleWidth;
  };

  const getDirection = (widgetType: string) => {
    return widgetType === widgetTypes.Divider
      ? props.widgetState.config.direction
      : 'none';
  };

  const locationCoordsCopy = props.locationCoords;

  if (props.editingMode) {
    if (locationCoordsCopy !== undefined) {
      const isDefaultPage = props.currentPage.slug === 'defaultContentPage';
      return (
        <div
          onClick={e => e.stopPropagation()}
          className="widgetWrapperDiv"
          data-test-id="widget-wrapper"
        >
          {widgetList.map((item, idx) => {
            if (item === undefined) return;
            return (
              <div onContextMenu={handleContextMenu} className={'contextDiv'}>
                {showLines && props.shouldDisplayGridLines && (
                  <>
                    <div
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '1px',
                        top: gridlinesPosition.y,
                        left: 0,
                        borderStyle: 'dashed',
                        borderColor: '#999',
                        pointerEvents: 'none',
                      }}
                    />

                    <div
                      style={{
                        position: 'absolute',
                        width: '1px',
                        height: '100%',
                        top: 0,
                        left: gridlinesPosition.x,
                        borderStyle: 'dashed',
                        borderColor: '#999',
                        pointerEvents: 'none',
                      }}
                    />
                  </>
                )}
                <StyledRnd
                  className={`rndDiv ${getFullScreenEnabled(item.widgetType) &&
                    'react-draggable-overwrite'}`}
                  ref={rndRef}
                  style={style}
                  size={{
                    width: getWidgetWidth(
                      item.widgetType,
                      item.width,
                      getDirection(item.widgetType),
                      getFullScreenEnabled(item.widgetType)
                    ),
                    height: item.height,
                  }}
                  position={{
                    x: item.x,
                    y: props.isAccordionPanelOpen
                      ? getShiftedYposition(item.uniqueId, item.y)
                      : item.y,
                  }}
                  onDrag={(_e, d) => onDragWidget(d, item.uniqueId)}
                  onDragStop={(_e, d) => {
                    updateLocation(d, idx, item.uniqueId, item.widgetType);
                  }}
                  onResizeStop={(_e, _dir, ref, _delta, position) => {
                    updateSize(
                      ref,
                      position,
                      idx,
                      item.uniqueId,
                      item.widgetType
                    );
                  }}
                  resizeGrid={[1, 1]}
                  dragGrid={[1, 1]}
                  minHeight={item.minHeight}
                  minWidth={item.minWidth}
                  enableResizing={
                    item.widgetType !== widgetTypes.Application ? true : false
                  }
                  disableDragging={
                    isDefaultPage ||
                    item.uniqueId === 'headerSection' ||
                    item.widgetType === widgetTypes.Banner
                  }
                  key={idx}
                  id={item.uniqueId}
                  onClick={() => {
                    props.openPopup(true);
                    props.getActiveWidgetId(item.uniqueId);
                  }}
                  onMouseDown={_e => {
                    if (_e.button === 2) {
                      let wx = item.x + item.width / 2;
                      let wy = item.y + item.height / 2;
                      setContextMenuPosition({ x: wx, y: wy });
                      setCurrentWidget(item.uniqueId);
                      _e.preventDefault();
                    }
                  }}
                  onResize={(_e, _dir, _ref, _delta, position) => {
                    handleResize(position);
                  }}
                  onMouseOver={_e => {
                    setHoveredWidget(item.uniqueId);
                  }}
                  onMouseOut={_e => {
                    setHoveredWidget(null);
                  }}
                  fullScreenEnabled={getFullScreenEnabled(item.widgetType)}
                  itemXLocation={item.x}
                  itemYLocation={item.y}
                >
                  {props.widgetState.type === 'TextInput' ||
                  props.widgetState.type === 'FileUploader' ? (
                    <>{item.content}</>
                  ) : (
                    <StyledSpan>{item.content}</StyledSpan>
                  )}
                </StyledRnd>
              </div>
            );
          })}
          {showContextMenu &&
            (props.widgetState.type !== 'Banner' ? (
              <ContextMenu
                disabled={isDisabled(props.widgetId)}
                key={props.widgetState.id}
                menuitems={[
                  {
                    label: 'Delete',
                    action: () => handleDeleteWidget(currentWidget, true),
                    shortcut: '\u2318 + ⌫',
                  },
                  {
                    label: 'Duplicate',
                    action: () => handleDuplicateWidget(currentWidget),
                    shortcut: '\u2318 + D',
                  },
                  ,
                  {
                    label: 'Divider',
                    action: () => {
                      return;
                    },
                  },
                  {
                    label: 'Download',
                    action: () =>
                      handleDownloadWidget(
                        props.widgetState,
                        props.currentPage
                      ),
                  },
                ]}
                position={contextMenuPosition}
              />
            ) : (
              <ContextMenu
                disabled={isDisabled(props.widgetId)}
                key={props.widgetState.id}
                menuitems={[
                  {
                    label: 'Delete',
                    action: () => handleDeleteWidget(currentWidget, true),
                    shortcut: '\u2318 + ⌫',
                  },
                  ,
                  {
                    label: 'Divider',
                    action: () => {
                      return;
                    },
                  },
                  {
                    label: 'Download',
                    action: () =>
                      handleDownloadWidget(
                        props.widgetState,
                        props.currentPage
                      ),
                  },
                ]}
                position={contextMenuPosition}
              />
            ))}
        </div>
      );
    } else {
      return (
        <div className="noWidgets">
          <h3>Get Started by Adding a New Section or Template</h3>
          <button>Add New Widgets</button>
        </div>
      );
    }
  } else {
    if (locationCoordsCopy !== undefined) {
      return (
        <div
          onClick={e => e.stopPropagation()}
          className="widgetWrapperDiv"
          data-test-id="widget-wrapper"
        >
          {widgetList.map((item, idx) => {
            if (item === undefined) return;

            return (
              <StyledRnd
                className={`rndDiv ${getFullScreenEnabled(item.widgetType) &&
                  'react-draggable-overwrite'}`}
                ref={rndRef}
                style={style}
                size={{
                  width: getWidgetWidth(
                    item.widgetType,
                    item.width,
                    getDirection(item.widgetType),
                    getFullScreenEnabled(item.widgetType)
                  ),
                  height: item.height,
                }}
                position={{
                  x: item.x,
                  y: props.isAccordionPanelOpen
                    ? getShiftedYposition(item.uniqueId, item.y)
                    : item.y,
                }}
                bounds={bounds}
                resizeGrid={[1, 1]}
                dragGrid={[1, 1]}
                minHeight={item.minHeight}
                minWidth={item.minWidth}
                enableResizing={false}
                key={idx}
                id={item.uniqueId}
                disableDragging={true}
                fullScreenEnabled={getFullScreenEnabled(item.widgetType)}
                itemXLocation={item.x}
                itemYLocation={item.y}
              >
                {props.widgetState.type === 'TextInput' ||
                props.widgetState.type === 'FileUploader' ? (
                  <>{item.content}</>
                ) : (
                  <StyledSpan>{item.content}</StyledSpan>
                )}
              </StyledRnd>
            );
          })}
        </div>
      );
    }
  }
};
