import axios from 'shared/util/axios';

const fetchFormTypes = (siteIdsList: Array<number>) => {
  return axios
    .get<Array<FormTypesData>>('site-builder/form', {
      params: {
        siteIds: siteIdsList.join(','),
      },
      paramsSerializer: params => {
        return new URLSearchParams(params).toString();
      },
    })
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const fetchFormsByformId = (formId: number) => {
  return axios
    .get<Array<FormTableData>>(`site-builder/form/submissions?formId=${formId}`)
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const createFormName = (
  formName: string,
  siteId: number,
  pageId: number,
  widgetId: string
) => {
  const params = {
    formName: formName,
    siteId: siteId.toString(),
    pageId: pageId.toString(),
    widgetId: widgetId,
  };
  const queryString = new URLSearchParams(params).toString();
  return axios
    .post(`site-builder/form/create?${queryString}`)
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const submitFormData = (formPayload: FormSubmissionRequest) => {
  return axios
    .post('site-builder/form/submit', formPayload)
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const removeFormName = (formName: string) => {
  return axios
    .delete(`site-builder/form/delete?formName=${formName}`)
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

export {
  fetchFormTypes,
  fetchFormsByformId,
  createFormName,
  submitFormData,
  removeFormName,
};
