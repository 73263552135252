import { connect } from 'react-redux';
import { RootState } from 'shared/state';
import SessionObserver from './SessionObserver';
import { selectUser } from 'shared/state/misc/oidc';

const mapStateToProps = (state: RootState) => ({
  user: selectUser()(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SessionObserver);
