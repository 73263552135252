import _track, { Options } from 'react-tracking';

export interface Track<PP = {}, SS = null, AA extends Array<any> = Array<any>> {
  <P = PP, S = SS, A extends any[] = AA>(
    trackingInfo?:
      | PageData
      | PageAction
      | DefaultRecord
      | ((props: P, state: S, args: A) => any),
    options?: Options<Partial<any>>
  ): any;
}

export const track: Track = _track;
