import UploadWidget from './UploadWidget';
import { Config } from './types';
import UploadWidgetControl from './UploadWidgetControl';
import { defaultSizeLocationData } from 'builder/util/constants';

export default {
  type: 'UploadWidget',
  displayName: 'UploadWidget',
  icon: 'UploadWidgetIcon',
  initialConfig: {
    ...defaultSizeLocationData,
    uniqueId: '123abc',
    uploadedFile: {
      name: '',
      url: '',
    },
  },
  components: {
    render: UploadWidget,
    control: UploadWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
