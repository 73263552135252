import _ from 'lodash';

/**
 * This function returns unique id
 * @returns uniqueId
 */
export const genUniqueId = (): string => {
  const dateStr = Date.now().toString(36); // convert num to base 36 and stringify

  const randomStr = Math.random()
    .toString(36)
    .substring(2, 8); // start at index 2 to skip decimal point

  return `${dateStr}-${randomStr}`;
};
/**
 * This function will remove duplicate objects based on unique key provided.
 * @param items Array of objects
 * @param key unique key to be filtered
 * @returns
 */
export const getUniqueItemsByProperty = (
  items: Array<object>,
  key: string
): Array<object> => {
  return [...new Map(items.map(item => [item[key], item])).values()];
};
// For width and height, remove units
export const getValue = val => {
  if (_.isString(val)) {
    return Number(val.slice(0, -2));
  }
  return val;
};

// This function returns maxXLocation and maxYLocation for the child within the parent.
export const getMaxXYLocation = (parentId, childId, inputValue) => {
  const parentElement = document.getElementById(parentId);
  const rect = parentElement?.getBoundingClientRect();
  const parentRight = rect?.right;
  const parentBottom = rect?.bottom;
  const childElement = document.getElementById(childId);
  if (childElement === undefined) return;
  const rectWidget = childElement?.getBoundingClientRect();
  const eleRight = rectWidget?.right;
  const eleBottom = rectWidget?.bottom;
  const maxXlocation = parentRight - eleRight + inputValue;
  const maxYlocation = parentBottom - eleBottom + inputValue;
  return [maxXlocation, maxYlocation];
};
// get left and top location of the element
export const getLeftTopPosition = (id: string) => {
  const siteLandingEle = document.getElementById(id);
  const rect = siteLandingEle?.getBoundingClientRect();
  const eleLeft = rect?.left;
  const eleTop = rect?.top;
  return [eleLeft, eleTop];
};
