import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { HelperText, TextInput, Checkbox, FormControl } from '@uitk/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Config } from 'shared/widgets/Form/FileUploader/types';
import { MainLabelStyles } from '../Typography/Typography';

const LabelAndTextInputWrapper = styled.div`
  display: block;
  align-items: baseline;
  margin-bottom: 0.5rem;
`;

const LabelWrapper = styled.div`
  display: inline-flex;
  align-items: baseline;
`;

const OptionalLabel = styled(HelperText)`
  font-style: italic;
`;

const StyledTextInput = styled(TextInput)`
  height: 10px;
  font-family: 'OptumSans';
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 1.25rem;
  margin-left: -0.55rem;
`;

const LabelAndFileTypesWrapper = styled.div``;

const StyledFormControl = styled(FormControl)`
  border: none;
  > ul {
    display: inline-flex;
    margin-top: -1rem;
    margin-left: -0.5rem;
  }
  .Checkboxcomponent__CheckboxContainer-yui814-0 {
    padding-right: 0;
  }
  .Checkboxcomponent__Input-yui814-2
    + Checkboxcomponent__Label-yui814-1:before {
    align-self: start;
    -webkit-align-self: start;
  }
`;

export interface FileUploaderPanelProps<T> {
  pages?: Array<Page>;
  site?: Site;
  widgetState?: Widget<T>;
  updateSite?: (site: Site) => void;
  saveSite?: () => void;
  handleFieldLabel?: (data: string) => void;
  updateAcceptedFileTypes?: (key: string, data: boolean) => void;
  determineNumberOfEnabledFileTypes?: (
    pdf: boolean,
    doc: boolean,
    png: boolean,
    jpg: boolean
  ) => number;
}

const FileUploaderPanel: React.FC<FileUploaderPanelProps<Config>> = (
  props: FileUploaderPanelProps<Config>
) => {
  const {
    widgetState,
    handleFieldLabel,
    updateAcceptedFileTypes,
    determineNumberOfEnabledFileTypes,
  } = props;

  const [label, setLabel] = useState(widgetState.config.label);
  const [pdf, setPDF] = useState(widgetState.config.enablePDF);
  const [doc, setDOC] = useState(widgetState.config.enableDOC);
  const [png, setPNG] = useState(widgetState.config.enablePNG);
  const [jpg, setJPG] = useState(widgetState.config.enableJPG);

  useEffect(() => {
    setLabel(widgetState.config.label);
    setPDF(widgetState.config.enablePDF);
    setDOC(widgetState.config.enableDOC);
    setPNG(widgetState.config.enablePNG);
    setJPG(widgetState.config.enableJPG);
  }, [widgetState.config]);

  const handleLabel = (e: ChangeEvent<HTMLInputElement>) => {
    setLabel(e.target.value);
    handleFieldLabel(e.target.value);
  };

  const handleFileTypeCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (
      determineNumberOfEnabledFileTypes(pdf, doc, png, jpg) === 1 &&
      e.target.checked === false
    ) {
      toast('At least one file type must be selected', {
        type: 'error',
        theme: 'colored',
      });
    } else {
      switch (e.target.name) {
        case 'PDF':
          setPDF(e.target.checked);
          updateAcceptedFileTypes('enablePDF', e.target.checked);
          break;
        case 'DOC':
          setDOC(e.target.checked);
          updateAcceptedFileTypes('enableDOC', e.target.checked);
          break;
        case 'PNG':
          setPNG(e.target.checked);
          updateAcceptedFileTypes('enablePNG', e.target.checked);
          break;
        case 'JPG':
          setJPG(e.target.checked);
          updateAcceptedFileTypes('enableJPG', e.target.checked);
          break;
        default:
          toast('An error has occurred', {
            type: 'error',
            theme: 'colored',
          });
          break;
      }
    }
  };

  return (
    <>
      <LabelAndTextInputWrapper>
        <LabelWrapper>
          <MainLabelStyles>Label</MainLabelStyles>
          <OptionalLabel>(Optional)</OptionalLabel>
        </LabelWrapper>
        <StyledTextInput
          data-test-id="file-uploader-label"
          value={label}
          onChange={handleLabel}
        />
      </LabelAndTextInputWrapper>
      <LabelAndFileTypesWrapper>
        <LabelWrapper>
          <MainLabelStyles>File types accepted</MainLabelStyles>
          <OptionalLabel>(Minimum 1 type)</OptionalLabel>
        </LabelWrapper>
        <StyledFormControl id="file-types-form-control">
          <StyledCheckbox
            name="PDF"
            data-test-id="pdf-checkbox"
            checked={pdf}
            onChange={handleFileTypeCheckBoxChange}
          >
            .pdf
          </StyledCheckbox>
          <StyledCheckbox
            name="DOC"
            data-test-id="doc-checkbox"
            checked={doc}
            onChange={handleFileTypeCheckBoxChange}
          >
            .doc
          </StyledCheckbox>
          <StyledCheckbox
            name="PNG"
            data-test-id="png-checkbox"
            checked={png}
            onChange={handleFileTypeCheckBoxChange}
          >
            .png
          </StyledCheckbox>
          <StyledCheckbox
            name="JPG"
            data-test-id="jpg-checkbox"
            checked={jpg}
            onChange={handleFileTypeCheckBoxChange}
          >
            .jpg
          </StyledCheckbox>
        </StyledFormControl>
      </LabelAndFileTypesWrapper>
    </>
  );
};

export default FileUploaderPanel;
