import { Fragment, PureComponent } from 'react';
import { Button } from 'link-ui-react';
import { CurrentSite } from 'client/types';

interface ManifestState {
  site: CurrentSite | undefined;
}

/**
 * Utility component that allows downloading the JSON representation of the
 * site manifest.
 */
class Manifest extends PureComponent<{}, ManifestState> {
  state: ManifestState = {
    site: undefined,
  };

  elem: HTMLAnchorElement;

  componentDidMount() {
    const site = (window as any).LINK_SITE_MANIFEST as CurrentSite;
    this.setState({
      site,
    });
  }

  handleDownload = () => {
    this.elem.click();
  };

  render() {
    const { site } = this.state;
    const href = `data:application/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(site, null, 2)
    )}`;

    return (
      <Fragment>
        <Button onClick={this.handleDownload}>Download</Button>
        <a
          ref={elem => (this.elem = elem)}
          style={{ display: 'none' }}
          href={href}
          download="manifest.json"
        />
      </Fragment>
    );
  }
}

export default Manifest;
