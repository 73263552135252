import { Location } from 'history';

const STORAGE_KEY = 'duplo-location';

let postLoginPage: string;

let postLoginUrl: string;

let postLoginType: string = 'page';

const persistLocation = (location: Location) => {
  window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(location));
};

const persistPostLoginPage = (location: Location) => {
  window.sessionStorage.setItem(
    STORAGE_KEY,
    JSON.stringify(
      postLoginType === 'page'
        ? {
            hash: '',
            pathname: `/${postLoginPage}`,
            search: location.search,
            state: undefined,
          }
        : {
            url: postLoginUrl,
          }
    )
  );
};

export interface LocationWithUrl extends Location {
  url: string;
}

const retrieveLocation = () => {
  let location: LocationWithUrl;
  try {
    location = JSON.parse(window.sessionStorage.getItem(STORAGE_KEY));
  } catch (e) {}

  if (location && location.toString() === 'null') {
    location = undefined;
  }

  return location;
};

const setPostLoginPage = (page: PageVersion) => {
  if (page) {
    postLoginType = 'page';
    postLoginPage = page.slug;
  }
};

const setPostLoginUrl = (url: string) => {
  if (url) {
    postLoginType = 'url';
    postLoginUrl = url;
  }
};

export {
  persistLocation,
  persistPostLoginPage,
  retrieveLocation,
  setPostLoginPage,
  postLoginPage,
  setPostLoginUrl,
};
