import React from 'react';
import { Config } from './types';
import { FormControl, FormGroup } from '@material-ui/core';
import { VIDEO_FILE_REGEX } from 'builder/util/constants';
import { UploadedFile } from '../shared/types';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import MultipartUpload from './MultipartUpload';
import {
  PanelTop,
  SizeLocationPanel,
  StyledPanel,
  StyledPanelTitle,
} from '../Text/TextWidgetControl';
import { Label, Panel } from '@uitk/react';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import {
  Padding,
  PaddingData,
} from 'builder/components/WidgetConfigurations/Padding';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';

const VideoUploadControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const { value, onChange, widgetState } = props;
  const handleFileChange = (file: UploadedFile) => {
    onChange({
      ...value,
      file: file,
    });
  };

  const updateWidgetSizeLoction = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };
  const updatePaddingData = (data: PaddingData) => {
    onChange({
      ...value,
      paddingData: data,
    });
  };

  const updateBorderData = (data: BorderData) => {
    onChange({
      ...value,
      borderData: data,
    });
  };

  const handleLineColor = data => {
    onChange({
      ...value,
      lineColor: data,
    });
  };

  return (
    <>
      <Panel.Group>
        <StyledPanel>
          <PanelTop>
            <Panel
              title={<Label style={StyledPanelTitle}>Video</Label>}
              icon={Arrow}
              data-test-id="typography-panel"
            >
              <FormGroup>
                <FormControl fullWidth margin="normal">
                  <MultipartUpload
                    file={value.file}
                    label="Upload Video"
                    onUpload={handleFileChange}
                    accept=".mov, .mp4"
                    fileFormatRegex={VIDEO_FILE_REGEX}
                    data-test-id="multipart-upload"
                  />
                </FormControl>
              </FormGroup>
            </Panel>
          </PanelTop>
        </StyledPanel>
        <StyledPanel>
          <SizeLocationPanel
            title={<Label style={StyledPanelTitle}>Size and Location</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="authentication-panel"
          >
            <WidgetSizeLocation
              data-test-id="widget-size-location"
              sizeLocation={{
                xLocation: value.xLocation,
                yLocation: value.yLocation,
                width: value.width,
                height: value.height,
                uniqueId: value.uniqueId,
              }}
              updateWidgetSizeLoction={updateWidgetSizeLoction}
              activeWidgetId={widgetState.id}
            />
          </SizeLocationPanel>
        </StyledPanel>
        <StyledPanel>
          <PanelTop>
            <Panel
              title={<Label style={StyledPanelTitle}>Border</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="border-panel"
            >
              <Border
                borderData={updateBorderData}
                handleLineColor={handleLineColor}
                widgetState={widgetState}
                data-test-id="border-component"
                pages={props.pages}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
              />
            </Panel>
          </PanelTop>
        </StyledPanel>
        <StyledPanel>
          <PanelTop>
            <Panel
              title={<Label style={StyledPanelTitle}>Padding</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="padding-panel"
            >
              <Padding
                paddingData={updatePaddingData}
                widgetState={widgetState}
                data-test-id="padding-component"
              />
            </Panel>
          </PanelTop>
        </StyledPanel>
      </Panel.Group>
    </>
  );
};

export default VideoUploadControl;
