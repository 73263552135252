import '@babel/polyfill';
import 'eligrey-classlist-js-polyfill';
import 'core-js';
import 'window-location-origin';
import 'minireset.css';

import ReactDOM from 'react-dom';
import SiteApp from 'client/SiteApp';
import MissingSite from 'client/scenes/MissingSite';
import { CurrentSite } from 'client/types';

// Site definition injected via script.
const site = (window as any).LINK_SITE_MANIFEST as CurrentSite;

if (site) {
  ReactDOM.render(<SiteApp site={site} />, document.getElementById('root'));
} else {
  console.error('Missing LINK_SITE_MANIFEST');
  ReactDOM.render(<MissingSite />, document.getElementById('root'));
}
