import React, { Fragment } from 'react';
import {
  TableRow,
  TableCell,
  Table,
  TableHead,
  StylesProvider,
  Link,
  Breadcrumbs,
  Divider,
  Typography,
} from '@material-ui/core';
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  Row,
  TableColumnInstance,
  Column,
  TableOptions,
} from 'react-table';
import styled from 'styled-components';
import { Icon, Input } from 'link-ui-react';
import { useHistory } from 'react-router-dom';
import {
  PaginationActionButtonsWrapper,
  PaginationButtonsWrapper,
  PaginationWrapper,
  RawActionButton,
  TextWrapper,
  createButtons,
} from '../SiteDataTable/Style';
import InfoIcon from '../SiteDataTable/InfoIcon';
import { IconWrapper } from '../SiteDataTable/SiteDataTable';

const MarginLeftIcon = styled(Icon)`
  margin-left: 0.5em;
`;

const TableBody = styled.tbody`
  & > :nth-child(2n) {
    background-color: ${p => p.theme.colors.aux.lightestGrey};
  }
`;
const StyledCell = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 1em;
    font-family: OptumSans;
  }
`;

const HeaderCell = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 0.5em;
    font-size: large;
    font-family: OptumSans;
    font-weight: 700;
  }
`;

const StyledRow = styled(TableRow)`
  &:hover {
    background: ${p => p.theme.colors.aux.grey};
  }
`;

const StyledInput = styled(Input)`
  font-family: OptumSans;
`;

const StyleTypography = styled(Typography)`
  &.MuiTypography-h4 {
    font-family: OptumSans;
    color: #002677;
    font-weight: 600;
    font-size: x-large;
    padding: 1em 0em;
  }
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  .MuiBreadcrumbs-ol {
    font-family: OptumSans;
  }
`;

const StyledLink = styled(Link)`
  cursor: pointer;
`;

type Data = object;

export interface FormTableProps {
  formData: Array<FormTableData>;
  formId?: number;
  formName: string;
}

//TODO: remove this aspect once we develop our own Table css
const getWidth = (id: string) => {
  switch (id) {
    case 'ID':
      return '6.5em';
    case 'Actions':
      return '0.71em';
    case 'Created By':
      return '14.3em';
    case 'Domain':
      return '19.3em';
    default:
      return '17.9em';
  }
};

// Define a default UI for filtering
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, id },
}: {
  column: TableColumnInstance<Data>;
}) => {
  return (
    <StyledInput
      w={getWidth(id)}
      data-test-id="site-table-filter-input"
      leftIcon={<Icon icon="Search" />}
      value={filterValue || ''}
      onChange={(e: any) => {
        setFilter(e.target.value.trimLeft() || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Filter`}
    />
  );
};

const filterUserAndDate = (rows: Row[], id: string, filterValue: string) => {
  return rows.filter(row => {
    const rowValue = row.values[id];
    const user = rowValue.user;
    return (
      user &&
      String(user)
        .toLowerCase()
        .includes(filterValue.toLowerCase())
    );
  });
};

const defaultSortBy = (rowA: Row, rowB: Row, id: string) => {
  const a = String(rowA.values[id]).toLowerCase();
  const b = String(rowB.values[id]).toLowerCase();
  return a === b ? 0 : a > b ? 1 : -1;
};

const numberSortBy = (rowA: Row, rowB: Row, id: string) => {
  const a = Number(rowA.values[id]);
  const b = Number(rowB.values[id]);
  return a === b ? 0 : a > b ? 1 : -1;
};

const sortByDateFunction = (rowA: Row, rowB: Row, id: string) => {
  const a = rowA.values[id].date;
  const b = rowB.values[id].date;
  return a === b ? 0 : a > b ? 1 : -1;
};

const getColumns = (formData: Array<FormTableData>): Array<Column> => {
  const formDataKeys = Array.from(
    new Set(formData.flatMap(item => Object.keys(item.formData)))
  );
  const dynamicColumns: Column[] = formDataKeys.map(key => ({
    Header: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
    accessor: `formData.${key}`,
    id: key,
  }));

  return [
    {
      Header: 'Submission date',
      accessor: (data: FormTableData) =>
        new Date(data.submittedTime).toLocaleDateString() +
        ' ' +
        new Date(data.submittedTime).toLocaleTimeString(),
      sortType: 'numberSortBy',
    },
    ...dynamicColumns,
  ] as Column[];
};

const FormDetailsTable = (props: FormTableProps) => {
  const { formData } = props;

  const history = useHistory();
  const columnData = React.useMemo(() => getColumns(formData), [formData]);
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const filterTypes = React.useMemo(
    () => ({
      text: (rows: Row[], id: string, filterValue: string) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          rowValue &&
            String(rowValue)
              .toLowerCase()
              .includes(filterValue.toLowerCase());
        });
      },
      createPublishFilter: filterUserAndDate,
    }),
    []
  );

  const sortTypes = React.useMemo(
    () => ({
      alphanumeric: defaultSortBy,
      numberSortBy: numberSortBy,
      dateSortBy: sortByDateFunction,
    }),
    []
  );

  const {
    page,
    getTableProps,
    headerGroups,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex },
  } = useTable(
    {
      columns: columnData,
      data: formData,
      initialState: { pageIndex: 0, pageSize: 25 },
      defaultColumn,
      filterTypes,
      sortTypes,
    } as TableOptions<Data>,
    useFilters,
    useSortBy,
    usePagination
  );

  const handleBreadCrumbsClick = () => {
    history.push('/forms');
  };

  const breadcrumbs = [
    <StyledLink
      data-test-id="bread-crumb-link"
      underline="always"
      color="primary"
      onClick={handleBreadCrumbsClick}
    >
      Form Submissions
    </StyledLink>,
    <span data-test-id="bread-crumb-span">{props.formName}</span>,
  ];

  return (
    <>
      <StyledBreadcrumbs
        data-test-id="bread-crumb-main"
        separator=">"
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </StyledBreadcrumbs>
      <StyleTypography variant="h4" data-test-id="form-name">
        {props.formName}
      </StyleTypography>
      <Divider />
      <StylesProvider injectFirst>
        <Table {...getTableProps()} data-test-id={`site-table`}>
          <TableHead key={`table-header`}>
            {headerGroups.map((headerGroup, index) => (
              <Fragment key={`frag-${index}`}>
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(
                    (column: TableColumnInstance<Data>) => {
                      return (
                        <HeaderCell
                          style={{ maxWidth: getWidth(column.id) }}
                          variant="head"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <MarginLeftIcon
                                icon={'ArrowDown'}
                                height="10px"
                                width="10px"
                              />
                            ) : (
                              <MarginLeftIcon
                                icon={'ArrowUp'}
                                height="10px"
                                width="10px"
                              />
                            )
                          ) : (
                            ''
                          )}
                        </HeaderCell>
                      );
                    }
                  )}
                </TableRow>
                <TableRow key={`filter-row`}>
                  {headerGroup.headers.map(
                    (column: TableColumnInstance<Data>, index: number) => {
                      return (
                        <StyledCell
                          key={`filter-${index}`}
                          style={{ maxWidth: getWidth(column.id) }}
                        >
                          {column.canFilter ? column.render('Filter') : null}
                        </StyledCell>
                      );
                    }
                  )}
                </TableRow>
              </Fragment>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <StyledRow
                  {...row.getRowProps()}
                  data-test-id={`site-table-body-row-${i}`}
                >
                  {row.cells.map(cell => {
                    return (
                      <StyledCell
                        variant="body"
                        {...cell.getCellProps()}
                        style={{ maxWidth: getWidth(cell.column.id) }}
                      >
                        {cell.render('Cell')}
                      </StyledCell>
                    );
                  })}
                </StyledRow>
              );
            })}
          </TableBody>
        </Table>
        <PaginationWrapper>
          <PaginationButtonsWrapper>
            <TextWrapper isBold={true} reducePadding={true}>
              {'Entries per page '}
            </TextWrapper>
            <IconWrapper
              iconActive={false}
              tabIndex={0}
              data-test-id="info-wrapper"
            >
              <InfoIcon />
            </IconWrapper>
            {createButtons(3, setPageSize)}
          </PaginationButtonsWrapper>
          <PaginationActionButtonsWrapper>
            <TextWrapper isBold={true} reducePadding={true}>
              {'Displaying page: '}
              {pageIndex + 1}
              {' of '}
              {`${pageOptions.length} `}
            </TextWrapper>
            <RawActionButton
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              isPreviousButton={true}
              tertiary={false}
            >
              <TextWrapper reducePadding={true} isBold={false}>
                {'Previous'}
              </TextWrapper>
            </RawActionButton>
            <RawActionButton
              onClick={() => nextPage()}
              disabled={!canNextPage}
              tertiary={false}
              isPreviousButton={false}
            >
              <TextWrapper reducePadding={true} isBold={false}>
                {'Next'}{' '}
              </TextWrapper>
            </RawActionButton>
          </PaginationActionButtonsWrapper>
        </PaginationWrapper>
      </StylesProvider>
    </>
  );
};

export default FormDetailsTable;
