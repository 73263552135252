import { SiteStylesProps } from './SiteStylesPanel';

export default function useHandleColorSave(
  activeColor: string,
  activeColorName: string,
  activeColorType: string | 'Custom' | 'Default' | 'Text' | 'Brand',
  props: SiteStylesProps
) {
  const saveSite = (updatedSite: Site) => {
    props.updateSite(updatedSite);
  };

  const handleDefaultColorType = () => {
    const updatedSite: Site = {
      ...props.site,
      theme: {
        ...props.site.theme,
        colors: {
          ...props.site.theme.colors,
          [activeColorName.toLowerCase()]: activeColor,
        },
      },
    };
    saveSite(updatedSite);
  };

  const handleCustomColorType = () => {
    const updatedCustomColor = {
      id: activeColor,
      type: activeColorType,
      name: activeColorName,
      value: activeColor,
    };
    const updatedColorIndex =
      props.site.theme.colors.custom[
        props.site.theme.colors.custom.findIndex(
          obj => obj.name === activeColorName
        )
      ];
    const newCustomColorArray = props.site.theme.colors.custom.map(c => {
      if (c === updatedColorIndex) {
        return updatedCustomColor;
      } else {
        return c;
      }
    });
    const changedSite: Site = {
      ...props.site,
      theme: {
        ...props.site.theme,
        colors: {
          ...props.site.theme.colors,
          custom: newCustomColorArray,
        },
      },
    };
    saveSite(changedSite);
  };

  const handleNewColorType = () => {
    const newColor: Color = {
      id: activeColor,
      type: 'Custom',
      value: activeColor,
      name: activeColorName,
    };
    const newColorArray = [...props.site.theme.colors.custom, newColor];
    const newSite: Site = {
      ...props.site,
      theme: {
        ...props.site.theme,
        colors: {
          ...props.site.theme.colors,
          custom: newColorArray,
        },
      },
    };
    saveSite(newSite);
  };

  if (activeColorType === 'Default') {
    handleDefaultColorType();
  } else if (activeColorType === 'Custom') {
    handleCustomColorType();
  } else if (activeColorType === undefined) {
    handleNewColorType();
  }
}
