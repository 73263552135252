import { ActionCreator, AnyAction, Reducer } from 'redux';
import { Selector, ThunkActionCreator } from 'shared/state';
import { fetchFormTypes as doFetchFormTypes } from 'shared/api/formSubmission';

export interface State {
  data: {
    formTypes: Array<FormTypesData>;
  };
  meta: StateMeta;
}

const defaultState: State = {
  data: {
    formTypes: [],
  },
  meta: {
    pending: false,
  },
};

export const FETCH_FORMS_FULFILLED = 'duplo/FETCH_FORMS_FULFILLED';
export const FETCH_FORMS_PENDING = 'duplo/FETCH_FORMS_PENDING';
export const FETCH_FORMS_FAILED = 'duplo/FETCH_FORMS_FAILED';

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_FORMS_PENDING:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: true,
        },
      };
    case FETCH_FORMS_FULFILLED:
      return {
        ...state,
        data: {
          formTypes: action.formsTypes,
        },
        meta: {
          ...state.meta,
          pending: false,
          successful: true,
          error: null,
        },
      };
    case FETCH_FORMS_FAILED:
      return {
        ...state,
        data: {
          formTypes: [],
        },
        meta: {
          ...state.meta,
          pending: false,
          successful: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default reducer;

const fetchFormsPending: ActionCreator<AnyAction> = () => {
  return { type: FETCH_FORMS_PENDING };
};

const fetchFormsFulfilled: ActionCreator<AnyAction> = (
  formsTypes: Array<FormTypesData>
) => {
  return { type: FETCH_FORMS_FULFILLED, formsTypes };
};

const fetchFormsFailed: ActionCreator<AnyAction> = () => {
  return { type: FETCH_FORMS_FAILED };
};

export const fetchFormTypes: ThunkActionCreator = (
  siteIds: Array<number>
) => dispatch => {
  dispatch(fetchFormsPending());
  doFetchFormTypes(siteIds)
    .then(formTypes => {
      dispatch(fetchFormsFulfilled(formTypes));
    })
    .catch(_ => {
      dispatch(fetchFormsFailed());
    });
};

const selectSlice: Selector<State> = () => state => {
  return state.forms;
};

export const selectFormTypes: Selector<Array<FormTypesData>> = () => state => {
  return selectSlice()(state).data.formTypes;
};

export const selectFormTypesMeta: Selector<StateMeta> = () => state => {
  return selectSlice()(state).meta;
};
