import { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import SitePage from 'shared/components/SitePage';
import SiteBody from 'shared/components/SiteBody';
import { Footer } from 'link-ui-react';
import UserImpersonation from 'client/components/UserImpersonation';
import { User } from 'shared/state/misc/oidc';

export interface PageWrapperProps {
  title: string;
  header: Header;
  logo: Image;
  pages: Array<Page>;
  siteId: number;
  user: User;
  clientId: string;
}

class PageWrapper extends PureComponent<PageWrapperProps> {
  constructor(props: PageWrapperProps) {
    super(props);
  }
  render() {
    const { title, children, user, clientId } = this.props;
    return (
      <SitePage>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <UserImpersonation clientId={clientId} user={user} />
        <SiteBody>{children}</SiteBody>
        <Footer />
      </SitePage>
    );
  }
}

export default PageWrapper;
