import React from 'react';
import styled from 'styled-components';
import { Panel, Label, Link } from '@uitk/react';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import { Config } from './types';
import {
  Background,
  BackgroundData,
} from 'builder/components/WidgetConfigurations/Background/Background';
import {
  Padding,
  PaddingData,
} from 'builder/components/WidgetConfigurations/Padding';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';
import { SlideShow } from 'builder/components/WidgetConfigurations/SlideShow/SlideShow';
import {
  DragDropBeautifulDnD,
  DragDropItemData,
} from 'builder/components/WidgetConfigurations/DragDropBeautifulDnD';
import { StyledPanel, StyledPanelTitle } from '../Text/TextWidgetControl';
import { SizeLocationPanel } from '../Link/LinkWidgetControl';

export const SidebarNav = styled.nav`
  width: 365px;
  height: 80vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 60px;
  transition: 850ms;
  z-index: 10;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;
`;

export const NavIcon = styled(Link)`
  font-size: 2rem;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #cccac9;
  border-bottom: 1px #888888;
`;

export const SidebarWrap = styled.div`
  width: 100%;
`;

const SlideShowWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const {
    value,
    onChange,
    widgetState,
    addChild,
    currentPage,
    updateCurrentPage,
    removeWidget,
  } = props;

  const handleLineColor = data => {
    onChange({
      ...value,
      lineColor: data,
    });
  };

  const updateWidgetSizeLoction = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };
  const updateBackgroundData = (data: BackgroundData) => {
    onChange({
      ...value,
      backgroundData: data,
    });
  };
  const updateBackgroundImage = (data: Image) => {
    onChange({
      ...value,
      backgroundImage: data,
    });
  };
  const updatePaddingData = (data: PaddingData) => {
    onChange({
      ...value,
      paddingData: data,
    });
  };

  const updateBorderData = (data: BorderData) => {
    onChange({
      ...value,
      borderData: data,
    });
  };

  const updateSlideShowData = (
    data: Array<DragDropItemData>,
    currentSlideIndex?: number
  ) => {
    const currentSlideIndexAfterDelete = currentSlideIndex
      ? currentSlideIndex
      : currentSlideIndex === 0
      ? 0
      : value.currentSlideIndex;
    onChange({
      ...value,
      slides: data,
      currentSlideIndex: currentSlideIndexAfterDelete,
    });
  };

  const handleArrowColor = (data: string) => {
    onChange({
      ...value,
      slideArrowColor: data,
    });
  };

  const handleSelectedDotsColor = (data: string) => {
    onChange({
      ...value,
      slideSelectedDotsColor: data,
    });
  };

  const handleUnSelectedDotsColor = (data: string) => {
    onChange({
      ...value,
      slideUnselectedDotsColor: data,
    });
  };

  const handleArrowsChecked = (data: Boolean) => {
    onChange({
      ...value,
      arrowsCheck: data,
    });
  };

  const handlePositionalDotsChecked = (data: Boolean) => {
    onChange({
      ...value,
      positionalDotsCheck: data,
    });
  };

  const handleAutoplayChecked = (data: Boolean) => {
    onChange({
      ...value,
      autoPlayChecked: data,
    });
  };

  const handleAutoplaySpeed = (data: string) => {
    onChange({
      ...value,
      autoPlayInterval: data,
    });
  };

  const handleSlideClick = (panelId: number) => {
    onChange(
      {
        ...value,
        currentSlideIndex: panelId,
      },
      widgetState.id
    );
  };

  const handleBackgroundColor = (data: string) => {
    onChange({
      ...value,
      backgroundData: { ...value.backgroundData, color: data },
    });
  };

  return (
    <>
      <Panel.Group>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>General</Label>}
            icon={Arrow}
            data-test-id="General-panel"
          >
            <SlideShow
              data-test-id="slideshow-component"
              widgetState={widgetState}
              handleArrowColor={handleArrowColor}
              handleSelectedDotsColor={handleSelectedDotsColor}
              handleUnSelectedDotsColor={handleUnSelectedDotsColor}
              handleArrowsChecked={handleArrowsChecked}
              handleAutoplayChecked={handleAutoplayChecked}
              handleAutoplaySpeed={handleAutoplaySpeed}
              handlePositionalDotsChecked={handlePositionalDotsChecked}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
            <DragDropBeautifulDnD
              dragDropItemsData={updateSlideShowData}
              itemsState={widgetState.config.slides}
              itemName={'Slide'}
              heading={'Slides'}
              addChild={addChild}
              widgetState={widgetState}
              currentPage={currentPage}
              updateCurrentPage={updateCurrentPage}
              updateCurrentTab={handleSlideClick}
              removeWidget={removeWidget}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <SizeLocationPanel
            title={<Label style={StyledPanelTitle}>Size and Location</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="authentication-panel"
          >
            <WidgetSizeLocation
              data-test-id="widget-size-location"
              sizeLocation={{
                xLocation: value.xLocation,
                yLocation: value.yLocation,
                width: value.width,
                height: value.height,
                uniqueId: value.uniqueId,
              }}
              updateWidgetSizeLoction={updateWidgetSizeLoction}
              activeWidgetId={widgetState.id}
            />
          </SizeLocationPanel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Background</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="provisionig-service-panel"
          >
            <Background
              data-test-id="background-component"
              backgroundData={updateBackgroundData}
              backgroundImage={updateBackgroundImage}
              handleBackgroundColor={handleBackgroundColor}
              widgetState={widgetState}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Border</Label>}
            initiallyClosed
            icon={Arrow}
          >
            <Border
              data-test-id="border-data"
              borderData={updateBorderData}
              handleLineColor={handleLineColor}
              widgetState={widgetState}
              pages={props.pages}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Padding</Label>}
            initiallyClosed
            icon={Arrow}
          >
            <Padding
              data-test-id="padding-data"
              paddingData={updatePaddingData}
              widgetState={widgetState}
            />
          </Panel>
        </StyledPanel>
      </Panel.Group>
    </>
  );
};

export default SlideShowWidgetControl;
