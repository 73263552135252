import { Component } from 'react';
import { default as RawSelect } from 'react-select';
import { withTheme } from 'styled-components';
import CSS from 'csstype';

type SelectProps<OptionType> = JSX.LibraryManagedAttributes<
  typeof RawSelect,
  RawSelect<OptionType>['props']
> & { theme?: Theme };

export interface Option<OptionType> {
  label: string;
  value: OptionType;
}

class Select<OptionType> extends Component<SelectProps<OptionType>> {
  /**
   * Workaround until JedWatson/react-select#{1020,2816,3013} is fixed.
   */
  selectElem: any;
  componentDidMount() {
    const select = this.selectElem.select;
    if (!select.onInputBlurPatched) {
      const originalOnInputBlur = select.onInputBlur;
      select.onInputBlur = (e: FocusEvent) => {
        if (
          select.menuListRef &&
          select.menuListRef.contains(document.activeElement)
        ) {
          select.inputRef.focus();
          return;
        }
        originalOnInputBlur(e);
      };
      select.onInputBlurPatched = true;
    }
  }

  render() {
    const { theme, ...props } = this.props;

    const styles: {
      [index: string]: (base: CSS.Properties) => CSS.Properties;
    } = {
      control: base => ({
        ...base,
        minWidth: '200px',
      }),
      menu: base => ({
        ...base,
        zIndex: 10,
      }),
      noOptionsMessage: base => ({
        ...base,
        fontFamily: `${theme.font}, 'Helvetica Neue', Helvetica`,
      }),
      option: base => ({
        ...base,
        fontFamily: `${theme.font}, 'Helvetica Neue', Helvetica`,
      }),
      placeholder: base => ({
        ...base,
        fontFamily: `${theme.font}, 'Helvetica Neue', Helvetica`,
      }),
      singleValue: base => ({
        ...base,
        fontFamily: `${theme.font}, 'Helvetica Neue', Helvetica`,
      }),
    };

    return (
      <div data-test-id="select-id">
        <RawSelect
          ref={(elem: any) => (this.selectElem = elem)}
          styles={styles}
          {...props}
        />
      </div>
    );
  }
}

export default withTheme(Select);
