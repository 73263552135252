import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

// TODO: Use Typography component. Probably requires some normalizeTheme tweaks.

const MissingSite = () => (
  <Wrapper>
    <h2>This site doesn't exist.</h2>
  </Wrapper>
);

export default MissingSite;
