import { PureComponent } from 'react';
import onClickOutside, { InjectedOnClickOutProps } from 'react-onclickoutside';

interface ClickOutsideComponentProps {
  onClickOutside: (e: any) => void;
}

class ClickOutsideComponent extends PureComponent<
  ClickOutsideComponentProps & InjectedOnClickOutProps
> {
  handleClickOutside = (e: any) => {
    this.props.onClickOutside(e);
  };
  render() {
    return <div>{this.props.children}</div>;
  }
}

export default onClickOutside(ClickOutsideComponent);
