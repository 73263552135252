import { Config } from './types';
import { Label, FormControl, HelperText } from '@uitk/react';
import styled from 'styled-components';
import { UploadedFile } from '../../shared/types';
import S3UploadFileButton from 'builder/components/S3UploadFileButton/S3UploadFileButton';

const StyledFormControl = styled(FormControl)`
  padding: 0.25rem;
  align-content: center;
  & > .uitk-helper-text {
    font-family: 'OptumSans';
  }
`;

const WrapperForBorder = styled.div`
  padding: 0.25rem;
  display: block;
  width: 100%;
  height: 100%;
  & > .uitk-label {
    font-family: 'OptumSans';
  }
  .uitk-form-control {
    width: auto;
    height: auto;
  }
  & > div > div > label {
    cursor: default;
  }
`;

const StyledS3UploadFileButton = styled(S3UploadFileButton)`
  cursor: default;
`;

let acceptedFileTypesMime: string;
let acceptedFileTypesRegex: RegExp;
let acceptedFileTypesExpression: string;
let showFileTypes: string;
let temp: string;

function displayAcceptedFileTypes(
  pdf: boolean,
  doc: boolean,
  png: boolean,
  jpg: boolean
) {
  showFileTypes =
    'Accepted file types: ' +
    `${pdf ? '.pdf ' : ''}` +
    `${doc ? '.doc .docx ' : ''}` +
    `${png ? '.png ' : ''}` +
    `${jpg ? '.jpg .jpeg' : ''}`;
  return showFileTypes;
}

function handleAcceptedFileTypes(
  pdf: boolean,
  doc: boolean,
  png: boolean,
  jpg: boolean
) {
  temp = '';
  acceptedFileTypesMime = '';
  if (pdf) {
    acceptedFileTypesMime = acceptedFileTypesMime.concat('application/pdf, ');
    temp += 'pdf|PDF|';
  }
  if (doc) {
    acceptedFileTypesMime = acceptedFileTypesMime.concat(
      'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, '
    );
    temp += 'docx|DOCX|doc|DOC|';
  }
  if (png) {
    acceptedFileTypesMime = acceptedFileTypesMime.concat('image/png, ');
    temp += 'png|PNG|';
  }
  if (jpg) {
    acceptedFileTypesMime = acceptedFileTypesMime.concat(
      'image/jpg, image/jpeg, '
    );
    temp += 'jpg|JPG|jpeg|JPEG|';
  }
  acceptedFileTypesExpression = `.*\\\.(${temp.substring(0, temp.length - 1)})`;
  acceptedFileTypesRegex = new RegExp(acceptedFileTypesExpression);
  return acceptedFileTypesMime;
}

export const FileUploaderWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
) => {
  const {
    label,
    enablePDF,
    enableDOC,
    enablePNG,
    enableJPG,
    uploadedFile,
    lineColor,
    borderData,
  } = props.widgetState.config;

  const handleUpload = (file: UploadedFile) => {
    const { onChange } = props;
    const { config } = props.widgetState;
    onChange(
      {
        ...config,
        uploadedFile: file,
      },
      props.widgetState.id,
      props.widgetState.defaultWidget
    );
  };

  return (
    <WrapperForBorder
      style={{
        borderStyle: 'solid',
        borderColor: `${lineColor}`,
        borderWidth: `${borderData?.thickness}px`,
        borderRadius: `${borderData?.radius}px`,
      }}
    >
      <Label>{label || label === '' ? label : 'Attachments'}</Label>
      <StyledFormControl id={'field-label'}>
        <HelperText>
          {displayAcceptedFileTypes(enablePDF, enableDOC, enablePNG, enableJPG)}
        </HelperText>
      </StyledFormControl>
      <StyledS3UploadFileButton
        id="file-uploader"
        label="Click to Upload"
        accept={handleAcceptedFileTypes(
          enablePDF,
          enableDOC,
          enablePNG,
          enableJPG
        )}
        onUpload={handleUpload}
        file={uploadedFile}
        icon={'Upload'}
        iconProps={{
          width: '1.2em',
          height: '1.3em',
          viewBox: '0 0 24 24',
        }}
        fileFormatRegex={acceptedFileTypesRegex}
        data-test-id={`file-uploader`}
      />
      <HelperText>{uploadedFile ? uploadedFile?.name : ''}</HelperText>
    </WrapperForBorder>
  );
};

export default FileUploaderWidget;
