import { ComponentClass, HTMLProps } from 'react';
import styled from 'styled-components';
import { ScreenSizeBreakpoints } from 'shared/constants';
import {
  fontWeights,
  fontSizes,
  FontWeight,
  FontSize,
} from 'shared/util/theme';

interface Variant {
  component: string;
  render: ComponentClass; // TODO: This should be typed to StyledComponentClass
}

export interface Variants {
  plainText: Variant;
  display2: Variant;
  display1: Variant;
  headline: Variant;
  title: Variant;
  subheading: Variant;
  caption: Variant;
  body1: Variant;
  nav: Variant;
  subnav: Variant;
  section: Variant;
  listitem: Variant;
  unorderedlist: Variant;
  Details: Variant;
  trademark: Variant;
  poweredByLink: Variant;
  staticlinks: Variant;
  linkSubheading: Variant;
  categoryTitle: Variant;
  profileName: Variant;
  hint: Variant;
}

export interface TypographyProps<V extends keyof Variants, P>
  extends HTMLProps<P> {
  variant?: V;
  gutterBottom?: boolean;
  component?: string | ComponentClass<P>;
  href?: string;
  target?: string;
  title?: string;
  children?: any;
  fontWeight?: string;
  fontSize?: string;
}

const base = styled.span<{
  gutter: boolean;
  fontWeight: FontWeight;
  fontSize: FontSize;
}>`
  font-family: ${p => p.theme.font}, 'Helvetica Neue', Helvetica;
  margin-bottom: ${p => (p.gutter ? '0.35em' : '0')};
  font-weight: ${p => fontWeights[p.fontWeight] ?? '400'};
  font-size: ${p => fontSizes[p.fontSize] ?? '0.875rem'};
`;

const variants: Variants = {
  plainText: {
    component: 'p',
    render: (base as unknown) as ComponentClass,
  },
  display2: {
    component: 'h1',
    render: (styled(base)`
      font-size: 2.8125rem;
      line-height: 1.06667em;
    ` as unknown) as ComponentClass,
  },
  display1: {
    component: 'h1',
    render: (styled(base)`
      font-size: 2.125rem;
      line-height: 1.20588em;
    ` as unknown) as ComponentClass,
  },
  headline: {
    component: 'h1',
    render: (styled(base)`
      font-size: 1.5rem;
      line-height: 1.35417em;
    ` as unknown) as ComponentClass,
  },
  title: {
    component: 'h2',
    render: (styled(base)`
      font-size: 1.3125rem;
      line-height: 1.16667em;
    ` as unknown) as ComponentClass,
  },
  subheading: {
    component: 'h3',
    render: (styled(base)`
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5em;
    ` as unknown) as ComponentClass,
  },
  caption: {
    component: 'span',
    render: (styled(base)`
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.375em;
    ` as unknown) as ComponentClass,
  },
  listitem: {
    component: 'li',
    render: (styled(base)`
      font-weight: 400;
      line-height: 1.46429em;
      margin-left: 1em;
    ` as unknown) as ComponentClass,
  },
  unorderedlist: {
    component: 'ul',
    render: (styled(base)`
      font-weight: 400;
      line-height: 1.46429em;
      list-style-type: disc;
      list-style-position: outside;
    ` as unknown) as ComponentClass,
  },
  body1: {
    component: 'p',
    render: (styled(base)`
      font-weight: 400;
      line-height: 1.46429em;
    ` as unknown) as ComponentClass,
  },
  nav: {
    component: 'li',
    render: (styled(base)`
      font-weight: 400;
      line-height: 1.5em;
    ` as unknown) as ComponentClass,
  },
  subnav: {
    component: 'li',
    render: (styled(base)`
      font-weight: 400;
      line-height: 1.46429em;
    ` as unknown) as ComponentClass,
  },
  section: {
    component: 'div',
    render: (styled(base)`
      font-weight: 400;
      line-height: 1.46429em;
    ` as unknown) as ComponentClass,
  },
  Details: {
    component: 'p',
    render: (styled(base)`
      font-size: 0.812rem;
      font-weight: 400;
      line-height: 1.46429em;
    ` as unknown) as ComponentClass,
  },
  trademark: {
    component: 'p',
    render: (styled(base)`
      font-size: 0.825rem;
      font-weight: 400;
      line-height: 1.46429em;
      @media (max-width: ${ScreenSizeBreakpoints.extraSmall}px) {
        height: 20px;
        font-size: 0.625rem;
      }
    ` as unknown) as ComponentClass,
  },
  poweredByLink: {
    component: 'p',
    render: (styled(base)`
      font-size: 0.825rem;
      font-weight: 400;
      line-height: 1.46429em;
      @media (max-width: ${ScreenSizeBreakpoints.extraSmall}px) {
        height: 20px;
        font-size: 0.625rem;
        position: relative;
      }

      @media (max-width: ${ScreenSizeBreakpoints.tiny}px) {
        height: 20px;
        font-size: 0.625rem;
        position: relative;
        left: 25px;
      }
    ` as unknown) as ComponentClass,
  },
  staticlinks: {
    component: 'p',
    render: (styled(base)`
      font-size: 0.825rem;
      font-weight: 400;
      line-height: 1.46429em;
      @media (max-width: ${ScreenSizeBreakpoints.extraSmall}px) {
        height: 20px;
        font-size: 0.625rem;
      }
    ` as unknown) as ComponentClass,
  },
  linkSubheading: {
    component: 'h3',
    render: (styled(base)`
      font-size: 1rem;
      line-height: 1.5em;
      @media (min-width: ${ScreenSizeBreakpoints.small}px) and (max-width: ${ScreenSizeBreakpoints.medium}px) {
        font-size: 0.825rem;
      }
    ` as unknown) as ComponentClass,
  },
  categoryTitle: {
    component: 'h3',
    render: (styled(base)`
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5em;
      white-space: nowrap;
    ` as unknown) as ComponentClass,
  },
  profileName: {
    component: 'p',
    render: (styled(base)`
      font-weight: 400;
      white-space: nowrap;
    ` as unknown) as ComponentClass,
  },
  hint: {
    component: 'span',
    render: (styled(base)`
      font-weight: 600;
      line-height: 1.46429em;
    ` as unknown) as ComponentClass,
  },
};

const Typography = <V extends keyof Variants, P>({
  variant,
  gutterBottom,
  component,
  href,
  target,
  title,
  ...props
}: TypographyProps<V, P>) => {
  const Component = (variants[variant || 'body1'].render as any).withComponent(
    component || variants[variant || 'body1'].component
  );

  return (
    <Component
      gutter={gutterBottom}
      href={href}
      target={target}
      title={title}
      {...props}
    />
  );
};

export default Typography;
