import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { RootState } from 'shared/state';
import {
  selectSite,
  selectPages,
  selectDomain,
  selectWidget,
} from 'shared/state/ducks/sites';
import { selectUser } from 'shared/state/misc/oidc';
import SiteRoutes from './SiteRoutes';

interface SiteRootContainerProps {
  siteId: number;
  domain?: string;
  pageId?: number;
}

const mapStateToProps = (
  state: RootState,
  ownProps: SiteRootContainerProps & RouteComponentProps<{}>
) => ({
  site: selectSite(ownProps.siteId)(state),
  pages: selectPages(ownProps.siteId)(state),
  user: selectUser()(state),
  domain: selectDomain()(state),
  widgetState: selectWidget(ownProps.siteId, ownProps.pageId, 'root')(state),
});

const mapDispatchToProps = () => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SiteRoutes)
);
