import { Fragment, PureComponent } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Typography from 'shared/components/Typography';
import Div from 'shared/components/Div';
import { ScreenSizeBreakpoints } from 'shared/constants';
import { Icon } from 'link-ui-react';
import { ResponsiveState } from 'shared/state/ducks';
import { track } from 'shared/util/analytics/track';
import { User } from 'shared/state/misc/oidc';
// import { getColorValue } from 'shared/util/theme';

const Wrapper = styled(Div)<{ background: string }>`
  width: 100%;
  flex: 1 1 auto;
  padding: 16px 0px;
  display: -webkit-box;
  justify-content: center;
  @media (min-width: ${ScreenSizeBreakpoints.small}px) and (max-width: ${ScreenSizeBreakpoints.medium}px) {
    padding: 0px 5px 0px 5px;
    flex: 0 1 auto;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    padding: 1em 0em 1.875em 0em;
  }

  @media (max-width: ${ScreenSizeBreakpoints.small}px) {
    padding: 0;
  }
`;

const Accordion = styled.button`
  width: 100%;
  font-size: 1.2em;
  cursor: pointer;
  height: 40px;
  color: ${p => p.theme.colors.aux.black};
  border: none;

  @media (max-width: ${ScreenSizeBreakpoints.small}px) {
    text-align: left;
    background-color: inherit;
    padding-right: 15px;
  }
`;

const AccordionContent = styled.div<{ isOpen: boolean }>`
  overflow: hidden;
  font-size: 0.825em;
  padding-left: 5px;
  width: 100%;
  border: none;
  ${p => (p.isOpen ? '' : 'height: 0')};
`;

const Arrow = styled(Icon).attrs({
  height: '.8em',
  width: '.8em',
  tabIndex: -1,
})<{
  transform: number;
}>`
  margin-left: 1em;
  fill: ${p => p.theme.colors.text[p.color] || p.theme.colors.aux.black};
  cursor: pointer;
  outline: none;
  float: right;
  transform: rotate(${p => p.transform}deg);
`;

const InnerWrapper = styled.div`
  max-width: ${p => p.theme.maxWidth}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (max-width: ${ScreenSizeBreakpoints.extraSmall}px) {
    flex-direction: column;
  }

  @media (max-width: ${ScreenSizeBreakpoints.small}px) {
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: ${ScreenSizeBreakpoints.small}px) and (max-width: ${ScreenSizeBreakpoints.medium}px) {
    padding-top: 10px;
  }
`;

const Details = styled.div<{ mobileView: boolean }>`
  flex: 0 1 250px;
  flex-direction: column;
  @media (max-width: ${ScreenSizeBreakpoints.extraSmall}px) {
    flex: 0 1 20px;
  }

  @media (min-width: ${ScreenSizeBreakpoints.small}px) and (max-width: ${ScreenSizeBreakpoints.medium}px) {
    padding-top: 0px;
  }
`;

const LogoWrapper = styled.div<{ display: string }>`
  @media (min-width: ${ScreenSizeBreakpoints.small}px) and (max-width: ${ScreenSizeBreakpoints.medium}px) {
    display: ${p => p.display};
    justify-content: center;
    margin-bottom: 0px;
  }

  @media (max-width: ${ScreenSizeBreakpoints.small}px) {
    display: ${p => p.display};
    padding-top: 10px;
  }

  @media (max-width: ${ScreenSizeBreakpoints.extraSmall}px) {
    display: flex;
    justify-content: center;
  }
`;

const Logo = styled.img`
  max-width: 200px;
  @media (min-width: ${ScreenSizeBreakpoints.small}) and (max-width: ${ScreenSizeBreakpoints.medium}px) {
    width: 110px;
  }
`;

const Info = styled(Typography)`
  padding-bottom: 10px;
  @media (max-width: ${ScreenSizeBreakpoints.extraSmall}px) {
    text-align: center;
  }

  @media (min-width: ${ScreenSizeBreakpoints.small}px) and (max-width: ${ScreenSizeBreakpoints.medium}px) {
    text-align: center;
    padding-bottom: 10px;
  }
`;

const CategoryTitle = styled(Typography)`
  color: ${p => p.theme.colors.primary};
  display: flex;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  @media (max-width: ${ScreenSizeBreakpoints.small}px) {
    padding-left: 15px;
  }
`;

const FooterLink = styled(Typography)`
  margin-bottom: 8px;
  text-decoration: none;
  color: ${p => p.theme.colors.aux.black};
  @media (min-width: ${ScreenSizeBreakpoints.small}px) and (max-width: ${ScreenSizeBreakpoints.medium}px) {
    width: 66%;
  }
`;

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: ${ScreenSizeBreakpoints.extraSmall}px) {
    justify-content: center;
  }

  @media (min-width: ${ScreenSizeBreakpoints.small}px) and (max-width: ${ScreenSizeBreakpoints.medium}px) {
    justify-content: center;
  }
`;

const SocialLink = styled.a`
  margin: 0 4px;
`;

const SocialImage = styled.img`
  height: 30px;
  width: 30px;
`;

export interface SiteFooterProps {
  footer: Footer;
  pages: Array<Page>;
  logo: Image;
  browser?: ResponsiveState;
  tracking?: any; //brought in as a class wrapper with @track
  user?: User;
}

interface SiteFooterState {
  isMobile: boolean;
  isOpen: Array<boolean>;
  transform: Array<number>;
}

@track()
class SiteFooter extends PureComponent<SiteFooterProps, SiteFooterState> {
  constructor(props: SiteFooterProps) {
    super(props);
    this.state = {
      isOpen: Array(6).fill(false),
      transform: Array(6).fill(0),
      isMobile: props.browser && props.browser.lessThan.small,
    };
  }

  static getDerivedStateFromProps(
    nextProps: SiteFooterProps,
    prevState: SiteFooterState
  ) {
    const isMobile = nextProps.browser && nextProps.browser.lessThan.medium;
    if (isMobile !== prevState.isMobile) {
      return { isMobile };
    } else {
      return null;
    }
  }

  onClick = (index: number) => () => {
    const isOpen = [...this.state.isOpen];
    isOpen[index] = !isOpen[index];
    this.setState({ isOpen });

    const transform = [...this.state.transform];
    if (transform[index] > 0) {
      transform[index] = 0;
      this.setState({ transform });
    } else {
      transform[index] = 180;
      this.setState({ transform });
    }
  };

  renderFooterLink = (link: NavItem, index: number, renderType: string) => {
    const { pages } = this.props;
    const type = link.type;
    let navLinkProps: any = {
      key: index,
      target: link.openInNewTab ? '_blank' : undefined,
      onClick: (e: any) => this.linkClick(e, link),
    };
    if (type === 'page') {
      const page: Page = pages.find((page: Page) => link.pageId === page.id);
      if (page) {
        navLinkProps = {
          ...navLinkProps,
          component: Link,
          to: page.current.slug,
        };
      } else {
        navLinkProps = {
          ...navLinkProps,
          component: 'a',
          href: link.url,
        };
      }
    } else {
      navLinkProps = {
        ...navLinkProps,
        component: 'a',
        href: link.url,
      };
    }

    return (
      <FooterLink {...navLinkProps}>
        {renderType !== 'flat' && (
          <Typography data-test-id={`footer-category-${index}`} variant="body1">
            {this.getLabel(link)}
          </Typography>
        )}
        {renderType === 'flat' && (
          <CategoryTitle
            data-test-id={`footer-category-${renderType}-${index}`}
            variant="categoryTitle"
          >
            {this.getLabel(link)}
          </CategoryTitle>
        )}
      </FooterLink>
    );
  };

  getLabel = (link: NavItem) => {
    const { pages } = this.props;
    const type = link.type;
    if (type === 'page') {
      const page: Page = pages.find((page: Page) => link.pageId === page.id);
      return page ? page.current.title : link.label;
    } else {
      return link.label;
    }
  };
  //strictly for tracking
  linkClick = (e: React.MouseEvent, link: NavItem) => {
    const { tracking, user } = this.props;
    tracking.trackEvent({
      action: 'link_click',
      linkLabel: link.label,
      linkUrl: link.url ? link.url : null,
      pageX: e.pageX,
      pageY: e.pageY,
      authenticated: !!user, //TODO: evaluate if needed
    });
  };

  render() {
    const { bgcolor, variant } = this.props.footer;
    const { isMobile } = this.state;
    return (
      <Wrapper background={bgcolor}>
        <InnerWrapper>
          {variant === 'logo' && this.renderLogo(isMobile)}
          {variant === 'logoinfo' && this.renderLogoInfo(isMobile)}
          {variant === 'flat' && this.renderFlat(isMobile)}
          {this.renderLinks()}
        </InnerWrapper>
      </Wrapper>
    );
  }

  renderFlat = (mobileCheck: boolean) => {
    const { logo = { url: '', alt: '' } } = this.props;
    if (mobileCheck) {
      return <LogoWrapper display={'none'}>{this.props.children}</LogoWrapper>;
    } else {
      return (
        <LogoWrapper display={'flex'}>
          <Link to="/">
            <Logo data-test-id="footer-logo" src={logo.url} />
          </Link>
        </LogoWrapper>
      );
    }
  };

  renderLogo = (mobileCheck: boolean) => {
    const { logo = { url: '', alt: '' } } = this.props;
    return (
      <Details mobileView={mobileCheck}>
        <LogoWrapper display={'flex'}>
          <Link to="/">
            <Logo data-test-id="footer-logo" src={logo.url} />
          </Link>
        </LogoWrapper>
      </Details>
    );
  };

  renderLogoInfo = (mobileCheck: boolean) => {
    const {
      footer: { info, social = [] },
      logo = { url: '', alt: '' },
    } = this.props;
    return (
      <Details mobileView={mobileCheck}>
        <LogoWrapper display={'flex'}>
          <Link to="/">
            <Logo data-test-id="footer-logo" src={logo.url} />
          </Link>
        </LogoWrapper>
        <Fragment>
          <Info data-test-id="footer-info" variant="body1">
            {info}
          </Info>
          <SocialWrapper>
            {social.map((link, index) => (
              <SocialLink data-test-id="" key={index} href={link.url}>
                <SocialImage
                  data-test-id={`footer-social-img-${index}`}
                  src={link.image.url}
                  title={link.title}
                />
              </SocialLink>
            ))}
          </SocialWrapper>
        </Fragment>
      </Details>
    );
  };

  renderLinks = () => {
    const { isOpen, transform, isMobile } = this.state;
    const {
      footer: { items = [], variant },
    } = this.props;

    const Category = styled.div<{ padding: string; justify: string }>`
      display: flex;
      flex-direction: column;
      padding-top: ${p => p.padding};
      justify-content: ${p => p.justify};
      @media (max-width: ${ScreenSizeBreakpoints.small}px) {
        text-align: center;
      }
    `;

    if (isMobile) {
      //Render this if a Mobile viewport is present
      if (variant === 'flat') {
        /**
         * In the flat variant, we use the links from just the first section
         */
        const section: Partial<NavItem> = items[0] || {
          children: [],
        };
        return section.children.map((link, index) => (
          <Category key={index} padding={'0px'} justify={'center'}>
            {this.renderFooterLink(link, index, 'flat')}
          </Category>
        ));
      } else {
        /**
         * Loop over the categories, rendering each label and child links
         */
        return items.map((category, index) => (
          <Fragment>
            <Accordion onClick={this.onClick(index)}>
              {category.label}
              <Arrow icon="ArrowDownOpen" transform={transform[index]} />
            </Accordion>
            <AccordionContent key={index} isOpen={isOpen[index]}>
              <Links>
                {category.children.map((link, index) =>
                  this.renderFooterLink(link, index, '')
                )}
              </Links>
            </AccordionContent>
          </Fragment>
        ));
      }
    } else {
      if (variant === 'flat') {
        const item: Partial<NavItem> = items[0] || {
          children: [],
        };
        return item.children.map((link, index) => (
          <Category key={index} padding={'0px'} justify={'center'}>
            {this.renderFooterLink(link, index, 'flat')}
          </Category>
        ));
      } else {
        return items.map((category, index) => (
          <Category key={index} padding={'0px'} justify={'none'}>
            <CategoryTitle variant="categoryTitle">
              {category.label}
            </CategoryTitle>
            <Links>
              {category.children.map((link, index) =>
                this.renderFooterLink(link, index, '')
              )}
            </Links>
          </Category>
        ));
      }
    }
  };
}

export default SiteFooter;
