import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uuid from 'uuid/v4';
import styled from 'styled-components';
import { CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import URI from 'urijs';
import { getSignedUrl as fetchSignedUrl } from 'shared/api/s3';
import ReactS3Uploader from 'react-s3-uploader';
import { withRouter } from 'react-router-dom';
import config from 'builder/config';
import SiteEditContext from 'shared/util/SiteEditContext';
import { SignedUrlObject } from 'shared/api/s3';
import { UploadedFile, IconProps } from 'shared/widgets/shared/types';
import { Icon, IconType, linkDeveloperTheme as theme } from 'link-ui-react';
import { RouteComponentProps } from 'react-router';

export type PathParamsType = {
  siteId: string;
};

export interface S3UploadFileProps extends RouteComponentProps<PathParamsType> {
  id?: string;
  file?: UploadedFile;
  label: string;
  accept: string;
  fileFormatRegex?: RegExp;
  icon?: IconType;
  iconProps?: IconProps;
  onUpload: (file: UploadedFile) => void;
  staticContext: any;
}

const InputWrapper = styled.div`
  & .MuiInputLabel-root,
  .MuiInputBase-root {
    font-family: 'OptumSans';
  }
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  margin-top: 0.4em;
`;

export const UploadLabel = styled.label`
  &:hover {
    cursor: pointer;
  }
  width: 100%;
`;

const StyledIcon = styled(Icon)`
  fill: ${theme.colors.aux.blue};
`;

export interface S3UploadFileState {
  uploading: boolean;
  uuid: string;
}

class S3UploadFile extends PureComponent<S3UploadFileProps, S3UploadFileState> {
  static contextType = SiteEditContext;
  context!: React.ContextType<typeof SiteEditContext>;

  constructor(props: S3UploadFileProps) {
    super(props);
    this.state = {
      uploading: false,
      uuid: uuid(),
    };
  }

  handleFinish = (signedUrlObject: SignedUrlObject) => {
    const { onUpload } = this.props;
    this.setState({
      uploading: false,
    });
    const uri = URI(signedUrlObject.signedUrl);
    const file: UploadedFile = {
      url: uri
        .hostname(config.cdn)
        .query('')
        .toString(),
      name: uri.filename().toString(),
    };
    onUpload(file);
  };

  rejectUpload = () => {
    this.setState({
      uploading: false,
    });
    toast('This file type is not supported', {
      type: 'error',
      theme: 'colored',
    });
  };

  onUploadStart(file: File, callback: any) {
    const fileName = file.name;
    const { fileFormatRegex } = this.props;
    // If extension of file being uploaded is as per fileformatRegex then do callback otherwise reject the upload.
    fileFormatRegex &&
      (fileFormatRegex.test(fileName) ? callback(file) : this.rejectUpload());
  }

  render() {
    const { uploading, uuid } = this.state;
    const {
      file,
      label,
      accept,
      icon,
      match: {
        params: { siteId },
      },
      iconProps,
    } = this.props;
    return (
      <Wrapper>
        <ReactS3Uploader
          getSignedUrl={(file, cb) => fetchSignedUrl(file, cb, siteId)}
          s3path={`site-builder/${siteId}`}
          scrubFilename={filename => filename}
          preprocess={(file, callback) => this.onUploadStart(file, callback)}
          uploadRequestHeaders={{}}
          onProgress={() => {
            this.setState({ uploading: true });
          }}
          onFinish={this.handleFinish}
          style={{ display: 'none' }}
          accept={accept}
          onError={() => {
            this.setState({ uploading: false });
            toast('Error uploading file', { type: 'error', theme: 'colored' });
          }}
          id={uuid}
          type="file"
          data-test-id={`file-uploader`}
        />
        <InputWrapper>
          <UploadLabel htmlFor={uuid}>
            <TextField
              type="text"
              value={file ? file.name : 'Upload a file'}
              label={label}
              margin="dense"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {uploading ? (
                      <CircularProgress size={25} />
                    ) : (
                      <StyledIcon
                        icon={icon}
                        width={iconProps?.width}
                        height={iconProps?.height}
                        viewBox={iconProps?.viewBox}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </UploadLabel>
        </InputWrapper>
      </Wrapper>
    );
  }
}

export { S3UploadFile };
export default withRouter<S3UploadFileProps>(S3UploadFile);
