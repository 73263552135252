import React, { useEffect } from 'react';
import { Panel, Label } from '@uitk/react';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import { Config } from './types';
import {
  Padding,
  PaddingData,
} from 'builder/components/WidgetConfigurations/Padding';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';
import { ImageUpload } from 'builder/components/WidgetConfigurations/Image/ImageUpload';
import EnableFullScreenWidth from 'builder/components/WidgetConfigurations/EnableFullScreenWidth';
import {
  SizeLocationPanel,
  StyledPanel,
  StyledPanelTitle,
} from '../Text/TextWidgetControl';
import styled from 'styled-components';
import {
  StyledLabel,
  StyledTextInput,
} from 'builder/components/WidgetConfigurations/EnableFullScreenWidth';

const AltText = styled.div`
  display: block;
  margin-top: 0.5rem;
`;

const ImageWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const { value, onChange, widgetState } = props;
  const [altText, setAltText] = React.useState(widgetState.config.altText);

  useEffect(() => {
    setAltText(widgetState.config.altText);
  }, [widgetState.config]);

  const updateWidgetSizeLoction = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };
  const handleBackgroundImage = (data: Image) => {
    onChange({
      ...value,
      backgroundImage: data,
    });
  };
  const updatePaddingData = (data: PaddingData) => {
    onChange({
      ...value,
      paddingData: data,
    });
  };

  const updateBorderData = (data: BorderData) => {
    onChange({
      ...value,
      borderData: data,
    });
  };
  const handleLineColor = data => {
    onChange({
      ...value,
      lineColor: data,
    });
  };
  const handleFullScreen = (data: boolean) => {
    onChange({
      ...value,
      enableFullScreen: data,
    });
  };
  const handleAltTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAltText(e.target.value);
    onChange({
      ...value,
      altText: e.target.value,
    });
  };

  return (
    <>
      <Panel.Group>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>General</Label>}
            icon={Arrow}
            data-test-id="general-image-panel"
          >
            <ImageUpload
              backgroundImage={handleBackgroundImage}
              widgetState={props.widgetState}
              data-test-id="image-upload-component"
            />
            <EnableFullScreenWidth
              handleEnableFullScreen={handleFullScreen}
              enableFullScreenWidth={widgetState.config.enableFullScreen}
            />
            <AltText>
              <StyledLabel>Alt Text</StyledLabel>
              <StyledTextInput
                data-test-id="alt-text-input"
                value={altText ? altText : ''}
                onChange={handleAltTextChange}
              />
            </AltText>
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <SizeLocationPanel
            title={<Label style={StyledPanelTitle}>Size and Location</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="sizeandlocation-panel"
          >
            <WidgetSizeLocation
              data-test-id="widget-size-location"
              sizeLocation={{
                xLocation: value.xLocation,
                yLocation: value.yLocation,
                width: value.width,
                height: value.height,
                uniqueId: value.uniqueId,
              }}
              updateWidgetSizeLoction={updateWidgetSizeLoction}
              activeWidgetId={widgetState.id}
            />
          </SizeLocationPanel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Border</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="border-panel"
          >
            <Border
              data-test-id="border-component"
              borderData={updateBorderData}
              handleLineColor={handleLineColor}
              widgetState={widgetState}
              pages={props.pages}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Padding</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="padding-panel"
          >
            <Padding
              paddingData={updatePaddingData}
              widgetState={widgetState}
              data-test-id="padding-component"
            />
          </Panel>
        </StyledPanel>
      </Panel.Group>
    </>
  );
};

export default ImageWidgetControl;
