import { connect } from 'react-redux';
import { RootState, Dispatch } from 'shared/state';
import {
  fetchMyApps,
  selectApps,
  selectErrors,
  selectMeta,
} from 'shared/state/ducks/myapps';
import { selectActiveId, setActiveId } from 'shared/state/ducks/org';
import {
  selectOrganizations,
  selectUserAccounts,
} from 'shared/state/misc/oidc';
import { selectUser } from 'shared/state/misc/oidc';
import authWrapper from 'shared/components/AuthWrapper';
import { Config as MyAppsConfig } from './types';
import {
  closeAccessRequestDialog,
  openAccessRequestDialog,
} from 'shared/state/ducks/ui';
import MyApps from './MyApps';
import { updateWidgetConfig } from 'shared/state/ducks/sites';

const mapStateToProps = (state: RootState, ownProps: MyAppsConfig) => ({
  myapps: selectApps()(state),
  myappsErrors: selectErrors()(state),
  myappsMeta: selectMeta()(state),
  published: state.published,
  sites: state.sites,
  user: selectUser()(state),
  orgs: selectOrganizations()(state),
  userAccounts: selectUserAccounts()(state),
  activeOrgId: selectActiveId()(state),
  siteDomain: state.sites.data[0].domain,
  tenancy: state.sites.data[0].clientId,
  siteProvisioningStores: state.sites.data[0].siteProvisioningStores,
  ownProps,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose: () => {
    dispatch(closeAccessRequestDialog());
  },
  openAccessRequestDialog: () => {
    dispatch(openAccessRequestDialog());
  },
  fetchMyApps: (published: boolean) => {
    const ret = dispatch(fetchMyApps(published));
    return ret;
  },
  setActiveOrgId: (id: string) => {
    dispatch(setActiveId(id));
  },
  onUpdateConfig: (
    config: any,
    widgetId: string,
    defaultWidget: boolean,
    siteId: number,
    pageId: number
  ) => {
    dispatch(
      updateWidgetConfig(siteId, pageId, widgetId, config, defaultWidget)
    );
  },
});

export default authWrapper(
  connect(mapStateToProps, mapDispatchToProps)(MyApps)
);
