import axios from 'shared/util/axios';

const fetchProvStores = () => {
  return axios
    .get<Array<ProvisioningStore>>('site-builder/v3/sites/provisioningStores')
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

export { fetchProvStores };
