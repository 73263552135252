import { FC } from 'react';
import { defaultTheme as theme } from 'link-ui-react';
import { Divider } from '@material-ui/core';

interface ContextMenuItem {
  label: string;
  action: () => void;
  shortcut?: string;
}

interface ContextMenuProps {
  menuitems: ContextMenuItem[];
  position: { x: number; y: number };
  disabled?: boolean;
}

export const ContextMenu: FC<ContextMenuProps> = ({
  menuitems,
  position,
  disabled,
}) => (
  <span title={disabled ? 'This item cannot be deleted or duplicated.' : ''}>
    <div
      className="custom-context-menu"
      style={{
        position: 'absolute',
        top: position.y,
        left: position.x,
        padding: '4px 0',
        background: theme.colors.aux.white,
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        border: '1px solid #ccc',
        borderRadius: '4px',
        zIndex: 9999,
        minWidth: '10rem', // Adjust the minimum width as needed
        color: `${disabled ? 'rgb(204, 204, 204)' : 'black'}`,
      }}
    >
      {menuitems.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            item.action();
          }}
          style={{
            pointerEvents: `${disabled ? 'none' : 'auto'}`,
            padding: `${item.label === 'Divider' ? '0 8px' : '8px'}`,
            cursor: 'pointer',
            whiteSpace: 'nowrap',
          }}
        >
          {item.label === 'Divider' ? (
            <Divider variant="middle" style={{ margin: '0px' }} />
          ) : (
            item.label
          )}
          <span
            style={{
              float: 'right',
              color: `${disabled ? 'rgb(204, 204, 204)' : 'black'}`,
            }}
          >
            {item.shortcut}
          </span>
        </div>
      ))}
    </div>
  </span>
);
