import { PureComponent } from 'react';
import axios, { AxiosError } from 'axios';
import config from 'client/config';
import { User } from 'shared/state/misc/oidc';
import { getUserManager } from 'client/util/userManager';

const SESSION_CHECK_INTERVAL = 15; // seconds

interface SessionObserverProps {
  user: User;
}

/**
 * Observe user's session, periodically checking if the session is still valid
 * in IDX (i.e. hasn't been signed out by another app). Also enable silent
 * renewal if the user has third-party cookies enabled.
 */
class SessionObserver extends PureComponent<SessionObserverProps> {
  intervalId: number;

  componentDidMount() {
    const { user } = this.props;
    if (user) {
      this.intervalId = this.scheduleChecks();
    }

    window.addEventListener('message', this.iframeMessageListener);
  }

  componentDidUpdate(prevProps: SessionObserverProps) {
    const { user } = this.props;

    if (!user && this.intervalId) {
      window.clearInterval(this.intervalId);
      this.intervalId = null;
    } else if (!prevProps.user && user) {
      this.intervalId = this.scheduleChecks();
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalId);
    window.removeEventListener('message', this.iframeMessageListener);
  }

  /**
   * Hit /userinfo to determine if the user's session is still valid.
   */
  check = () => {
    const { user } = this.props;
    getUserManager()
      .metadataService.getUserInfoEndpoint()
      .then(endpoint => {
        axios
          .get(endpoint, {
            headers: { Authorization: `Bearer ${user.access_token}` },
          })
          .catch((error: AxiosError) => {
            if (
              error.response &&
              error.response.status &&
              error.response.status === 401
            ) {
              /**
               * If /user-info returns a 401, the session has expired and we should remove the user.
               */

              getUserManager().removeUser();
            } else {
              /**
               * Retain the user session if we get a 5xx response, or a connection error
               * from lack of pre-flight CORS headers. IDX could be
               * down, in which case we'll assume they're still logged in.
               */
            }
          });
      })
      .catch(() => {
        console.debug('Failed to retrieve user info endpoint');
      });
  };

  scheduleChecks = () => {
    this.check();
    return window.setInterval(this.check, SESSION_CHECK_INTERVAL * 1000);
  };

  /**
   * Listen for messages from the third-party-cookie-checking iframe.
   */
  iframeMessageListener = (event: MessageEvent) => {
    if (event.data === 'MM:3PCunsupported') {
    } else if (event.data === 'MM:3PCsupported') {
      // Third-party cookies are enabled, so we can safely enable silent renewal.
      getUserManager().startSilentRenew();
    }
  };

  render() {
    return (
      <iframe src={`${config.tpc}/start.html`} style={{ display: 'none' }} />
    );
  }
}

export default SessionObserver;
