import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'shared/util/axios';

interface SigningResponse {
  expiresAt: number;
  signedUrl: string;
  uploadPath: string;
}

export interface SignedUrlObject {
  signedUrl: string;
}
export interface MultipartUploadInput {
  name: string;
}

export interface MultipartUploadOutput {
  bucketKeyEnabled: boolean | null;
  bucketName: string;
  key: string;
  uploadId: string;
  abortDate: string | null;
  abortRuleId: string | null;
  requesterCharged: boolean;
  ssealgorithm: string;
  ssecustomerAlgorithm: string | null;
  ssecustomerKeyMd5: string | null;
  cccserverSideEncryption: string;
}

export interface PresignedUrlInput {
  fileId: string;
  fileKey: string;
  parts: number;
}

export interface Part {
  signedUrl: string;
  partNumber: number;
  eTag?: string;
}

export interface GetUrlsResponse {
  parts: Part[];
}

const initializeMultipart = async (
  file: File,
  siteId: string
): Promise<MultipartUploadOutput> => {
  try {
    const response = await axios.post<MultipartUploadOutput>(
      `site-builder/s3/initializeMultipartUpload?siteId=${siteId}&fileName=${file.name}`
    );
    return response.data;
  } catch (error) {
    throw new Error('Error initializing multipart upload: ' + error.message);
  }
};

const getMultipartPreSignedUrls = async (
  siteId: string,
  fileKey: string,
  parts: number,
  uploadId: string
): Promise<GetUrlsResponse> => {
  try {
    const response = await axios.post<GetUrlsResponse>(
      `site-builder/s3/getMultipartPreSignedUrls?siteId=${siteId}&fileKey=${fileKey}&parts=${parts}&uploadId=${uploadId}`
    );
    return response.data;
  } catch (error) {
    throw new Error('Error getting presigned URLs: ' + error.message);
  }
};

const sendCompleteRequest = async (
  fileId: string,
  fileKey: string,
  parts: any[]
) => {
  if (fileId && fileKey) {
    const videoFinalizationMultiPartInput = {
      parts: parts,
    };
    await axios.post(
      `site-builder/s3/finalizeMultipartUpload?fileId=${fileId}&fileKey=${fileKey}`,
      videoFinalizationMultiPartInput
    );
  }
};

const finalizeMultipart = async (
  fileId: string,
  fileKey: string,
  parts: any[]
) => {
  try {
    const videoFinalizationMultiPartInput = {
      parts: parts,
    };
    await axios.post(
      `site-builder/s3/finalizeMultipartUpload?fileId=${fileId}&fileKey=${fileKey}`,
      videoFinalizationMultiPartInput
    );
  } catch (error) {
    throw new Error('Error finalizing multipart upload: ' + error.message);
  }
};

const abortMultipart = (fileId: string, fileKey: string) => {
  try {
    axios.post(
      `site-builder/s3/abortMultipartUpload?fileId=${fileId}&fileKey=${fileKey}`
    );
  } catch (error) {
    throw new Error('Error aborting multipart upload: ' + error.message);
  }
};

const getSignedUrl = (
  file: File,
  callback: (obj: SignedUrlObject) => void,
  siteId: string
) => {
  axios
    .post<SigningResponse>(
      `site-builder/s3/sign?siteId=${siteId}&filename=${file.name}`
    )
    .then(response => {
      callback(response.data);
    })
    .catch(error => {
      toast('error retrieving signed url: ' + error, {
        type: 'error',
        theme: 'colored',
      });
    });
};

export {
  initializeMultipart,
  getMultipartPreSignedUrls,
  sendCompleteRequest,
  finalizeMultipart,
  abortMultipart,
  getSignedUrl,
};
