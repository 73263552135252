import {
  defaultBorderData,
  defaultPaddingData,
  defaultSizeLocationData,
} from 'builder/util/constants';
import HtmlWidget from './HtmlWidget';
import HtmlWidgetControl from './HtmlWidgetControl';
import { Config } from './types';

export default {
  type: 'Html',
  displayName: 'Html',
  icon: 'Code',
  initialConfig: {
    ...defaultBorderData,
    ...defaultPaddingData,
    ...defaultSizeLocationData,
    inputUrl: '',
    jsValue:
      'function myFunction() {\n\tdocument.getElementById("demo").innerHTML = "Hello World!";\n} myFunction();',
    cssValue: 'p {\n\tcolor: red;\n}',
    htmlValue: '<p id="demo"></p>',
    uniqueId: '456def',
    lineColor: 'black',
  },
  components: {
    render: HtmlWidget,
    control: HtmlWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
