import React, { ReactElement } from 'react';
import { Config } from './types';

const HtmlWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    inputUrl,
    htmlValue,
    cssValue,
    jsValue,
    borderData,
    paddingData,
    lineColor,
    height,
    width,
  } = props.widgetState.config;

  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  React.useEffect(() => {
    const iframeDocument = iframeRef.current?.contentDocument;
    const iframeWindow = iframeRef.current?.contentWindow;
    if (iframeDocument && iframeWindow) {
      if (inputUrl === '') {
        iframeDocument.open();
        iframeDocument.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <style>${cssValue}</style>
          </head>
          <body>${htmlValue}</body>
          <script>${jsValue}</script>
        </html>
      `);
        iframeDocument.close();
      } else {
        iframeDocument.open();
        iframeDocument.write(`
          <!DOCTYPE html>
          <html>
          <body> 
            <iframe width='${width}' height='${height}' src='${inputUrl}' scrolling:"no" ></iframe>
          </body>
          </html>
      `);
        iframeDocument.close();
      }
    }
  }, [htmlValue, cssValue, jsValue, inputUrl, height, width]);

  return (
    <>
      <iframe
        data-test-id="html-iframe-widget"
        style={{
          paddingTop: `${paddingData?.top}px`,
          paddingRight: `${paddingData?.right}px`,
          paddingBottom: `${paddingData?.bottom}px`,
          paddingLeft: `${paddingData?.left}px`,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          borderWidth: `${borderData?.thickness}px`,
          borderStyle: 'solid',
          borderRadius: `${borderData?.radius}px`,
          borderColor: `${lineColor}`,
          overflow: 'hidden',
        }}
        ref={iframeRef}
      />
    </>
  );
};
export default HtmlWidget;
