import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { FormControl, Select } from '@uitk/react';
import { linkDeveloperTheme as theme } from 'link-ui-react';
import ColorPicker, {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from '../ColorPicker/ColorPicker';
import { ColorButton } from '../ColorButton/ColorButton';
import { MainLabelStyles } from './Typography/Typography';
import { ColorButtonWrapper } from './Background/Background';

const BorderThicknessSelect = styled(Select)`
  font-family: OptumSans;
  width: 4rem;
  padding: 0 0.5rem 0 0;
  border-radius: 0.375rem;
  .kASQuK {
    margin-top: 0;
    font-family: OptumSans;
    line-height: 1.15rem;
    box-shadow: 0 0 0 1px ${theme.colors.aux.black};
    padding: 3px;
    padding-left: 10px;
    font-size: 14px;
  }
`;

const SpaceDiv = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding-right: 0.5rem;
  margin-left: -5px;
  align-items: center;
`;

const BorderDropdowns = styled.div`
  display: flex;
  align-items: center;
`;

export interface BorderData {
  thickness: number;
  radius: number;
}

export interface BorderProps<T> {
  borderData: (data: BorderData) => void;
  handleLineColor?: (data: string) => void;
  handleBackgroundColor?: (data: string) => void;
  widgetState: Widget<T>;
  pages?: Array<Page>;
  site?: Site;
  updateSite?: (site: Site) => void;
  saveSite?: () => void;
  currentTab?: number;
}
const borderThicknessSizes = Array.from(Array(11), (_, index) => index);

export function getThickness(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.borderData?.thickness;
    case 1:
      return widgetState.config.hoverData?.borderData?.thickness;
    default:
  }
}

export function getRadius(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.borderData?.radius;
    case 1:
      return widgetState.config.hoverData?.borderData?.radius;
    default:
  }
}

export function getLineColor(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.lineColor;
    case 1:
      return widgetState.config.hoverData?.lineColor;
    default:
  }
}

export function getBorderData(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.borderData;
    case 1:
      return widgetState.config.hoverData?.borderData;
    default:
  }
}

export const Border: React.FC<BorderProps<any>> = (props: BorderProps<any>) => {
  const {
    widgetState,
    borderData,
    handleLineColor,
    handleBackgroundColor,
    site,
    updateSite,
    saveSite,
    currentTab,
  } = props;

  const [border, setBorder] = useState(getBorderData(widgetState, currentTab));

  const [thickness, setThickness] = useState(
    getThickness(widgetState, currentTab)
  );
  const [radius, setRadius] = useState(getRadius(widgetState, currentTab));
  const [color, setColor] = useState(getLineColor(widgetState, currentTab));

  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover' : undefined;

  React.useEffect(() => {
    borderData(border);
  }, [border]);

  React.useEffect(() => {
    handleLineColor(color);
  }, [color]);

  React.useEffect(() => {
    setColor(getLineColor(widgetState, currentTab));
    setThickness(getThickness(widgetState, currentTab));
    setRadius(getRadius(widgetState, currentTab));
  }, [widgetState.config]);

  const handleThicknessChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setThickness(e.target.value);
    setBorder({
      ...border,
      thickness: parseInt(e.target.value),
    });
    borderData({
      ...border,
      thickness: parseInt(e.target.value),
    });
  };

  const handleRadiusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setRadius(e.target.value);
    setBorder({
      ...border,
      radius: parseInt(e.target.value),
    });
    borderData({
      ...border,
      radius: parseInt(e.target.value),
    });
  };

  const handleColorChange = newColor => {
    if (pickerAnchorEl === document.querySelector('#line-color')) {
      setColor(newColor);
      handleLineColor(newColor);
    }
    if (pickerAnchorEl === document.querySelector('#background-color')) {
      setColor(newColor);
      handleBackgroundColor(newColor);
    }
  };

  const handleColorEditClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    setPickerAnchorEl(e.currentTarget);
  };

  const handlePickerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  return (
    <>
      <BorderDropdowns>
        <SpaceDiv>
          <MainLabelStyles>Color</MainLabelStyles>
          <ColorButtonWrapper
            id="line-color"
            data-test-id="line-color"
            onClick={handleColorEditClick}
          >
            <ColorButton color={color} />
          </ColorButtonWrapper>
        </SpaceDiv>
        <StyledPickerPopover
          data-test-id="widget-color-picker-popover"
          id={pickerId}
          open={pickerOpen}
          onClick={handlePickerClick}
          onClose={handlePickerClose}
          anchorEl={pickerAnchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <StyledPickerWrapper>
            <StyledPickerHeader data-test-id="styled-picker-header">
              Color Selector
            </StyledPickerHeader>
            <StyledClose
              data-test-id="close-icon"
              onClick={handlePickerClose}
            />
          </StyledPickerWrapper>
          <ColorPicker
            data-test-id="divider-color"
            site={site}
            handleColorChange={handleColorChange}
            updateSite={updateSite}
            saveSite={saveSite}
            anchorEl={pickerAnchorEl}
          ></ColorPicker>
        </StyledPickerPopover>
        <MainLabelStyles>Width</MainLabelStyles>
        <FormControl
          data-test-id="border-thickness-dropdown"
          id={'controlled-select'}
        >
          <BorderThicknessSelect
            onChange={handleThicknessChange}
            value={thickness}
            name="thickness"
            data-test-id="border-thickness"
          >
            {borderThicknessSizes.map(size => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </BorderThicknessSelect>
        </FormControl>
        <MainLabelStyles>Radius</MainLabelStyles>
        <FormControl
          data-test-id="border-radius-dropdown"
          id={'controlled-select'}
        >
          <BorderThicknessSelect
            onChange={handleRadiusChange}
            value={radius}
            name="radius"
            data-test-id="border-radius"
          >
            {borderThicknessSizes.map(size => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </BorderThicknessSelect>
        </FormControl>
      </BorderDropdowns>
    </>
  );
};
