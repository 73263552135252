import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Config } from './types';

const StyledSectionWidget = styled.div``;

const SectionWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    borderData,
    backgroundData,
    backgroundImage,
    boxShadowData,
    lineColor,
    boxShadowColor,
  } = props.widgetState.config;

  return (
    <StyledSectionWidget
      id={'sectionWidget'}
      className={'sectionWidget'}
      style={{
        backgroundColor: backgroundData?.color,
        backgroundImage:
          backgroundData?.style === 'Image'
            ? `url('${backgroundImage?.url}')`
            : '',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderWidth: `${borderData?.thickness}px`,
        borderStyle: 'solid',
        borderColor: `${lineColor}`,
        borderRadius: `${borderData?.radius}px`,
        backgroundClip: 'content-box',
        boxShadow: `${boxShadowData?.horizontal}px ${boxShadowData?.vertical}px ${boxShadowData?.blurRadius}px ${boxShadowData?.spreadRadius}px ${boxShadowColor}`,
        width: '100%',
        height: '100%',
      }}
    />
  );
};
export default SectionWidget;
