import { Reducer } from 'redux';
import { ThunkActionCreator } from 'shared/state';
import api from 'shared/api/accessrequesttoadmin';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { closeAccessRequestDialog } from './ui';

export interface State {
  data: Array<AdminEmailForm>;
  meta: StateMeta;
}

export const defaultState: State = {
  data: [],
  meta: {
    pending: false,
  },
};

export const SEND_ADMIN_EMAIL_FULFILLED =
  'duplo/accessrequesttoadmin/SEND_ADMIN_EMAIL_FULFILLED';
export const SEND_ADMIN_EMAIL_PENDING =
  'duplo/accessrequesttoadmin/SEND_ADMIN_EMAIL_PENDING';
export const SEND_ADMIN_EMAIL_FAILED =
  'duplo/accessrequesttoadmin/SEND_ADMIN_EMAIL_FAILED';

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case SEND_ADMIN_EMAIL_PENDING:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: true,
        },
      };
    case SEND_ADMIN_EMAIL_FULFILLED:
      return {
        ...state,
        data: action.data,
        meta: {
          ...state.meta,
          pending: false,
          successful: true,
          error: null,
        },
      };
    case SEND_ADMIN_EMAIL_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: false,
          successful: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default reducer;

export const sendEmailToAdmin: ThunkActionCreator = (
  adminRequestForm: AdminEmailForm
) => dispatch => {
  dispatch({ type: SEND_ADMIN_EMAIL_PENDING });
  api
    .sendEmailToAdmin(adminRequestForm)
    .then(response => {
      if (response) {
        dispatch({
          type: SEND_ADMIN_EMAIL_FULFILLED,
          adminRequestForm,
        });
        dispatch(closeAccessRequestDialog());
        toast('Request has been submitted successfully', {
          type: 'success',
          theme: 'colored',
        });
      }
    })
    .catch(() => {
      dispatch({
        type: SEND_ADMIN_EMAIL_FAILED,
        error: 'Error sending an email to admin',
      });
      toast('Error sending an email to admin', {
        type: 'error',
        theme: 'colored',
      });
    });
};
