import React, { Fragment, useState } from 'react';
import {
  TableRow,
  TableCell,
  Table,
  TableHead,
  StylesProvider,
} from '@material-ui/core';
import {
  useTable,
  useFilters,
  Cell,
  CellProps,
  useSortBy,
  usePagination,
  TableColumnInstance,
  Column,
  TableOptions,
} from 'react-table';
import styled from 'styled-components';
import { Dropdown, TableFilter } from 'link-ui-react';
import { History } from 'history';
import { defaultTheme as theme } from 'link-ui-react';
import { Button as UITKButton, Label, Card } from '@uitk/react';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider } from '@material-ui/core';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import Button from '@mui/material/Button';
import { Icon } from 'link-ui-react';
import { Popover } from '@material-ui/core';
import CreateSiteDialog from 'builder/scenes/SiteBuilder/CreateSiteDialog';
import { SiteListDialogType } from 'builder/components/SiteListDialog/SiteListDialog';
import SiteListDialog from 'builder/components/SiteListDialog';
import config from 'builder/config';
import {
  defaultSortBy,
  filterByContains,
  numberSortBy,
  sortByDateFunction,
} from './SortFilter';
import {
  PaginationActionButtonsWrapper,
  PaginationButtonsWrapper,
  PaginationWrapper,
  RawActionButton,
  TextWrapper,
  createButtons,
} from './Style';
import InfoIcon from './InfoIcon';
import {
  StelliumData,
  stelliumRedirectionUrl,
} from 'shared/util/stelliumRedirection';

export const IconWrapper = styled.div<{ iconActive: boolean }>`
  cursor: pointer;
  & :hover {
    font-weight: 600;
  }
  & path[class^='fa-primary'] {
    opacity: ${p =>
      p.iconActive
        ? 'var(--fa-primary-opacity, 1)'
        : 'var(--fa-primary-opacity, 0.5)'};
    color: ${p => (p.iconActive ? 'var(--fa-primary-color, #0c55b8)' : 'none')};
  }
  & path[class^='fa-secondary'] {
    opacity: ${p =>
      p.iconActive
        ? 'var(--fa-secondary-opacity, 1)'
        : 'var(--fa-secondary-opacity, 0.3)'};
    color: ${p =>
      p.iconActive ? 'var(--fa-secondary-color, #ff612b)' : 'none'};
  }
  & :hover {
    & path[class^='fa-primary'] {
      color: var(--fa-primary-color, #0c55b8);
      opacity: var(--fa-primary-opacity, 1);
    }
    & path[class^='fa-secondary'] {
      color: var(--fa-secondary-color, #ff612b);
      opacity: var(--fa-secondary-opacity, 1);
    }
  }
`;

const DropdownWrapper = styled.div`
  padding-right: inherit;
  white-space: nowrap;
  a {
    text-decoration: none;
    min-width: 100px;
  }
`;

const StyledTable = styled(Table)`
  width: 108%;
  max-width: 108%;
  word-break: break-word;
  table-layout: fixed;
  thead {
    tr {
      th {
        div {
          input {
            font-family: OptumSans;
          }
        }
      }
    }
  }
`;

const StyledTableHeader = styled.div`
  padding: 1em;
  display: flex;
  flex-wrap: wrap;
  min-width: 900px;
  width: 100%;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TableSubHeaderWrapper = styled.div`
  font-size: 16px;
  font-family: 'OptumSans';
  color: black;
`;

const TableHeadingWrapper = styled.span`
  font-weight: bold;
  font-size: 1.75em;
  font-family: OptumSans;
  font-weight: 700;
  color: ${theme.colors.aux.darkNavyBlue};
`;

const TableBody = styled.tbody`
  & > :nth-child(2n) {
    background-color: ${p => p.theme.colors.aux.lightestGrey};
  }
`;

const StyledCell = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 0.5em;
    font-family: OptumSans;
  }
`;

const HeaderCell = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 0.5em;
    font-size: large;
    font-family: OptumSans;
    font-weight: 700;
    & > input {
      font-family: OptumSans;
    }
  }
`;

const StyledRow = styled(TableRow)`
  &:hover {
    background: #e5e6e8;
  }
`;

const StyledButton = styled(UITKButton)`
  font-family: 'OptumSans';
  height: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledLabel = styled(Label)`
  font-family: 'OptumSans';
  font-weight: normal;
  color: black;
  font-size: 14px;
  padding-right: 0.5rem;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  padding-top: 3px;
  height: 2em;
`;

const DateWrapper = styled.span`
  color: #999999;
`;

const UserWrapper = styled.span`
  color: ${p => p.theme.colors.aux.black};
`;

const StyledUITKButton = styled(UITKButton)`
  font-family: 'OptumSans';
  font-size: 13.5px;
  font-weight: bold;
  padding-left: 30px;
  padding-right: 30px;
`;

const StyledPopover = styled(Popover)`
  .MuiPaper-elevation8 {
    font-family: 'OptumSans';
    box-shadow: none;
    & > div > div {
      padding: 0;
    }
  }
`;

const StyledCardView = styled.div`
  justify-items: center;
  padding-left: 30px;
  padding-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 0.5fr);
  grid-auto-rows: auto;
  gap: 2rem;
  @media (min-width: 1600px) {
    grid-template-columns: repeat(4, 0.5fr);
  }
`;

const CardStyled = styled(Card)`
  width: 140px;
  height: auto;
`;

const StyledCard = styled.div`
  height: auto;
  width: 400px;
`;

const CardDiv = styled.div`
  position: relative;
  display: flex;
`;

const IconDiv = styled.div`
  cursor: pointer;
`;

const StyledMenuItem = styled.p`
  font-size: 13px;
  padding: 0.5em;
  cursor: pointer;
`;

const MarginLeftIcon = styled(Icon)`
  margin-left: 0.5em;
  color: ${p => p.theme.colors.aux.lightestGrey};
`;

type Data = object;

export interface SiteDataProps {
  history?: History;
  sitesData: Array<Site>;
  openCreateSiteDialog: () => void;
  publishSite?: (siteId: number) => Promise<void>;
  fetchSitesByUuid?: () => void;
  fetchSite?: (siteId: number, openDialog: boolean) => void;
  openSiteListDialog?: (
    dialogType: string,
    siteId: number,
    siteName: string
  ) => void;
  updateSite?: (siteId: number, site: Site) => void;
}

// Define a default UI for filtering
const DefaultColumnFilter = ({
  column: { setFilter },
}: {
  column: TableColumnInstance<Data>;
}) => {
  return (
    <TableFilter
      width={'50%'}
      onChange={(data: any) => {
        setFilter(data.trimLeft() || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
};

const getRow = (site: Site) => {
  return {
    id: site.id,
    siteName: site.name,
    domain: site.domain,
    createdBy: site.createdByUser || 'Unknown',
    modifiedDate:
      site.lastModifiedDate &&
      new Date(site.lastModifiedDate).toLocaleDateString(),
    lastModifiedByUser: site.lastModifiedByUser,
    publishedDate:
      site.lastPublishedDate &&
      new Date(site.lastPublishedDate).toLocaleDateString(),
    lastPublishedByUser: site.lastPublishedByUser,
    published: site.published,
  } as SiteData;
};

const renderLastEditedBy = (lastEditedByUserAndDate: string) => {
  const lastEditedByUser = lastEditedByUserAndDate.substring(
    0,
    lastEditedByUserAndDate.indexOf(' - ')
  );
  const lastEditedByDate = lastEditedByUserAndDate.substring(
    lastEditedByUserAndDate.indexOf(' - ') + 3,
    lastEditedByUserAndDate.length
  );
  return (
    <Fragment>
      <UserWrapper data-test-id={`site-table-user-date`}>
        {lastEditedByUser !== 'null' ? lastEditedByUser : 'Unknown'}
      </UserWrapper>
      <DateWrapper>
        {' - ' +
          (lastEditedByDate !== 'null'
            ? new Date(lastEditedByDate).toLocaleDateString()
            : 'No Edited Date')}
      </DateWrapper>
    </Fragment>
  );
};

const renderLastPublishedBy = (lastPublishedByUserAndDate: string) => {
  const lastPublishedByUser = lastPublishedByUserAndDate.substring(
    0,
    lastPublishedByUserAndDate.indexOf(' - ')
  );
  const lastPublishedByDate = lastPublishedByUserAndDate.substring(
    lastPublishedByUserAndDate.indexOf(' - ') + 3,
    lastPublishedByUserAndDate.length
  );
  return (
    <Fragment>
      <UserWrapper data-test-id={`site-table-user-date`}>
        {lastPublishedByUser !== 'null' ? lastPublishedByUser : 'Unknown'}
      </UserWrapper>
      <DateWrapper>
        {' - ' +
          (lastPublishedByDate !== 'null'
            ? new Date(lastPublishedByDate).toLocaleDateString()
            : 'No Published Date')}
      </DateWrapper>
    </Fragment>
  );
};

const renderDemoUrl = (url: string) => {
  let demoUrl = '';
  if (url === 'developer-stage.optumoi.com') {
    return 'developer.linkplatform-stage.com';
  } else if (url === 'developer.optumoi.com') {
    return 'developer.optumoi-demo.com';
  }

  if (!url) {
    return demoUrl;
  }
  if (url.includes('-stage')) {
    demoUrl = url.slice(0, url.length - 17) + 'linkplatform-stage.com';
  } else {
    demoUrl = url.slice(0, url.length - 4) + '-demo.com';
  }
  return demoUrl;
};

const SiteDataTable = (props: SiteDataProps) => {
  const [_selectedSite, setSelectedSite] = useState(null);
  const handleClick = () => {
    props.openCreateSiteDialog();
  };
  const handleSiteEditClick = (siteId: number) => {
    const { history, sitesData } = props;
    const site = sitesData.find(site => site.id === siteId);
    if (site?.horizonhomepageId !== null) {
      history.push(`/sites/${siteId}/`);
    } else {
      props.openSiteListDialog(
        SiteListDialogType.CREATE_PAGE,
        site.id,
        site.name
      );
    }
  };

  const routeToHomePage = (siteId: number, pageId: number) => {
    const { history, updateSite } = props;
    const site = sitesData.find(site => site.id === siteId);
    updateSite(siteId, {
      ...site,
      horizonhomepageId: pageId,
      horizonpostLoginPageId: pageId,
    });
    history.push(`/sites/${siteId}/`);
  };

  const handleUnPublishSiteClick = (site: Site) => {
    props.openSiteListDialog(SiteListDialogType.UNPUBLISH, site.id, site.name);
  };

  const handleFetchSiteClick = (site: Site) => {
    const { fetchSite } = props;
    setSelectedSite(site);
    fetchSite(site.id, true);
  };

  const onManageApplicationClick = (site: Site) => {
    const stelliumSessionData: StelliumData = {
      siteIds: [site.id],
      tenantId: site.tenantId,
      askId: site.aideId,
    };

    window.open(stelliumRedirectionUrl(stelliumSessionData), '_blank');
  };

  const handleGrantAccessButton = (record: SiteData) => {
    const { sitesData } = props;
    const currentSiteTenancy = sitesData.find(site => site.id === record.id)
      .tenantId;
    const tenantId = currentSiteTenancy ? currentSiteTenancy : 'LD';
    window.open(config.userManagementBaseUrl + `/${tenantId}/users`, '_blank');
  };

  const RenderCellAction = (record: SiteData) => {
    const currentSite = sitesData.find(site => site.id === record.id);
    const optionsList: Array<any> = [
      {
        title: 'Edit Site',
        onClick: () => handleSiteEditClick(record.id),
      },
      {
        title: 'Grant Access',
        onClick: () => {
          handleGrantAccessButton(record);
        },
      },
    ];
    if (record.published) {
      optionsList.splice(optionsList.length - 1, 0, {
        title: 'View Live Site',
        link: `https://${currentSite.domain}`,
      });
      optionsList.splice(optionsList.length - 1, 0, {
        title: 'View Demo Site',
        link: 'https://' + renderDemoUrl(currentSite.domain),
      });
      optionsList.splice(optionsList.length - 1, 0, {
        title: 'Unpublish',
        onClick: () => handleUnPublishSiteClick(currentSite),
      });
    } else {
      optionsList.splice(optionsList.length - 1, 0, {
        title: 'Delete',
        onClick: () => handleFetchSiteClick(currentSite),
      });
    }

    optionsList.splice(optionsList.length - 1, 0, {
      title: 'Manage Apps',
      onClick: () => onManageApplicationClick(currentSite),
    });

    return (
      <DropdownWrapper>
        <Dropdown
          data-test-id={`site-dropdown`}
          options={optionsList}
          iconSize="15"
        />
      </DropdownWrapper>
    );
  };

  const getColumns = (): Array<Column> => {
    return [
      {
        Header: 'ID',
        accessor: (site: SiteData) => site.id,
        id: 'id-column',
      },
      {
        Header: 'Site Name',
        accessor: (site: SiteData) => site.siteName,
      },
      {
        Header: 'Domain',
        accessor: (site: SiteData) => site.domain,
      },
      {
        Header: 'Demo URL',
        accessor: (site: SiteData) => site.domain,
        Cell: ({ cell: { value } }: CellProps<Data>) => renderDemoUrl(value),
      },
      {
        Header: 'Last Edited',
        accessor: (site: SiteData) =>
          site.lastModifiedByUser + ' - ' + site.modifiedDate,
        Cell: ({ cell: { value } }: CellProps<Data>) =>
          renderLastEditedBy(value),
      },
      {
        Header: 'Last Published',
        accessor: (site: SiteData) =>
          site.lastPublishedByUser + ' - ' + site.publishedDate,
        Cell: ({ cell: { value } }: CellProps<Data>) =>
          renderLastPublishedBy(value),
      },
      {
        Header: 'Actions',
        accessor: (site: Site) => site,
        Cell: (cell: Cell<SiteData>) => RenderCellAction(cell.row.original),
        disableFilters: true,
        disableSorting: true,
      },
    ];
  };
  const { sitesData, history } = props;
  const columnData = React.useMemo(() => getColumns(), []);
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      text: filterByContains,
    }),
    []
  );

  const sortTypes = React.useMemo(
    () => ({
      alphanumeric: defaultSortBy,
      numberSortBy: numberSortBy,
      dateSortBy: sortByDateFunction,
    }),
    []
  );

  const data = React.useMemo(
    () => sitesData.map((siteData: Site) => getRow(siteData)),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const existingDomains = sitesData.map(site => site.domain);
  const existingNames = sitesData.map(site => site.name.toLowerCase());

  const {
    page,
    getTableProps,
    headerGroups,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex },
  } = useTable(
    {
      columns: columnData,
      data,
      initialState: { pageIndex: 0, pageSize: 25 },
      defaultColumn,
      filterTypes,
      sortTypes,
    } as TableOptions<Data>,
    useFilters,
    useSortBy,
    usePagination
  );
  const [cardView, setCardView] = useState(false);
  const [siteRecord, setSiteRecord] = useState<SiteData | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleIconClick = (e: React.MouseEvent<any>, site) => {
    setAnchorEl(e.currentTarget);
    setSiteRecord({
      id: site.id,
      siteName: site.name,
      domain: site.domain,
      createdBy: site.createdByUser,
      modifiedDate: site.lastModifiedDate,
      lastModifiedByUser: site.lastModifiedByUser,
      publishedDate: site.lastPublishedDate,
      lastPublishedByUser: site.lastPublishedByUser,
      published: site.published,
    });
  };

  const onLivePress = site => {
    window.open(`https://${site.domain}`);
  };

  const onDemoPress = site => {
    window.open('https://' + renderDemoUrl(site.domain));
  };

  const pickerOpen = Boolean(anchorEl);
  const id = pickerOpen ? 'simple-popover' : undefined;

  return (
    <StylesProvider injectFirst>
      <StyledTableHeader>
        <div>
          <TableHeadingWrapper>My Sites</TableHeadingWrapper>
          <TableSubHeaderWrapper>
            Select a site to edit, view and publish.
          </TableSubHeaderWrapper>
        </div>
        <StyledWrapper>
          <StyledLabel>View:</StyledLabel>
          <IconWrapper iconActive={cardView}>
            <StyledIcon
              data-test-id="card-icon"
              icon={duotone('credit-card')}
              title="Card"
              style={{ paddingRight: '15px' }}
              onClick={() => setCardView(true)}
            />
          </IconWrapper>
          <IconWrapper iconActive={!cardView}>
            <StyledIcon
              data-test-id="table-icon"
              icon={duotone('table')}
              title="Table"
              onClick={() => setCardView(false)}
            />
          </IconWrapper>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            style={{ height: '40px' }}
          />
          <StyledButton onPress={handleClick}>+ Create New Site</StyledButton>
        </StyledWrapper>
      </StyledTableHeader>
      <CreateSiteDialog
        existingDomains={existingDomains}
        existingNames={existingNames}
      />
      <SiteListDialog routeToHomePage={routeToHomePage} />
      {!cardView ? (
        <>
          <StyledTable {...getTableProps()} data-test-id={`site-table`}>
            <TableHead key={`table-header`}>
              {headerGroups.map((headerGroup, headerIndex) => (
                <Fragment key={`frag-${headerIndex}`}>
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(
                      (column: TableColumnInstance<Data>) => {
                        return (
                          <HeaderCell
                            variant="head"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render('Header')}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <MarginLeftIcon
                                    icon={'ArrowDown'}
                                    height="0.5em"
                                    width="0.5em"
                                  />
                                ) : (
                                  <MarginLeftIcon
                                    icon={'ArrowUp'}
                                    height="0.5em"
                                    width="0.5em"
                                  />
                                )
                              ) : (
                                ''
                              )}
                            </span>
                          </HeaderCell>
                        );
                      }
                    )}
                  </TableRow>
                  <TableRow key={`filter-row`}>
                    {headerGroup.headers.map(
                      (column: TableColumnInstance<Data>, rowIndex: number) => {
                        return (
                          <StyledCell
                            key={`filter-${rowIndex}`}
                            data-test-id={`filter-${column.id
                              .toLowerCase()
                              .replace(/\s/g, '-')}`}
                          >
                            {column.canFilter ? column.render('Filter') : null}
                          </StyledCell>
                        );
                      }
                    )}
                  </TableRow>
                </Fragment>
              ))}
            </TableHead>
            <TableBody>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <StyledRow
                    {...row.getRowProps()}
                    data-test-id={`site-table-body-row-${i}`}
                  >
                    {row.cells.map(cell => {
                      return (
                        <StyledCell variant="body" {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </StyledCell>
                      );
                    })}
                  </StyledRow>
                );
              })}
            </TableBody>
          </StyledTable>
          <PaginationWrapper>
            <PaginationButtonsWrapper>
              <TextWrapper isBold={true} reducePadding={true}>
                {'Entries per page '}
              </TextWrapper>
              <IconWrapper
                iconActive={false}
                tabIndex={0}
                data-test-id="info-wrapper"
              >
                <InfoIcon />
              </IconWrapper>
              {createButtons(3, setPageSize)}
            </PaginationButtonsWrapper>
            <PaginationActionButtonsWrapper>
              <TextWrapper isBold={true} reducePadding={true}>
                {'Displaying page: '}
                {pageIndex + 1}
                {' of '}
                {`${pageOptions.length} `}
              </TextWrapper>
              <RawActionButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                isPreviousButton={true}
                tertiary={false}
              >
                <TextWrapper reducePadding={true} isBold={false}>
                  {'Previous'}
                </TextWrapper>
              </RawActionButton>
              <RawActionButton
                onClick={() => nextPage()}
                disabled={!canNextPage}
                tertiary={false}
                isPreviousButton={false}
              >
                <TextWrapper reducePadding={true} isBold={false}>
                  {'Next'}{' '}
                </TextWrapper>
              </RawActionButton>
            </PaginationActionButtonsWrapper>
          </PaginationWrapper>
        </>
      ) : (
        <>
          <Divider variant="middle" style={{ marginTop: '10px' }} />
          <StyledCardView>
            {sitesData.map((site, i) => {
              return (
                <StyledCard>
                  <Card
                    key={i}
                    data-test-id={`site-card-${i}`}
                    header={
                      <div
                        style={{
                          flexBasis: '100%',
                        }}
                      >
                        <CardDiv>
                          <Label
                            level="h2"
                            style={{
                              fontFamily: 'OptumSans',
                              color: 'black',
                              flexBasis: '100%',
                            }}
                          >
                            {site.name}
                          </Label>
                          <Button
                            variant="outlined"
                            startIcon={<RocketLaunchIcon />}
                            style={{
                              height: '20px',
                              backgroundColor: site.published
                                ? 'lightgreen'
                                : 'lightpink',
                              color: site.published ? 'green' : 'darkred',
                              border: 'none',
                              borderRadius: '10px',
                              flexBasis: '60%',
                              cursor: 'default',
                            }}
                          >
                            {site.published ? 'Published' : 'Unpublished'}
                          </Button>
                        </CardDiv>

                        <Label
                          level="h3"
                          style={{
                            fontWeight: '500',
                            fontFamily: 'OptumSans',
                            color: 'black',
                            fontSize: '13px',
                            marginTop: '10px',
                            display: 'block',
                          }}
                        >
                          Live URL: {site.domain}
                        </Label>
                        <Label
                          level="h3"
                          style={{
                            fontWeight: '500',
                            fontFamily: 'OptumSans',
                            color: 'black',
                            fontSize: '13px',
                            marginTop: '10px',
                            display: 'block',
                          }}
                        >
                          Demo URL: {renderDemoUrl(site.domain)}
                        </Label>
                      </div>
                    }
                    media={{
                      img: (
                        <img
                          src={
                            'https://cdn-stage.linkhealth.com/site-builder/horizon/CardTemplateImage.png'
                          }
                          alt="Alternate text goes here"
                          height={20}
                          width={20}
                          style={{
                            marginLeft: '25px',
                            width: '348px',
                            height: '200px',
                            borderRadius: '10px',
                          }}
                        />
                      ),
                    }}
                  >
                    <p
                      style={{
                        fontFamily: 'OptumSans',
                        fontSize: '13.5px',
                        color: 'black',
                        paddingTop: '10px',
                      }}
                    >
                      Last Edited:{' '}
                      <p style={{ color: 'gray', display: 'contents' }}>
                        {new Date(site.lastModifiedDate).toLocaleDateString()}
                      </p>{' '}
                      - {site.lastModifiedByUser}
                    </p>
                    <p
                      style={{
                        fontFamily: 'OptumSans',
                        fontSize: '13.5px',
                        color: 'black',
                        paddingBottom: '20px',
                      }}
                    >
                      Last Published:{' '}
                      <p style={{ color: 'gray', display: 'contents' }}>
                        {new Date(site.lastPublishedDate).toLocaleDateString()}
                      </p>{' '}
                      - {site.lastPublishedByUser}
                    </p>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                      <StyledUITKButton
                        variant="tertiary"
                        onPress={() => {
                          history.push(`/sites/${site.id}/`);
                        }}
                      >
                        Edit Site
                      </StyledUITKButton>
                      <div
                        title={
                          site.published
                            ? ''
                            : 'Site must be published to view live'
                        }
                      >
                        <StyledUITKButton
                          variant="tertiary"
                          disabled={!site.published}
                          onPress={() => onLivePress(site)}
                        >
                          View Live
                        </StyledUITKButton>
                      </div>
                      <div
                        title={
                          site.published
                            ? ''
                            : 'Site must be published to view demo'
                        }
                      >
                        <StyledUITKButton
                          variant="tertiary"
                          disabled={!site.published}
                          onPress={() => onDemoPress(site)}
                        >
                          View Demo
                        </StyledUITKButton>
                      </div>
                      <IconDiv
                        id={`icon-wrapper-${i}`}
                        onClick={e => handleIconClick(e, site)}
                      >
                        <Icon
                          icon="MoreVertical"
                          height={20}
                          width={15}
                          style={{ marginTop: '8px', cursor: 'pointer' }}
                        />
                      </IconDiv>
                      <StyledPopover
                        data-test-id="color-picker-popover"
                        id={id}
                        open={pickerOpen}
                        onClose={handleClose}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <CardStyled>
                          {siteRecord?.published === true ? (
                            <StyledMenuItem
                              onClick={() => handleUnPublishSiteClick(site)}
                            >
                              Unpublish
                            </StyledMenuItem>
                          ) : (
                            <StyledMenuItem
                              onClick={() => props.publishSite(site.id)}
                            >
                              Publish
                            </StyledMenuItem>
                          )}
                          <Divider variant="fullWidth" />
                          <StyledMenuItem
                            onClick={() =>
                              handleGrantAccessButton({
                                id: site.id,
                                siteName: site.name,
                                domain: site.domain,
                                createdBy: site.createdByUser,
                                modifiedDate: site.lastModifiedDate.toLocaleString(),
                                lastModifiedByUser: site.lastModifiedByUser,
                                publishedDate: site.lastPublishedDate.toLocaleString(),
                                lastPublishedByUser: site.lastPublishedByUser,
                                published: site.published,
                              })
                            }
                          >
                            Grant Access
                          </StyledMenuItem>
                          {!siteRecord?.published && (
                            <>
                              <Divider variant="fullWidth" />
                              <StyledMenuItem
                                onClick={() => handleFetchSiteClick(site)}
                              >
                                Delete
                              </StyledMenuItem>
                            </>
                          )}
                        </CardStyled>
                      </StyledPopover>
                    </div>
                  </Card>
                </StyledCard>
              );
            })}
          </StyledCardView>
        </>
      )}
    </StylesProvider>
  );
};

export default SiteDataTable;
