import { Config } from './types';
import { track } from 'shared/util/analytics/track';

const VideoUpload: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
) => {
  const { paddingData, borderData, lineColor } = props.widgetState.config;

  let image: Image;
  const renderImage =
    image ||
    (props.editing && {
      url:
        'https://cdn-stage.linkhealth.com/site-builder/horizon/video-placeholder.png',
      alt: '',
    });

  return (
    <div
      style={{
        paddingTop: `${paddingData?.top}px`,
        paddingRight: `${paddingData?.right}px`,
        paddingBottom: `${paddingData?.bottom}px`,
        paddingLeft: `${paddingData?.left}px`,
        borderWidth: `${borderData?.thickness}px`,
        borderStyle: 'solid',
        borderColor: lineColor,
        borderRadius: `${borderData?.radius}px`,
        width: '100%',
        height: '100%',
      }}
      data-test-id="video-upload"
    >
      {props.widgetState.config?.file?.url ? (
        <video style={{ height: '100%', width: '100%' }} controls>
          <source src={props.widgetState.config.file.url} />
        </video>
      ) : (
        <img
          style={{ height: '100%', width: '100%' }}
          src={
            'https://cdn-stage.linkhealth.com/site-builder/horizon/video-placeholder.png'
          }
          alt={renderImage?.alt}
          draggable={false}
        />
      )}
    </div>
  );
};

export default track()(VideoUpload);
