import { connect } from 'react-redux';
import { RootState, Dispatch } from 'shared/state';
import { selectSites, updateSite } from 'shared/state/ducks/sites';
import { openCreateSiteDialog } from 'shared/state/ducks/ui';
import SiteDataTable from './SiteDataTable';

interface SiteDataProps {
  openCreateSiteDialog: () => void;
}

const mapStateToProps = (state: RootState, ownProps: SiteDataProps) => ({
  sites: selectSites()(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openCreateSiteDialog: () => {
    dispatch(openCreateSiteDialog());
  },
  updateSite: (siteId: number, site: Site) => {
    dispatch(updateSite(siteId, site));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteDataTable);
