import DividerWidget from './DividerWidget';
import { Config } from './types';
import DividerWidgetControl from 'shared/widgets/Divider/DividerWidgetControl';
import { defaultPaddingData } from 'builder/util/constants';

export default {
  type: 'Divider',
  displayName: 'Divider',
  icon: 'Divider',
  initialConfig: {
    width: 180,
    height: 30,
    minWidth: 1,
    minHeight: 1,
    xLocation: 10,
    yLocation: 10,
    uniqueId: '123abc',
    ...defaultPaddingData,
    direction: 'Horizontal',
    thickness: '10',
    lineColor: 'red',
    enableFullScreen: false,
  },
  components: {
    render: DividerWidget,
    control: DividerWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
