import styled from 'styled-components';
import { linkDeveloperTheme } from 'link-ui-react';

const SitePage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: auto;
  max-width: 1280px;
  footer {
    min-height: 5em;
    width: 100%;
    font-family: ${p => p.theme.font};
    div {
      color: ${linkDeveloperTheme.colors.aux.darkGrey};
    }
  }
`;

export default SitePage;
