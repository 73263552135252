import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Label } from '@uitk/react/';
import { ArrowDown } from '@uitk/react-icons';
import { Icon } from 'link-ui-react';
import {
  Button as MenuButton,
  Divider,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import config from 'builder/config';
import { linkDeveloperTheme as theme } from 'link-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  Select,
  SelectChangeEvent,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';
import {
  StelliumData,
  stelliumRedirectionUrl,
} from 'shared/util/stelliumRedirection';

const TopNav = styled.div`
  top: 0;
  width: 100%;
  display: flex;
  height: 4rem;
  border-bottom: 0.1rem solid #e0e0e0;
`;

const TopNavLeft = styled.div`
  width: 100rem;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

const TopNavRight = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SelectWrapper = styled.div`
  width: 180px;
  padding-top: 0.5rem;
  & .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    display: block;
    font-family: 'OptumSans';
    border: none;
    border-radius: 0.25rem;
  }
  & > div > div {
    max-width: 10rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  & .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    fill: #717272;
  }
`;

const StyledIcon = styled(Icon)`
  margin: -0.375rem;
`;

const StyledManagePage = styled.div`
  cursor: pointer;
  font-family: OptumSans;
  font-size: 12pt;
  padding: 0.5rem;
  border-top: 1px solid #e0e0e0;
  color: #105bc8;
  text-align: center;
`;

const StyledArrow = styled(Icon)`
  width: 0.75rem;
  margin: 0.5rem;
`;

const ButtonWrapper = styled.div`
  padding-bottom: 0.75rem;
  display: contents;
  .uitk-button {
    margin-right: 0.625rem;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    font-family: OptumSans;
    font-weight: 700;
  }
`;

const StyledMenu = styled(Menu)`
  & .MuiMenuItem-root {
    font-family: OptumSans;
  }
  & .MuiTypography-body1 {
    font-family: OptumSans;
  }
  & .MuiList-padding {
    padding: 0;
  }
`;

const PublishMenu = styled(Menu)`
  margin-top: 0.5rem;
  & .MuiMenuItem-root {
    font-family: OptumSans;
  }
  & .MuiTypography-body1 {
    font-family: OptumSans;
  }
`;

const StyledPageHeading = styled.div`
  color: #000;
  font-family: OptumSans !important;
  font-size: 1.2rem;
  padding-bottom: 5px;
`;

const StyledMenuButton = styled.span`
  margin-right: 0.625rem;
  margin-top: 0.625rem;
  .MuiButton-root {
    font-family: OptumSans;
    text-transform: none;
    font-weight: 400;
  }
  .MuiButton-label {
    font-size: 1rem;
  }
`;

const StyledLabel = styled(Label)`
  padding: 0 0.25rem 0 1.25rem;
  font-family: OptumSans;
`;

const StyledDropdownOption = styled(MenuItem)`
  font-family: OptumSans !important;
  font-size: 12pt !important;
`;

const UrlDropdownWrapper = styled.div`
  width: 100%;
`;

const StyledUrlDropdown = styled(Select)`
  & .MuiSelect-select {
    font-family: OptumSans;
  }
  font-family: OptumSans;
  width: 100%;
`;
const StyledButtonUitk = styled(Button)`
  min-width: 0rem !important;
  padding: 0.75rem 1rem;
`;

export const StyledButtonUitkSave = styled(Button)`
  min-width: 0rem !important;
  :hover {
    color: rgb(255, 255, 255);
    border-color: transparent;
    background: rgb(0, 30, 94);
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 2px,
      rgb(0, 30, 94) 0px 0px 0px 5px;
    outline: none;
  }
`;

const GridIcon = styled(FontAwesomeIcon)`
  height: 2em;
  &:hover,
  &:focus {
    background: ${theme.colors.aux.skyBlue};
  }
`;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 250,
    fontSize: '12pt',
    border: '1px solid #dadde9',
    fontFamily: 'OptumSans !important',
  },
}));

const StyledSelect = styled(Select)`
  #page-select-dropdown {
    cursor: default;
  }
`;

export interface BuilderTopMenuProps {
  pages: Array<Page>;
  page?: Page;
  pageId?: number;
  isPreviewMode?: boolean;
  currentPageId?: String;
  isNewPage?: boolean;
  updateSite: (site: Site) => void;
  saveSite: () => void;
  savePage: (page: PageVersion) => void;
  toggleShowGrid?: () => void;
  siteId: number;
  closeConfigPanel?: (open: boolean) => void;
  publishSite: () => void;
  publishSiteToDemo: () => void;
  site: Site;
  canUndo?: boolean;
  canRedo?: boolean;
  onUndo?: () => void;
  onRedo?: () => void;
  whenClickedUndo?: () => void;
  whenClickedRedo?: () => void;
  fetchCompareSiteandPage?: (siteId: number) => void;
  hasChanged?: boolean;
}

export const BuilderTopMenu: React.FC<BuilderTopMenuProps> = (
  props: BuilderTopMenuProps
) => {
  const {
    pages,
    siteId,
    closeConfigPanel,
    toggleShowGrid,
    isPreviewMode,
    site,
    canUndo,
    canRedo,
    onUndo,
    onRedo,
    whenClickedUndo,
    whenClickedRedo,
    fetchCompareSiteandPage,
    pageId,
    currentPageId,
    isNewPage,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [
    publishAnchorEl,
    setPublishAnchorEl,
  ] = React.useState<null | HTMLElement>(null);
  const [checked, setChecked] = React.useState(false);
  const [pageValue, setPageValue] = React.useState(
    pageId ??
      pages.find(page => page.current.pageId === site.horizonhomepageId)?.id
  );
  const [urlDropdownValue, setUrlDropdownValue] = React.useState(0);
  const open = Boolean(anchorEl);
  const publishOpen = Boolean(publishAnchorEl);
  const history = useHistory();
  const pageDropdownRef = React.useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const handleTooltip = open => {
    setTooltipOpen(open);
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleDropdown = isOpen => {
    setDropdownOpen(isOpen);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const publishButton = document.getElementById('publish-button');
  const handlePublishClick = () => {
    setPublishAnchorEl(publishButton);
  };
  const handlePublishClose = () => {
    setPublishAnchorEl(null);
  };
  const handleCheck = (isChecked: boolean) => {
    setChecked(isChecked);
    toggleShowGrid();
  };

  const handlePageClick = (event: SelectChangeEvent<typeof pageValue>) => {
    const {
      target: { value },
    } = event;
    setPageValue(Number(value));
    setDropdownOpen(false);
  };

  const handleUrlDropdownValue = event => {
    const {
      target: { value },
    } = event;
    setUrlDropdownValue(Number(value));
  };

  const handleSaveClick = () => {
    fetchCompareSiteandPage(siteId);
  };

  const handlePublish = () => {
    const { publishSite } = props;
    publishSite();
  };

  const handlePublishToDemo = () => {
    const { publishSiteToDemo } = props;
    publishSiteToDemo();
  };

  const handleUndoWorkflow = () => {
    if (config.undoRedoEnabled) {
      onUndo();
      whenClickedUndo();
    }
  };

  const handleRedoWorkflow = () => {
    if (config.undoRedoEnabled) {
      onRedo();
      whenClickedRedo();
    }
  };

  const tenantId = site.tenantId ? site.tenantId : 'LD';

  React.useEffect(() => {
    if (!isPreviewMode) {
      closeConfigPanel(false);
      history.push(`/sites/${siteId}/pages/${pageValue}`);
    }
  }, [pageValue]);

  React.useEffect(() => {
    if (!isPreviewMode && pageId !== null) {
      setPageValue(pageId);
    }
  }, [pageId]);

  const getPreviewPath = () => {
    const { siteId, pages } = props;
    if (isNewPage) {
      return `/sites/${siteId}/preview/${
        pages.find(page => page.current.title === currentPageId).current.slug
      }`;
    } else if (pageValue && pages.find(page => page.id === pageValue)) {
      return `/sites/${siteId}/preview/${
        pages.find(page => page.id === pageValue).current.slug
      }`;
    } else {
      return `/sites/${siteId}/preview`;
    }
  };

  const handlePreview = () => {
    history.push({
      pathname: getPreviewPath(),
      state: {
        pageId: isNewPage
          ? pages.find(page => page.current.title === currentPageId)?.id
          : pageValue,
      },
    });
  };

  const handleBackToEdit = () => {
    history.push(`/sites/${siteId}/pages/${pageValue}`);
  };

  const getDemoDomain = (siteDomain: string) => {
    let demoUrl = '';
    if (siteDomain.includes('-stage')) {
      demoUrl =
        'https://' +
        siteDomain.slice(0, siteDomain.length - 17) +
        'linkplatform-stage.com';
    } else {
      demoUrl =
        'https://' + siteDomain.slice(0, siteDomain.length - 4) + '-demo.com';
    }
    return demoUrl;
  };

  const getLiveDomain = (siteDomain: string) => {
    return 'https://' + siteDomain;
  };

  const handleManageAppClick = () => {
    const stelliumSessionData: StelliumData = {
      siteIds: [site.id],
      tenantId: site.tenantId,
      askId: site.aideId,
    };
    window.open(stelliumRedirectionUrl(stelliumSessionData), '_blank');
  };

  return (
    <>
      <TopNav>
        {!isPreviewMode && (
          <TopNavLeft>
            <StyledLabel>Page:</StyledLabel>

            <HtmlTooltip
              title={
                <React.Fragment>
                  <StyledPageHeading>Switch page</StyledPageHeading>{' '}
                  <p>See all the pages on your site and switch between them.</p>
                </React.Fragment>
              }
              open={tooltipOpen}
              arrow
            >
              <SelectWrapper>
                <StyledSelect
                  id="page-select-dropdown"
                  open={dropdownOpen}
                  ref={pageDropdownRef}
                  onClick={() => {
                    handleDropdown(!dropdownOpen);
                  }}
                  onMouseEnter={() => {
                    !dropdownOpen && handleTooltip(true);
                  }}
                  onMouseLeave={() => {
                    handleTooltip(false);
                  }}
                  onOpen={() => {
                    handleTooltip(false);
                    handleDropdown(true);
                  }}
                  data-test-id="page-select-dropdown"
                  onChange={handlePageClick}
                  value={
                    isNewPage
                      ? pages.find(page => page.current.title === currentPageId)
                          ?.id
                      : pages.find(page => page.current.pageId === pageValue)
                      ? pageValue
                      : pages.find(
                          page => page.current.pageId === site.horizonhomepageId
                        )?.id
                  }
                >
                  {pages.map(
                    page =>
                      page.current.slug !== 'defaultContentPage' && (
                        <StyledDropdownOption
                          disableRipple
                          key={page.id}
                          value={page.id}
                        >
                          {page.current.title}
                        </StyledDropdownOption>
                      )
                  )}
                  <StyledManagePage
                    onClick={e => {
                      pageDropdownRef.current.click();
                      document.getElementById('pages-panel-sidebar').click();
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    Manage Pages
                  </StyledManagePage>
                </StyledSelect>
              </SelectWrapper>
            </HtmlTooltip>
            <Divider orientation="vertical" variant="middle" flexItem />
            <UrlDropdownWrapper>
              <StyledUrlDropdown
                key={'url-dropdown'}
                value={urlDropdownValue}
                onChange={handleUrlDropdownValue}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                style={{
                  borderRadius: '2rem',
                  height: '2.5rem',
                  background: '#f7f8f8',
                }}
              >
                <MenuItem value={0} style={{ fontFamily: 'OptumSans' }}>
                  Live URL: {getLiveDomain(site.domain)}
                </MenuItem>
                <MenuItem value={1} style={{ fontFamily: 'OptumSans' }}>
                  Demo URL: {getDemoDomain(site.domain)}
                </MenuItem>
              </StyledUrlDropdown>
            </UrlDropdownWrapper>
          </TopNavLeft>
        )}
        <Divider orientation="vertical" variant="middle" flexItem />
        <TopNavRight>
          {!isPreviewMode && (
            <>
              <StyledMenuButton>
                <MenuButton
                  data-test-id="more-options-button"
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  Manage Site
                  <StyledArrow icon="ArrowDownOpen" />
                </MenuButton>
              </StyledMenuButton>
              <StyledMenu
                data-test-id="more-options-menu"
                getContentAnchorEl={null}
                elevation={3}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Divider variant="middle" />
                <MenuItem onClick={handleClose} disabled={!site.access.edit}>
                  Version History
                </MenuItem>
                <MenuItem
                  href={config.userManagementBaseUrl + `/${tenantId}/users`}
                  target="_blank"
                  component="a"
                  disabled={!site.access.edit}
                >
                  Grant Access to Site
                </MenuItem>{' '}
                <MenuItem onClick={handleManageAppClick}>
                  Manage Applications
                </MenuItem>{' '}
                <Divider variant="middle" />
                <MenuItem onClick={handleClose} disabled={!site.access.delete}>
                  Unpublish Site
                </MenuItem>
              </StyledMenu>
            </>
          )}
          <Divider orientation="vertical" variant="middle" flexItem />
          <ButtonWrapper>
            {!isPreviewMode && (
              <>
                <GridIcon
                  data-test-id="grid-icon"
                  icon={duotone('game-board')}
                  onClick={() => handleCheck(!checked)}
                  style={{
                    padding: '10px',
                    marginRight: '20px',
                    display: 'flex',
                    backgroundColor: checked ? '#D9F6FA' : 'transparent',
                    color: checked ? '#002677' : '#777580',
                    borderRadius: '11px',
                  }}
                  title="Enable/disable page grid"
                />
                <div
                  title={canUndo ? 'Undo last change' : 'Nothing to undo'}
                  style={{ display: 'contents' }}
                >
                  <StyledButtonUitk
                    data-test-id="undo-button"
                    variant="tertiary"
                    aria-label="undo"
                    onClick={handleUndoWorkflow}
                    disabled={!canUndo || !site.access.edit}
                    size="s"
                  >
                    <StyledIcon icon="Undo" />
                  </StyledButtonUitk>
                </div>
                <div
                  title={canRedo ? 'Redo last change' : 'Nothing to redo'}
                  style={{ display: 'contents' }}
                >
                  <StyledButtonUitk
                    data-test-id="redo-button"
                    variant="tertiary"
                    onClick={handleRedoWorkflow}
                    disabled={!canRedo || !site.access.edit}
                    size="s"
                  >
                    <StyledIcon icon="Redo" />
                  </StyledButtonUitk>
                </div>
              </>
            )}

            <StyledButtonUitkSave
              data-test-id="save-button"
              variant="secondary"
              onPress={handleSaveClick}
              disabled={!site.access.edit}
            >
              Save
            </StyledButtonUitkSave>
            {isPreviewMode ? (
              <StyledButtonUitkSave
                data-test-id="back-to-edit-button"
                variant="secondary"
                onPress={handleBackToEdit}
              >
                Back to Edit
              </StyledButtonUitkSave>
            ) : (
              <StyledButtonUitkSave
                data-test-id="preview-button"
                variant="secondary"
                onPress={handlePreview}
              >
                Preview
              </StyledButtonUitkSave>
            )}

            <StyledButtonUitkSave
              data-test-id="publish-button"
              id="publish-button"
              onPress={handlePublishClick}
              icon={<ArrowDown />}
              disabled={!site.access.edit}
            >
              Publish
              <PublishMenu
                data-test-id="publish-menu"
                getContentAnchorEl={null}
                elevation={3}
                anchorEl={publishAnchorEl}
                open={publishOpen}
                onClose={handlePublishClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                MenuListProps={{
                  'aria-labelledby': 'publish-button',
                }}
              >
                <MenuItem onClick={handlePublishToDemo}>
                  Publish to Demo
                </MenuItem>
                <MenuItem onClick={handlePublish}>Publish to Live</MenuItem>
              </PublishMenu>
            </StyledButtonUitkSave>
          </ButtonWrapper>
        </TopNavRight>
      </TopNav>
    </>
  );
};
