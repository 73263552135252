import { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import URI from 'urijs';

export interface OrgObserverProps extends RouteComponentProps<{}> {
  setActiveOrgId: (orgId: string) => void;
}

/**
 * Listen to route changes and update the active org whenever the `linkOrgId`
 * query param is present.
 */
class OrgObserver extends PureComponent<OrgObserverProps> {
  componentDidMount() {
    this.checkForOrgId();
  }

  componentDidUpdate() {
    this.checkForOrgId();
  }

  checkForOrgId = () => {
    const { location, setActiveOrgId } = this.props;
    const { linkOrgId } = URI.parseQuery(location.search) as {
      linkOrgId?: string;
    };
    if (linkOrgId) {
      setActiveOrgId(linkOrgId);
    }
  };

  render() {
    return null as null;
  }
}

export default OrgObserver;
