import React, { ReactElement } from 'react';
import { Config } from './types';

const DividerWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const { paddingData, lineColor } = props.widgetState.config;
  return (
    <>
      <div
        className="dividerFormatting"
        style={{
          paddingTop: `${paddingData?.top}px`,
          paddingRight: `${paddingData?.right}px`,
          paddingBottom: `${paddingData?.bottom}px`,
          paddingLeft: `${paddingData?.left}px`,
          backgroundColor: `${lineColor ? lineColor : 'red'}`,
          backgroundClip: 'content-box',
          width: '100%',
          height: '100%',
        }}
      />
    </>
  );
};

export default DividerWidget;
