import axios from 'shared/util/axios';
import env from 'builder/config';

const version = env.permissionsEnabled ? 'v3' : 'v2';

const saveSiteProvisioningStores = (
  siteId: number,
  siteProvisioningStores: Array<number>
) => {
  return axios
    .post<Site>(`site-builder/${version}/sites/${siteId}/provisioningStores`, {
      siteProvisioningStores: siteProvisioningStores,
    })
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

const removeSiteProvisioningStores = (
  siteId: number,
  siteProvisioningStores: Array<number>
) => {
  return axios
    .delete(`site-builder/${version}/sites/${siteId}/provisioningStores`, {
      data: { siteProvisioningStores: siteProvisioningStores },
    })
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
};

export { saveSiteProvisioningStores, removeSiteProvisioningStores };
