import { PureComponent } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface FailureProps {
  /**
   * Custom message to display. A generic message will be shown otherwise.
   */
  message?: string;
}

class Failure extends PureComponent<FailureProps> {
  render() {
    const { message: userMessage } = this.props;
    const message = userMessage || 'An error has occurred.';

    return (
      <Wrapper>
        <Error color="error" />
        <Typography variant="body1">{message}</Typography>
      </Wrapper>
    );
  }
}

export default Failure;
