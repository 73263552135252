import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { StyledTextInput } from '../WidgetSizeLocation';
import { SmallLabelStyles } from './Typography/Typography';

const PaddingInputs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface PaddingData {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

export interface PaddingProps<T> {
  paddingData: (data: PaddingData) => void;
  widgetState: Widget<T>;
  currentTab?: number;
}

export function getPaddingTop(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.paddingData.top;
    case 1:
      return widgetState.config.hoverData.paddingData.top;
    default:
  }
}

export function getPaddingBottom(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.paddingData.bottom;
    case 1:
      return widgetState.config.hoverData.paddingData.bottom;
    default:
  }
}

export function getPaddingRight(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.paddingData.right;
    case 1:
      return widgetState.config.hoverData.paddingData.right;
    default:
  }
}

export function getPaddingLeft(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.paddingData.left;
    case 1:
      return widgetState.config.hoverData.paddingData.left;
    default:
  }
}

export const Padding: React.FC<PaddingProps<any>> = (
  props: PaddingProps<any>
) => {
  const { widgetState, currentTab, paddingData } = props;

  const data: PaddingData = {
    top: getPaddingTop(widgetState, currentTab),
    bottom: getPaddingBottom(widgetState, currentTab),
    left: getPaddingLeft(widgetState, currentTab),
    right: getPaddingRight(widgetState, currentTab),
  };
  const [padding, setPadding] = useState(data);

  const handlePaddingData = () => {
    paddingData(padding);
  };

  const handlePaddingChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPadding(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    setPadding(data);
  }, [widgetState.config]);

  return (
    <>
      <PaddingInputs>
        <Wrapper>
          <SmallLabelStyles>Top</SmallLabelStyles>
          <StyledTextInput
            name="top"
            data-test-id="top-padding-text-box"
            value={padding.top}
            onChange={handlePaddingChange}
            onBlur={handlePaddingData}
          />
        </Wrapper>
        <Wrapper>
          <SmallLabelStyles>Bottom</SmallLabelStyles>
          <StyledTextInput
            name="bottom"
            data-test-id="bottom-padding-text-box"
            value={padding.bottom}
            onChange={handlePaddingChange}
            onBlur={handlePaddingData}
          />
        </Wrapper>
        <Wrapper>
          <SmallLabelStyles>Left</SmallLabelStyles>
          <StyledTextInput
            name="left"
            data-test-id="left-padding-text-box"
            value={padding.left}
            onChange={handlePaddingChange}
            onBlur={handlePaddingData}
          />
        </Wrapper>
        <Wrapper>
          <SmallLabelStyles>Right</SmallLabelStyles>
          <StyledTextInput
            name="right"
            data-test-id="right-padding-text-box"
            value={padding.right}
            onChange={handlePaddingChange}
            onBlur={handlePaddingData}
          />
        </Wrapper>
      </PaddingInputs>
    </>
  );
};
