import { SFC } from 'react';
import { TextField } from '@material-ui/core';
import { FieldRenderProps } from 'react-final-form';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField';

interface FormOutlinedTextFieldProps
  extends OutlinedTextFieldProps,
    FieldRenderProps {}

const FormOutlinedTextField: SFC<FormOutlinedTextFieldProps> = ({
  input: { name, onChange, value, ...restInput },
  meta,
  helperText,
  ...rest
}) => (
  <TextField
    {...rest}
    name={name}
    helperText={(meta.touched && meta.error) || helperText}
    error={meta.error && meta.touched}
    inputProps={restInput}
    onChange={onChange}
    value={value}
    variant="outlined"
  />
);

export default FormOutlinedTextField;
