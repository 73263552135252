import { Dispatch, RootState } from 'shared/state';
import FormTypesList from './FormTypesList';
import { connect } from 'react-redux';
import {
  fetchFormTypes,
  selectFormTypes,
  selectFormTypesMeta,
} from 'shared/state/ducks/forms';
import { selectSites } from 'shared/state/ducks/sites';

const mapStateToProps = (state: RootState) => ({
  formTypes: selectFormTypes()(state),
  formTypesMeta: selectFormTypesMeta()(state),
  sites: selectSites()(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFormTypes: (siteIds: Array<number>) => {
    dispatch(fetchFormTypes(siteIds));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormTypesList);
