import React, { ReactElement, useEffect, useState } from 'react';
import { Config } from './types';
import { Button, Link, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown, MoreVert } from '@material-ui/icons';
import styled from 'styled-components';
import {
  doSignInWithLocationAndIdp,
  doSignInWithPostLoginPageAndIdp,
  getUserManager,
} from 'client/util/userManager';
import { useLocation } from 'react-router-dom';
import { BorderData } from 'builder/components/WidgetConfigurations/Border';
import { PaddingData } from 'builder/components/WidgetConfigurations/Padding';
import { BackgroundData } from 'builder/components/WidgetConfigurations/Background/Background';
import { User } from 'shared/state/misc/oidc';

const ButtonWrapper = styled.div<{
  width: number;
  height: number;
  linkColor: string;
  fontSize: number;
  font: string;
  signInType: string;
  buttonColor: string;
  bold: boolean;
  italic: boolean;
  underline: boolean;
  strikeThrough: boolean;
  borderData: BorderData;
  paddingData: PaddingData;
  backgroundData: BackgroundData;
  background: string;
  backgroundImage: Image;
  lineColor: string;
}>`
  border-width: ${p => p.borderData?.thickness}px;
  border-style: solid;
  border-color: ${p => p.lineColor};
  border-radius: ${p => p.borderData?.radius}px;
  background-clip: content-box;
  padding: ${p => p.paddingData?.top}px ${p => p.paddingData?.right}px
    ${p => p.paddingData?.bottom}px ${p => p.paddingData?.left}px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background: ${p => p.background};
  background-image: ${p =>
    p.backgroundData?.style === 'Image'
      ? `url('${p.backgroundImage?.url}')`
      : ''};
  & #options-button {
    background-color: ${p =>
      p.signInType === 'button' ? p.buttonColor : 'transparent'};
    margin: 15px;
    font-family: ${p => p.font};
    font-size: ${p => p.fontSize}pt;
    text-transform: none;
    width: ${p => p.width - 30}px;
    height: ${p => p.height - 30}px;
    color: ${p => p.linkColor};
    font-weight: ${p => p.bold && '700'};
    font-style: ${p => p.italic && 'italic'};
    text-decoration: ${p => p.underline && 'underline'}
      ${p => (p.underline && p.strikeThrough ? ' ' : '')}
      ${p => p.strikeThrough && 'line-through'};
  }
`;

const StyledMenu = styled(Menu)<{
  linkColor: string;
  fontSize: number;
  font: string;
  bold: boolean;
  italic: boolean;
  underline: boolean;
  strikeThrough: boolean;
  borderData: BorderData;
  paddingData: PaddingData;
  background: string;
  backgroundData: BackgroundData;
  backgroundImage: Image;
  lineColor: string;
}>`
  border-width: ${p => p.borderData?.thickness}px;
  border-style: solid;
  border-color: ${p => p.lineColor};
  border-radius: ${p => p.borderData?.radius}px;
  background-clip: content-box;
  padding-top: ${p => p.paddingData?.top}px;
  padding-right: ${p => p.paddingData?.right}px;
  padding-bottom: ${p => p.paddingData?.bottom}px;
  padding-left: ${p => p.paddingData?.left}px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background: ${p => p.background};
  background-image: ${p =>
    p.backgroundData?.style === 'Image'
      ? `url('${p.backgroundImage?.url}')`
      : ''};
  & ul[aria-labelledby^='options-button'] > li {
    font-weight: ${p => p.bold && '700'};
    font-style: ${p => p.italic && 'italic'};
    font-family: ${p => p.font};
    font-size: ${p => p.fontSize}pt;
    color: ${p => p.linkColor};
    text-decoration: ${p => p.underline && 'underline'}
      ${p => (p.underline && p.strikeThrough ? ' ' : '')}
      ${p => p.strikeThrough && 'line-through'};
    & > a {
      text-decoration: ${p =>
          p.underline && !p.strikeThrough ? 'underline' : 'none'}
        ${p => (p.underline && p.strikeThrough ? ' ' : '')}
        ${p => p.strikeThrough && 'line-through'};
      color: ${p => p.linkColor};
      font-weight: ${p => p.bold && '700'};
      font-style: ${p => p.italic && 'italic'};
    }
  }
`;
const StyledMenuItem = styled(MenuItem)``;

export interface LoginButtonProps {
  initiateLogout: () => void;
}

const LoginButton: React.FC<WidgetRenderProps<Config> & LoginButtonProps> = (
  props: WidgetRenderProps<Config> & LoginButtonProps
): ReactElement => {
  const {
    signInIcon,
    signInType,
    signInOptionEnabled,
    signInOptionLinks,
    signInOptionTitle,
    width,
    height,
    signInLinkColor,
    font,
    fontSize,
    buttonColor,
    bold,
    italic,
    underline,
    strikeThrough,
    borderData,
    paddingData,
    backgroundData,
    backgroundImage,
    lineColor,
    simulateLoggedOffUser,
  } = props.widgetState.config;

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchorEl);
  const [loggedInUser, setRealUser] = React.useState<User | undefined>(
    props.user
  );

  useEffect(() => {
    const slug = location.pathname;
    if (simulateLoggedOffUser) {
      setRealUser(undefined);
    } else {
      setRealUser(props.user);
    }
    if (!props.editing && !slug.includes('preview')) {
      setRealUser(props.user);
    }
  }, [simulateLoggedOffUser, props.editing, props.user]);

  const location = useLocation();

  const handleMenuClick = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    if (!props.editing) {
      setMenuAnchorEl(e.currentTarget);
    }
  };

  const handleButtonClick = (target: SignInLink['type']) => {
    const slug = location.pathname;
    if (
      slug.includes(props.currentPage?.pageId.toString()) ||
      slug.includes('preview')
    ) {
      return;
    } else {
      const currentPage = props.currentPage;
      currentPage && currentPage.authentication
        ? doSignInWithLocationAndIdp(location, target)
        : doSignInWithPostLoginPageAndIdp(location, target);
    }
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleButtonMenuClick = (
    target: SignInLink['type'],
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    if (signInOptionEnabled === 'enabled') {
      setMenuAnchorEl(e.currentTarget);
    } else {
      if (target !== 'custom') {
        const slug = location.pathname;
        if (
          slug.includes(props.currentPage?.pageId.toString()) ||
          slug.includes('preview')
        ) {
          return;
        } else {
          const currentPage = props.currentPage;
          currentPage && currentPage.authentication
            ? doSignInWithLocationAndIdp(location, target)
            : doSignInWithPostLoginPageAndIdp(location, target);
        }
      } else {
        return;
      }
    }
  };

  const getRedirectUri = () => {
    if (!props.site.postLogoutPage) {
      return `https://${props.site.domain}/home`;
    } else if (
      props.site.postLogoutPage.url &&
      props.site.postLogoutPage.url !== ''
    ) {
      return props.site.postLogoutPage.url;
    } else {
      const postLogoutSlug = props.pages?.find(
        page => page.current?.pageId === props.site?.postLogoutPage.pageId
      ).current.slug;
      return `https://${props.site.domain}/${postLogoutSlug}`;
    }
  };

  const handleLogout = () => {
    if (loggedInUser && !props.editing) {
      props.initiateLogout();
      getUserManager().signoutRedirect({
        post_logout_redirect_uri: getRedirectUri(),
      });
    } else {
      return;
    }
  };

  const menuItems = () => {
    if (!loggedInUser) {
      if (signInOptionEnabled === 'enabled') {
        return [
          <StyledMenuItem
            key={'default-item'}
            data-test-id="default-menu-item"
            disabled
            disableRipple
          >
            {signInOptionTitle}
          </StyledMenuItem>,
          signInOptionLinks?.map((link, index) => {
            return (
              <StyledMenuItem key={index}>
                <Link
                  data-test-id="menu-item"
                  href={link.type === 'custom' && link.url}
                  target={link.openInNewTab && '_blank'}
                  rel="noopener"
                  onClick={() => handleButtonClick(link.type)}
                >
                  {link.label}
                </Link>
              </StyledMenuItem>
            );
          }),
        ];
      } else {
        return;
      }
    } else {
      return [
        <StyledMenuItem>
          <Link
            key={'menu-item'}
            data-test-id="menu-item"
            href={`https://${props.site?.domain}/profile`}
            target="_blank"
            rel="noopener"
          >
            Profile
          </Link>
        </StyledMenuItem>,
        <StyledMenuItem onClick={handleLogout}>Sign Out</StyledMenuItem>,
      ];
    }
  };

  /* doesn't actually update "width" property, but helps ensure proper rendering if user 
      has a long name that goes beyond the width of the widget */
  const actualWidth = () => {
    if (signInType === 'button') {
      if (loggedInUser) {
        return width + (loggedInUser?.profile.name.length * fontSize) / 2;
      } else {
        return width;
      }
    }
    return width;
  };

  function background() {
    if (backgroundData.style === 'Color') {
      return backgroundData?.color;
    }
    if (backgroundData.style === 'Image') {
      return `url('${backgroundImage?.url}')`;
    }
    return 'transparent';
  }

  return (
    <>
      <ButtonWrapper
        data-test-id="login-button-widget"
        borderData={borderData}
        backgroundData={backgroundData}
        background={background()}
        paddingData={paddingData}
        backgroundImage={backgroundImage}
        bold={bold}
        italic={italic}
        underline={underline}
        strikeThrough={strikeThrough}
        buttonColor={loggedInUser ? 'transparent' : buttonColor}
        signInType={signInType}
        width={actualWidth()}
        height={height}
        linkColor={signInLinkColor}
        font={font}
        fontSize={fontSize}
        lineColor={lineColor}
      >
        {!loggedInUser ? (
          <Button
            data-test-id="login-button"
            disableRipple
            id="options-button"
            variant={'text'}
            color="primary"
            aria-controls={open ? 'options-menu' : undefined}
            aria-haspopup="true"
            disableElevation
            aria-expanded={open ? 'true' : undefined}
            onClick={e =>
              handleButtonMenuClick(props.site?.idpHint || 'portal-login', e)
            }
          >
            Sign In
          </Button>
        ) : (
          <Button
            data-test-id="user-button"
            disableRipple
            id="options-button"
            variant={signInType === 'button' ? 'contained' : 'text'}
            color="primary"
            endIcon={
              signInIcon === 'MoreVertical' ? (
                <MoreVert width={`${fontSize}px`} />
              ) : (
                <ArrowDropDown width={`${fontSize}px`} />
              )
            }
            aria-controls={open ? 'options-menu' : undefined}
            aria-haspopup="true"
            disableElevation
            aria-expanded={open ? 'true' : undefined}
            onClick={handleMenuClick}
          >
            {loggedInUser?.profile.name}
          </Button>
        )}
      </ButtonWrapper>

      <StyledMenu
        data-test-id="login-button-menu"
        borderData={borderData}
        backgroundData={backgroundData}
        background={background()}
        paddingData={paddingData}
        backgroundImage={backgroundImage}
        bold={bold}
        italic={italic}
        underline={underline}
        strikeThrough={strikeThrough}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        linkColor={signInLinkColor}
        font={font}
        fontSize={fontSize}
        id="options-menu"
        open={open}
        MenuListProps={{ 'aria-labelledby': 'options-button' }}
        anchorEl={menuAnchorEl}
        onClose={handleMenuClose}
        lineColor={lineColor}
      >
        {menuItems()}
      </StyledMenu>
    </>
  );
};
export default LoginButton;
